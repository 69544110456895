import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../password.match';

@Injectable({
    providedIn: 'root'
})
export class RegisterFormBuilder{
    pwdPattern = "^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,12}$";
    private form;
    private validationRules = {
        firstname: ['',Validators.required],
        lastname: ['',Validators.required],
        city_id: ['',Validators.required],
        role_id: ['',''],
        start_datetime:['',Validators.required],
        first_couple_type:['',Validators.required],
        state_id: ['', Validators.required],
        weddingId: ['',''],
        email: ['', [Validators.required, Validators.email]],
        //password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-8])(?=.*["$@$!%*?&#^)(+"])[A-Za-z\d$@$!%*?&#)(+^].{8,}')]], 
        password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-8])(?=.*?[#?)+(!@$%^&*-]).{8,}$')]],
        terms: [false, Validators.requiredTrue],
        google_provider_id:['',''],
        fb_provider_id:['',''],  
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    
}