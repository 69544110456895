import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { VendorBusinessInfoModel } from 'src/app/models/vendor.business.info.model';
import { CustomValidator } from '../../../customvalidation';

@Injectable({
    providedIn: 'root'
})
export class BusinessFormBuilder{

    private form;
    private validationRules = {
        business_name: ['',Validators.required],
        business_description: ['',Validators.required],
        business_contact_person: ['',Validators.required],
        business_email: ['',Validators.required],
        business_phone: ['',[Validators.required,CustomValidator.numeric]],
        business_secondary_no: ['', [CustomValidator.numeric]],
        website: ['','']       
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:VendorBusinessInfoModel){
        this.form.patchValue({
            business_name:response.business_name,
            business_description:response.business_description,
            business_contact_person:response.business_contact_person,
            business_email:response.business_email,
            business_phone:response.business_phone,
            business_secondary_no:response.business_secondary_no,
            website:response.website
        });
    }
}