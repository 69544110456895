import { Deseriazable } from './deserializable.model';

export class RegisterModel implements Deseriazable{
    
    id: string;
    token: string;
    username: string;
    password: string;
    confirm_password: string;
    business_name: string;
    business_type: string;
    category_id: string;
    firstname: string;
    lastname: string;
    address: string;
    city_id: string;
    zip_code: string;
    phone: string;
    website: string;
    mobile: string;
    email: string;
    terms: string;
    region_id: string;
    role_id: string;
    question: any;
    weddingId: number;
    google_provider_id:any;
    fb_provider_id:any;



    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}