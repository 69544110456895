import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ChecklistService } from '../services/checklist.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PlannersService } from '../services/planners.service';
import { Router } from '@angular/router';
import { VendorsService } from '../../vendors/services/vendors.service';
import { Config } from '../../../config';
import { async } from '@angular/core/testing';
import { LocationsService } from '../../locations/services/locations.service';
import { LocationModel } from 'src/app/models/location.model';
import { RegionModel } from 'src/app/models/region.model';
declare var $: any;
@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.css']
})
export class ChecklistComponent implements OnInit {
  modalHeading: any;
  modalBtn: any;
  submitted = false;
  checkLists: any;
  categorys: any;
  public AddChecklistForm: FormGroup;
  activeUser: any;
  weddingCheckLists: any;
  allChecklist: any;
  updateData: any;
  moveToTrash: any;
  index: number;
  status: any = 0;
  categoryId: any = null;
  isTrash: any = 0;
  afterWedding: any = null;
  dueNow: any = null;
  listHeading: any = 'To Do';
  toDoStatus: boolean = true;
  countCheckLists: any;
  completed: number;
  countCategoryCheckLists: any;
  deleteModalText: string = '';
  deleteModalBtn: string = 'Remove Task';
  selectCategory: boolean[] = [];
  statusText: string = 'To Do';
  ByWhenText: string = '';
  ByCategoryText: string = '';
  vendorCategorys: any;
  VendorCategoryName: any = '';
  VendorCategory: boolean = false;
  VendorCategoryDrop: boolean = true;
  searchVendorName: any;
  VendorChecked: any = 2;
  toggle: boolean = false;
  searchValue: any;
  vendorCategoryId: any;
  selectedVendor: any;
  searchDiv: boolean = false;
  showSelectedVendor: boolean = false;
  countMonthWiseCheckLists: any;
  selectMonth: boolean[] = [];
  selectedMonth: any = '';
  ByMonthText: string = '';
  progressData: any;
  getCountByWhen: any;
  hired: number;
  selectedSaveVendor: any;
  saveVendorList: boolean = false;
  public AddVendorForm: FormGroup;
  regions: any;
  locationModel = new LocationModel();
  cities: any;
  vendor_submitted: boolean = false;
  vendorName: any;
  emailRegEx = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  selectedHairedVendor: any;
  vendorData: any;
  isHaired: any;

  chooseVendor: boolean[] = [];

  activeModels: any[] = [];
  openConfirm: boolean = false;

  saveVendorIsPresent: boolean = false;
  hairedVendorIsPresent: boolean = false;
  weddings: any;
  constructor(private checklistService: ChecklistService,
    private toastr: ToastrService, public formBuilder: FormBuilder,
    private plannersService: PlannersService, public router: Router,
    private vendorService: VendorsService, private locationService: LocationsService) {
    this.AddChecklistForm = this.formBuilder.group({
      task_name: ['', Validators.required],
      task_description: [''],
      task_date: ['', Validators.required],
      category_name: ['', Validators.required],
      note: [''],
      vendor_category_name: [''],
      vendor_id: [''],
      vendor_detail_id: [''],
    });


    this.AddVendorForm = this.formBuilder.group({
      vendor_name: ['', Validators.required],
      city_id: ['', Validators.required],
      state_id: ['', Validators.required],
      phone_number: [''],
      email: ['', Validators.compose([Validators.pattern(this.emailRegEx)])],
    });

    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }

    },
      (err) => {
        console.error(err);
      });
  }

  async ngOnInit() {
    if (this.activeUser === null) {
      await this.getWeddings();
    }
    await this.getProgress();
    await this.defaultCategorys();
    await this.getVendorCategorys();
    await this.countCheckList();
    await this.countMonthWiseCheckList();
    await this.countCategoryCheckList();
    await this.filterChecklist();
    await this.countByWhen();
    // await this.categoryCountChecklist();
    $('#basicExampleModal').on('hidden.bs.modal', () => {
      this.searchVendorName = null;
      this.searchVendorName = '';
      this.toggle = false;
      if (this.openConfirm == false) {
        this.modelOpen();
        // this.changeVendorCategory(this.AddChecklistForm.value.category_name);
        $('#addNewTask').modal('toggle');
      }
    });

    $('#vendorConfirmModal').on('hidden.bs.modal', () => {
      this.modelOpen();
      $('#addNewTask').modal('toggle');

    });

    $('#addVendor').on('hidden.bs.modal', () => {
      this.modelOpen();
      $('#addNewTask').modal('toggle');

    });

    $('#addNewTask').on('hidden.bs.modal', () => {
      if (this.AddChecklistForm.invalid && this.modalBtn == 'Update Task') {
        $('#addNewTask').modal('toggle');
        $('#update').click();
        return;
      }
      // this.selectedSaveVendor = null;
      this.submitted = false;
      this.VendorCategory = false;
      this.showSelectedVendor = false;
      this.saveVendorList = false;
      // this.saveVendorIsPresent = false;
      // this.selectedHairedVendor = null;
    });

    this.locationService
      .getRegions("")
      .subscribe(
        (response: RegionModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.regions = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  async getWeddings() {
    var allUsers = await this.plannersService.getWeddings().toPromise();
    this.weddings = allUsers;
    this.plannersService.storeWeddings(this.weddings);
    var weddings = this.weddings.filter((x: any) => x.is_active == 1);
    var result = null;
    if (weddings.length > 0) {
      result = weddings[0];
      this.activeUser = result;
      this.plannersService.storeActiveUser(result);
    }
  }

  async modelOpen() {
    this.openConfirm = false;
    await this.changeVendorCategory(this.AddChecklistForm.value.category_name);
  }


  getRegionWiseCities(region_id: number) {
    this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response: LocationModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }




  get f() {
    return this.AddChecklistForm.controls;

  }

  get g() {
    return this.AddVendorForm.controls;

  }



  download() {
    var authkey = localStorage.getItem("USER_TOKEN");
    window.open(`${Config.apiBaseUrl}/checklist/download_checklist/${this.activeUser.id}?token=${authkey}`);
    return;
  }

  async categoryCountChecklist() {
    if (this.countCategoryCheckLists) {
      this.countCategoryCheckLists.forEach((o, i) => {
        const index = this.vendorCategorys.findIndex((e) => e.id == o.category_id);
        this.vendorCategorys[index]['total'] = o.total;
      });

    }
  }

  async clickCategory(e: any, j: number) {
    this.categoryId = e.category_id;
    this.selectCategory = [];
    this.selectCategory[this.categoryId] = !this.selectCategory[this.categoryId];
    if (e.name) {
      this.ByCategoryText = '(' + e.name + ')';
    } else {
      this.ByCategoryText = '(' + 'Other' + ')';
    }
    // this.categorys.splice(j, 0, cat);
    //   this.categorys.splice(j+1, 1);
    this.filterChecklist();
    await this.countCheckList();
    await this.countMonthWiseCheckList();
    await this.countByWhen();
  }

  async setFilter() {
    await this.countCheckList();
    await this.countMonthWiseCheckList();
    await this.countCategoryCheckList();
    await this.countByWhen();
    // await this.categoryCountChecklist();
  }

  async clickMonth(e: any, m: number) {
    this.dueNow = null;
    this.afterWedding = null;
    this.selectedMonth = e.date;
    this.selectMonth = [];
    this.selectMonth[e.date] = !this.selectMonth[e.date];
    this.ByWhenText = e.date;
    this.filterChecklist();
    await this.countCheckList();
    await this.countCategoryCheckList();

  }

  async getProgress() {
    var result = await this.checklistService.getProgressCategorys(this.activeUser.id).toPromise();
    this.progressData = result;

    if (this.progressData.total != 0) {
      var p = this.progressData.total_complet / this.progressData.total * 100;
    } else {
      var p = 0;
    }
    this.completed = Math.round(p);
  }

  async defaultCheckList() {
    var result = await this.checklistService.getDefaultCheckList().toPromise();
    this.checkLists = result;
  }

  async countByWhen() {

    let data = {
      id: this.activeUser.id,
      status: this.status,
      catagory_id: this.categoryId,
      is_trash: this.isTrash,
    };
    var byWhen = await this.checklistService.getCountByWhen(data).toPromise();
    this.getCountByWhen = byWhen.data;
  }

  async countCheckList() {

    let data = {
      id: this.activeUser.id,
      status: this.status,
      due_now: this.dueNow,
      after_wedding: this.afterWedding,
      catagory_id: this.categoryId,
      date_month_year: this.selectedMonth,
      is_trash: this.isTrash,

    };

    var result = await this.checklistService.getCountCheckList(data).toPromise();
    this.countCheckLists = result;
  }

  async countCategoryCheckList() {
    let data = {
      id: this.activeUser.id,
      status: this.status,
      due_now: this.dueNow,
      after_wedding: this.afterWedding,
      catagory_id: this.categoryId,
      date_month_year: this.selectedMonth,
      is_trash: this.isTrash,

    };
    var countCategory = await this.checklistService.getCountCategoryCheckList(data).toPromise();
    this.countCategoryCheckLists = countCategory;
  }

  async countMonthWiseCheckList() {
    let data = {
      id: this.activeUser.id,
      status: this.status,
      catagory_id: this.categoryId,
      is_trash: this.isTrash,
    };
    var monthCategory = await this.checklistService.getCountMonthWiseCheckList(data).toPromise();
    this.countMonthWiseCheckLists = monthCategory;
    const catLength = this.countMonthWiseCheckLists.length;
    for (let idx = 0; idx < catLength; idx++) {
      this.selectMonth[idx] = false;
    }
  }


  async defaultCategorys() {
    var category = await this.checklistService.getCategoryList().toPromise();
    this.categorys = category;
  }

  async getVendorCategorys() {
    var vendorCategory = await this.checklistService.getVendorCategorys().toPromise();
    this.vendorCategorys = vendorCategory;
    var other = {
      id: 0,
      name: 'Other',
      type: 'vendor'
    };
    this.vendorCategorys.push(other);
  }

  async filterChecklist() {
    if (this.statusText == '' && this.ByWhenText == '' && this.ByCategoryText == '' && this.ByMonthText == '') {
      this.listHeading = 'All';
    } else {
      this.listHeading = this.statusText + ' ' + this.ByWhenText + ' ' + this.ByMonthText + ' ' + this.ByCategoryText;
    }

    let data = {
      wedding_id: this.activeUser.id,
      status: this.status,
      is_trash: this.isTrash,
      due_now: this.dueNow,
      after_wedding: this.afterWedding,
      catagory_id: this.categoryId,
      date_month_year: this.selectedMonth

    };
    this.checklistService.filterCheckList(data).subscribe((result: any) => {
      this.allChecklist = result.data;
    },
      error => {
        this.toastr.error(error.message);
      })
  }
  async clickStatus(status: null) {
    if (status == 1) {
      this.statusText = 'Completed';
      this.toDoStatus = false;
    } else {
      this.statusText = 'To Do';
      this.toDoStatus = true;
    }
    this.status = status;
    this.isTrash = 0;
    this.filterChecklist();
    await this.countByWhen();
    await this.countMonthWiseCheckList();
    await this.countCategoryCheckList();
    // await this.setFilter();
  }

  async clickDueNow(d: any) {
    this.ByWhenText = 'Due Now';
    this.dueNow = d;
    this.afterWedding = null;
    this.selectedMonth = null;
    this.selectMonth = [];
    this.ByMonthText = '';
    this.filterChecklist();
    await this.countCheckList();
    await this.countCategoryCheckList();
    // await this.setFilter();
  }
  async clickAfterWedding(a: any) {
    this.afterWedding = a;
    this.dueNow = null;
    this.ByWhenText = 'After The Wedding';
    this.selectedMonth = null;
    this.selectMonth = [];
    this.ByMonthText = '';
    this.filterChecklist();
    await this.countCheckList();
    await this.countCategoryCheckList();
  }

  async clickTrash(t: any) {
    this.toDoStatus = false;
    this.isTrash = Number(t);
    this.status = null;
    this.statusText = 'In Trash';
    this.filterChecklist();
    await this.setFilter();
  }

  async clickStatusReset() {
    this.toDoStatus = true;
    this.isTrash = null;
    this.status = null;
    this.statusText = '';
    if (this.listHeading == '' || this.listHeading == null) {
      this.listHeading = 'All';
    }
    this.filterChecklist();
    await this.setFilter();
  }

  async clickByWhenReset() {
    this.ByWhenText = '';
    this.dueNow = null;
    this.afterWedding = null;
    this.selectedMonth = null;
    this.ByMonthText = '';
    this.selectMonth = [];
    this.filterChecklist();
    await this.setFilter();
  }

  async clickCategoryReset() {
    this.ByCategoryText = '';
    this.categoryId = null;
    this.selectCategory = [];
    this.filterChecklist();
    await this.setFilter();
  }


  onSubmit(vendorId = null, vendorDetailId = null, showPublic = null, saveVendor = null) {
    this.submitted = true;
    if (this.AddChecklistForm.invalid) {
      return;
    }
    var date = this.dateConvert(this.AddChecklistForm.value.task_date);
    let data = {
      name: this.AddChecklistForm.value.task_name,
      description: this.AddChecklistForm.value.task_description,
      status: 0,
      category_id: this.AddChecklistForm.value.category_name,
      note: null,
      event_id: this.activeUser.id,
      date: date,
      vendor_catagory_id: null,
      vendor_id: null,
      bugged_id: null,
      bugged_catagory_id: null,
      vendor_detail_id: null,
      show_public: null,
      hired: this.VendorChecked,
      vendor_name: null,
      state_id: null,
      city_id: null,
      phone_number: null,
      email: null,
    };

    if (vendorId) {
      data.vendor_id = vendorId;
      data.vendor_detail_id = vendorDetailId;
      data.show_public = showPublic;
      data.hired = this.VendorChecked;
    }

    this.checklistService.addCheckList(data).subscribe((result: any) => {
      this.submitted = false;
      this.VendorChecked = 2;
      this.toastr.success(result.message);
      this.filterChecklist();
      // this.allChecklist = result.data;
      this.resetLeftPanel(1);

      if (saveVendor) {
      } else {
        if (vendorId) {
          $('#vendorConfirmModal').modal('toggle');
        } else {
          $('#addNewTask').modal('toggle');
        }
      }

    },
      error => {
        this.toastr.error(error.message);
      })

  }

  dateConvert(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  async addTask(lavel: any, id: number) {
    this.hairedVendorIsPresent = false;
    this.saveVendorIsPresent = false;
    this.showSelectedVendor = false;
    this.VendorCategory = false;
    this.VendorCategoryDrop = true;
    if (lavel == 0 && id == 0) {
      this.AddChecklistForm.reset();
      this.AddChecklistForm.get('category_name').setValue(null);

      this.modalHeading = 'Add New Task';
      this.modalBtn = 'Create Task';
    } else {
      this.AddChecklistForm.get('vendor_category_name').setValue(null);
      this.modalHeading = 'Update Task';
      this.modalBtn = 'Update Task';
      this.VendorCategory = false;

      await this.getUpdateCheckList(id);
    }
    $('#addNewTask').modal('toggle');
    this.activeModels.push($('#addNewTask'));
  }

  async getUpdateCheckList(id: number) {
    var res = await this.checklistService.geUpdateCheckList(id).toPromise();
    this.updateData = res;
    console.log('ff', this.updateData);
    this.AddChecklistForm.get('category_name').setValue(this.updateData.category_id);
    this.AddChecklistForm.get('task_description').setValue(this.updateData.description);
    this.AddChecklistForm.get('task_name').setValue(this.updateData.name);
    var event_date = new Date(this.datetimeToDate(this.updateData.date));
    this.AddChecklistForm.get('task_date').setValue(event_date);
    this.AddChecklistForm.get('note').setValue(this.updateData.note);
    this.AddChecklistForm.get('vendor_category_name').setValue(this.updateData.vendor_catagory_id);
    this.AddChecklistForm.get('vendor_id').setValue(this.updateData.vendor_id);
    this.AddChecklistForm.get('vendor_detail_id').setValue(this.updateData.vendor_detail_id);
    if (this.updateData.category_id) {
      await this.changeVendorCategory(this.updateData.category_id);
    } else if (this.updateData.category_id == 0) {
      await this.changeVendorCategory(this.updateData.category_id);
    }

  }

  datetimeToDate(datetime: any) {
    if (!datetime) return;
    var dateTime = datetime.split(" ");
    var exp = dateTime[0].split("-");
    var year = exp[0];
    var month = exp[1];
    var day = exp[2];
    return [month, day, year].join('/');
  }

  deleteTask(id: number, status: any, index: number) {
    this.moveToTrash = {
      id: id,
      status: status
    }
    this.index = index;
    if (status == 1) {
      this.deleteModalText = 'This task will be sent to trash.';
      this.deleteModalBtn = 'Remove Task';
    } else if (status == 0) {
      this.deleteModalText = 'This task will be restored.';
      this.deleteModalBtn = 'Restore Task';
    } else if (status == 2) {
      this.deleteModalText = 'This task will be permanently deleted.';
      this.deleteModalBtn = 'Delete Task';
    }

    $('#DeleteTask').modal('toggle');
  }
  removeTask() {
    if (this.moveToTrash.status != 2) {
      this.checklistService.taskMoveToTrash(this.moveToTrash).subscribe((result: any) => {
        if (this.moveToTrash.status == 1) {
          this.toastr.success('Successfully moved to trash');
        } else if (this.moveToTrash.status == 0) {
          this.toastr.success('Successfully moved to checklist');
        }
        this.allChecklist.splice(this.index, 1);
        this.resetLeftPanel(0);
        $('#DeleteTask').modal('toggle');
      },
        error => {
          this.toastr.error(error.message);
        })
    } else {
      this.checklistService.deleteCheckList(this.moveToTrash.id).subscribe((result: any) => {
        this.toastr.success('Task was deleted');
        this.allChecklist.splice(this.index, 1);
        this.resetLeftPanel(0);
        $('#DeleteTask').modal('toggle');
      },
        error => {
          this.toastr.error(error.message);
        })
    }

  }


  changeStatus(id: any, status: number) {
    let data = {
      id: id,
      status: status,
      event_id: this.activeUser.id
    }
    this.checklistService.changeStatus(data).subscribe((result: any) => {
      // this.allChecklist = result.data;
      this.filterChecklist();
      this.resetLeftPanel(1);
    },
      error => {
        this.toastr.error(error.message);
      })
  }


  onUpdate(vendorId = null, vendorDetailId = null, showPublic = null, saveVendor = null) {
    this.submitted = true;
    if (this.AddChecklistForm.invalid) {
      return;
    }

    var date = this.dateConvert(this.AddChecklistForm.value.task_date);
    let data = {
      name: this.AddChecklistForm.value.task_name,
      description: this.AddChecklistForm.value.task_description,
      status: this.updateData.status,
      category_id: this.AddChecklistForm.value.category_name,
      note: this.AddChecklistForm.value.note,
      event_id: this.activeUser.id,
      date: date,
      id: this.updateData.id,
      vendor_catagory_id: this.AddChecklistForm.value.vendor_category_name,
      vendor_id: this.AddChecklistForm.value.vendor_id,
      vendor_detail_id: this.AddChecklistForm.value.vendor_detail_id,
      bugged_id: null,
      bugged_catagory_id: null,
      show_public: null,
      hired: this.VendorChecked
    };

    if (vendorId) {
      data.vendor_id = vendorId;
      data.vendor_detail_id = vendorDetailId;
      data.show_public = showPublic;
      data.hired = this.VendorChecked
    }

    this.checklistService.updateChecklist(data).subscribe((result: any) => {
      this.submitted = false;
      this.toastr.success(result.message);
      this.filterChecklist();
      this.resetLeftPanel(0);
      this.showSelectedVendor = false;
      this.VendorCategory = true;
      if (vendorId) {
        $('#vendorConfirmModal').modal('toggle');
      } else {
        $('#addNewTask').modal('toggle');
      }
    },
      error => {
        this.toastr.error(error.message);
      })

  }

  async setNumberOfCategory() {
    this.categorys.forEach((o, i) => {
      if (o.total) {
        o.total = null;
      }
    });
  }

  async resetLeftPanel(type: any) {
    await this.countCheckList();
    await this.getProgress();
    await this.countMonthWiseCheckList();
    await this.setNumberOfCategory();
    await this.countCategoryCheckList();
    // await this.categoryCountChecklist();
    await this.countByWhen();

  }

  async changeVendorCategory(e: any) {
    this.vendorCategoryId = e;
    this.VendorCategory = true;
    this.VendorCategoryDrop = false;
    this.getCategoryName(this.vendorCategoryId);
    await this.getSavedVendorList(e);
    await this.getHairedVendorList(e);
  }

  async getSavedVendorList(catId: number) {
    let data = {
      event_id: this.activeUser.id,
      category_id: Number(catId),
      is_hired: 2

    };
    var vendorList = await this.checklistService.getWeddingVendor(data).toPromise();
    console.log('vendorList', vendorList);
    this.selectedSaveVendor = vendorList.data;
    if (this.selectedSaveVendor.length > 0) {
      this.saveVendorIsPresent = true;
    } else {
      this.saveVendorIsPresent = false;
    }


  }

  async getHairedVendorList(catId: number) {
    let data = {
      event_id: this.activeUser.id,
      category_id: Number(catId),
      is_hired: 1

    };
    var vendorList = await this.checklistService.getWeddingVendor(data).toPromise();
    console.log('getHairedVendorList', vendorList);
    this.selectedHairedVendor = vendorList.data;
    if (this.selectedHairedVendor.length == 0) {
      this.hairedVendorIsPresent = false;
      this.VendorCategory = true;
    } else {
      this.VendorCategory = false;
      this.hairedVendorIsPresent = true;
    }
  }

  showVendor(e: any) {
    this.vendorCategoryId = e;
    this.showSelectedVendor = true;
    this.VendorCategoryDrop = false;
    this.getCategoryName(this.vendorCategoryId);
  }

  getCategoryName(id: any) {
    var category = this.vendorCategorys.filter((x: any) => x.id == id);
    this.VendorCategoryName = category[0].name;
  }
  closeDiv() {
    this.AddChecklistForm.get('category_name').setValue(null);
    this.VendorCategory = false;
    this.VendorCategoryDrop = true;
    this.saveVendorList = false;
    this.selectedSaveVendor = null;
    this.selectedHairedVendor = null;
  }

  searchVendor() {
    $('#addNewTask').modal('toggle');
    this.VendorCategoryDrop = true;
    let filter = {
      category: this.VendorCategoryName,
      city: this.activeUser.city_name
    };
    this.vendorService.setSearchFilter(filter);
    this.router.navigate([]).then(result => { window.open('vendor/listings/' + this.VendorCategoryName + '/' + this.activeUser.city_name + '/', '_blank'); });
  }

  hiredVendor() {
    this.VendorCategoryDrop = true;
    $('#addNewTask').modal('toggle');
    $('#basicExampleModal').modal('toggle');
  }

  print() {
    this.router.navigate(['checklistPrint']);
  }

  vendorYes(e: number) {
    this.VendorChecked = e;
    this.hired = e;
  }
  vendorNo(e: number) {
    this.VendorChecked = e;
    this.hired = e;

  }

  search(e: any) {
    if (e.target.value.length > 2) {
      this.toggle = true;
      this.vendorName = e.target.value;
      this.getSearchValue(e.target.value);
    } else {
      this.toggle = false;
    }
  }

  getSearchValue(val: any) {
    if (!isNaN(val)) {
      var data = {
        search_value: null,
        category_id: null,
        vendor_detail_id: val
      };
    } else {
      if (this.vendorCategoryId) {
        var vendorCategoryId = this.vendorCategoryId;
      } else {
        var vendorCategoryId = this.updateData.category_id;
      }
      var data = {
        search_value: val,
        category_id: vendorCategoryId,
        vendor_detail_id: null
      };
    }
    this.checklistService.searchValue(data).subscribe((result: any) => {
      this.searchValue = result.data;
      const venLength = this.searchValue.length;
      for (let idx = 0; idx < venLength; idx++) {
        this.chooseVendor[idx] = false;
      }
      if (isNaN(val)) {
        this.searchDiv = true;
      }
    },
      error => {
        this.toastr.error(error.message);
      })
  }

  selectVendor(e: any, index: number) {
    this.chooseVendor = [];
    this.selectedVendor = e;
    this.searchVendorName = this.selectedVendor.business_name;
    this.chooseVendor[index] = !this.chooseVendor[index];
    // this.searchDiv = false;
  }

  saveVendor(show_public: any) {
    $('#basicExampleModal').modal('toggle');
    if (this.VendorChecked == 1) {
      let data = {
        category_id: this.AddChecklistForm.value.category_name,
        event_id: this.activeUser.id,
        vendor_id: this.selectedVendor.vendor_id,
        show_public: show_public,
        hired: this.VendorChecked,
      };
      // this.VendorCategory = false;
      // this.saveVendorList = false;

      this.checklistService.addWeddingVendor(data).subscribe((result: any) => {
        if (this.VendorChecked == 2) {
          $('#vendorConfirmModal').modal('toggle');
        }
        this.vendor_submitted = false;
        this.VendorChecked = 2;
        this.toastr.success('Vendor has been added');


        this.filterChecklist();
        this.resetLeftPanel(1);
      },
        error => {

          this.toastr.error(error.message);
        })
    } else if (this.VendorChecked == 2) {
      this.openConfirm = true;
      $('#vendorConfirmModal').modal('toggle');
    }
  }

  addVendor(showPublic: any) {
    let data = {
      category_id: this.AddChecklistForm.value.category_name,
      event_id: this.activeUser.id,
      vendor_id: this.selectedVendor.vendor_id,
      show_public: showPublic,
      hired: this.VendorChecked,
    };

    this.checklistService.addWeddingVendor(data).subscribe((result: any) => {
      this.vendor_submitted = false;
      this.VendorChecked = 2;
      this.toastr.success('Vendor has been added');
      $('#vendorConfirmModal').modal('toggle');
      this.filterChecklist();
      this.resetLeftPanel(1);
    },
      error => {
        $('#vendorConfirmModal').modal('toggle');
        this.toastr.error(error.message);
      })
  }

  async hairedOrNot(value: any, is_haired: any, modalEvent: any) {

    let data = {
      event_id: value.wedding_id,
      category_id: value.category_id,
      vendor_id: value.vendor_id,
      hired: is_haired,
      id: value.id
    };

    this.checklistService.hairedOrNotVendor(data).subscribe((result: any) => {
      this.submitted = false;
      if (is_haired == 1) {
        this.VendorCategoryDrop = true;
        this.VendorCategory = false;
        this.toastr.success('Vendor was removed as hired.');
      } else if (is_haired == 2) {
        this.toastr.success('Vendor was updated as hired.');
      }

      this.showSelectedVendor = false;

      this.getCategoryName(value.category_id);
      this.getSavedVendorList(value.category_id);
      this.getHairedVendorList(value.category_id);
      if (modalEvent == 2) {
        $('#basicExampleModal').modal('toggle');
      }

    },
      error => {
        this.toastr.error(error.message);
      })

  }

  unlink() {
    $('#DeleteVendor').modal('toggle');
    var modalEvent = 0;
    this.hairedOrNot(this.vendorData, this.isHaired, modalEvent);
  }

  removeVendor(data: any, haired: any) {
    this.vendorData = null;
    this.vendorData = data;
    this.isHaired = haired;
    $('#DeleteVendor').modal('toggle');
  }


  SearchselectVendor(e: any) {
    this.selectedVendor = e;
    this.searchVendorName = this.selectedVendor.business_name;
    this.searchDiv = false;
    this.getCategoryName(this.vendorCategoryId);
  }

  submitVendor(type: any) {
    this.vendor_submitted = true;
    if (this.AddVendorForm.invalid) {
      return;
    }
    let data = {
      category_id: this.AddChecklistForm.value.category_name,
      event_id: this.activeUser.id,
      vendor_catagory_id: null,
      vendor_id: null,
      show_public: 1,
      hired: this.VendorChecked,
      vendor_name: this.AddVendorForm.value.vendor_name,
      state_id: this.AddVendorForm.value.state_id,
      city_id: this.AddVendorForm.value.city_id,
      phone_number: this.AddVendorForm.value.phone_number,
      email: this.AddVendorForm.value.email,
    };
    this.VendorCategory = false;
    this.saveVendorList = false;

    this.checklistService.addWeddingVendor(data).subscribe((result: any) => {
      this.vendor_submitted = false;
      this.VendorChecked = 2;
      this.AddVendorForm.reset();
      $('#addVendor').modal('toggle');
      this.toastr.success('Vendor has been added');
      this.filterChecklist();
      this.resetLeftPanel(1);
    },
      error => {
        this.toastr.error(error.message);
      })

  }

  addVendorClick() {
    this.openConfirm = true;
    $('#basicExampleModal').modal('toggle');
    this.AddVendorForm.get('vendor_name').setValue(this.vendorName);
    $('#addVendor').modal('toggle');

  }

  linkVendorClick(data: any, haired: any, modalEvent: any) {
    this.hairedOrNot(data, haired, modalEvent);
  }
}
