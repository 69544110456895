import { Component, OnInit } from '@angular/core';

//import * as $ from 'jquery';
declare var jQuery:any;
@Component({
  selector: 'app-comming-soon',
  templateUrl: './comming-soon.component.html',
  styleUrls: ['./comming-soon.component.css']
})
export class CommingSoonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    //setTimeout(function(){
      jQuery('.carousel').carousel('pause');
    //},1000);
  }
}
