import { Component, OnInit } from '@angular/core';
import { PlannersService } from '../services/planners.service';
import { DiscussionService } from '../services/discussions.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import {Location} from '@angular/common'; 
import { Config } from '../../../config';
import { SortablejsOptions } from 'angular-sortablejs';

declare var $: any;

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  isEditorVisible:boolean = false;
  activeUser: any;
  discussion_image_url: string = Config.imageUrl + 'images/discussion_media_images/';

  // Mini CK Editor Config --
  miniCKEditorConfig = {
    height: '6em',
    toolbar: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'links', items: ['Link', 'Unlink','Blockquote'] },
      { name: 'insert' },
      { name: 'styles', items: ['Styles', 'Format', 'Font'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
    ],
    toolbarHiddenButtons: [
      ['fontName', 'insertUnorderedList',
        'insertOrderedList', 'Table', 'FontSize', 'Strike', 'Subscript',
        'Superscript', '-', 'CopyFormatting', 'RemoveFormat', 'CreateDiv', 'Anchor']
    ],
  };

  discussion_slug: string = "";
  discussion_details: any = {};
  my_comment: string = "";
  allComments: any[] = [];
  comments: any[] = [];
  total_comments: number = 0;
  selected_comment: any = {};
  page_number: number = 1;
  isRightAligned = false;
  showComment = false;

  visibleContent: string = '';
  remainingContent: string = '';
  showFullContent: boolean = false;
  isLongContent: boolean = false;

  // Edit Form --
  editted_comment: any;
  edit_mode: boolean = false;
  edit_my_comment = "";
  added_images: any[] = [];

  // Discussion Form --
  updateDiscussionModalIsActive: boolean = false;
  discussion_comment: string = "";
  discussion_title: string = "";
  discussion_images: any = [];

  selected_discussion_image_index: number = 0;
  selected_lighthouseImageData: string = "";

  isGalleryPhotosSortDisabled:boolean = true;
  galleryPhotoOrderOptions: SortablejsOptions = {
    disabled: this.isGalleryPhotosSortDisabled,
    onEnd: (evt: any) => {
      this.updateImageOrder(evt);
    }
  }
  checkedPhotos: any = [];
  singleDeleteImg: any;
  showRemoveButton: boolean = false;

  // Reply Form --
  isReplyEditorVisible: boolean = false;
  my_reply: string = "";
  edit_my_reply = "";
  selected_reply: any = null;

  constructor( 
    private plannersService: PlannersService,
    public router: Router,
    public route: ActivatedRoute,
    private discussionService: DiscussionService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private location: Location
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.activeUser["role"] = "Wedding Users";
      } else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Vendor"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
      }else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Community Guest"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
        console.log(this.activeUser);
      }else{
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        if (params['slug']) {
          this.discussion_slug = params['slug'];
          this.getDiscussionDetails(this.discussion_slug);
        }
      }
    });
  }
  async getDiscussionDetails(slug: string){
    let formData = {
      "slug": slug
    };
    var response = await this.discussionService.getDiscussionDetails(formData).toPromise();
    this.discussion_details = response.data;
    const desktopLimit = 2500;
    const mobileLimit = 1200;
    const isMobile = window.innerWidth <= 768;
    const limit = isMobile ? mobileLimit : desktopLimit;
    if (this.discussion_details.details.discussion_details.length > limit) {
      this.isLongContent = true;
      this.visibleContent = this.discussion_details.details.discussion_details.substring(0, limit);
      this.remainingContent = this.discussion_details.details.discussion_details.substring(limit);
    } else {
      this.visibleContent = this.discussion_details.details.discussion_details;
    }
    this.allComments = this.discussion_details.comments;
    this.comments = this.allComments.slice(0, 5);
    this.allComments.forEach(e => {
      e.showReply = false;
    });
    this.total_comments = this.discussion_details.total_comments;
    this.page_number = 1;
    this.updateView(this.discussion_details.details.id);
    this.checkArticleLength();
    // console.log(this.discussion_details);
  }

  checkArticleLength() {
    const desktopLimit = 2500;
    const mobileLimit = 1200;
    const articleLength = this.discussion_details.details.discussion_details.length;
    const isMobile = window.innerWidth <= 768;
    const limit = isMobile ? mobileLimit : desktopLimit;
    this.isRightAligned = articleLength > limit;
  }

  isLongComment(comment: string): boolean {
    return this.removeHtmlTags(comment).length > 400;
  }

  getTruncatedComment(comment: string): string {
    const plainText = this.removeHtmlTags(comment);
    return plainText.length > 400 ? plainText.slice(0, 400) + '...' : plainText;
  }

  removeHtmlTags(comment: string): string {
    const doc = new DOMParser().parseFromString(comment, 'text/html');
    return doc.body.textContent || "";
  }

  toggleCommentVisibility(commentId: number) {
    const comment = this.allComments.find(c => c.id === commentId);
    if (comment) {
      comment.showFull = !comment.showFull;
    }
  }

  toggleReplyVisibility(commentId: number, replyId: number) {
    const comment = this.allComments.find(c => c.id === commentId);
    if (comment) {
      const reply = comment.replies.find(c => c.id === replyId);
      if(reply){
        reply.showFull = !reply.showFull;
      }
    }
  }

  isFullVisible(commentId: number): boolean {
    const comment = this.allComments.find(c => c.id === commentId);
    return comment ? comment.showFull : false;
  }

  isFullReplyVisible(commentId: number, replyId: number): boolean {
    const comment = this.allComments.find(c => c.id === commentId);
    const reply = comment.replies.find(c => c.id === replyId);
    return reply ? reply.showFull : false;
  }

  async updateView(id: number){
    let formData = {
      "discussion_id": id
    };
    await this.discussionService.updateViews(formData).toPromise();
  }

  showEditor(){
    if(this.activeUser){
      this.isEditorVisible = true;
      this.selected_comment = null;
      this.my_comment = "";
    }else{
      $('#login_modal').modal('toggle');
    }
  }
  closeLoginModalPopup(){
    $('#login_modal').modal('hide');
  }
  hideEditor(){
    this.isEditorVisible = false;
  }
  showReplyEditor(comment: any){
    if(this.activeUser){
      this.selected_comment = comment;
      this.isReplyEditorVisible = true;
      this.selected_reply = null;
      this.my_reply = "";
    }else{
      $('#login_modal').modal('toggle');
    }
  }
  hideReplyEditor(){
    this.isReplyEditorVisible = false;
  }
  async postComment(){
    let formData = {};
    if(this.selected_comment){
      let editorData = this.edit_my_comment.trim();  // Getting the content and trimming spaces

      let strippedContent = editorData.replace(/<\/?[^>]+(>|$)/g, '').trim();
  
      // Check if content is empty
      if (!strippedContent || strippedContent === '<p>&nbsp;</p>' || strippedContent === '<p><br></p>') {
        this.toastr.error("Comment may not be blank");
        return false;
      }
  
      if (strippedContent.length > 5000) {
        this.toastr.error("5,000 character maximum exceeded");
        return false;  // Prevent form submission or further processing
      }
      formData = {
        "id": this.selected_comment.id,
        "comment": this.edit_my_comment,
        "discussion_id": this.discussion_details.details.id
      };
    }else{
      let editorData = this.my_comment.trim();  // Getting the content and trimming spaces

      let strippedContent = editorData.replace(/<\/?[^>]+(>|$)/g, '').trim();
  
      // Check if content is empty
      if (!strippedContent || strippedContent === '<p>&nbsp;</p>' || strippedContent === '<p><br></p>') {
        this.toastr.error("Comment may not be blank");
        return false;
      }
  
      if (strippedContent.length > 5000) {
        this.toastr.error("5,000 character maximum exceeded");
        return false;  // Prevent form submission or further processing
      }
      formData = {
        "comment": this.my_comment,
        "discussion_id": this.discussion_details.details.id
      };
    }
    this.discussionService.postComment(formData).subscribe((response: any) => {
      if(response.hasError){
        this.toastr.error(response.message);
      }else{
        this.discussion_details = response.data;
        if(this.selected_comment){
          this.edit_my_comment = "";
          this.edit_mode = false;
          $('#update_comment_modal').modal('toggle');
        }else{
          this.my_comment = "";
          this.isEditorVisible = false;  
        }
        this.getDiscussionDetails(this.discussion_slug);
        this.toastr.success(response.message);
      }
    },
    (error) => {
      // Handle the error here if needed (though the toast will show already)
      console.error('Error occurred while editing discussion:', error);
    });
  }
  async postReply(){
    let formData = {};
    if(this.selected_reply){
      let editorData = this.edit_my_reply.trim();  // Getting the content and trimming spaces

      let strippedContent = editorData.replace(/<\/?[^>]+(>|$)/g, '').trim();
  
      // Check if content is empty
      if (!strippedContent || strippedContent === '<p>&nbsp;</p>' || strippedContent === '<p><br></p>') {
        this.toastr.error("Reply may not be blank");
        return false;
      }
  
      if (strippedContent.length > 5000) {
        this.toastr.error("5,000 character maximum exceeded");
        return false;  // Prevent form submission or further processing
      }
      formData = {
        "id": this.selected_reply.id,
        "comment_id": this.selected_comment.id,
        "reply_message": this.edit_my_reply
      };
    }else{
      let editorData = this.my_reply.trim();  // Getting the content and trimming spaces

      let strippedContent = editorData.replace(/<\/?[^>]+(>|$)/g, '').trim();
  
      // Check if content is empty
      if (!strippedContent || strippedContent === '<p>&nbsp;</p>' || strippedContent === '<p><br></p>') {
        this.toastr.error("Reply may not be blank");
        return false;
      }
  
      if (strippedContent.length > 5000) {
        this.toastr.error("5,000 character maximum exceeded");
        return false;  // Prevent form submission or further processing
      }
      formData = {
        "comment_id": this.selected_comment.id,
        "reply_message": this.my_reply
      };
    }
    this.discussionService.postReply(formData).subscribe((response: any) => {
        // Handle the response if no error
        if(response.hasError){
          this.toastr.error(response.message);
        }else{
          this.discussion_details = response.data;
          if(this.selected_reply){
            this.edit_my_reply = "";
            this.edit_mode = false;
            $('#update_reply_modal').modal('toggle');  
          }else{
            this.my_reply = "";
            this.isReplyEditorVisible = false;
          }
          this.getDiscussionDetails(this.discussion_slug);
          this.toastr.success(response.message);
        }    
      },
      (error) => {
        console.error('Error occurred while editing discussion:', error);
      }
    );
  }
  async load_more_comments(){
    const currentLength = this.comments.length;
    if(this.allComments.length >= currentLength){
      this.comments = this.allComments.slice(0, (currentLength + 5));
    }
  }
  confirm_delete_comment(comment: any){
    this.selected_comment = comment;
    $('#delete_comment_modal').modal('show');
  }
  confirm_delete_discussion(comment: any){
    this.selected_comment = comment;
    $('#delete_discussion_modal').modal('show');
  }
  confirm_delete_reply(reply: any){
    this.selected_reply = reply;
    $('#delete_reply_modal').modal('show');
  }
  async confirmDeleteComment(){
    console.log(this.selected_comment);
    let formData = {
      "comment_id": this.selected_comment.id
    };
    try{
      var response = await this.discussionService.deleteComment(formData).toPromise();
      console.log(response);
      if(response.hasError === false){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
      }

      // this.allComments = this.allComments.filter((comment)=>{
      //   return this.selected_comment.id !== comment.id
      // });

      this.selected_comment = null;
      $('#delete_comment_modal').modal('toggle');
      this.getDiscussionDetails(this.discussion_slug);
    }catch(error){
      this.toastr.error(error.message);
      $('#delete_comment_modal').modal('toggle');
    }
  }
  async deleteReply(){
    let formData = {
      "reply_id": this.selected_reply.id
    };
    try{
      var response = await this.discussionService.deleteReply(formData).toPromise();
      console.log(response);
      if(response.hasError === false){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
      }
      this.selected_reply = null;
      $('#delete_reply_modal').modal('toggle');
      this.getDiscussionDetails(this.discussion_slug);
    }catch(error){
      this.toastr.error(error.message);
      $('#delete_reply_modal').modal('toggle');
    }
  }
  edit_reply(comment: any, reply: any){
    this.edit_mode = true;
    this.selected_comment = comment;
    this.selected_reply = reply;
    this.edit_my_reply = reply.reply_message;
    $('#update_reply_modal').modal('show');
  }
  async confirmDeleteDiscussion(){
    let formData = {
      "discussion_id": this.discussion_details.details.id
    };
    try{
      var response = await this.discussionService.deleteDiscussion(formData).toPromise();
      console.log(response);
      if(response.hasError === false){
        // Return to category page --
        this.router.navigateByUrl('/community/' + this.discussion_details.category_details.name);
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
      }
      $('#delete_discussion_modal').modal('toggle');
    }catch(error){
      this.toastr.error(error.message);
      $('#delete_discussion_modal').modal('toggle');
    }
  }
  getRichTextContent(content: any) {
    let el = document.createElement("div");
    el.innerHTML = content;
    let tempcontent = el.outerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(tempcontent);
  }
  async edit_comment(comment: any){
    this.edit_mode = true;
    this.selected_comment = comment;
    this.edit_my_comment = comment.comment;
    $('#update_comment_modal').modal('show');
  }
  async updateDiscussion(){
    let formData = {
      "id": this.discussion_details.details.id,
      "discussion_title": this.discussion_title,
      "discussion_details": this.discussion_comment,
      "wed_category_id": this.discussion_details.category_details.id,
      "discussion_images": this.discussion_images.filter((item: any)=>item.imageData !== undefined),
    };
    try{
      var response = await this.discussionService.createDiscussion(formData).toPromise();
      if(response.hasError){
        this.toastr.error(response.message);
      }else{
        this.getDiscussionDetails(response.data.slug);
        if(this.discussion_slug !== response.data.slug){
          this.location.replaceState("/conversation/" + response.data.slug);
        }
        this.toastr.success(response.message);
        $('#update_discussion_modal').modal('toggle');
      }
      
    }catch(error){
      this.toastr.error(error.message);
    }
  }
  async open_edit_discussion_modal(){
    this.updateDiscussionModalIsActive = true;
    $('#update_discussion_modal').modal('show');
    this.discussion_title = this.discussion_details.details.discussion_title;
    this.discussion_comment = this.discussion_details.details.discussion_details;
    this.discussion_images = [...this.discussion_details.discussion_images];
    this.isGalleryPhotosSortDisabled = true;
    this.checkedPhotos = [];
    this.singleDeleteImg = undefined;
    this.showRemoveButton = false;
  }
  openImageUploader(){
    $("#upload_file_input").click();
  }
  toggleGalleryPhotosSort(){
    this.isGalleryPhotosSortDisabled = !this.isGalleryPhotosSortDisabled;
    this.galleryPhotoOrderOptions = {
      disabled: this.isGalleryPhotosSortDisabled,
      onEnd: (evt: any) => {
        this.updateImageOrder(evt);
      }
    }
  }
  singleSavedChecked(e: any, id: any) {
    if (e.target.checked == true) {
      this.checkedPhotos.push(id);
      this.showRemoveButton = true;
    } else {
      const index = this.checkedPhotos.indexOf(id);
      this.checkedPhotos.splice(index, 1);
      if (this.checkedPhotos.length == 0) {
        this.showRemoveButton = false;
      }
    }
  }
  openConfirmDeleteModal(file: any) {
    if(file != -1){
      this.singleDeleteImg = file;
    }
    $('#confirmDeleteModal').modal('show');
  }
  fileChangeEvent(event: any): void {
    var allowed_file_types = ["image/jpeg", "image/jpg", "image/gif", "image/png"];
    var multipleFiles = event.target.files;
    if (multipleFiles) {
      // this.numberOfPhotosSelected = multipleFiles.length;
      // this.photosSelected = true;
      let image_skipped = 0;
      for (var file of multipleFiles) {
        if (!(file.size > (1024 * 100) && file.size < (1024 * 1024 * 5))) {
          image_skipped++;
          continue;
        }
        if (!allowed_file_types.includes(file.type)) {
          image_skipped++;
          continue;
        }
        var multipleReader = new FileReader();
        multipleReader.onload = (e: any) => {
          const highestDisplayOrder = this.discussion_images.reduce((max, item) => {
            return item.display_order > max ? item.display_order : max;
          }, 0);
          this.discussion_images = [...this.discussion_images, {
            'id': this.discussion_images.length + 1,
            'type': 'image',
            'imageData': e.target.result,
            'display_order': highestDisplayOrder + 1
          }];
        }
        multipleReader.readAsDataURL(file);
      }

      if (image_skipped > 0) {
        if (image_skipped == 1) {
          this.toastr.error(`${image_skipped} image was failed to upload. Please upload a jpeg, jpg, gif or png image and file size should be between 100KB and 5MB`);
        } else {
          this.toastr.error(`${image_skipped} images were failed to upload. Please upload a jpeg, jpg, gif or png image and file size should be between 100KB and 5MB`);
        }
      }
    }
  }
  getSRCofImageData(discussion_image: any){
    if(discussion_image.file_name){
      return this.discussion_image_url + discussion_image.file_name;
    }else{
      return discussion_image.imageData;
    }
  }
  async deleteDiscussionImage(){
    if(this.singleDeleteImg){
      if(this.singleDeleteImg.id){
        let formData = {
          'media_id': this.singleDeleteImg.id
        };
        var response = await this.discussionService.deleteDiscussionImage(formData).toPromise();
        if(response.hasError === false){
          this.toastr.success(response.message);
          this.discussion_images = this.discussion_images.filter((item:any)=>item.id!==this.singleDeleteImg.id);
          this.discussion_details.discussion_images = [...this.discussion_images.filter((item: any)=>item.id !== undefined)];
        }
      }else if(this.singleDeleteImg.imageData){
        let index = this.discussion_images.indexOf(this.singleDeleteImg);
        this.discussion_images.splice(index, 1);
      }
      this.singleDeleteImg = undefined;
    }else{
      this.checkedPhotos.forEach(async e => {
        if(e.id){
          let formData = {
            'media_id': e.id
          };
          var response = await this.discussionService.deleteDiscussionImage(formData).toPromise();
          if(response.hasError === false){
            this.toastr.success(response.message);
            this.discussion_images = this.discussion_images.filter((item:any)=>item.id!==e.id);
            this.discussion_details.discussion_images = this.discussion_images.filter((item: any)=>item.id !== undefined);
          }
        }else if(e.imageData){
          let index = this.discussion_images.indexOf(e);
          this.discussion_images.splice(index, 1);
        }
      });
      this.checkedPhotos = [];
    }
    this.showRemoveButton = false;
    $('#confirmDeleteModal').modal('hide');
  }
  async updateImageOrder(evt: any) {
    let sortedIds = Array.from(evt.from.children).map((child: any) => child.dataset?child.dataset.id:undefined);
    sortedIds = sortedIds.filter(id => id !== null && id !== undefined);
    this.discussion_images = this.discussion_images.filter(image => image !== null && image !== undefined);

    this.discussion_images = this.discussion_images.map(image => {
      const index = sortedIds.indexOf(image.id?image.id.toString():undefined);
      image.display_order = index + 1;
      return image;
    }).sort((a, b) => a.display_order - b.display_order);

    const newOrder = this.discussion_images.map((image, index) => ({
      id: image.type != "image"?image.id:'-1',
      display_order: index + 1
    }));
    let formData = {
      'newOrder': newOrder
    };
    var response = await this.discussionService.updateImageOrder(this.discussion_details.details.id,formData).toPromise();
    if(response.hasError === false){
      this.toastr.success(response.message);
      this.discussion_details.discussion_images = this.discussion_images.filter((item: any)=>item.id !== undefined);
    }else{
      this.toastr.error(response.message);
    }
  }
  slideNext() {
    if(this.selected_discussion_image_index < this.discussion_details.discussion_images.length - 1){
      this.selected_discussion_image_index = this.selected_discussion_image_index + 1;
      this.setSlide(this.selected_discussion_image_index, this.discussion_details.discussion_images);
    }else{
      this.selected_discussion_image_index = 0;
      this.setSlide(this.selected_discussion_image_index, this.discussion_details.discussion_images);
    }
  }
  slidePrev() {
    if(this.selected_discussion_image_index > 0){
      this.selected_discussion_image_index = this.selected_discussion_image_index - 1;
      this.setSlide(this.selected_discussion_image_index, this.discussion_details.discussion_images);
    }else{
      this.selected_discussion_image_index = this.discussion_details.discussion_images.length - 1;
      this.setSlide(this.selected_discussion_image_index, this.discussion_details.discussion_images);
    }
  }
  openDiscussionImageLightBox(index: number){
    $('#photo_lightbox').modal('toggle');
    this.setSlide(index, this.discussion_details.discussion_images);
  }
  setSlide(index: number, images: any){
    this.selected_discussion_image_index = index;
    if(images[this.selected_discussion_image_index].imageData){
      this.selected_lighthouseImageData = images[this.selected_discussion_image_index].imageData;
    }else if(images[this.selected_discussion_image_index].file_name){
      this.selected_lighthouseImageData = this.discussion_image_url + images[this.selected_discussion_image_index].file_name;
    }
  }
  getUserName(userDetails: any){
    let name = userDetails.firstname;
    if(userDetails.lastname){
      return name + " " + userDetails.lastname;
    }
    return name;
  }
  canModify(user_id: any){
    const currentUserId = localStorage.getItem("UID");
    if(currentUserId && (parseInt(currentUserId) === parseInt(user_id))){
      return true;
    }
    return false;
  }
}