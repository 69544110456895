import { Deseriazable } from './deserializable.model';

export class PlannerProfileModel implements Deseriazable{
    
    id: string;
    token: string;
    first_couple_id: string;
    first_couple_name: string;
    first_couple_type: number;
    second_couple_id: string;
    second_couple_name: string;
    second_couple_type: number;
    start_datetime: string;
    end_datetime: string;
    color: string;
    event_date: string;
    startHour: string;
    startMinute: string;
    endHour: string;
    endMinute: string;
    state_id: number;
    city_id: number;
    message:string;
    wedding_website_url_name:string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}