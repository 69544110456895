import { Component, OnInit } from '@angular/core';
import { WeddingWebsiteService } from '../services/wedding-website.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlannersService } from '../services/planners.service';
import { ToastrService } from 'ngx-toastr';
import { Config } from '../../../config';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SortablejsOptions } from 'angular-sortablejs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-wedding-website-setting',
  templateUrl: './wedding-website-setting.component.html',
  styleUrls: ['./wedding-website-setting.component.scss']
})
export class WeddingWebsiteSettingComponent implements OnInit {
  public AddEventForm: FormGroup;
  public AddTravelForm: FormGroup;
  public AddPhotoForm: FormGroup;
  public AddAlbumForm: FormGroup;
  public AddThingsToDoForm: FormGroup;
  public AddTitleForm: FormGroup;
  confirm_modal_delete_key: string = "";
  selected_file_to_delete: any;
  ourTitle: any;
  ourDescription: any;
  event_submitted: boolean = false;
  travel_submitted: boolean = false;
  things_submitted: boolean = false;
  album_submitted: boolean = false;
  layoutData: any;
  visibleTagListData: any;
  tagListData: any;
  tagValue: any = [];
  activeUser: any;
  themeDetails: any;
  templateId: any;
  weddingDetails: any;
  website_info: any;
  selectTemplate: boolean = false;
  pages: any;
  storyContent: any;
  storyTextBlock: any;
  storyData: any;
  templateSavedPages: any;
  savedPages: any;
  pageIdArray: any = [];
  showMoreTagsButton: boolean = false;
  tagsExpended: boolean = false;
  first_tag_items_count: number = 5;
  pagination_items: any[] = [];
  total_designs_per_page: number = 8;
  current_design_list_page: number = 1;
  // Sidebar Variables --
  showMenu: boolean = true;
  menu_item_name: string = '';
  menu_item_display_name: string = '';
  question: any;
  answer: any;
  fileName: any;
  fileType: any;
  base64: any;
  uploadPhoto: any;
  galleryPhotos: any;
  photo_galary_tab_name: string = 'upload_photo';
  selectedGalleryPhotos: number[] = [];
  showRemoveGalleryPhotosButton: boolean = false;
  imgId: any;
  // Website Info --
  groomName: string = "";
  brideName: string = "";
  weddingDate: Date = new Date();
  weddingCity: string = "";
  weddingState: string = "";
  weddingCountry: string = "";
  coverPhotoImageData: any = Config.imageUrl + 'images/wedding_website_photos/default_cover_image.jpg';
  // Faqs --
  faqs: any;
  faqId: number;
  selected_event_index: number = null;
  selected_things_to_do_index: number = null;
  selected_qa_index: number = null;
  selected_photo_album_index: number = null;
  selected_our_story_item_index: number = null;
  eventId: any;
  eventList: any;
  editEvent: boolean = false;
  editFaq: boolean = false;
  editTravel: boolean = false;
  editThings: boolean = false;
  editAlbum: boolean = false;
  description: string = "";
  travelId: any;
  travelList: any;
  selected_travel_index: any = 0; 
  album: any;
  albumId: any;
  showphotos: boolean = false;
  showAlbum: boolean = true;
  thingsId: any;
  thingsList: any;
  upload_image: any = [];
  uploadPhotos: any;
  photoAlbumId: any;
  albumTitle: any;
  filterGalleryPhotos: any;
  numberOfPhotosSelected: Number;
  photosSelected: Boolean = false;
  refresh_count: number = 1;
  rsvpData: any;
  currentAlbumForImageMove: number;
  currentImageToMove: number;
  checkedPhotos: any = [];
  showRemoveButton: boolean = false;
  timelineDetail: any;
  showtimelinePhotos: boolean = false;
  showstory: boolean = true;
  timelineFileName: any;
  timelineDetails: any;
  timelinedeleteId: any;
  timelineEditId: any;
  galleryEditId: any;
  ourStoryItemOptionsPageIsActive: boolean;
  ourStoryEditFormContainerIsActive: boolean
  ourStoryContentListIsActive: boolean = true;
  saveEditData = "Save";
  galleryFileType: any;
  galleryFileName: any;
  galleryDetail: any;
  ourStoryData: any;
  storyTitle = [
    {
      id: 1,
      value: 'Small'
    },
    {
      id: 2,
      value: 'Medium'
    },
    {
      id: 3,
      value: 'Large'
    },
  ];
  selectedOption: any;
  title_submitted: boolean = false;
  titleBlock: any;
  ourStoryActiveItemName: any;
  titleEditId: any;
  storyUpdateData: any
  textBlockEditId: any;
  storyGifData: any;
  gifEditId: any;
  storyPhotoData: any;
  showCropper: boolean;
  storyPhotoImageData: any;
  storyImage: any;
  imageChangedCropEvent: any;
  showTham: boolean = false;
  showDeleteButton: boolean = false;
  selectedTime: any;
  time: string;
  PhoneRegEx = "[0-9]{3}-[0-9]{3}-[0-9]{4}";

  our_story_story_item_photo_url: string;
  our_story_photo_block_image_url: string;
  currentStoryItem: any;
  timeline_image: any = [];
  gallery_image: any = [];
  profile_image: any = [];
  imagedata = [];

  // Custome Color Style --
  selected_font_attribute_name: string = "";
  selected_font_attribute_index: number = null;
  selected_font_color: string = "#fff";
  selected_font_size: string = null;
  selected_font_weight: string = null;
  selected_font_family: string = null;
  apply_to_all_field: boolean = false;
  selected_font_color_for_text_header_box: string = null;
  opacity_for_text_header_box: number = 0;
  selected_font_color_for_text_box: string = null;
  opacity_for_text_box: number = null;
  selected_font_color_for_media_box: string = null;
  opacity_for_media_box: number = 0;
  custom_style: any = {};
  available_colors: any = ["#FF0000", "#800000", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#00FFFF", "#A52A2A", "#C0C0C0",
    "#009688", "#4caf50", "#ffeb3b", "#FF00FF", "#00FF00", "#FFC0CB", "#FFFFFF", "#000000", "#FFA500", "#808000", "#7FFFD4", "#808080", "#800080"];
  font_sizes: any = ['10px', '11px', '12px', '13px', '14px', '15px', '16px', '17px', '18px', '19px', '20px', '21px', '22px', '23px', '24px', '25px', '26px', '27px', '28px', '29px', '30px', '31px', '32px', '33px', '34px', '35px', '36px', '37px', '38px', '39px', '40px'];
  font_weight: any = ['100', '200', '300', '400', '500', '600', 'bold', 'bolder', 'lighter'];
  font_family: any = ['Times New Roman', 'Lucida Console', 'Courier New', 'Fantasy', 'Monospace', '"Lucida Handwriting", cursive', 'Arial', 'Helvetica', 'Sans-serif'];

  storyItemImageId: any;
  opacity_for_text_header_box_value: any = 0;
  opacity_for_text_box_value: any = 0;
  opacity_for_media_box_value: any = 0;

  // Sortale options --
  isEventSortDisabled: boolean = true;
  isAlbumSortDisabled:boolean = true;
  isTravelSortDisabled:boolean = true;
  isThingsToDoSortDisabled:boolean = true;
  isFaqsSortDisabled:boolean = true;
  isOurStoryContentOrderSortDisabled:boolean = true;

  eventOrderOptions: SortablejsOptions = {
    disabled: this.isEventSortDisabled,
    onUpdate: (event: any) => {
      this.saveEventOrder();
    }
  }
  toggleEventSort(){
    this.isEventSortDisabled = !this.isEventSortDisabled;
    this.eventOrderOptions = {
      disabled: this.isEventSortDisabled,
      onUpdate: (event: any) => {
        this.saveEventOrder();
      }
    }
  }
  faqOrderOptions: SortablejsOptions = {
    disabled: this.isFaqsSortDisabled,
    onUpdate: (event: any) => {
      this.saveFaqOrder();
    }
  }
  toggleFaqsSort(){
    this.isFaqsSortDisabled = !this.isFaqsSortDisabled;
    this.faqOrderOptions = {
      disabled: this.isFaqsSortDisabled,
      onUpdate: (event: any) => {
        this.saveFaqOrder();
      }
    }
  }
  travelOrderOptions: SortablejsOptions = {
    disabled: this.isTravelSortDisabled,
    onUpdate: (event: any) => {
      this.saveTravelOrder();
    }
  }
  toggleTravelSort(){
    this.isTravelSortDisabled = !this.isTravelSortDisabled;
    this.travelOrderOptions = {
      disabled: this.isTravelSortDisabled,
      onUpdate: (event: any) => {
        this.saveTravelOrder();
      }
    }
  }
  thingsToDoOrderOptions: SortablejsOptions = {
    disabled: this.isThingsToDoSortDisabled,
    onUpdate: (event: any) => {
      this.saveThingsToDoOrder();
    }
  }
  toggleThingsToDoSort(){
    this.isThingsToDoSortDisabled = !this.isThingsToDoSortDisabled;
    this.thingsToDoOrderOptions = {
      disabled: this.isThingsToDoSortDisabled,
      onUpdate: (event: any) => {
        this.saveThingsToDoOrder();
      }
    }
  }
  isGalleryPhotosSortDisabled:boolean = true;
  galleryPhotoOrderOptions: SortablejsOptions = {
    disabled: this.isGalleryPhotosSortDisabled,
    onUpdate: (event: any) => {
      this.saveGalleryPhotoOrder();
    }
  }
  toggleGalleryPhotosSort(){
    this.isGalleryPhotosSortDisabled = !this.isGalleryPhotosSortDisabled;
    this.galleryPhotoOrderOptions = {
      disabled: this.isGalleryPhotosSortDisabled,
      onUpdate: (event: any) => {
        this.saveGalleryPhotoOrder();
      }
    }
  }
  albumOrderOptions: SortablejsOptions = {
    disabled: this.isAlbumSortDisabled,
    onUpdate: (event: any) => {
      this.saveAlbumOrder();
    }
  }
  toggleAlbumSort(){
    this.isAlbumSortDisabled = !this.isAlbumSortDisabled;
    this.albumOrderOptions = {
      disabled: this.isAlbumSortDisabled,
      onUpdate: (event: any) => {
        this.saveAlbumOrder();
      }
    }
  }
  ourStoryContentOrderOptions: SortablejsOptions = {
    disabled: this.isOurStoryContentOrderSortDisabled,
    onUpdate: (event: any) => {
      this.saveOurStoryContentOrder();
    }
  }
  toggleOurStoryContentOrderSort(){
    this.isOurStoryContentOrderSortDisabled = !this.isOurStoryContentOrderSortDisabled;
    this.ourStoryContentOrderOptions = {
      disabled: this.isOurStoryContentOrderSortDisabled,
      onUpdate: (event: any) => {
        this.saveOurStoryContentOrder();
      }
    }
  }
  isOurStorygalleryImageOrderSortDisabled:boolean = true;
  ourStoryGalleryImagesOrderOptions: SortablejsOptions = {
    disabled: this.isOurStorygalleryImageOrderSortDisabled,
    onUpdate: (event: any) => {
      this.saveOurStoryGalleryImageDisplayOrder();
    }
  }
  toggleOurStorygalleryImageOrderSort(){
    this.isOurStorygalleryImageOrderSortDisabled = !this.isOurStorygalleryImageOrderSortDisabled;
    this.ourStoryGalleryImagesOrderOptions = {
      disabled: this.isOurStorygalleryImageOrderSortDisabled,
      onUpdate: (event: any) => {
        this.saveOurStoryGalleryImageDisplayOrder();
      }
    }
  }

  isOurStoryTimelineOrderSortDisabled:boolean = true;
  ourStoryTimelineImagesOrderOptions: SortablejsOptions = {
    disabled: this.isOurStoryTimelineOrderSortDisabled,
    onUpdate: (event: any) => {
      this.saveOurStoryTimelineImageDisplayOrder();
    }
  }
  toggleOurStoryTimelineOrderSort(){
    this.isOurStoryTimelineOrderSortDisabled = !this.isOurStoryTimelineOrderSortDisabled;
    this.ourStoryTimelineImagesOrderOptions = {
      disabled: this.isOurStoryTimelineOrderSortDisabled,
      onUpdate: (event: any) => {
        this.saveOurStoryTimelineImageDisplayOrder();
      }
    }
  }

  constructor(private sanitizer: DomSanitizer, private WeddingWebsiteService: WeddingWebsiteService, private plannersService: PlannersService, public router: Router, public route: ActivatedRoute, private toastr: ToastrService, public formBuilder: FormBuilder) {
    this.AddEventForm = this.formBuilder.group({
      title: ['', Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: [''],
      phoneNumber: ['', Validators.compose([Validators.required, Validators.pattern(this.PhoneRegEx)])],
      website: [''],
      address: ['', Validators.required],
      description: [''],
      direction: ['']
    });

    this.AddTravelForm = this.formBuilder.group({
      title: ['', Validators.required],
      direction: [''],
      Website: [''],
      phoneNumber: ['', Validators.compose([Validators.required, Validators.pattern(this.PhoneRegEx)])],
      address: ['', Validators.required],
      city: [''],
      zipCode: [''],
      description: ['']
    });

    this.AddThingsToDoForm = this.formBuilder.group({
      thingsToDo: ['', Validators.required],
      direction: [''],
      HoursOfOperation: [''],
      phoneNumber: ['', Validators.compose([Validators.required, Validators.pattern(this.PhoneRegEx)])],
      city: [''],
      zipCode: [''],
      address: [''],
      description: [''],
      website_url: ['']
    });

    this.AddPhotoForm = this.formBuilder.group({
      description: ['']
    });

    this.AddTitleForm = this.formBuilder.group({
      title: ['', Validators.required]
    });
    this.AddAlbumForm = this.formBuilder.group({
      albumName: ['', Validators.required],
    });
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    }, (err) => {
      console.error(err);
    });
  }

  templateData: any;
  imageBaseUrl = location.origin + '/assets/images/wedding_website/template_thumbs/';
  layoutBaseUrl = location.origin + '/assets/images/wedding_website/layout_thumbs/';
  wedding_website_url = location.origin + "/my-wedding/";
  
  // public editorValue: string = '';
  // 'Blockquote'
  config = {
    height: '6em',
    toolbar: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'links', items: ['Link', 'Unlink','Blockquote'] },
      { name: 'insert' },
      { name: 'styles', items: ['Styles', 'Format', 'Font'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
    ],
    toolbarHiddenButtons: [
      ['fontName', 'insertUnorderedList',
        'insertOrderedList', 'Table', 'FontSize', 'Strike', 'Subscript',
        'Superscript', '-', 'CopyFormatting', 'RemoveFormat', 'CreateDiv', 'Anchor']
    ],
  };
  // Mini CK Editor Config --
  miniCKEditorConfig = {
    height: '6em',
    toolbar: [
      { name: 'basicstyles', items: ['Bold', 'Italic'] },
      // { name: 'links', items: ['Link'] },
      { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
    ]
  };
  galleryPhotosUrl = Config.imageUrl + 'images/wedding_website_photos/';
  ngOnInit() {
    this.tagList();
    this.templateList();
    this.getWeddingWebsite();
    this.getWebsiteTemplateSavedPages(this.activeUser.id);
    this.getWebsiteTemplatePages(this.activeUser.id);
  }
  templateList() {
    this.WeddingWebsiteService.getTemplateList(this.total_designs_per_page, this.current_design_list_page, this.tagValue).subscribe(res => {
      this.templateData = res.data;
      this.pagination_items = this.pagination(this.current_design_list_page, Math.ceil(res.total_records / this.total_designs_per_page));
    }, error => {
      console.log(error);
    })
  }
  getWeddingWebsite() {
    this.WeddingWebsiteService.getWebsiteDesign(this.activeUser.id).subscribe(res => {
      this.themeDetails = res.theme_details;
      this.weddingDetails = res.wedding_details;
      this.galleryPhotos = res.photos;
      this.ourStoryData = res.our_story_details;
      if (this.photoAlbumId) {
        this.filterGalleryPhotos = this.galleryPhotos.filter((res) => res.album_id == this.photoAlbumId);
      }
      this.faqs = res.faqs;
      this.eventList = res.events;
      this.travelList = res.travels;
      this.album = res.albums;
      this.thingsList = res.things_to_do;
      if (this.themeDetails) {
        this.templateId = res.theme_details.id;
      } else {
        this.selectTemplate = true;
        this.themeDetails = this.templateData[0];
        this.templateId = 1;
      }
      if (res.cover_photo && res.cover_photo.image_name) {
        this.coverPhotoImageData = this.galleryPhotosUrl + res.cover_photo.image_name;
      }
      if (res.website_info) {
        this.website_info = res.website_info;
        this.groomName = this.website_info.first_name;
        this.brideName = this.website_info.second_name;
        this.weddingDate = this.website_info.wedding_date;
        this.weddingCity = this.website_info.city;
        this.weddingState = this.website_info.state;
        this.weddingCountry = this.website_info.country;

        if (res.website_info.box_setting) {
          this.resetBoxSetting(res.website_info.box_setting);
        }
      }
      // RSVP Data --
      let rsvp_items = res.rsvp_responses;
      let rsvpData = {};
      rsvp_items.forEach((rsvp_item: any) => {
        if (!rsvpData[rsvp_item.guest_id]) {
          rsvpData[rsvp_item.guest_id] = [rsvp_item];
        } else {
          rsvpData[rsvp_item.guest_id].push(rsvp_item);
        }
      });
      let rsvp_data = [];
      Object.keys(rsvpData).forEach((guest_id: any) => {
        if (rsvpData[guest_id][0].note) {
          rsvp_data.push({
            guest_id: guest_id,
            name: rsvpData[guest_id][0].first_name + " " + rsvpData[guest_id][0].last_name,
            note: rsvpData[guest_id][0].note,
            rsvp_response_date: rsvpData[guest_id][0].rsvp_response_date
          });
        }
      });
      this.rsvpData = rsvp_data;
    }, error => {
      console.log(error);
    })
  }
  resetBoxSetting(box_setting: any) {
    let setting = JSON.parse(box_setting);
    this.selected_font_color_for_text_header_box = setting.text_header_box_color;
    this.opacity_for_text_header_box = setting.text_header_box_opacity;
    this.opacity_for_text_header_box_value = this.opacity_for_text_header_box ? this.opacity_for_text_header_box * 100 : 0;
    this.selected_font_color_for_text_box = setting.text_box_color;
    this.opacity_for_text_box = setting.text_box_opacity;
    this.opacity_for_text_box_value = this.opacity_for_text_box ? this.opacity_for_text_box * 100 : 0;
    this.selected_font_color_for_media_box = setting.media_box_color;
    this.opacity_for_media_box = setting.media_box_opacity;
    this.opacity_for_media_box_value = this.opacity_for_media_box ? this.opacity_for_media_box * 100 : 0;
  }
  getRichTextContent(content: any) {
    let el = document.createElement("div");
    el.innerHTML = content;
    let tempcontent = el.outerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(tempcontent);
  }
  tagList() {
    this.WeddingWebsiteService.getTagList().subscribe(res => {
      this.tagListData = res.tags;
      if (this.tagListData.length > 5) {
        this.showMoreTagsButton = true;
        this.visibleTagListData = [...this.tagListData].slice(0, this.first_tag_items_count);
      } else {
        this.showMoreTagsButton = false;
        this.visibleTagListData = [...this.tagListData];
      }
    }, error => {
      console.log(error);
    })
  }
  handle_design_selected(data: any) {
    this.templateId = data.id;
    this.themeDetails = data;
  }
  getTagValue(value) {
    if (!this.tagValue.includes(value)) {
      this.tagValue.push(value);
    } else {
      const index = this.tagValue.indexOf(value);
      this.tagValue.splice(index, 1);
    }
    this.templateList();
  }
  savedTemplate() {
    let data = {
      template_id: this.templateId,
      wedding_id: this.activeUser.id
    }
    this.WeddingWebsiteService.savedSelectedTemplate(data).subscribe(res => {
      this.toastr.success(res.message);
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  getWebsiteTemplatePages(wedding_id: number) {
    this.WeddingWebsiteService.getWebsiteTemplatePages(wedding_id).subscribe(res => {
      this.pages = res.pages;
    }, error => {
      console.log(error);
    })
  }
  getPagesId(e: any, id: any, data: any) {
    if (e.target.checked == true) {
      let final_data = { ...data, ...{ is_active: e.target.checked } };
      let pageData = {
        wedding_id: this.activeUser.id,
        id: final_data.id,
        display_name: final_data.display_name,
        comment: final_data.comment,
        is_active: final_data.is_active,
        custom_style: final_data.custom_style
      };
      this.pageIdArray.push(pageData);
    } else {
      for (let index = 0; index < this.pageIdArray.length; index++) {
        if (this.pageIdArray[index].id == id) {
          this.pageIdArray[index].is_active = false;
        }
      }
    }
  }
  savedTemplatePages() {
    if (this.pageIdArray.length < 1) {
      this.toastr.error("Please Select at least one");
      return;
    }
    let data = {
      wedding_id: this.activeUser.id,
      pages: this.pageIdArray,
    };
    this.WeddingWebsiteService.savedTemplatePages(data).subscribe(res => {
      this.toastr.success(res.message);
      this.pageIdArray = [];
      this.getWebsiteTemplateSavedPages(this.activeUser.id);
      this.getWebsiteTemplatePages(this.activeUser.id);
    }, error => {
      console.log(error);
    });
  }
  savedTemplatePagesdescription(data: any) {
    let is_active = true;
    this.pageIdArray.forEach((item: any) => {
      if (item.id === data.id) {
        is_active = item.is_active;
      }
    });

    let pageData = {
      wedding_id: this.activeUser.id,
      id: data.id,
      display_name: data.display_name,
      comment: data.comment,
      is_active: is_active,
      cover_image: data.cover_image,
      tips: data.tips,
      custom_style: JSON.stringify(this.custom_style)
    };
    var descData = [];
    descData.push(pageData);
    let updateData = {
      wedding_id: this.activeUser.id,
      pages: descData
    };

    this.WeddingWebsiteService.savedTemplatePages(updateData).subscribe(res => {
      this.toastr.success(res.message);
      this.pageIdArray = [];
      this.getWebsiteTemplateSavedPages(this.activeUser.id);
      this.getWebsiteTemplatePages(this.activeUser.id);
      this.refresh_count++;
    }, error => {
      console.log(error);
    });
  }
  getWebsiteTemplateSavedPages(wedding_id: number) {
    this.WeddingWebsiteService.getWebsiteTemplateSavedPages(wedding_id).subscribe(res => {
      this.templateSavedPages = res.pages;

      for (let index = 0; index < this.templateSavedPages.length; index++) {
        let savedTemplateData = {
          wedding_id: this.activeUser.id,
          id: this.templateSavedPages[index].id,
          display_name: this.templateSavedPages[index].display_name,
          comment: this.templateSavedPages[index].comment,
          is_active: this.templateSavedPages[index].is_active,
          custom_style: this.templateSavedPages[index].custom_style
        }
        this.pageIdArray.push(savedTemplateData);
      }
      this.templateSavedPages.sort((a, b) => {
        return a.display_order - b.display_order;
      });
    }, error => {
      console.log(error);
    })
  }
  removeCoverPhoto(page_details: any) {
    if (page_details.cover_image) {
      let data = {
        wedding_id: this.activeUser.id,
        page_id: page_details.id
      };
      this.WeddingWebsiteService.removeCoverPhoto(data).subscribe(res => {
        this.toastr.success(res.message);
        $('#confirmDeleteModal').modal('hide');
        this.getWeddingWebsite();
        this.getWebsiteTemplateSavedPages(this.activeUser.id);
        this.getWebsiteTemplatePages(this.activeUser.id);
        this.refresh_count++;
      }, error => {
        this.toastr.error(error.message);
        $('#confirmDeleteModal').modal('hide');
        console.log(error);
      });
    }
  }
  handle_design_menu_item_click(menu_name: string, display_name: string) {
    this.menu_item_name = menu_name;
    this.menu_item_display_name = display_name;
    this.showMenu = false;

    if (this.menu_item_name == "select_story") {
      this.ourStoryContentListIsActive = true;
      this.ourStoryItemOptionsPageIsActive = false;
      this.ourStoryEditFormContainerIsActive = false;
      this.selectedGalleryPhotos = [];
    } else if (this.menu_item_name == "select_photo") {
      this.showphotos = false;
      this.showAlbum = true;
    }

    // Reset Submenu forms --
    this.cancelUpdateAlbum();
    this.cancelUpdateEvent();
    this.cancelUpdateTravel();
    this.cancelUpdateThings();
    this.cancelUpdateFaq();

    // Reset Custome Style --
    this.custom_style = null;
  }
  openConfirmDeleteModal(key: string, file: any) {
    this.confirm_modal_delete_key = key;
    this.selected_file_to_delete = file;
    $('#confirmDeleteModal').modal('show');
  }
  confirmDelete() {
    if (this.confirm_modal_delete_key === 'gallery') {
      this.deleteGallery(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'gallery_images') {
      this.confirmRemoveGalleryImages();
    } else if (this.confirm_modal_delete_key === 'gallery_image') {
      this.deleteGalleryPhoto(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'timeline_image') {
      this.deleteTimelinePhoto(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'timeline') {
      this.deleteTimeline(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'cover_image') {
      this.removeCoverPhoto(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'our_story_story_item') {
      this.deleteStory(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'our_story_story_item_photo') {
      this.deleteStoryItemPhoto();
    } else if (this.confirm_modal_delete_key === 'our_story_header') {
      this.deleteTitle(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'our_story_text_block') {
      this.deleteTextBlock(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'our_story_photo_block') {
      this.deleteOurStoryPhotoBlock(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'our_story_gif_block') {
      this.deleteOurStoryPhotoBlock(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'event') {
      this.removeEvent(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'travel') {
      this.removeTravel(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'photo_album') {
      this.removeAlbum(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'album_photo') {
      this.removePhotos(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'album_photos') {
      this.confirmDeleteAlbumPhotos();
    } else if (this.confirm_modal_delete_key === 'things_to_do') {
      this.removeThingsTodo(this.selected_file_to_delete);
    } else if (this.confirm_modal_delete_key === 'q_and_a') {
      this.removeFaqs(this.selected_file_to_delete);
    }
  }
  go_back_handler() {
    if (this.showphotos) {
      this.showMenu = false;
      this.showAlbum = true;
      this.showphotos = false;
    } else if (this.ourStoryItemOptionsPageIsActive === true) {
      this.showMenu = false;
      this.ourStoryContentListIsActive = true;
      this.ourStoryItemOptionsPageIsActive = false;
      this.ourStoryEditFormContainerIsActive = false;
    } else if (this.ourStoryEditFormContainerIsActive === true) {
      this.showMenu = false;
      this.ourStoryContentListIsActive = true;
      this.ourStoryItemOptionsPageIsActive = false;
      this.ourStoryEditFormContainerIsActive = false;
    } else {
      this.showMenu = true;
    }
  }
  openLeftSidebar() {
    let el = document.getElementById("template_left_sidebar");
    el.style.display = "block";
    let overlayEl = document.getElementById("mobile_sidebar_overlay");
    overlayEl.style.display = "block";
  }
  closeLeftSidebar() {
    let el = document.getElementById("template_left_sidebar");
    el.style.display = "none";
    let overlayEl = document.getElementById("mobile_sidebar_overlay");
    overlayEl.style.display = "none";
  }
  expandCollapse() {
    if (this.tagsExpended) {
      this.tagsExpended = false;
      this.visibleTagListData = [...this.tagListData].splice(0, this.first_tag_items_count);
    } else {
      this.tagsExpended = true;
      this.visibleTagListData = [...this.tagListData];
    }
  }
  pagination(current: number, last: number, delta = 2) {
    if (last === 1) return ["*" + 1 + "*"];

    const left = current - delta,
      right = current + delta + 1,
      range = [];

    if (last > 1 && current !== 1) {
      range.push("<");
    }

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        if (i === left && i > 2) {
          range.push("...");
        }

        if (i === current) {
          range.push("*" + i + "*");
        } else {
          range.push(i);
        }

        if (i === right - 1 && i < last - 1) {
          range.push("...");
        }
      }
    }

    if (last > 1 && current !== last) {
      range.push(">");
    }

    return range;
  }
  getPageItemText(pageItem: any) {
    if (isNaN(pageItem)) {
      if (pageItem.indexOf('*') === 0) {
        return pageItem.substr(1, (pageItem.length - 2));
      }
    } else {
      return pageItem;
    }
  }
  isNumber(value: any) {
    return !isNaN(value);
  }
  goPrev() {
    this.current_design_list_page = this.current_design_list_page - 1;
    this.templateList();
  }
  goNext() {
    this.current_design_list_page = this.current_design_list_page + 1;
    this.templateList();
  }
  goToPage(pageItem: any) {
    this.current_design_list_page = Number(pageItem);
    this.templateList();
  }
  saveFaqs() {
    let data = {
      wedding_id: this.activeUser.id,
      question: this.question,
      answer: this.answer,
      custom_style: JSON.stringify(this.custom_style)
    }
    this.WeddingWebsiteService.savedfaqs(data).subscribe(res => {
      this.toastr.success(res.message);
      this.question = "";
      this.answer = "";
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      this.toastr.error(error.message);
      console.log(error);
    })
  }
  updateFaq() {
    let data = {
      id: this.faqId,
      wedding_id: this.activeUser.id,
      question: this.question,
      answer: this.answer,
      custom_style: JSON.stringify(this.custom_style)
    }
    this.WeddingWebsiteService.updateFaq(data).subscribe(res => {
      this.toastr.success(res.message);
      this.cancelUpdateFaq();
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      this.toastr.error(error.message);
      console.log(error);
    })
  }
  cancelUpdateFaq() {
    this.editFaq = false;
    this.faqId = null;
    this.question = "";
    this.answer = "";
  }
  setFaq(data: any, index: number) {
    this.editFaq = true;
    this.faqId = data.id;
    this.question = data.question;
    this.answer = data.answer;
    this.selected_qa_index = index;
  }
  removeFaqs(faqsId: number) {
    let data = {
      id: faqsId
    };
    this.WeddingWebsiteService.deleteFaqs(data).subscribe(res => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
      this.cancelUpdateFaq();
    }, error => {
      console.log(error);
      this.toastr.error(error.message);
      $('#confirmDeleteModal').modal('hide');
    })
  }
  coverImageChangeEvent(event: any): void {
    var files = event.target.files;
    let file: File = files[0];
    if (!(file.size > (1024 * 100) && file.size < (1024 * 5 * 1024))) {
      this.toastr.error("Image file size should be between 100KB and 5MB");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e: any) => {
      const imgBase64Path = e.target.result;
      this.coverPhotoImageData = imgBase64Path;

      let data = {
        image_type: "cover",
        wedding_id: this.activeUser.id,
        // title: "",
        description: "",
        // name: file.name,
        // type: file.type,
        base64: imgBase64Path
      };
      this.WeddingWebsiteService.uploadPhotos(data).subscribe(res => {
        this.toastr.success('Cover Image has been updated successfully');
        this.getWeddingWebsite();
        this.refresh_count++;
      }, error => {
        console.log(error);
      });
    };
  }
  getImageURL(imageName: string) {
    if (imageName && imageName.includes("data:")) {
      return imageName;
    } else {
      return this.galleryPhotosUrl + imageName;
    }
  }
  coverImageForPageChangeEvent(event: any, pageData: any): void {
    var files = event.target.files;
    let file: File = files[0];
    if (!(file.size > (1024 * 100) && file.size < (1024 * 5 * 1024))) {
      this.toastr.error("Image file size should be between 100KB and 5MB");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e: any) => {
      const imgBase64Path = e.target.result;
      pageData.cover_image = imgBase64Path;
    };
  }
  updateWebsiteInfo() {
    let formData = {
      weddingId: this.activeUser.id,
      firstName: this.groomName,
      secondName: this.brideName,
      weddingDate: this.weddingDate,
      weddingCity: this.weddingCity,
      weddingState: this.weddingState,
      weddingCountry: this.weddingCountry,
      custom_style: JSON.stringify(this.custom_style)
    };
    this.WeddingWebsiteService.updateWebsiteInfo(formData).subscribe(res => {
      this.toastr.success(res.message);
      this.website_info = res.website_info;
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  // add event 
  get g() {
    return this.AddEventForm.controls;
  }
  getTime(e: any) {
    this.selectedTime = e;
  }
  convertTimeTo12HourFormat(datetime: any) {
    if (!datetime) return;
    var d = new Date(datetime);
    var hours = d.getHours();
    var minute_string = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();

    let time_format = "";
    let hour_string = "";
    if (hours > 11) {
      time_format = "pm";
      hours = hours - 12;
      hour_string = (hours < 10 ? '0' : '') + hours;
    } else {
      time_format = "am";
      hour_string = (hours < 10 ? '0' : '') + hours;
    }
    return [hour_string, minute_string].join(':') + " " + time_format;
  }
  convertFormatedTimeTo24HourTime(time_string: any) {
    let time_format = time_string.substr(6, 2);
    let event_time = time_string.substr(0, 5);
    let hour = event_time.split(":")[0];
    let minute = event_time.split(":")[1];
    if (time_format === "pm") {
      hour = Number(hour) + 12;
    }
    let time = hour + ":" + minute + ":00";
    return time;
  }
  getTodayString() {
    let d = new Date();
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    let day_string = (day < 10 ? "0" : "") + day;
    let month_string = (month < 10 ? "0" : "") + month;
    return [year, month_string, day_string].join("-");
  }
  saveEvent() {
    this.event_submitted = true;
    if (this.AddEventForm.invalid) {
      this.toastr.error('Please fill out all required fields');
      return;
    }
    let event_time = this.convertTimeTo12HourFormat(this.AddEventForm.value.time);

    var data = {
      wedding_id: this.activeUser.id,
      title: this.AddEventForm.value.title,
      date: this.AddEventForm.value.date,
      time: event_time,
      city: this.AddEventForm.value.city,
      zip: this.AddEventForm.value.zipCode,
      phone_number: this.AddEventForm.value.phoneNumber,
      website: this.AddEventForm.value.website,
      address: this.AddEventForm.value.address,
      description: this.AddEventForm.value.description,
      direction: this.AddEventForm.value.direction,
      custom_style: JSON.stringify(this.custom_style)
    }
    this.WeddingWebsiteService.updateEvent(data).subscribe(res => {
      this.toastr.success(res.message);
      this.refresh_count++;
      this.getWeddingWebsite();
      this.AddEventForm.reset();
      this.event_submitted = false;
    }, error => {
      console.log(error);
    });
  }
  setEventValue(data: any, index: number) {
    this.editEvent = true;
    this.eventId = data.id;
    let event_time = null;
    if (data.event_time) {
      event_time = new Date(this.getTodayString() + " " + this.convertFormatedTimeTo24HourTime(data.event_time));
      this.selectedTime = event_time;
    }
    this.AddEventForm.get('title').setValue(data.title);
    this.AddEventForm.get('date').setValue(data.event_date);
    this.AddEventForm.get('time').setValue(event_time);
    this.AddEventForm.get('city').setValue(data.city);
    this.AddEventForm.get('zipCode').setValue(data.zip);
    this.AddEventForm.get('phoneNumber').setValue(data.phone_number);
    this.AddEventForm.get('website').setValue(data.website);
    this.AddEventForm.get('address').setValue(data.address);
    this.AddEventForm.get('description').setValue(data.description);
    this.AddEventForm.get('direction').setValue(data.direction);
    this.selected_event_index = index;
  }
  updateEvent() {
    this.event_submitted = true;
    if (this.AddEventForm.invalid) {
      this.toastr.error('Please fill out all required fields');
      return;
    }
    let updateTime = this.convertTimeTo12HourFormat(this.AddEventForm.value.time);
    var data = {
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
      title: this.AddEventForm.value.title,
      date: this.AddEventForm.value.date,
      time: updateTime,
      city: this.AddEventForm.value.city,
      zip: this.AddEventForm.value.zipCode,
      phone_number: this.AddEventForm.value.phoneNumber,
      website: this.AddEventForm.value.website,
      address: this.AddEventForm.value.address,
      description: this.AddEventForm.value.description,
      direction: this.AddEventForm.value.direction,
      custom_style: JSON.stringify(this.custom_style)
    }
    this.WeddingWebsiteService.updateEvent(data).subscribe(res => {
      this.toastr.success(res.message);
      this.refresh_count++;
      this.getWeddingWebsite();
      this.cancelUpdateEvent();
    }, error => {
      console.log(error);
    })
  }
  cancelUpdateEvent() {
    this.editEvent = false;
    this.AddEventForm.reset();
    this.event_submitted = false;
    this.eventId = null;
  }
  removeEvent(id: number) {
    let data = {
      id: id
    };
    this.WeddingWebsiteService.deleteEvent(data).subscribe(res => {
      this.toastr.success(res.message);
      $('#confirmDeleteModal').modal('hide');
      this.getWeddingWebsite();
      this.refresh_count++;
      this.cancelUpdateEvent();
    }, error => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.error(error.message);
      console.log(error);
    })
  }
  // add travel 
  get f() {
    return this.AddTravelForm.controls;
  }
  saveTravel() {
    this.travel_submitted = true;
    if (this.AddTravelForm.invalid) {
      this.toastr.error('Please fill out all required fields');
      return;
    }
    var data = {
      wedding_id: this.activeUser.id,
      title: this.AddTravelForm.value.title,
      website: this.AddTravelForm.value.Website,
      phone_number: this.AddTravelForm.value.phoneNumber,
      address: this.AddTravelForm.value.address,
      city: this.AddTravelForm.value.city,
      zip: this.AddTravelForm.value.zipCode,
      description: this.AddTravelForm.value.description,
      direction: this.AddTravelForm.value.direction,
      custom_style: JSON.stringify(this.custom_style)
    };
    this.WeddingWebsiteService.updateTravel(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.AddTravelForm.reset();
      this.travel_submitted = false;
      this.refresh_count++;
    }, error => {
      console.log(error);
    });
  }
  setTravelValue(data: any, index: number) {
    this.selected_travel_index = index;
    this.editTravel = true;
    this.travelId = data.id;
    this.AddTravelForm.get('title').setValue(data.title);
    this.AddTravelForm.get('Website').setValue(data.website);
    this.AddTravelForm.get('direction').setValue(data.direction);
    this.AddTravelForm.get('phoneNumber').setValue(data.phone_number);
    this.AddTravelForm.get('address').setValue(data.address);
    this.AddTravelForm.get('city').setValue(data.city);
    this.AddTravelForm.get('zipCode').setValue(data.zip);
    this.AddTravelForm.get('description').setValue(data.description);
  }
  updateTravel() {
    this.travel_submitted = true;
    if (this.AddTravelForm.invalid) {
      this.toastr.error('Please fill out all required fields');
      return;
    }
    var data = {
      wedding_id: this.activeUser.id,
      travel_id: this.travelId,
      title: this.AddTravelForm.value.title,
      website: this.AddTravelForm.value.Website,
      phone_number: this.AddTravelForm.value.phoneNumber,
      address: this.AddTravelForm.value.address,
      city: this.AddTravelForm.value.city,
      zip: this.AddTravelForm.value.zipCode,
      description: this.AddTravelForm.value.description,
      direction: this.AddTravelForm.value.direction,
      custom_style: JSON.stringify(this.custom_style)
    }
    this.WeddingWebsiteService.updateTravel(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.cancelUpdateTravel();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  removeTravel(id: number) {
    let data = {
      id: id
    };
    this.WeddingWebsiteService.deleteTravel(data).subscribe(res => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
      this.cancelUpdateTravel();
    }, error => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.error(error.message);
      console.log(error);
    })
  }
  cancelUpdateTravel() {
    this.editTravel = false;
    this.AddTravelForm.reset();
    this.travel_submitted = false;
    this.travelId = null;
  }
  // add things to do  
  get t() {
    return this.AddThingsToDoForm.controls;
  }
  saveThings() {
    this.things_submitted = true;
    if (this.AddThingsToDoForm.invalid) {
      this.toastr.error('Please fill out all required fields');
      return;
    }
    var data = {
      wedding_id: this.activeUser.id,
      title: this.AddThingsToDoForm.value.thingsToDo,
      website_url: this.AddThingsToDoForm.value.website_url,
      phone_number: this.AddThingsToDoForm.value.phoneNumber,
      city: this.AddThingsToDoForm.value.city,
      zipCode: this.AddThingsToDoForm.value.zipCode,
      address: this.AddThingsToDoForm.value.address,
      note: this.AddThingsToDoForm.value.description,
      direction: this.AddThingsToDoForm.value.direction,
      hours_of_operation: this.AddThingsToDoForm.value.HoursOfOperation,
      custom_style: JSON.stringify(this.custom_style)
    }
    this.WeddingWebsiteService.updateThingsToDo(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.AddThingsToDoForm.reset();
      this.things_submitted = false;
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  setThingstodo(data: any, index: number) {
    this.editThings = true;
    this.thingsId = data.id;
    this.selected_things_to_do_index = index;
    this.AddThingsToDoForm.get('thingsToDo').setValue(data.title);
    this.AddThingsToDoForm.get('website_url').setValue(data.website_url);
    this.AddThingsToDoForm.get('direction').setValue(data.direction);
    this.AddThingsToDoForm.get('phoneNumber').setValue(data.phone_number);
    this.AddThingsToDoForm.get('city').setValue(data.city);
    this.AddThingsToDoForm.get('zipCode').setValue(data.zip);
    this.AddThingsToDoForm.get('address').setValue(data.address);
    this.AddThingsToDoForm.get('description').setValue(data.note);
    this.AddThingsToDoForm.get('HoursOfOperation').setValue(data.hours_of_operation);
  }
  updateThings() {
    this.things_submitted = true;
    if (this.AddThingsToDoForm.invalid) {
      this.toastr.error('Please fill out all required fields');
      return;
    }
    var data = {
      id: this.thingsId,
      wedding_id: this.activeUser.id,
      title: this.AddThingsToDoForm.value.thingsToDo,
      website_url: this.AddThingsToDoForm.value.website_url,
      phone_number: this.AddThingsToDoForm.value.phoneNumber,
      city: this.AddThingsToDoForm.value.city,
      zipCode: this.AddThingsToDoForm.value.zipCode,
      address: this.AddThingsToDoForm.value.address,
      note: this.AddThingsToDoForm.value.description,
      direction: this.AddThingsToDoForm.value.direction,
      hours_of_operation: this.AddThingsToDoForm.value.HoursOfOperation,
      custom_style: JSON.stringify(this.custom_style)
    }
    this.WeddingWebsiteService.updateThingsToDo(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.cancelUpdateThings();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  removeThingsTodo(id: number) {
    let data = {
      id: id
    };
    this.WeddingWebsiteService.deleteThingsToDo(data).subscribe(res => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
      this.cancelUpdateThings();
    }, error => {
      this.toastr.error(error.message);
      $('#confirmDeleteModal').modal('hide');
      console.log(error);
    })
  }
  cancelUpdateThings() {
    this.AddThingsToDoForm.reset();
    this.editThings = false;
    this.things_submitted = false;
    this.thingsId = null;
  }
  // add photos and album 
  get p() {
    return this.AddPhotoForm.controls;
  }
  handle_photos(data: any, index: number) {
    this.selected_photo_album_index = index;
    this.photoAlbumId = data.id;
    this.albumTitle = data.title;
    this.showMenu = false;
    this.showphotos = true;
    this.showAlbum = false;
    this.filterGalleryPhotos = this.galleryPhotos.filter((res: any) => res.album_id == this.photoAlbumId)
    this.AddPhotoForm.get('description').setValue(data.note);
    this.numberOfPhotosSelected = 0;
    this.photosSelected = false;
  }
  fileChangeEvent(event: any): void {
    var allowed_file_types = ["image/jpeg", "image/jpg", "image/gif", "image/png"];
    var multipleFiles = event.target.files;
    if (multipleFiles) {
      this.numberOfPhotosSelected = multipleFiles.length;
      this.photosSelected = true;
      let image_skipped = 0;
      for (var file of multipleFiles) {
        if (!(file.size > (1024 * 100) && file.size < (1024 * 1024 * 5))) {
          image_skipped++;
          continue;
        }
        if (!allowed_file_types.includes(file.type)) {
          image_skipped++;
          continue;
        }
        var multipleReader = new FileReader();
        multipleReader.onload = (e: any) => {
          this.profile_image.push(e.target.result);
        }
        multipleReader.readAsDataURL(file);
      }
      if (image_skipped > 0) {
        if (image_skipped == 1) {
          this.toastr.error(`${image_skipped} image was failed to upload. Please upload a jpeg, jpg, gif or png image and file size should be between 100KB and 5MB`);
        } else {
          this.toastr.error(`${image_skipped} images were failed to upload. Please upload a jpeg, jpg, gif or png image and file size should be between 100KB and 5MB`);
        }
      }
    }
  }
  savedAlbum() {
    this.album_submitted = true;
    if (this.AddPhotoForm.invalid) {
      return;
    }
    var data = {
      image_type: "gallery",
      wedding_id: this.activeUser.id,
      album_id: this.photoAlbumId,
      album_note: this.AddPhotoForm.value.description,
      base64: this.profile_image,
      custom_style: JSON.stringify(this.custom_style)
    };
    this.WeddingWebsiteService.uploadPhotos(data).subscribe(res => {
      this.toastr.success(res.message);
      this.profile_image = []
      this.numberOfPhotosSelected = 0;
      this.photosSelected = false;
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  removePhotos(id: number) {
    this.imgId = id;
    let data = {
      id: this.imgId
    };
    this.WeddingWebsiteService.deletePhotos(data).subscribe(res => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.error(error.message);
      console.log(error);
    })
  }
  addAlbum() {
    if (this.AddAlbumForm.invalid) {
      return;
    }
    let data = {
      album_name: this.AddAlbumForm.value.albumName,
      wedding_id: this.activeUser.id,
      custom_style: JSON.stringify(this.custom_style)
    }
    this.WeddingWebsiteService.addPhotoAlbum(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.AddAlbumForm.reset();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  setAlbumValue(data: any, index: number) {
    this.editAlbum = true;
    this.albumId = data.id;
    this.AddAlbumForm.get('albumName').setValue(data.title);
    this.selected_photo_album_index = index;
  }
  updateAlbum() {
    if (this.AddAlbumForm.invalid) {
      return;
    }
    var data = {
      id: this.albumId,
      album_name: this.AddAlbumForm.value.albumName,
      wedding_id: this.activeUser.id,
      custom_style: JSON.stringify(this.custom_style)
    }
    this.WeddingWebsiteService.addPhotoAlbum(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.editAlbum = false;
      this.AddAlbumForm.reset();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  cancelUpdateAlbum() {
    this.editAlbum = false;
    this.albumId = null;
    this.AddAlbumForm.reset();
  }
  removeAlbum(id: number) {
    let data = {
      id: id
    };
    this.WeddingWebsiteService.deletePhotoAlbum(data).subscribe(res => {
      this.toastr.success(res.message);
      $('#confirmDeleteModal').modal('hide');
      this.getWeddingWebsite();
      this.cancelUpdateAlbum();
      this.refresh_count++;
    }, error => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.error(error.message);
      console.log(error);
    })
  }
  openMoveToAlbumModal(image: any) {
    this.currentAlbumForImageMove = image.album_id;
    this.currentImageToMove = image.id;
    $('#moveToAlbumModal').modal('toggle');
  }
  selectAlbum(data: any) {
    this.currentAlbumForImageMove = data.id;
  }
  moveToAlbum() {
    let data = {
      album_id: this.currentAlbumForImageMove,
      wedding_id: this.activeUser.id,
      image_id: this.currentImageToMove
    };
    this.WeddingWebsiteService.movePhotoToAlbum(data).subscribe(res => {
      this.toastr.success(res.message);
      $('#moveToAlbumModal').modal('toggle');
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  confirmDeleteAlbumPhotos() {
    let data = {
      ids: this.checkedPhotos
    };
    this.WeddingWebsiteService.deleteMultiplePhotos(data).subscribe(res => {
      this.toastr.success(res.message);
      $('#confirmDeleteModal').modal('hide');
      this.getWeddingWebsite();
      this.cancelUpdateAlbum();
      this.refresh_count++;
      this.showRemoveButton = false;
      this.checkedPhotos = [];
    }, error => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.error(error.message);
      console.log(error);
    });
  }
  saveEventOrder() {
    let newOrder = this.eventList.map((event: any, index: number) => {
      return { "event_id": event.id, "display_order": index }
    });
    let data = {
      wedding_id: this.activeUser.id,
      display_order: newOrder
    }
    this.WeddingWebsiteService.updateEventOrder(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  saveFaqOrder() {
    let newOrder = this.faqs.map((event: any, index: number) => {
      return { "faq_id": event.id, "display_order": index }
    });
    let data = {
      wedding_id: this.activeUser.id,
      display_order: newOrder
    }
    this.WeddingWebsiteService.updateFaqOrder(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  saveTravelOrder() {
    let newOrder = this.travelList.map((travelData: any, index: number) => {
      return { "travel_id": travelData.id, "display_order": index }
    });
    let data = {
      wedding_id: this.activeUser.id,
      display_order: newOrder
    }
    this.WeddingWebsiteService.updateTravelOrder(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  saveThingsToDoOrder() {
    let newOrder = this.thingsList.map((data: any, index: number) => {
      return { "things_to_do_id": data.id, "display_order": index }
    });
    let data = {
      wedding_id: this.activeUser.id,
      display_order: newOrder
    }
    this.WeddingWebsiteService.updateThingsToDoOrder(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  saveGalleryPhotoOrder() {
    let newOrder = this.filterGalleryPhotos.map((photo: any, index: number) => {
      return { "photo_id": photo.id, "display_order": index }
    });
    let data = {
      wedding_id: this.activeUser.id,
      display_order: newOrder
    }
    this.WeddingWebsiteService.updateAlbumImageOrder(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  saveAlbumOrder() {
    let newOrder = this.album.map((eachAlbum: any, index: number) => {
      return { "album_id": eachAlbum.id, "display_order": index }
    });
    let data = {
      wedding_id: this.activeUser.id,
      display_order: newOrder
    }
    this.WeddingWebsiteService.updateAlbumOrder(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    })
  }
  singleSavedChecked(e: any, id: any) {
    if (e.target.checked == true) {
      this.checkedPhotos.push(id);
      this.showRemoveButton = true;
    } else {
      const index = this.checkedPhotos.indexOf(id);
      this.checkedPhotos.splice(index, 1);
      if (this.checkedPhotos.length == 0) {
        this.showRemoveButton = false;
      }
    }
  }
  formatDate() {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  get tt() {
    return this.AddTitleForm.controls;
  }
  // Our Story --
  editOurStory(storyData: any, index: number) {
    this.ourStoryContentListIsActive = false;
    this.ourStoryItemOptionsPageIsActive = false;
    this.ourStoryEditFormContainerIsActive = true;
    this.saveEditData = "Edit";
    this.currentStoryItem = storyData;

    if (storyData.content_type == "photo_timeline") {
      this.ourStoryActiveItemName = 'timeline';
      this.timeline_image = storyData.content.photos.map((item: any) => {
        return { ...item, image_date: item.image_date.substr(0, 10), custom_style: item.custom_style };
      });
    } else if (storyData.content_type == "photo_gallery") {
      this.ourStoryActiveItemName = 'gallery'
      this.gallery_image = storyData.content.photos;
      this.resetGallery(storyData);
    } else if (storyData.content_type == "title_block") {
      this.ourStoryActiveItemName = 'title';
      this.resetOurStoryTitleForm(storyData);
    } else if (storyData.content_type == "story_item") {
      this.ourStoryActiveItemName = 'story';
      this.resetOurStoryStoryItemForm(storyData);
    } else if (storyData.content_type == "text_block") {
      this.ourStoryActiveItemName = 'text';
      this.resetOurStoryTextBlock(storyData);
    } else if (storyData.content_type == "photo_block") {
      this.ourStoryActiveItemName = 'photo';
      this.our_story_photo_block_image_url = this.galleryPhotosUrl + storyData.content.image_file_name;;
    }

    this.custom_style = null;
    this.selected_our_story_item_index = index;
  }
  showOurStoryItemOptionsPage() {
    this.ourStoryItemOptionsPageIsActive = true;
    this.ourStoryContentListIsActive = false;
    this.ourStoryEditFormContainerIsActive = false;
  }
  showOurStoryEditFormContainer(ourStoryContentType: string) {
    this.ourStoryEditFormContainerIsActive = true;
    this.ourStoryContentListIsActive = false;
    this.ourStoryItemOptionsPageIsActive = false;
    this.saveEditData = 'Save';
    this.ourStoryActiveItemName = ourStoryContentType;

    // Reset Form --
    this.currentStoryItem = null;
    if (ourStoryContentType === 'story') {
      this.resetOurStoryStoryItemForm();
    } else if (ourStoryContentType === "title") {
      this.resetOurStoryTitleForm();
    } else if (ourStoryContentType === "timeline") {
      this.resetTimelineForm();
    } else if (ourStoryContentType == "text") {
      this.resetOurStoryTextForm();
    } else if (ourStoryContentType == "gallery") {
      this.resetGallery();
    }
  }
  saveOurStoryContentOrder() {
    let newOrder = this.ourStoryData.map((content: any, index: number) => {
      return { "our_story_content_id": content.id, "display_order": index }
    });
    let data = {
      wedding_id: this.activeUser.id,
      display_order: newOrder
    }
    this.WeddingWebsiteService.updateOurStoryContentDisplayOrder(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    });
  }
  // Our Story: Story Item --
  storyImageChangeEvent(event: any): void {
    var files = event.target.files;
    let file: File = files[0];
    if (!(file.size > (1024 * 100) && file.size < (1024 * 5 * 1024))) {
      this.toastr.error("Image file size should be between 100KB and 5MB");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      const imgBase64Path = e.target.result;
      this.storyPhotoImageData = imgBase64Path;
      this.storyImage = imgBase64Path;
      this.our_story_story_item_photo_url = imgBase64Path;
    };
  }
  saveStory() {
    if (this.ourTitle == '') {
      this.toastr.success('Please enter the title');
      return
    }
    if (this.saveEditData == "Save") {
      this.storyUpdateData = {
        our_story_content_id: '',
        wedding_id: this.activeUser.id,
        display_order: '',
        title: this.ourTitle,
        note: this.storyContent,
        base64: this.storyPhotoImageData,
        custom_style: JSON.stringify(this.custom_style)
      };
      this.WeddingWebsiteService.savedStoryContent(this.storyUpdateData).subscribe(res => {
        this.toastr.success(res.message);
        this.resetOurStoryStoryItemForm();
        this.getWeddingWebsite();
        this.refresh_count++;
        this.go_back_handler();
      }, error => {
        console.log(error);
      })
    }
    if (this.saveEditData == "Edit") {
      this.storyUpdateData = {
        our_story_content_id: this.currentStoryItem.id,
        wedding_id: this.activeUser.id,
        title: this.ourTitle,
        note: this.storyContent,
        base64: this.our_story_story_item_photo_url,
        custom_style: JSON.stringify(this.custom_style)
      };
      this.WeddingWebsiteService.savedStoryContent(this.storyUpdateData).subscribe(res => {
        this.toastr.success(res.message);
        this.getWeddingWebsite();
        this.refresh_count++;
      }, error => {
        console.log(error);
      });
    }
  }
  deleteStory(storyData: any) {
    this.currentStoryItem = storyData;
    let data = {
      our_story_content_id: this.currentStoryItem.id,
      wedding_id: this.activeUser.id
    };
    this.WeddingWebsiteService.removeOurStoryItem(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      $('#confirmDeleteModal').modal('hide');
      this.ourStoryEditFormContainerIsActive = false;
      this.ourStoryContentListIsActive = true;

      this.ourTitle = '';
      this.storyImage = ''
      this.ourTitle = '';
      this.storyContent = '';
      this.storyPhotoImageData = '';

      this.refresh_count++;
    }, error => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.error(error.message);
      console.log(error);
    })
  }
  deleteStoryItemPhoto() {
    if (this.saveEditData == 'Save') {
      this.our_story_story_item_photo_url = '';
      this.storyImage = '';
      this.storyPhotoImageData = '';
      $('#confirmDeleteModal').modal('hide');
    } else if (this.saveEditData == 'Edit') {
      let data = {
        our_story_id: this.storyItemImageId,
      };
      this.WeddingWebsiteService.removeOurStoryItemPhoto(data).subscribe(res => {
        $('#confirmDeleteModal').modal('hide');
        this.toastr.success(res.message);
        this.our_story_story_item_photo_url = '';
        this.storyImage = ''
        this.storyPhotoImageData = '';
        this.getWeddingWebsite();
        this.refresh_count++;
      }, error => {
        this.toastr.error(error.message);
        $('#confirmDeleteModal').modal('hide');
        console.log(error);
      })
    }
  }
  resetOurStoryStoryItemForm(data: any = null) {
    if (data) {
      if (data.content.image_file_name) {
        this.our_story_story_item_photo_url = this.galleryPhotosUrl + data.content.image_file_name;
      }
      this.ourTitle = data.content.title;
      this.storyContent = data.content.note;
      this.storyItemImageId = data.ref_id;
      this.selectedGalleryPhotos = [];
    } else {
      this.our_story_story_item_photo_url = "";
      this.ourTitle = "";
      this.storyContent = "";
    }
  }
  // Our Story: Title --
  resetOurStoryTitleForm(data: any = null) {
    if (data) {
      this.AddTitleForm.get('title').setValue(data.content.title);
      this.selectedOption = data.content.title_size;
    } else {
      this.AddTitleForm.get('title').setValue("");
      this.selectedOption = null;
    }
  }
  handleTitleSizeChange(event: any) {
    this.selectedOption = event.target.value;
  }
  saveTitle() {
    if (this.saveEditData == "Save") {
      this.title_submitted = true;
      if (this.AddTitleForm.invalid) {
        return;
      }
      let formData = {
        wedding_id: this.activeUser.id,
        title: this.AddTitleForm.value.title,
        title_size: this.selected_font_size,
        custom_style: JSON.stringify(this.custom_style)
      }
      console.log(formData);
      this.WeddingWebsiteService.createTitle(formData).subscribe(res => {
        this.toastr.success(res.message);
        this.getWeddingWebsite();
        this.AddTitleForm.reset();
        this.title_submitted = false;
        this.refresh_count++;
        this.go_back_handler();
      }, error => {
        console.log(error);
      })
    } else if (this.saveEditData == "Edit") {
      let editformData = {
        our_story_content_id: this.currentStoryItem.id,
        wedding_id: this.activeUser.id,
        title: this.AddTitleForm.value.title,
        title_size: this.selected_font_size,
        custom_style: JSON.stringify(this.custom_style)
      };
      this.WeddingWebsiteService.createTitle(editformData).subscribe(res => {
        this.toastr.success(res.message);
        this.getWeddingWebsite();
        this.title_submitted = false;
        this.refresh_count++;
      }, error => {
        console.log(error);
      })
    }
  }
  deleteTitle(storyData: any) {
    this.currentStoryItem = storyData;
    let data = {
      our_story_content_id: this.currentStoryItem.id,
      wedding_id: this.activeUser.id
    }
    this.WeddingWebsiteService.removeOurStoryItem(data).subscribe(res => {
      this.toastr.success(res.message);
      $('#confirmDeleteModal').modal('hide');
      this.getWeddingWebsite();
      this.ourStoryEditFormContainerIsActive = false;
      this.ourStoryContentListIsActive = true;
      this.refresh_count++;
    }, error => {
      console.log(error);
      $('#confirmDeleteModal').modal('hide');
      this.toastr.error(error.message);
    })
  }
  // Our Story: Text --
  resetOurStoryTextBlock(data: any = null) {
    if (data) {
      this.storyTextBlock = data.content.note;
    } else {
      this.storyTextBlock = "";
    }
  }
  saveTextBlock() {
    if (this.saveEditData == "Save") {
      let textBlockData = {
        wedding_id: this.activeUser.id,
        note: this.storyTextBlock
      }
      this.WeddingWebsiteService.createTextBlock(textBlockData).subscribe(res => {
        this.toastr.success(res.message);
        this.getWeddingWebsite();
        this.refresh_count++;
        this.go_back_handler();
      }, error => {
        console.log(error);
      })
    } else if (this.saveEditData == "Edit") {
      let updateTextBlockData = {
        our_story_content_id: this.currentStoryItem.id,
        wedding_id: this.activeUser.id,
        note: this.storyTextBlock
      };
      this.WeddingWebsiteService.createTextBlock(updateTextBlockData).subscribe(res => {
        this.toastr.success(res.message);
        this.getWeddingWebsite();
        this.refresh_count++;
      }, error => {
        console.log(error);
      });
    }
  }
  deleteTextBlock(storyData: any) {
    this.currentStoryItem = storyData;
    let data = {
      our_story_content_id: this.currentStoryItem.id,
      wedding_id: this.activeUser.id
    };
    this.WeddingWebsiteService.removeOurStoryItem(data).subscribe(res => {
      this.toastr.success(res.message);
      $('#confirmDeleteModal').modal('hide');
      this.getWeddingWebsite();
      this.ourStoryEditFormContainerIsActive = false;
      this.ourStoryContentListIsActive = true;
      this.refresh_count++;
    }, error => {
      console.log(error);
      $('#confirmDeleteModal').modal('hide');
      this.toastr.error(error.message);
    });
  }
  resetOurStoryTextForm(data: any = null) {
    if (data) {
      this.storyTextBlock = data;
    } else {
      this.storyTextBlock = null;
    }
  }
  // Our Story: Timeline --
  handleFileUploadOurStoryTimeline(event: any): void {
    var allowed_file_types = ["image/jpeg", "image/jpg", "image/gif", "image/png"];
    var multipleFiles = event.target.files;

    if (multipleFiles) {
      let image_skipped = 0;
      for (var file of multipleFiles) {
        let timelineFileType = file.type;
        if (!allowed_file_types.includes(timelineFileType)) {
          image_skipped++;
          continue;
        }
        if (!(file.size > (1024 * 100) && file.size < (1024 * 500))) {
          image_skipped++;
          continue;
        }
        var multipleReader = new FileReader();
        multipleReader.onload = (e: any) => {
          let timelineData = {
            image_date: this.getTodayString(),
            note: '',
            base64: e.target.result,
            custom_style: JSON.stringify(this.custom_style)
          };
          this.timeline_image.push(timelineData);
        }
        multipleReader.readAsDataURL(file);
      }
      if (image_skipped > 0) {
        if (image_skipped == 1) {
          this.toastr.error(`${image_skipped} image was failed to upload. Please upload a jpeg, jpg, gif or png image and file size should be between 100KB and 500KB`);
        } else {
          this.toastr.error(`${image_skipped} images were failed to upload. Please upload a jpeg, jpg, gif or png image and file size should be between 100KB and 500KB`);
        }
      }
    }
  }
  deleteTimeline(storyData: any) {
    this.currentStoryItem = storyData;
    let data = {
      our_story_content_id: this.currentStoryItem.id,
      wedding_id: this.activeUser.id
    };
    this.WeddingWebsiteService.removeOurStoryItem(data).subscribe(res => {
      this.toastr.success(res.message);
      $('#confirmDeleteModal').modal('hide');
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
      this.toastr.error(error.message);
      $('#confirmDeleteModal').modal('hide');
    });
  }
  saveTimelinePhotos() {
    if (this.saveEditData == "Save") {
      let timelineCreateData = {
        wedding_id: this.activeUser.id
      };
      var data = {
        timeline_id: '',
        image_data: this.timeline_image
      };
      this.WeddingWebsiteService.createStoryTimeline(timelineCreateData).subscribe(res => {
        this.timelineDetail = res.content_details;
        data.timeline_id = this.timelineDetail.ref_id;
        this.WeddingWebsiteService.savedTimeline(data).subscribe(res => {
          this.timeline_image = res.timelineDetails.photos.map((item: any) => {
            return { ...item, image_date: item.image_date.substr(0, 10) };
          });
          this.toastr.success(res.message);
          this.getWeddingWebsite();
          this.go_back_handler();
          this.refresh_count++;
        }, error => {
          console.log(error);
        })
      }, error => {
        console.log(error);
      });
    } else if (this.saveEditData == "Edit") {
      var Editdata = {
        timeline_id: this.currentStoryItem.ref_id,
        image_data: this.timeline_image
      };
      this.WeddingWebsiteService.savedTimeline(Editdata).subscribe(res => {
        this.timeline_image = res.timelineDetails.photos.map((item: any) => {
          return { ...item, image_date: item.image_date.substr(0, 10) };
        });
        this.toastr.success(res.message);
        this.getWeddingWebsite();
        this.refresh_count++;
      }, error => {
        console.log(error);
      })
    }
  }
  deleteTimelinePhoto(imageData: any) {
    if (imageData.base64) {
      this.timeline_image = this.timeline_image.filter((object: any) => {
        return object.base64 !== imageData.base64;
      });
      $('#confirmDeleteModal').modal('hide');
    } else if (imageData.image_file_name) {
      let data = {
        timeline_photo_id: imageData.id
      };
      this.WeddingWebsiteService.removeTimelinePhoto(data).subscribe(res => {
        this.toastr.success(res.message);
        $('#confirmDeleteModal').modal('hide');
        this.getWeddingWebsite();
        this.timeline_image = this.timeline_image.filter((object: any) => {
          return object.id !== imageData.id;
        });
        this.refresh_count++;
      }, error => {
        this.toastr.error(error.message);
        $('#confirmDeleteModal').modal('hide');
        console.log(error);
      })
    }
  }
  resetTimelineForm(data: any = null) {
    if (data) {
      this.timeline_image = data;
    } else {
      this.timeline_image = [];
    }
  }
  saveOurStoryTimelineImageDisplayOrder() {
    let newOrder = this.timeline_image.map((content: any, index: number) => {
      return { "timeline_id": content.id, "display_order": index }
    });
    let data = {
      wedding_id: this.activeUser.id,
      display_order: newOrder
    }
    this.WeddingWebsiteService.updateOurStoryTimelinePhotosDisplayOrder(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    });
  }
  // Our Story - Gallery Photos --
  galleryFileUploadEvent(event: any): void {
    var allowed_file_types = ["image/jpeg", "image/jpg", "image/gif", "image/png"];
    var multipleFiles = event.target.files;

    if (multipleFiles) {
      let image_skipped = 0;
      for (var file of multipleFiles) {
        let galleryFileType = file.type;
        if (!allowed_file_types.includes(galleryFileType)) {
          image_skipped++;
          continue;
        }
        if (!(file.size > (1024 * 1) && file.size < (1024 * 500))) {
          image_skipped++;
          continue;
        }
        var multipleReader = new FileReader();
        multipleReader.onload = (e: any) => {
          let galleryData = {
            base64: e.target.result
          };
          this.gallery_image.push(galleryData);
        }
        multipleReader.readAsDataURL(file);
      }
      if (image_skipped > 0) {
        if (image_skipped == 1) {
          this.toastr.error(`${image_skipped} image was failed to upload. Please upload a jpeg, jpg, gif or png image and file size should be between 100KB and 500KB`);
        } else {
          this.toastr.error(`${image_skipped} images were failed to upload. Please upload a jpeg, jpg, gif or png image and file size should be between 100KB and 500KB`);
        }
      }
    }
  }
  savedGallery() {
    if (this.saveEditData == "Save") {
      let galleryCreateData = {
        wedding_id: this.activeUser.id
      };
      var data = {
        gallery_id: '',
        image_data: this.gallery_image
      };
      this.WeddingWebsiteService.createStoryGallery(galleryCreateData).subscribe(res => {
        this.galleryDetail = res.content_details;
        data.gallery_id = this.galleryDetail.ref_id;
        this.WeddingWebsiteService.savedGallery(data).subscribe(res => {
          this.gallery_image = res.galleryDetails.photos;
          this.toastr.success(res.message);
          this.getWeddingWebsite();
          this.go_back_handler();
          this.refresh_count++;
        }, error => {
          console.log(error);
        });
      }, error => {
        console.log(error);
      });
    } else if (this.saveEditData == "Edit") {
      var Editdata = {
        gallery_id: this.currentStoryItem.ref_id,
        image_data: this.gallery_image
      };
      this.WeddingWebsiteService.savedGallery(Editdata).subscribe(res => {
        this.gallery_image = res.galleryDetails.photos;
        this.toastr.success(res.message);
        this.getWeddingWebsite();
        this.go_back_handler();
        this.refresh_count++;
      }, error => {
        console.log(error);
      });
    }
  }
  deleteGallery(storyData: any) {
    this.currentStoryItem = storyData;
    let data = {
      our_story_content_id: this.currentStoryItem.id,
      wedding_id: this.activeUser.id
    };
    this.WeddingWebsiteService.removeOurStoryItem(data).subscribe(res => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      this.toastr.error(error.message);
      console.log(error);
    });
  }
  deleteGalleryPhoto(galleryImage: any) {
    if (galleryImage.image_file_name) {
      let data = {
        gallery_photo_id: galleryImage.id,
      };
      this.WeddingWebsiteService.removeGalleryPhotos(data).subscribe(res => {
        $('#confirmDeleteModal').modal('hide');
        this.toastr.success(res.message);
        this.getWeddingWebsite();
        this.refresh_count++;
        // Remove image from gallery_image --
        this.gallery_image = this.gallery_image.filter((object: any) => {
          return object.id !== galleryImage.id;
        });
        // Remove id from selectedGalleryPhotos -- 
        const index = this.selectedGalleryPhotos.indexOf(galleryImage.id);
        this.selectedGalleryPhotos.splice(index, 1);
        if (this.selectedGalleryPhotos.length == 0) {
          this.showRemoveGalleryPhotosButton = false;
        }
      }, error => {
        this.toastr.error(error.message);
        $('#confirmDeleteModal').modal('hide');
        console.log(error);
      });
    } else if (galleryImage.base64) {
      this.gallery_image = this.gallery_image.filter((object: any) => {
        return object.base64 !== galleryImage.base64;
      });
      $('#confirmDeleteModal').modal('hide');
    }
  }
  confirmRemoveGalleryImages() {
    let data = {
      gallery_photo_ids: this.selectedGalleryPhotos,
    };
    this.WeddingWebsiteService.removeGalleryPhotos(data).subscribe(res => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.success(res.message);
      this.gallery_image = this.gallery_image.filter((object: any) => {
        return !this.selectedGalleryPhotos.includes(object.id);
      });
      this.selectedGalleryPhotos = [];
      this.getWeddingWebsite();
      this.refresh_count++;
      this.showRemoveGalleryPhotosButton = false;
    }, error => {
      this.toastr.error(error.message);
      $('#confirmDeleteModal').modal('hide');
      console.log(error);
    });
  }
  resetGallery(data: any = null) {
    if (data) {
      this.gallery_image = data.content.photos.map((item: any) => { return { ...item, ...{ isSelected: false } } });
      this.selectedGalleryPhotos = [];
    } else {
      this.gallery_image = [];
      this.selectedGalleryPhotos = [];
    }
  }
  selectGalleryImage(e: any, id: any) {
    if (e.target.checked == true) {
      this.selectedGalleryPhotos.push(id);
      this.showRemoveGalleryPhotosButton = true;
    } else {
      const index = this.selectedGalleryPhotos.indexOf(id);
      this.selectedGalleryPhotos.splice(index, 1);
      if (this.selectedGalleryPhotos.length == 0) {
        this.showRemoveGalleryPhotosButton = false;
      }
    }
  }
  saveOurStoryGalleryImageDisplayOrder() {
    let newOrder = this.gallery_image.map((content: any, index: number) => {
      return { "gallery_photo_id": content.id, "display_order": index }
    });
    let data = {
      wedding_id: this.activeUser.id,
      display_order: newOrder
    }
    this.WeddingWebsiteService.updateOurStoryGalleryPhotosDisplayOrder(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      console.log(error);
    });
  }
  // Photo and GIF --
  gifChangeEvent(event: any): void {
    var files = event.target.files;
    let file: File = files[0];
    if (!(file.size > (1024 * 100) && file.size < (1024 * 1024 * 3))) {
      this.toastr.error("Image file size should be between 100KB and 3MB");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      const imgBase64Path = e.target.result;
      this.storyGifData = imgBase64Path;
      var data = {
        our_story_content_id: '',
        wedding_id: this.activeUser.id,
        image_type: "gif",
        photo: this.storyGifData
      }
      this.WeddingWebsiteService.createGif(data).subscribe(res => {
        this.toastr.success(res.message);
        this.getWeddingWebsite();
        this.refresh_count++;
        this.go_back_handler();
      }, error => {
        console.log(error);
      })
    };
  }
  photoChangeEvent(event: any): void {
    var files = event.target.files;
    let file: File = files[0];
    this.fileType = event.target.files[0].type;
    this.imageChangedCropEvent = event;
    if (!(file.size > (1024 * 100) && file.size < (1024 * 5 * 1024))) {
      this.toastr.error("Image file size should be between 100KB and 5MB");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      const imgBase64Path = e.target.result;
      this.storyPhotoData = imgBase64Path;
      if (this.fileType == 'image/jpeg' || this.fileType == 'image/jpg' || this.fileType == 'image/png') {
        $('#cropPhotoModal').modal('show');
        this.showCropper = true;
        this.showTham = false;
      } else {
        this.showCropper = false;
        this.toastr.error('Please upload a jpeg, jpg, png image');
      }
    };
  }
  async uploadPhotoForOurStoryPhotoBlock() {
    this.showCropper = false;
    this.showTham = true;
    var data = {
      our_story_content_id: '',
      wedding_id: this.activeUser.id,
      image_type: "static",
      photo: this.storyPhotoData
    }
    this.WeddingWebsiteService.createGif(data).subscribe(res => {
      this.toastr.success(res.message);
      $('#cropPhotoModal').modal('hide');
      this.getWeddingWebsite();
      this.refresh_count++;
      this.go_back_handler();
    }, error => {
      console.log(error);
    })
  }
  deleteOurStoryPhotoBlock(storyData: any) {
    this.currentStoryItem = storyData;
    let data = {
      our_story_content_id: this.currentStoryItem.id,
      wedding_id: this.activeUser.id
    };
    this.WeddingWebsiteService.removeOurStoryItem(data).subscribe(res => {
      $('#confirmDeleteModal').modal('hide');
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      this.toastr.error(error.message);
      $('#confirmDeleteModal').modal('hide');
      console.log(error);
    });
  }
  imageCropped(event: ImageCroppedEvent) {
    this.showCropper = true;
    this.storyPhotoData = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady(e: any) {
    // cropper ready
  }
  removeCroppedImage() {
    this.showCropper = false;
    this.showTham = true;
    $('#cropPhotoModal').modal('hide');
    this.ourStoryContentListIsActive = true;
  }
  // Template Design Font Theme --
  handleColorSelected(color: string) {
    this.selected_font_color = color;
  }
  handleColorSelectedForTextHeaderBox(color: string) {
    this.selected_font_color_for_text_header_box = color;
  }
  handleColorSelectedForTextBox(color: string) {
    this.selected_font_color_for_text_box = color;
  }
  handleColorSelectedForMediaBox(color: string) {
    this.selected_font_color_for_media_box = color;
  }
  openTemplateFontThemeModal(keyname: string, index: number = null) {
    this.selected_font_attribute_name = keyname;
    // Form Fields --
    let travel_form_fields = ["travel_name_of_accommodation", "travel_address", "travel_city", "travel_zipcode", "travel_phoneNumber", "travel_website", "travel_direction", "travel_description"];
    let event_form_fields = ["event_title", "event_date", "event_time", "event_phone_number", "event_website", "event_address", "event_city_state", "event_zip_code", "event_description", "event_direction"];
    let website_info_form_fields = ["website_info_groomName", "website_info_brideName", "website_info_weddingDate", "website_info_city", "website_info_state", "website_info_country"];
    let page_info_form_fields = ["page_title", "page_description", "page_travelling_tip"];
    let things_to_do_form_fields = ["things_to_do", "things_to_do_address", "things_to_do_phoneNumber", "things_to_do_city", "things_to_do_zip", "things_to_do_website", "things_to_do_description", "things_to_do_hours_of_operation", "things_to_do_direction"];
    let qa_form_fields = ["faqs_question", "faqs_answer"];
    let photo_album_form_fields = ["photos_albumName", "photos_album_description"];
    let our_story_story_item_fields = ["story_item_title"];
    let our_story_timeline_form_fields = ["timeline_date", "timeline_note"];
    let our_story_header_form_fields = ["story_title_block"];

    // Get Default Style --
    let defaultStyle: any;
    if(this.themeDetails && this.themeDetails.default_color_scheme){
      defaultStyle = this.getDefaultFontStyle(keyname, this.themeDetails.default_color_scheme);
    }else{
      defaultStyle = this.getDefaultFontStyle(keyname);
    }

    // Get Custom Style --
    if(page_info_form_fields.includes(keyname) && index >= 0){
      let attribute = this.pages[index];
      if(this.selected_font_attribute_index !== index){
        this.selected_font_attribute_index = index;
        if(attribute && attribute.custom_style && Object.keys(attribute.custom_style).length > 0){
          this.custom_style = JSON.parse(attribute.custom_style);
        }else{
          this.custom_style = {};
        }
      }
    }else if(website_info_form_fields.includes(keyname)){
      this.selected_font_attribute_index = null;
      let attribute = this.website_info;
      if(!this.custom_style || Object.keys(this.custom_style).length === 0){
        if(attribute && attribute.custom_style && Object.keys(attribute.custom_style).length > 0){
          this.custom_style = JSON.parse(attribute.custom_style);
        }else{
          this.custom_style = {};
        }
      }
    }else if(event_form_fields.includes(keyname)){
      if(this.selected_event_index !== this.selected_font_attribute_index){
        this.selected_font_attribute_index = this.selected_event_index;
        let attribute = this.eventList[this.selected_font_attribute_index];
        if(attribute && attribute.custom_style && Object.keys(attribute.custom_style).length > 0){
          this.custom_style = JSON.parse(attribute.custom_style);
        }else{
          this.custom_style = {};
        }
      }
    }else if(travel_form_fields.includes(keyname)){
      if(this.selected_travel_index !== this.selected_font_attribute_index){
        this.selected_font_attribute_index = this.selected_travel_index;
        let attribute = this.travelList[this.selected_font_attribute_index];
        if(attribute && attribute.custom_style && Object.keys(attribute.custom_style).length > 0){
          this.custom_style = JSON.parse(attribute.custom_style);
        }else{
          this.custom_style = {};
        }
      }
    }else if(things_to_do_form_fields.includes(keyname)){
      if(this.selected_things_to_do_index !== this.selected_font_attribute_index){
        this.selected_font_attribute_index = this.selected_things_to_do_index;
        let attribute = this.thingsList[this.selected_font_attribute_index];
        if(attribute && attribute.custom_style && Object.keys(attribute.custom_style).length > 0){
          this.custom_style = JSON.parse(attribute.custom_style);
        }else{
          this.custom_style = {};
        }
      }
    }else if(qa_form_fields.includes(keyname)){
      if(this.selected_qa_index !== this.selected_font_attribute_index){
        this.selected_font_attribute_index = this.selected_qa_index;
        let attribute = this.faqs[this.selected_font_attribute_index];
        if(attribute && attribute.custom_style && Object.keys(attribute.custom_style).length > 0){
          this.custom_style = JSON.parse(attribute.custom_style);
        }else{
          this.custom_style = {};
        }
      }
    }else if(photo_album_form_fields.includes(keyname)){
      if(this.selected_photo_album_index !== this.selected_font_attribute_index){
        this.selected_font_attribute_index = this.selected_photo_album_index;
        let attribute = this.album[this.selected_font_attribute_index];
        if(attribute && attribute.custom_style && Object.keys(attribute.custom_style).length > 0){
          this.custom_style = JSON.parse(attribute.custom_style);
        }else{
          this.custom_style = {};
        }
      }
    }else if(our_story_story_item_fields.includes(keyname)){
      let attribute = this.currentStoryItem.content;
      if(attribute && attribute.custom_style && Object.keys(attribute.custom_style).length > 0){
        this.custom_style = JSON.parse(attribute.custom_style);
      }else{
        this.custom_style = {};
      }
    }else if(our_story_timeline_form_fields.includes(keyname) && index >= 0){
      if(index != this.selected_font_attribute_index){
        let attribute = this.timeline_image[index];
        this.selected_font_attribute_index = index;
        if(attribute && attribute.custom_style && Object.keys(attribute.custom_style).length > 0){
          this.custom_style = JSON.parse(attribute.custom_style);
        }else{
          this.custom_style = {};
        }
      }
    }else if(our_story_header_form_fields.includes(keyname)){
      if(this.selected_font_attribute_name !== keyname || !this.custom_style){
        let attribute = this.currentStoryItem.content;
        this.selected_font_attribute_index = index;
        if(attribute && attribute.custom_style && Object.keys(attribute.custom_style).length > 0){
          this.custom_style = JSON.parse(attribute.custom_style);
        }else{
          this.custom_style = {};
        }
      }
    }

    // Pupulate form field --
    if (this.custom_style && this.custom_style.hasOwnProperty(keyname)) {
      let settings = this.custom_style[keyname];
      if(settings["color"] && settings["color"] !== "null"){
        this.selected_font_color = settings["color"];
      }else{
        this.selected_font_color = defaultStyle["color"];
      }
      if(settings["font-family"] && settings["font-family"] !== "null"){
        this.selected_font_family = settings["font-family"];
      }else{
        this.selected_font_family = defaultStyle["font-family"];
      }
      if(settings["font-size"] && settings["font-size"] !== "null"){
        this.selected_font_size = settings["font-size"];
      }else{
        this.selected_font_size = defaultStyle["font-size"];
      }
      if(settings["font-weight"] && settings["font-weight"] !== "null"){
        this.selected_font_weight = settings["font-weight"];
      }else{
        this.selected_font_weight = defaultStyle["font-weight"];
      }
    }else{
      this.selected_font_color = defaultStyle["color"];
      this.selected_font_family = defaultStyle["font-family"];
      this.selected_font_size = defaultStyle["font-size"];
      this.selected_font_weight = defaultStyle["font-weight"];
    }

    // Whenever the modal will be opened, the apply to all field checkbox should be unselected --
    this.apply_to_all_field = false;

    // Open the modal --
    $('#templateThemeModal').modal('show');
  }
  openAllPageSettingModal(){
    $('#allPageThemeSettingModal').modal('show');
  }
  getDefaultFontStyle(key_name: string, color_scheme: string = "black"){ 
    let finalStyleObject = {
      "font-family": "Arial"
    };
    if(color_scheme === 'white'){
      finalStyleObject["color"] = "#FFFFFF";
    }else{
      finalStyleObject["color"] = "#000000";
    }
    if(key_name === 'page_title' || key_name === 'timeline_date'){
      finalStyleObject['font-size'] = "16px";
      finalStyleObject['font-weight'] = "600";
    }else if(key_name === 'website_info_groomName' || key_name === 'website_info_brideName'){
      finalStyleObject['font-size'] = "40px";
      finalStyleObject['font-weight'] = "600";
    }else if(key_name === 'website_info_weddingDate' || key_name === 'website_info_city' || key_name === 'website_info_state' || key_name === 'faqs_question' || key_name === 'event_title' || key_name === 'travel_name_of_accommodation' || key_name === 'things_to_do' || key_name === 'story_item_title' || key_name === 'story_title_block' || key_name === "photos_albumName"){
      finalStyleObject['font-size'] = "25px";
      finalStyleObject['font-weight'] = "600";
    }else{
      finalStyleObject['font-size'] = "20px";
      finalStyleObject['font-weight'] = "400";
    }
    return finalStyleObject;
  }
  saveSelectedTemplateFontTheme() {
    // Form Fields --
    let travel_form_fields = ["travel_name_of_accommodation", "travel_address", "travel_city", "travel_zipcode", "travel_phoneNumber", "travel_website", "travel_direction", "travel_description"];
    let event_form_fields = ["event_title", "event_date", "event_time", "event_phone_number", "event_website", "event_address", "event_city_state", "event_zip_code", "event_description", "event_direction"];
    let website_info_form_fields = ["website_info_groomName", "website_info_brideName", "website_info_weddingDate", "website_info_city", "website_info_state", "website_info_country"];
    let things_to_do_form_fields = ["things_to_do", "things_to_do_address", "things_to_do_phoneNumber", "things_to_do_city", "things_to_do_zip", "things_to_do_website", "things_to_do_description", "things_to_do_hours_of_operation", "things_to_do_direction"];
    let qa_form_fields = ["faqs_question", "faqs_answer"];
    let our_story_timeline_form_fields = ["timeline_date", "timeline_note"];

    // Update Custom Style --
    if (this.custom_style && Object.keys(this.custom_style).length > 0) {
      if (this.custom_style[this.selected_font_attribute_name]) {
        this.custom_style[this.selected_font_attribute_name] = {
          ...this.custom_style[this.selected_font_attribute_name],
          ...{
            "color": this.selected_font_color,
            'font-size': this.selected_font_size,
            'font-weight': this.selected_font_weight,
            'font-family': this.selected_font_family
          }
        };
      } else {
        this.custom_style[this.selected_font_attribute_name] = {
          "color": this.selected_font_color,
          'font-size': this.selected_font_size,
          'font-weight': this.selected_font_weight,
          'font-family': this.selected_font_family
        };
      }
    }else{
      this.custom_style = {};
      this.custom_style[this.selected_font_attribute_name] = {
        "color": this.selected_font_color,
        'font-size': this.selected_font_size,
        'font-weight': this.selected_font_weight,
        'font-family': this.selected_font_family
      };
    }

    // Apply to all fields --
    if (this.apply_to_all_field === true) {
      if (website_info_form_fields.includes(this.selected_font_attribute_name)) {
        let style_to_apply = {...this.custom_style[this.selected_font_attribute_name]};
        website_info_form_fields.forEach((item) => {
          this.custom_style[item] = style_to_apply;
        });
      } else if (travel_form_fields.includes(this.selected_font_attribute_name)) {
        let style_to_apply = { ...this.custom_style[this.selected_font_attribute_name] };
        travel_form_fields.forEach((item) => {
          this.custom_style[item] = style_to_apply;
        });
      } else if (event_form_fields.includes(this.selected_font_attribute_name)) {
        let style_to_apply = { ...this.custom_style[this.selected_font_attribute_name] };
        event_form_fields.forEach((item) => {
          this.custom_style[item] = style_to_apply;
        });
      } else if (things_to_do_form_fields.includes(this.selected_font_attribute_name)) {
        let style_to_apply = { ...this.custom_style[this.selected_font_attribute_name] };
        things_to_do_form_fields.forEach((item) => {
          this.custom_style[item] = style_to_apply;
        });
      } else if (qa_form_fields.includes(this.selected_font_attribute_name)) {
        let style_to_apply = { ...this.custom_style[this.selected_font_attribute_name] };
        qa_form_fields.forEach((item) => {
          this.custom_style[item] = style_to_apply;
        });
      } else if (our_story_timeline_form_fields.includes(this.selected_font_attribute_name)) {
        // Apply to all fields of timeline item --
        our_story_timeline_form_fields.forEach((item) => {
          this.custom_style[item] = this.custom_style[this.selected_font_attribute_name];
        });
        // Apply custom style to all timiline item --
        let custom_style = JSON.stringify(this.custom_style);
        this.timeline_image = this.timeline_image.map((timeline: any) => {
          return { ...timeline, ...{ "custom_style": custom_style } };
        });
      }
    }else{
      if (our_story_timeline_form_fields.includes(this.selected_font_attribute_name)) {
        let custom_style = JSON.stringify(this.custom_style);
        this.timeline_image[this.selected_font_attribute_index].custom_style = custom_style;
      }
    }

    this.apply_to_all_field = false;
  }
  saveBoxThemeSetting() {
    let box_setting = {
      "text_header_box_color": this.selected_font_color_for_text_header_box,
      "text_header_box_opacity": this.opacity_for_text_header_box,
      "text_box_color": this.selected_font_color_for_text_box,
      "text_box_opacity": this.opacity_for_text_box,
      "media_box_color": this.selected_font_color_for_media_box,
      "media_box_opacity": this.opacity_for_media_box
    };

    let data = {
      wedding_id: this.activeUser.id,
      box_setting: JSON.stringify(box_setting),
    };

    this.WeddingWebsiteService.updateBoxThemeSetting(data).subscribe(res => {
      $('#allPageThemeSettingModal').modal('hide');
      this.toastr.success(res.message);
      this.getWeddingWebsite();
      this.refresh_count++;
    }, error => {
      this.toastr.error(error.message);
      $('#allPageThemeSettingModal').modal('hide');
      console.log(error);
    });
  }
  valueChanged(e,type) {
    if(type == 'header_Box'){
      this.opacity_for_text_header_box = e/100;
      this.opacity_for_text_header_box_value = e;
    }
    if(type == 'text_Box'){
      this.opacity_for_text_box = e/100;
      this.opacity_for_text_box_value = e;
    }
    if(type == 'media_Box'){
      this.opacity_for_media_box = e/100;
      this.opacity_for_media_box_value = e;
    }
  }
  getCompactURL(url: string){
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width <= 900){
      if(url.startsWith("https://www.")){
        return url.substring(12);
      }else if(url.startsWith("https://")){
        return url.substring(8);
      }else{
        return url;
      }
    }else{
      return url;
    }
  }

}