import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { LoginModel } from 'src/app/models/login.model';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { CategoryModel } from 'src/app/models/category.model';
import { VendorBusinessInfoModel } from 'src/app/models/vendor.business.info.model';
import { extend } from 'webdriver-js-extender';
import { UsersService } from '../../users/services/users.service';
import { VendorLocationInfoModel } from 'src/app/models/vendor.location.info.model';
import { ChangePasswordModel } from 'src/app/models/change-password.model';
import { EmployeeModel } from 'src/app/models/employee.model';
import { SettingsModel } from 'src/app/models/settings.model';
import { MediadModel } from 'src/app/models/media.model';
import { EventModel } from 'src/app/models/event.model';
import { EventTypeModel } from 'src/app/models/eventtype.model';
import { DealModel } from 'src/app/models/deal.model';
import { PriceModel } from 'src/app/models/price.model';
import { SocialMediaModel } from 'src/app/models/social.media.model';
import { MessageModel } from 'src/app/models/message.model';
import { AvailabilityModel } from 'src/app/models/availability.model';
import { RequestPriceModel } from 'src/app/models/request.price.model';
import { PackageSubscriptionModel } from 'src/app/models/package.subscription.model';
import { VendorDetailModel } from 'src/app/models/vendor.detail.model';

@Injectable({
  providedIn: 'root'
})
export class VendorsService extends BaseService {
  private searchSubject: BehaviorSubject<any>;
  public searchClick: Observable<any>;

  constructor(private http: HttpClient, protected usersService: UsersService) {
    super(usersService);
    this.searchSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('searchClick')));
    this.searchClick = this.searchSubject.asObservable();
  }

  getCategories(business_type: string): Observable<CategoryModel[]> {
    var data = {
      business_type: business_type
    };

    return this.http
      .post(Config.serviceEndPoints.taxonomies.typewisecategories, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var categories: CategoryModel[] = [];
        response.data.forEach(item => {
          categories.push((new CategoryModel).deserialize(item));
        });
        return categories;
      }));
  }

  getAllVendorReviews(pageNumber: number) : Observable<any> {    
    let url = Config.serviceEndPoints.vendors.allreviews;
    if(pageNumber){
      url = url + "?page_number=" + pageNumber;
    }
    return this.http
      .get(url, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  submitReviewReply(data: any) : Observable<any> {    
    let url = Config.serviceEndPoints.vendors.submitReviewReply;

    return this.http
      .post(url, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  submitReplyToReviewByUser(data: any) : Observable<any> {    
    let url = Config.serviceEndPoints.weddingUsers.submitReplyToReviewByUser;

    return this.http
      .post(url, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  deleteReviewReply(data: any) : Observable<any> {    
    let url = Config.serviceEndPoints.vendors.deleteReviewReply;

    return this.http
      .post(url, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  deleteReviewReplyByUser(data: any) : Observable<any> {    
    let url = Config.serviceEndPoints.weddingUsers.deleteReviewReplyByUser;

    return this.http
      .post(url, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  updateReviewReply(data: any) : Observable<any> {    
    let url = Config.serviceEndPoints.vendors.updateReviewReply;

    return this.http
      .post(url, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  updateReviewReplyByUser(data: any) : Observable<any> {    
    let url = Config.serviceEndPoints.weddingUsers.updateReviewReplyByUser;

    return this.http
      .post(url, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  submitReviewRequest(data: any) : Observable<any> {    
    let url = Config.serviceEndPoints.vendors.submitReviewRequest;

    return this.http
      .post(url, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getRequestForReviewLog(data: any) : Observable<any> {    
    let url = Config.serviceEndPoints.vendors.getRequestForReviewLog;

    const params = new URLSearchParams({});
    if(data.page_size){
      params.set('page_size', data.page_size);
    }
    if(data.page_number){
      params.set('page_number', data.page_number);
    }

    url = url + "?" + params.toString();

    return this.http
      .get(url, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getWeddingUserDetails(data: any) : Observable<any> {    
    let url = Config.serviceEndPoints.vendors.getWeddingUserDetails;

    const params = new URLSearchParams({});
    if(data.email){
      params.set('email', data.email);
    }

    url = url + "?" + params.toString();

    return this.http
      .get(url, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  setSearchFilter(filterValue : any){
    localStorage.setItem('searchClick', JSON.stringify(filterValue));
    this.searchSubject.next(filterValue);
  }

  allCategories(name: string): Observable<CategoryModel[]> {
    var data = {
      search_value : name 
    }
    return this.http
      .post(Config.serviceEndPoints.taxonomies.categories, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var categories: CategoryModel[] = [];
        response.data.forEach(item => {
          categories.push((new CategoryModel).deserialize(item));
        });
        return categories;
      }));
  }

  updateBusinessInfo(data: VendorBusinessInfoModel): Observable<VendorBusinessInfoModel> {
    //console.log(this.getHttpHeaders());return;
    return this.http
      .put(Config.serviceEndPoints.vendors.businessinfo, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new VendorBusinessInfoModel().deserialize(response);
      }));
  }

  getBusinessInfo(): Observable<VendorBusinessInfoModel> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.vendors.getbusinessinfo, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  updateLocationInfo(data: VendorLocationInfoModel): Observable<VendorLocationInfoModel> {
    //console.log(this.getHttpHeaders());return;
    return this.http
      .put(Config.serviceEndPoints.vendors.locationinfo, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new VendorLocationInfoModel().deserialize(response);
      }));
  }

  getLocationInfo(): Observable<VendorLocationInfoModel> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.vendors.getbusinessinfo, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  changePassword(data: ChangePasswordModel): Observable<ChangePasswordModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.changepassword, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new ChangePasswordModel().deserialize(response);
      }));
  }

  //Employee module
  saveEmployee(data: EmployeeModel): Observable<EmployeeModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.employee, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new EmployeeModel().deserialize(response);
      }));
  }

  getEmployee(id: number): Observable<EmployeeModel> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.vendors.getemployee + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getEmployees(data): Observable<EmployeeModel[]> {
     
    return this.http
      .post(Config.serviceEndPoints.vendors.employees, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var employees: EmployeeModel[] = [];
        response.data.forEach(item => {

          employees.push((new EmployeeModel).deserialize(item));
        });
        return employees;
      }));
  }

  getAuthVendorDetail() : Observable<any> {    
    return this.http
      .get(Config.serviceEndPoints.vendors.vendordetails, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  slugToVendorId(data): Observable<any> {     
    return this.http
      .post(Config.serviceEndPoints.vendors.slugtovendorid, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }       
        return response;
      }));
  }

  deleteEmployee(id: number): Observable<EmployeeModel> {
    var data = {
      id: id
    };
    return this.http
      .delete(Config.serviceEndPoints.vendors.deleteemployees + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  //Settings module
  saveSetting(data: SettingsModel): Observable<SettingsModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.savesettings, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new SettingsModel().deserialize(response);
      }));
  }

  saveAvailabilitySetting(data: SettingsModel): Observable<SettingsModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.availabilityupdatesetting, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new SettingsModel().deserialize(response);
      }));
  }

  getSettingByVendor(): Observable<SettingsModel> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.vendors.getsettings, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getSettings(): Observable<SettingsModel[]> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.settings.getsettings, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var settings: SettingsModel[] = [];
        response.data.forEach(item => {

          settings.push((new SettingsModel).deserialize(item));
        });
        return settings;
      }));
  }

  searchVendor(data): Observable<any> {
    
    return this.http
      .post(Config.serviceEndPoints.vendors.searchvendors, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        /* var vendors:[] = [];
        response.data.forEach(item => {
          
          vendors.push(item);
        }); */
        return response.data;
      }));
  }

  categoryWiseLeftPanel(data): Observable<any> {    
    return this.http
      .post(Config.serviceEndPoints.vendors.categorywiseleftpanel, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  vendorDetails(id:number): Observable<VendorBusinessInfoModel> {    
    return this.http
      .get(Config.serviceEndPoints.vendors.vendordetails+"/"+id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return new VendorBusinessInfoModel().deserialize(response.data);
      }));
  }
  // Vendor Info --
  getVendorInfo(data: any) : Observable<any> {
    return this.http
      .post(Config.serviceEndPoints.vendors.vendorinfo, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  //Media module
  getFileSettings(data: any) : Observable<any> {
    return this.http
      .post(Config.serviceEndPoints.vendors.mediasettings, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }
  saveMedia(data: any): Observable<MediadModel> {
    //console.log(data);return;
    return this.http
      .post(Config.serviceEndPoints.vendors.savemedia, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new MediadModel().deserialize(response);
      }));
  }

  saveMediaTitle(data: any): Observable<MediadModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.mediatitle, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new MediadModel().deserialize(response);
      }));
  }

  saveFeaturedImage(data: any): Observable<VendorDetailModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.savefeaturedimage, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new VendorDetailModel().deserialize(response);
      }));
  }

  generatePanoramicImage(){
    var data = {};
    return this.http
      .post(Config.serviceEndPoints.vendors.generatepanoramicimage, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new VendorDetailModel().deserialize(response);
      }));
  }

  getMedia(type: string): Observable<MediadModel[]> {
    return this.http
      .get(Config.serviceEndPoints.vendors.getmedia + "/" + type, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var settings: MediadModel[] = [];
        response.data.forEach(item => {

          settings.push((new MediadModel).deserialize(item));
        });
        return settings;
      }));
  }

  deleteMedia(ids: any): Observable<MediadModel> {
    var data = {
      ids: ids
    };
    return this.http
      .post(Config.serviceEndPoints.vendors.deletemedia,data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  makeFeatured(id: number): Observable<MediadModel> {
    var data = {
      id: id
    };    
    return this.http
      .put(Config.serviceEndPoints.vendors.featuredemedia,data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getThumbImage(): Observable<any> {
    var data = {
    };    
    return this.http
      .post(Config.serviceEndPoints.vendors.getthumbimage,data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteThumbImage(): Observable<any> {
    var data = {
    };
    return this.http
      .post(Config.serviceEndPoints.vendors.deletethumbimage,data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  saveDegreeVideo(file) : Observable<any> {
    var data = file;
    return this.http
      .post(Config.serviceEndPoints.vendors.savedegreevideo,data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getDegreeVideo(): Observable<any>{
    var data = {};
    return this.http
      .post(Config.serviceEndPoints.vendors.getdgreevideo,data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteDegreeVideo(): Observable<any>{
    var data = {};
    return this.http
      .post(Config.serviceEndPoints.vendors.deletedegreevideo,data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  //Event Module
  saveEvent(data: EventModel): Observable<EventModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.saveevent, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new EventModel().deserialize(response);
      }));
  }

  getEvent(id: number): Observable<EventModel> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.vendors.getevent + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getEvents(): Observable<EventModel[]> {
    var data = {
    };
    return this.http
      .post(Config.serviceEndPoints.vendors.getevents, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var events: EventModel[] = [];
        response.data.forEach(item => {
          events.push((new EventModel).deserialize(item));
        });
        return events;
      }));
  }

  deleteEvent(id: number): Observable<EventModel> {
    var data = {
      id: id
    };
    return this.http
      .delete(Config.serviceEndPoints.vendors.deleteevent + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getEventType(): Observable<EventTypeModel[]> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.vendors.eventtypes, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var eventtypes: EventTypeModel[] = [];
        response.data.forEach(item => {
          eventtypes.push((new EventTypeModel).deserialize(item));
        });
        return eventtypes;
      }));
  }

  deleteEventPhoto(id: number): Observable<EventModel> {
    var data = {
      id: id
    };
    return this.http
      .delete(Config.serviceEndPoints.vendors.deleteeventphoto + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  filterVendors(filterVal: string): Observable<VendorBusinessInfoModel[]> {
    var data = {
      search_value: filterVal
    };
    return this.http
      .post(Config.serviceEndPoints.vendors.filtervendors, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var vendors: VendorBusinessInfoModel[] = [];
        response.data.forEach(item => {
          vendors.push((new VendorBusinessInfoModel).deserialize(item));
        });
        return vendors;
      }));
  }

  savedVendors(): Observable<VendorBusinessInfoModel[]> {
    var data = {

    };
    return this.http
      .post(Config.serviceEndPoints.vendors.endorsments,data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var vendors: VendorBusinessInfoModel[] = [];
        response.data.forEach(item => {
          vendors.push((new VendorBusinessInfoModel).deserialize(item));
        });
        return vendors;
      }));
  }

  addVendor(id: any): Observable<VendorBusinessInfoModel> {
    var data = {
      preffer_vendor_id: id
    };
    return this.http
      .post(Config.serviceEndPoints.vendors.saveendorsment, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteEndorsment(id: number): Observable<VendorBusinessInfoModel> {
    var data = {
      id: id
    };
    return this.http
      .delete(Config.serviceEndPoints.vendors.deleteendorsments + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  saveComment(data: any): Observable<any> {
    return this.http
      .post(Config.serviceEndPoints.vendors.saveendorsementcomment, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  //Deal module
  saveDeal(data: DealModel): Observable<DealModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.savedeal, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new DealModel().deserialize(response);
      }));
  }

  getDeal(id: number): Observable<DealModel> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.vendors.getdeal + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getDeals(): Observable<DealModel[]> {
    var data = {
    };
    return this.http
      .post(Config.serviceEndPoints.vendors.deals, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var deals: DealModel[] = [];
        response.data.forEach(item => {

          deals.push((new DealModel).deserialize(item));
        });
        return deals;
      }));
  }

  deleteDeal(id: number): Observable<DealModel> {
    var data = {
      id: id
    };
    return this.http
      .delete(Config.serviceEndPoints.vendors.deletedeal + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteDealPhoto(id: number): Observable<DealModel> {
    var data = {
      id: id
    };
    return this.http
      .delete(Config.serviceEndPoints.vendors.deletedealphoto + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  saveDealPdf(data: any): Observable<any> {
    return this.http
      .post(Config.serviceEndPoints.vendors.savedealprice, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteDealPdf(): Observable<any> {
    var data = { };
    return this.http
      .delete(Config.serviceEndPoints.vendors.deletedealdoc, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  //Price module
  savePrice(data: PriceModel): Observable<PriceModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.saveprice, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new PriceModel().deserialize(response);
      }));
  }

  getPrice(id: number): Observable<PriceModel> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.vendors.getprice + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getPrices(): Observable<PriceModel[]> {
    var data = {
    };
    return this.http
      .post(Config.serviceEndPoints.vendors.prices, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var prices: PriceModel[] = [];
        response.data.forEach(item => {
          prices.push((new PriceModel).deserialize(item));
        });
        return prices;
      }));
  }

  deletePrice(id: number): Observable<PriceModel> {
    var data = {
      id: id
    };
    return this.http
      .delete(Config.serviceEndPoints.vendors.deleteprice + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  savePricingPdf(data: any): Observable<any> {
    return this.http
      .post(Config.serviceEndPoints.vendors.savepdfprice, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deletePricePdf(): Observable<any> {
    var data = { };
    return this.http
      .delete(Config.serviceEndPoints.vendors.deletepricedoc, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  //Social Media
  updateSocialMedia(data: SocialMediaModel): Observable<SocialMediaModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.savesettings, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new SocialMediaModel().deserialize(response);
      }));
  }

  getSocialMedia(): Observable<SocialMediaModel> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.vendors.getsettings, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  //Message
  saveMessageSettings(data): Observable<any>{
    return this.http
      .post(Config.serviceEndPoints.vendors.savemessagesettings, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response;
      }));
  }

  getMessageSettings(): Observable<any> {    
    var data = {};
    return this.http
      .post(Config.serviceEndPoints.vendors.forwardemaillist, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getInboxMessage(data: MessageModel): Observable<MessageModel[]> {    
    return this.http
      .post(Config.serviceEndPoints.vendors.messages, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        var messages: MessageModel[] = [];
        response.data.forEach(item => {
          messages.push((new MessageModel).deserialize(item));
        });
        return messages;
      }));
  }

  changeStatus(data): Observable<any>{
    return this.http
      .post(Config.serviceEndPoints.vendors.changemessagestatus, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response;
      }));
  }

  lastReply(data): Observable<any>{
    return this.http
      .post(Config.serviceEndPoints.vendors.lastreply, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  changeType(data): Observable<any>{
    return this.http
      .post(Config.serviceEndPoints.vendors.changemessagetype, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response;
      }));
  }

  replyMessage(data): Observable<any>{
    return this.http
      .post(Config.serviceEndPoints.vendors.replymessage, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response;
      }));
  }
  
  fowardMessage(data): Observable<any>{
    return this.http
      .post(Config.serviceEndPoints.vendors.fowardmessage, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response;
      }));
  }

  showInbox(data): Observable<any>{
    return this.http
      .post(Config.serviceEndPoints.vendors.showinbox, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response;
      }));
  }
  
  defaultEmails(): Observable<any>{
    return this.http
      .post(Config.serviceEndPoints.vendors.defaultemails, {}, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  deleteMessage(ids: any): Observable<MessageModel> {
    var data = {
      ids: ids
    };
    return this.http
      .post(Config.serviceEndPoints.vendors.deletemessage,data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  //Availability
  saveBooking(data: AvailabilityModel): Observable<AvailabilityModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.savebooking, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new AvailabilityModel().deserialize(response);
      }));
  }

  setBlockDay(data: any): Observable<any> {
    return this.http
      .post(Config.serviceEndPoints.vendors.blockday, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getBookings(data): Observable<any> {    
    return this.http
      .post(Config.serviceEndPoints.vendors.getbookings, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        /* var bookings = [];
        response.data.forEach(item => {
          bookings.push(item);
        }); */
        return response;
      }));
  }
  
  getBookingsByDateRange(data): Observable<any> {    
    return this.http
      .post(Config.serviceEndPoints.vendors.checkavailability, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
       
        return response;
      }));
  }

  deleteBooking(id: number): Observable<any> {
    var data = {
      id: id
    };
    return this.http
      .delete(Config.serviceEndPoints.vendors.deletebooking + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getBooking(id: number): Observable<any> {
    var data = {
    };
    return this.http
      .get(Config.serviceEndPoints.vendors.getbooking + "/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  saveAvailability(data: AvailabilityModel): Observable<AvailabilityModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.saveavailability, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new AvailabilityModel().deserialize(response);
      }));
  }

  getAvailability(): Observable<AvailabilityModel> {
    var data = {
    };
    return this.http
      .post(Config.serviceEndPoints.vendors.availability, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  //FAQ
  getFaqs(): Observable<any> {
    var data = {

    };
    return this.http
      .post(Config.serviceEndPoints.vendors.getfaqs, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  getRegFaqs(data): Observable<any> {
    
    return this.http
      .post(Config.serviceEndPoints.vendors.getregfaqs, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  getCustomFaqs(): Observable<any> {
    var data = {};
    return this.http
      .post(Config.serviceEndPoints.vendors.getfaqcustom, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  saveFaq(data: any): any {
    return this.http
      .post(Config.serviceEndPoints.vendors.savefaq, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  saveFaqCustom(data: any): any {
    return this.http
      .post(Config.serviceEndPoints.vendors.faqcustom, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  //RequestPrice
  saveRequestPrice(data: RequestPriceModel): Observable<RequestPriceModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.saverequestprice, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new RequestPriceModel().deserialize(response);
      }));
  }

  vendorPricePlans(): Observable<any> {    
    return this.http
      .get(Config.serviceEndPoints.vendors.getsubscriptionprices, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  //Subscription Plan
  vendorFeaturePlans(): Observable<any> {    
    return this.http
      .get(Config.serviceEndPoints.vendors.getsubscriptionfeatures, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  vendorCurrentPlan(): Observable<PackageSubscriptionModel> {    
    return this.http
      .get(Config.serviceEndPoints.vendors.getcurrentsubscription, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  calPrice(data: any): Observable<any> {
    return this.http
      .post(Config.serviceEndPoints.vendors.calculateprice, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  adjustmentAmount(data: any): Observable<any> {
    return this.http
      .post(Config.serviceEndPoints.vendors.adjustmentamount, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  addPlan(data: PackageSubscriptionModel): Observable<PackageSubscriptionModel> {
    return this.http
      .post(Config.serviceEndPoints.vendors.addplan, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return new PackageSubscriptionModel().deserialize(response);
      }));
  }

  //Service attribute
  saveAttribute(data: any): any {
    return this.http
      .post(Config.serviceEndPoints.vendors.saveattribute, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getAttributes(): Observable<any> {
    var data = {};
    return this.http
      .post(Config.serviceEndPoints.vendors.getattributes, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  selectedAttributes(): Observable<any> {
    var data = {};
    return this.http
      .post(Config.serviceEndPoints.vendors.selectedattributes, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  //Page view count
  viewCount(args): Observable<any> {
    var data = args;
    return this.http
      .post(Config.serviceEndPoints.vendors.viewcount, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  //Graph Data
  showGraph(args): Observable<any> {
    var data = args;
    return this.http
      .post(Config.serviceEndPoints.vendors.graph, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  showCount(data): Observable<any> {
    return this.http
      .post(Config.serviceEndPoints.vendors.showcount, data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }        
        return response.data;
      }));
  }

  removeFilter() {
    localStorage.removeItem('searchClick');
    this.searchSubject.next(null);
  }

}
