import { Deseriazable } from './deserializable.model';

export class VendorBusinessInfoModel implements Deseriazable{
    
    id: string;    
    business_name: string;
    business_description: string;
    business_contact_person: string;    
    business_email: string; 
    business_phone: string; 
    business_secondary_no: string; 
    website: string;
    photos: any;
    videos: any;
    message: string;
    pricing: any;
    min_price: any;
    max_price: any;
    faqs: any;
    slug: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}