import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  @Input() images: any[];
  @Input() base_image_url: string;

  imageList: any[];
  currentImageData: any;
  currentImageIndex: number;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    // Template ID --
    if (changes.images && changes.images.currentValue) {
      this.imageList = this.images;
      if(this.imageList.length > 0){
        this.currentImageData = this.imageList[0];
        this.currentImageIndex = 0;
      }else{
        this.currentImageData = null;
        this.currentImageIndex = -1;
      }
    }
  }

  goPrevious(){
    if(this.currentImageIndex == 0){
      this.currentImageIndex = this.imageList.length - 1;
      this.currentImageData = this.imageList[this.currentImageIndex];
    }else if(this.currentImageIndex > 0){
      this.currentImageIndex = this.currentImageIndex - 1;
      this.currentImageData = this.imageList[this.currentImageIndex];
    }
  }

  goNext(){
    if(this.currentImageIndex == (this.imageList.length - 1)){
      this.currentImageIndex = 0;
      this.currentImageData = this.imageList[0];
    }else if(this.currentImageIndex >= 0 && this.currentImageIndex < (this.imageList.length - 1)){
      this.currentImageIndex = this.currentImageIndex + 1;
      this.currentImageData = this.imageList[this.currentImageIndex];
    }
  }
}
