import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { VendorsService } from '../../vendors/services/vendors.service';
import { VendorManagerService } from '../services/vendor-manager.service';
import { Config } from '../../../config';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PlannersService } from '../services/planners.service';
import { GuestlistService } from '../services/guestlist.service';

declare var $: any
@Component({
  selector: 'app-vendor-ratings',
  templateUrl: './vendor-ratings.component.html',
  styleUrls: ['./vendor-ratings.component.scss']
})
export class VendorRatingsComponent implements OnInit {
  public AddReviewForm: FormGroup;
  review_submitted: boolean = false;
  recommend: any = 1;
  hired: any = 2;
  notHired: any = 1;
  step1: boolean = true;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  step5: boolean = false;

  btnText: string;
  ratingEffect: boolean = false;
  completed: number;
  starArray = [
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
  ];
  starArrayOne = [
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
  ];
  starArrayTwo = [
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
  ];
  starArrayThree = [
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
  ];
  starArrayFour = [
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
    { "color_fill": false },
  ];
  sub: any;
  id: any;
  vendorDetailsData: any;
  vendorName: any;
  vendorState: any;
  vendorLocation: any;
  vendorCity: any;
  imageChangedEvent: any;
  fileName: any;
  fileType: any;
  size: any;
  croppedImage: any;
  btnTextOne: string;
  ratingEffectOne: boolean;
  ratingEffectTwo: boolean;
  btnTextTwo: string;
  ratingEffectThree: boolean;
  btnTextThree: string;
  ratingEffectFour: boolean;
  btnTextFour: string;
  vendorRating: boolean = true;
  review: boolean = false;
  vendorImageUpload: boolean = false;
  overall: any;
  value: any;
  qualityOfService: any;
  professionalism: any;
  communication: any;
  errorMessage: boolean = false;
  nextButton: boolean = true;
  submitButton: boolean = false;
  updateButton: boolean = false;
  reviewId: any = null;
  reviewData: any;
  progressValue: number;
  vendorImg: any;
  vendorSlug: any;
  vendorListData: any;
  activeUser: any;
  categoryId: any;
  vendorDefaultImg: any;
  avgRating: any;
  vendorAvgRating: any;
  constructor(public route: ActivatedRoute, private vendorService: VendorsService, private toastr: ToastrService,
    private VendorManagerService: VendorManagerService,private plannersService: PlannersService,private guestlistService: GuestlistService,
     public router: Router, public formBuilder: FormBuilder) {
    this.AddReviewForm = this.formBuilder.group({
      review_headline: ['', Validators.required],
      message: ['', Validators.required],
    });
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        // Redirect to login page --
        this.router.navigate(["login"], {queryParams: {returnUrl: location.pathname}});
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.sub = this.route.params.subscribe(params => {
      if (params) {
        this.id = params['id'];
        this.vendorDetails(this.id);
        this.reviewList(this.id)
        if (params['id']) {
        }
      }
    });

    var p = 1 / 3 * 100;
    this.progressValue = 1;
    this.completed = Math.round(p);
  }
  vendorDetails(vendor_id) {
    this.VendorManagerService.getVendorDetails(vendor_id).subscribe((result: any) => {
      this.vendorDetailsData = result.data;      
      this.vendorName = this.vendorDetailsData.business_name;
      this.vendorLocation = this.vendorDetailsData.location_address;
      this.vendorState = this.vendorDetailsData.state_name;
      this.vendorCity = this.vendorDetailsData.city_name;
      this.vendorDetailsData.vendorImage = Config.imageUrl + 'images/vendor_thumb_images/' + this.vendorDetailsData.search_thumbs_image;
      this.vendorImg = this.vendorDetailsData.vendorImage;
      this.vendorDefaultImg = this.vendorDetailsData.search_thumbs_image      
      this.vendorSlug = this.vendorDetailsData.slug;
      this.categoryId = this.vendorDetailsData.category_id;
    }, error => {
      this.toastr.error(error.message);
    });
    // this.vendorDetailsData = vendorDetail.data;
  }

  reviewSubmitted() {
    this.router.navigate([]).then(result => {  window.open('/profile' + '/' +  this.vendorSlug ); });
    // this.router.navigate(['/profile/', this.vendorSlug]);
  }

  ratings(number: number) {
    this.overall = number;
    this.ratingEffect = false;
    let btnColor = document.getElementById("BtnColor");
    switch (number) {
      case 1:
        this.btnText = "1/5";
        // btnColor.style.background = 'rgb(186, 186, 186)';
        break;
      case 2:
        this.btnText = "2/5";
        // btnColor.style.background = "rgb(245, 195, 87)";
        break;
      case 3:
        this.btnText = "3/5";
        // btnColor.style.background = "rgb(255, 172, 90)";
        break;
      case 4:
        this.btnText = "4/5";
        // btnColor.style.background = "rgb(193, 215, 89)";
        break;
      case 5:
        this.btnText = "5/5";
        // btnColor.style.background = "rgb(72, 150, 77)";
        break;
    };

  }

  giveRating(value: any) {
    for (let index = 0; index < this.starArray.length; index++) {
      if (index < value) {
        this.starArray[index].color_fill = true;
      } else {
        this.starArray[index].color_fill = false;
      }
    }
  }

  ratingsOne(number: number) {
    this.value = number;
    this.ratingEffectOne = false;
    let btnColorOne = document.getElementById("btnColorOne");
    switch (number) {
      case 1:
        this.btnTextOne = "1/5";
        // btnColorOne.style.background = 'rgb(186, 186, 186)';
        break;
      case 2:
        this.btnTextOne = "2/5";
        // btnColorOne.style.background = "rgb(245, 195, 87)";
        break;
      case 3:
        this.btnTextOne = "3/5";
        // btnColorOne.style.background = "rgb(255, 172, 90)";
        break;
      case 4:
        this.btnTextOne = "4/5";
        // btnColorOne.style.background = "rgb(193, 215, 89)";
        break;
      case 5:
        this.btnTextOne = "5/5";
        // btnColorOne.style.background = "rgb(72, 150, 77)";
        break;
    };

  }

  giveRatingOne(value) {
    for (let index = 0; index < this.starArrayOne.length; index++) {
      if (index < value) {
        this.starArrayOne[index].color_fill = true;
      } else {
        this.starArrayOne[index].color_fill = false;
      }
    }
  }

  ratingsTwo(number: number) {
    this.qualityOfService = number;
    this.ratingEffectTwo = false;
    let btnColorTwo = document.getElementById("btnColorTwo");
    switch (number) {
      case 1:
        this.btnTextTwo = "1/5";
        // btnColorTwo.style.background = 'rgb(186, 186, 186)';
        break;
      case 2:
        this.btnTextTwo = "2/5";
        // btnColorTwo.style.background = "rgb(245, 195, 87)";
        break;
      case 3:
        this.btnTextTwo = "3/5";
        // btnColorTwo.style.background = "rgb(255, 172, 90)";
        break;
      case 4:
        this.btnTextTwo = "4/5";
        // btnColorTwo.style.background = "rgb(193, 215, 89)";
        break;
      case 5:
        this.btnTextTwo = "5/5";
        // btnColorTwo.style.background = "rgb(72, 150, 77)";
        break;
    };

  }

  giveRatingTwo(value) {
    for (let index = 0; index < this.starArrayTwo.length; index++) {
      if (index < value) {
        this.starArrayTwo[index].color_fill = true;
      } else {
        this.starArrayTwo[index].color_fill = false;
      }
    }
  }

  ratingsThree(number: number) {
    this.professionalism = number;
    this.ratingEffectThree = false;
    let btnColorThree = document.getElementById("btnColorThree");
    switch (number) {
      case 1:
        this.btnTextThree = "1/5";
        // btnColorThree.style.background = 'rgb(186, 186, 186)';
        break;
      case 2:
        this.btnTextThree = "2/5";
        // btnColorThree.style.background = "rgb(245, 195, 87)";
        break;
      case 3:
        this.btnTextThree = "3/5";
        // btnColorThree.style.background = "rgb(255, 172, 90)";
        break;
      case 4:
        this.btnTextThree = "4/5";
        // btnColorThree.style.background = "rgb(193, 215, 89)";
        break;
      case 5:
        this.btnTextThree = "5/5";
        // btnColorThree.style.background = "rgb(72, 150, 77)";
        break;
    };

  }

  giveRatingThree(value) {
    for (let index = 0; index < this.starArrayThree.length; index++) {
      if (index < value) {
        this.starArrayThree[index].color_fill = true;
      } else {
        this.starArrayThree[index].color_fill = false;
      }
    }
  }

  ratingsFour(number: number) {
    this.communication = number;
    this.ratingEffectFour = false;
    let btnColorFour = document.getElementById("btnColorFour");
    switch (number) {
      case 1:
        this.btnTextFour = "1/5";
        // btnColorFour.style.background = 'rgb(186, 186, 186)';
        break;
      case 2:
        this.btnTextFour = "2/5";
        // btnColorFour.style.background = "rgb(245, 195, 87)";
        break;
      case 3:
        this.btnTextFour = "3/5";
        // btnColorFour.style.background = "rgb(255, 172, 90)";
        break;
      case 4:
        this.btnTextFour = "4/5";
        // btnColorFour.style.background = "rgb(193, 215, 89)";
        break;
      case 5:
        this.btnTextFour = "5/5";
        // btnColorFour.style.background = "rgb(72, 150, 77)";
        break;
    };

  }

  giveRatingFour(value) {
    for (let index = 0; index < this.starArrayFour.length; index++) {
      if (index < value) {
        this.starArrayFour[index].color_fill = true;
      } else {
        this.starArrayFour[index].color_fill = false;
      }
    }
  }

  imagedata = [];
  imagevendorId = [];
  updateButtonreview = false;
  fileChangeEvent(event: any): void {
    var multipleFiles = event.target.files;
    this.imageChangedEvent = event;

    if (multipleFiles) {
      for (var files of multipleFiles) {
        let fileName = files.name;
        let fileType = files.type;
        let size = files.size;
        var multipleReader = new FileReader();
        multipleReader.onload = (e: any) => {
          if (size > 5300000) {
            this.toastr.error('Please upload upto 5MB image');
          } else {
            if (fileType == 'image/jpeg' || fileType == 'image/jpg' || fileType == 'image/gif' || fileType == 'image/png') {
              var file = {
                vendor_id: parseInt(this.id),
                name: fileName,
                type: fileType,
                base64: e.target.result,
                size: size,
                review_id: this.updateButtonreview ? this.reviewId : ''
              };
              this.VendorManagerService.vendorFileUpload(file).subscribe((result: any) => {
                let uploadResult = result.data;
                uploadResult.vendorImage = Config.imageUrl + 'images/vendor_review_images/' + uploadResult.image_name;
                if (this.updateButtonreview) {
                  uploadResult.review_id = this.reviewId;
                }
                this.imagedata.push(uploadResult);
                this.imagevendorId.push(uploadResult.id);
                localStorage.setItem("imageUpload", JSON.stringify(this.imagedata));
                localStorage.setItem("imageUploadid", JSON.stringify(this.imagevendorId));
              }, error => {
                this.toastr.error(error.message);
              })
            } else {
              this.toastr.error('Please upload a jpeg, jpg, gif, png image');
            }
          }
        }
        multipleReader.readAsDataURL(files);
      }
    }
  }

  removeImage(id, imageIndex) {
    let data = {
      image_id: id
    }
    this.VendorManagerService.removeFileUpload(data).subscribe((result: any) => {
      this.imagedata.splice(imageIndex, 1)
    }, error => {
      this.toastr.error(error.message);
    })

  }
  saveVendorReview(vendorRating, review, vendorImageUpload) {    
    if (this.vendorRating == true) {     
      this.errorMessage = true;
      if (this.overall > 0 && this.value && this.qualityOfService && this.professionalism && this.communication) {
        var ratingdetails = {
          overall: this.overall,
          value: this.value,
          qualityOfService: this.qualityOfService,
          professionalism: this.professionalism,
          communication: this.communication
        }
        this.vendorRating = false;
        this.review = true;
        this.vendorImageUpload = false;
        // if (this.reviewData != null) {
        //   var p = 2 / 3 * 100;
        //   this.progressValue = 2;
        // } else {
        //   var p = 0;
        // }
        var p = 2 / 3 * 100;
        this.progressValue = 2;
        this.completed = Math.round(p);
        this.errorMessage = false;
        localStorage.setItem("ratingdetails", JSON.stringify(ratingdetails));
      }else{
        this.toastr.error("Complete vendor rating please");
      }
    }
    else if (this.review == true) {
      this.review_submitted = true;
      // if (this.reviewData != null) {
      //   var p = 3 / 3 * 100;
      //   this.progressValue = 3;
      // } else {
      //   var p = 0;
      // }
      var p = 3 / 3 * 100;
      this.progressValue = 3;
      this.completed = Math.round(p);
      if (this.AddReviewForm.invalid) {
        var p = 2 / 3 * 100;
      this.progressValue = 3;
      this.completed = Math.round(p);
        return;
      } else {
        this.vendorRating = false;
        this.review = false;
        this.vendorImageUpload = true;
      }
      var reviewData = {
        review_headline: this.AddReviewForm.value.review_headline,
        message: this.AddReviewForm.value.message,
        hireOption: this.hired,
        recommendOption: this.recommend
      }
      localStorage.setItem("reviewData", JSON.stringify(reviewData));
      if (this.vendorImageUpload == true) {
        this.nextButton = false;
        if (this.updateButton) {
          this.submitButton = false;
        } else {
          this.submitButton = true;
        }
      }
    }
    else if (this.vendorImageUpload == true) {
      // if (this.reviewData != null) {
      //   var p = 1 / 3 * 100;
      // } else {
      //   var p = 0;
      // }
      var p = 1 / 3 * 100;
      this.completed = Math.round(p);
    }
  }
  submitVendorReview() {
    var ratingDetailData = JSON.parse(localStorage.getItem('ratingdetails'));
    var reviewDetailData = JSON.parse(localStorage.getItem('reviewData'));
    var submitData = {
      vendor_id: parseInt(this.id),
      overall: ratingDetailData.overall,
      value: ratingDetailData.value,
      quality_service: ratingDetailData.qualityOfService,
      professionalism: ratingDetailData.professionalism,
      communication: ratingDetailData.communication,
      title: reviewDetailData.review_headline,
      message: reviewDetailData.message,
      friend_recommendation: reviewDetailData.recommendOption,
      hired_from_wd: reviewDetailData.hireOption,
      images: this.imagevendorId
    }
    this.VendorManagerService.submitReview(submitData).subscribe((result: any) => {
      $('#vendorConfirmModal').modal('toggle');
      this.getVendorList(this.categoryId);
    }, error => {
      this.toastr.error(error.message);
    })

  }

  back(vendorRating, review, vendorImageUpload) {
    if (this.updateButton) {
      this.updateButton = false;
      this.submitButton = false;
      this.nextButton = true;
    }
    if (this.vendorRating == true) {
      // this.vendorRating = false;
      // this.review = true;
      // this.vendorImageUpload = false;
      this.nextButton = true;
      window.history.back();
    }
    else if (this.review == true) {
      this.vendorRating = true;
      this.submitButton = false;
      this.review = false;
      this.vendorImageUpload = false;
      this.nextButton = true;
      // if (this.reviewData != null) {
      //   var p = 1 / 3 * 100;
      //   this.progressValue = 1;
      // } else {
      //   var p = 0;
      // }
      var p = 1 / 3 * 100;
      this.progressValue = 1;
      this.completed = Math.round(p);
    }
    else if (this.vendorImageUpload == true) {
      this.vendorRating = false;
      this.vendorImageUpload = false;
      this.review = true;
      this.nextButton = true;
      this.submitButton = false;
      // if (this.reviewData != null) {
      //   var p = 2 / 3 * 100;
      //   this.progressValue = 2;
      // } else {
      //   var p = 0;
      // }
      var p = 2 / 3 * 100;
      this.progressValue = 2;
      this.completed = Math.round(p);
    }
    this.reviewList(this.id);
  }

  close() {
    window.history.back();
  }
  // review form 
  recommendYes(e: number) {
    this.recommend = e;
  }
  recommendNo(e: number) {
    this.recommend = e;
  }
  hiredYes(e: number) {
    this.hired = e;
    this.notHired = e;
  }
  hiredNo(e: number) {
    this.hired = e;
    this.notHired = e;
  }
  get g() {
    return this.AddReviewForm.controls;
  }

  reviewList(id) {
    var reviewId = {
      "vendor_id": parseInt(id)
    }
    this.VendorManagerService.reviewList(reviewId).subscribe(res => {      
      this.reviewData = res.my_review;      
      this.reviewId = null;
      if(res.my_review){
        this.reviewId = res.my_review.id;
      }
      if (res.hasError != true) {
        this.updateButton = true;
        this.updateButtonreview = true
        // this.updateVendor
      }

      if (res && res.my_review) {
        this.ratings(Number(res['my_review'].overall));
        this.giveRating(parseInt(res['my_review'].overall).toFixed(0));
        this.ratingsOne(Number(res['my_review'].value));
        this.giveRatingOne(parseInt(res['my_review'].value).toFixed(0));
        this.ratingsTwo(Number(res['my_review'].quality_service));
        this.giveRatingTwo(parseInt(res['my_review'].quality_service).toFixed(0));
        this.ratingsThree(Number(res['my_review'].professionalism));
        this.giveRatingThree(parseInt(res['my_review'].professionalism).toFixed(0));
        this.ratingsFour(Number(res['my_review'].communication));
        this.giveRatingFour(parseInt(res['my_review'].communication).toFixed(0));
        //second div
        if (res.my_review.hired_from_wd == 0) {
          this.hired = 2;
          this.hiredYes(this.hired);
        } else {
          this.hired = 1;
          this.hiredYes(this.hired);
        }

        this.recommend = res.my_review.friend_recommendation;
        this.recommendYes(this.recommend);

        this.AddReviewForm.patchValue({
          review_headline: res['my_review'].title,
          message: res['my_review'].message
        });
        //thired div
        let reviewImages = res['my_review'].images;
        for (let index = 0; index < res['my_review'].images.length; index++) {
          reviewImages[index].vendorImage = Config.imageUrl + 'images/vendor_review_images/' + res['my_review'].images[index].image_name;
          this.imagevendorId.push(reviewImages[index].id);
        }
        this.imagedata = reviewImages;
      }
    }, error => {
      console.log(error);
    })
  }

  updateVendorReview() {
    var data = {
      id: this.reviewId,
      vendor_id: parseInt(this.id),
      overall: this.overall,
      value: this.value,
      quality_service: this.qualityOfService,
      professionalism: this.professionalism,
      communication: this.communication,
      title: this.AddReviewForm.value.review_headline,
      message: this.AddReviewForm.value.message,
      hired_from_wd: this.hired,
      friend_recommendation: this.recommend,
      images: this.imagevendorId
    }
    this.VendorManagerService.updateVendor(data).subscribe(res => {
      this.reviewList(this.id);      
      $('#vendorConfirmModal').modal('toggle');
      this.getVendorList(this.categoryId);
    }, error => {
      console.log(error)
    })
  }

   // get vendor list categorywise
   getVendorList(categoryId) {
    this.VendorManagerService.getSavedVendorsList(categoryId, null, this.activeUser.id).subscribe((result: any) => {
      this.vendorListData = result;
      this.vendorAvgRating = this.vendorListData.filter(res => this.id == res.vendor_id)
      for (let index = 0; index < this.vendorListData.length; index++) {
        this.vendorListData[index].vendorImage = Config.imageUrl + 'images/vendor_thumb_images/' + this.vendorListData[index].search_thumbs_image;
        this.vendorListData[index].vendor_notes = false;
        this.vendorListData[index].vendor_contact = false;
        this.vendorListData[index].vendor_hide = false;
        this.vendorListData[index].vendor_price = true;        
        // this.vendorListData[index].phone_number = this.formatPhoneNumber(this.vendorListData[index].phone_number)
        this.reviewList(this.vendorListData[index].vendor_id);
        // if (this.vendorListData == 0) {
        //   this.disabledData = true;
        // }

        // this.checkboxChecked = true;
        // if (this.checkboxChecked == true) {
        //   if(this.vendorListData[index].hired == 1){
        //     var data = {
        //       vendors: [this.vendorListData[index].vendor_id],
        //       toStatus: 6
        //     }
        //     this.VendorManagerService.vendorUpdateStatus(data).subscribe((result: any) => {
        //       this.getVendorCategorys(this.activeUser.id);
        //       this.getVendorCategorysCount(this.activeUser.id);
        //       this.statusCount(this.activeUser.id);
        //       this.getVendorList(this.id);
        //     }, error => {
        //       this.toastr.error(error.message);
        //     })

        //   }
        //   // this.changeVendorStatus(this.id, this.checkboxChecked)
        // }
      }

    }, error => {
      this.toastr.error(error.message);
    });
  }
  getWidth(number: any) {
    // console.log(Math.floor(number*10*2)+'%');
    return Math.floor(number * 10 * 2) + '%';
  }
  
  home(){
    this.router.navigate(['/planner-dashboard']);
  }
}
