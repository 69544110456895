import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SocialMediaModel } from 'src/app/models/social.media.model';

@Injectable({
    providedIn: 'root'
})
export class SocialMediaFormBuilder{

    private form;
    private validationRules = {
        mode:['',''] ,
        facebook_link: ['',''],
        twitter_link: ['',''],
        youtube_link: ['',''],
        instagram_link: ['',''],
        printerest_link: ['',''],
        vimeo_link: ['',''],
         
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:SocialMediaModel){
        this.form.patchValue({  
            mode:response.mode,         
            facebook_link:response.facebook_link,
            twitter_link:response.twitter_link,
            youtube_link:response.youtube_link,
            instagram_link:response.instagram_link,
            printerest_link:response.printerest_link,
            vimeo_link:response.vimeo_link            
        });
    }
}