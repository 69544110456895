import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule  }   from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { HomeComponent } from './home/home.component';
import { WeddingVenuesComponent } from './wedding-venues/wedding-venues.component';
import { TopVendorSearchComponent } from './top-vendor-search/top-vendor-search.component';
import { CmsModule } from '../cms/cms.module';
import { BusinessInfoComponent } from './business-info/business-info.component';
import { LocationInfoComponent } from './location-info/location-info.component';
import { SettingsComponent } from './settings/settings.component';
import { ButtonsComponent } from './buttons/buttons.component';
import { FrontNavComponent } from 'src/app/front-nav/front-nav.component';
import { AppModule } from 'src/app/app.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { EmployeeComponent } from './employee/employee.component';
import { VendorListingComponent } from './vendor-listing/vendor-listing.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { AccountInfoSidebarComponent } from './account-info-sidebar/account-info-sidebar.component';
import { ProfileSidebarComponent } from './profile-sidebar/profile-sidebar.component';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { PhotosComponent } from './photos/photos.component';
import { VideosComponent } from './videos/videos.component';
import { OtherDocsComponent } from './other-docs/other-docs.component';
import { EventsComponent } from './events/events.component';
import { EndorsementsComponent } from './endorsements/endorsements.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule,OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE  } from 'ng-pick-datetime';
import { TopNavComponent } from './top-nav/top-nav.component';
import { VendorFooterComponent } from './vendor-footer/vendor-footer.component';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { DealsComponent } from './deals/deals.component';
import { PricingComponent } from './pricing/pricing.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { MessagesComponent } from './messages/messages.component';
import { AvailabilityComponent } from './availability/availability.component';
import { VendorDetailsComponent } from './vendor-details/vendor-details.component';
import { VendorListingMapComponent } from './vendor-listing-map/vendor-listing-map.component';
import { FaqsComponent } from './faqs/faqs.component';
import { MessageSidebarComponent } from './message-sidebar/message-sidebar.component';
import { RequestPricingComponent } from './request-pricing/request-pricing.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { SortablejsModule } from 'angular-sortablejs';
import { ServiceAttributesComponent } from './service-attributes/service-attributes.component';
import { CustomFaqComponent } from './custom-faq/custom-faq.component';
import { FeaturesBenefitsComponent } from './features-benefits/features-benefits.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgSelect2Module } from 'ng-select2';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { LoginGuardService } from 'src/app/_guards/login-guard.service';
import { AvailabilityCalendarComponent } from './availability-calendar/availability-calendar.component';
import { MessageSettingsComponent } from './message-settings/message-settings.component';
import { CheckAvailabilityComponent } from './check-availability/check-availability.component';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
import { CheckoutComponent } from './checkout/checkout.component';
import { PreferenceComponent } from './preference/preference.component';
import { Config } from '../../config';
import { ReviewsComponent } from './reviews/reviews.component';
import { ReviewRequestComponent } from './review-request/review-request.component';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';

var vendorsRoutes = [
    { path: 'vendor', component: HomeComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles }},
    { path: 'vendor/home', component: HomeComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles }},
    { path: 'vendor/register', component: RegisterComponent,canActivate: [LoginGuardService] },
    { path: 'vendor/benefits', component: FeaturesBenefitsComponent },
    { path: 'vendor/login', component: LoginComponent,canActivate: [LoginGuardService] },
    { path: 'vendor/forgotpassword', component: ForgotPasswordComponent,canActivate: [LoginGuardService] },
    { path: 'vendor/businessinfo', component: BusinessInfoComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/map', component: LocationInfoComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/buttons', component: ButtonsComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/settings', component: SettingsComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/change-password', component: ChangePasswordComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/authorized-users', component: EmployeeComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/preferences', component: PreferenceComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/authorized-users/:id', component: EmployeeComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/listings/:category/:city', component: VendorListingComponent },
    { path: 'vendor/maps/:category/:city', component: VendorListingMapComponent },
    { path: 'vendor/photos', component: PhotosComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/videos', component: VideosComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/documents', component: OtherDocsComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/events', component: EventsComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/reviews', component: ReviewsComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/reviews/request', component: ReviewRequestComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/endorsements', component: EndorsementsComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/deals', component: DealsComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/pricing', component: PricingComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/social-media', component: SocialMediaComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/message-settings', component: MessageSettingsComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/messages', component: MessagesComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/messages/:name', component: MessagesComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'profile/:slug', component: VendorDetailsComponent },
    { path: 'vendor/availability', component: AvailabilityComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/generaldetails', component: FaqsComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/membership', component: SubscriptionComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/checkout', component: CheckoutComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/service-attribute', component: ServiceAttributesComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
    { path: 'vendor/custom-faq', component: CustomFaqComponent,canActivate: [AuthGuardService],data: { roles: Config.serviceEndPoints.vendors.roles } },
];

/*export const CUSTOM_DATE_TIME_FORMATS = {
  parseInput: 'your custom value',
  fullPickerInput: 'your custom value',
  datePickerInput: 'your custom value',
  timePickerInput: 'your custom value',
  monthYearLabel: 'your custom value',
  dateA11yLabel: 'your custom value',
  monthYearA11yLabel: 'your custom value',
};*/

@NgModule({
  declarations: [
    LoginComponent, 
    RegisterComponent, 
    ForgotPasswordComponent, 
    HomeComponent,
    WeddingVenuesComponent,
    TopVendorSearchComponent,
    BusinessInfoComponent,
    LocationInfoComponent,
    SettingsComponent,
    ButtonsComponent,
    ChangePasswordComponent,
    EmployeeComponent,
    VendorListingComponent,
    HeaderMenuComponent,
    AccountInfoSidebarComponent,
    ProfileSidebarComponent,
    PhotosComponent,
    VideosComponent,
    OtherDocsComponent,
    EventsComponent,
    EndorsementsComponent,
    TopNavComponent,
    VendorFooterComponent,
    DealsComponent,
    PricingComponent,
    SocialMediaComponent,
    MessagesComponent,
    AvailabilityComponent,
    VendorDetailsComponent,
    VendorListingMapComponent,
    FaqsComponent,
    MessageSidebarComponent,
    RequestPricingComponent,
    SubscriptionComponent,
    ServiceAttributesComponent,
    CustomFaqComponent,
    FeaturesBenefitsComponent,
    AvailabilityCalendarComponent,
    MessageSettingsComponent,
    CheckAvailabilityComponent,
    CheckoutComponent,
    PreferenceComponent,
    ReviewsComponent,
    ReviewRequestComponent,
    ImageViewerComponent
  ],
  imports: [
    NgxPaginationModule,
    RouterModule.forChild(vendorsRoutes),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAw4vHh_gINZHpGgECp37FUgcoHEvmjRKw'
    }),
    CommonModule,
    CmsModule,
    GoogleChartsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AlifeFileToBase64Module,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgSelect2Module,
    SortablejsModule.forRoot({ animation: 150 }),
    DeviceDetectorModule.forRoot(),
    SharedModule
  ],
  exports: [
    RouterModule,
    WeddingVenuesComponent,
    TopVendorSearchComponent,
    RequestPricingComponent
  ],
  providers: [
    // use french locale
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'us'},
    //{provide: OWL_DATE_TIME_FORMATS, useValue: CUSTOM_DATE_TIME_FORMATS},
  ],
})
export class VendorsModule { }
