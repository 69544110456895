import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlaceholdersService {


  constructor(private http: HttpClient) { }

  getCenterStageImages(section:string){
    // TODO: need to implement actual service
    return of({
      data:{
        images:[
          {"imageUrl":"assets/images/banner-1.jpg","title":"Light mask","subTitle":"First text"},
          {"imageUrl":"assets/images/banner-2.jpg","title":"Strong mask","subTitle":"Secondary text"},
          {"imageUrl":"assets/images/banner-3.jpg","title":"Third slide","subTitle":"Slight mask"},
        ]
      }      
    });
  }

  getImagesAndText(section:string){
    // TODO: need to implement actual service
    return of({
      data:{
        image:'img/registertop.png',
        text:'This is a new area for registration',
        sub_text:'My dear text'
      }      
    });
  }

  getIdeasTips(section:string){
    // TODO: need to implement actual service
    return of({
      data:{
        contens : [
          {"content_slug": "the_21_best_wedding", "slug": 'planning_basics', "imageUrl":"assets/images/r-tips-1.png","title":"Planning Basics","content":"The 21 Best Wedding Planner Books to Help Keep You Organized"},
          {"content_slug": "the_21_best_wedding", "slug": 'wedding_ceremony', "imageUrl":"assets/images/r-tips-2.png","title":"Wedding Ceremony","content":"The 21 Best Wedding Planner Books to Help Keep You Organized"},
          {"content_slug": "the_21_best_wedding", "slug": 'wedding_reception', "imageUrl":"assets/images/r-tips-2.png","title":"Wedding Reception","content":"The 21 Best Wedding Planner Books to Help Keep You Organized"},
        ],        
        images : [
          {"imageUrl":"assets/images/tips-1.png","title":"Planning 101"},
          {"imageUrl":"assets/images/tips-2.png","title":"after the wedding<br>married life"},
          {"imageUrl":"assets/images/tips-3.png","title":"Wedding Reception Decor"},
          {"imageUrl":"assets/images/tips-4.png","title":"Wild or Tame?<br> Bachelor & Bachelorette Parties"}
        ]        
      }      
    });
  }

  getRealWedding(section:string){
    // TODO: need to implement actual service
    return of({
      data:{
        heading_text : "Browse Wedding Memories",
        body_text : "<p align='justify'>We love couples, their unique stories & the different ways in which they go about planning for their Big Day! From quaint budget affairs, to glam black tie events- the most inspiring real wedding stories from across cultures & cities!</p> <p align='justify'>We love couples, their unique stories & the different ways in which they go about planning for their Big Day! From quaint budget affairs, to glam black tie events- the most inspiring real wedding stories from across cultures & cities!</p>",
        images : [
          {"imageUrl":"assets/images/inspired-1.png"},
          {"imageUrl":"assets/images/inspired-2.png"},
          {"imageUrl":"assets/images/inspired-3.png"},
          {"imageUrl":"assets/images/inspired-4.png"},
          {"imageUrl":"assets/images/inspired-5.png"},
          {"imageUrl":"assets/images/inspired-6.png"},
        ]        
      }      
    });
  }

  getWeddingVenue(section:string){
    // TODO: need to implement actual service
    return of({
      data:{
        images:[
          {"imageUrl":"assets/images/wedding_venue_1.jpg","title":"Yadu Green (USA)"},
          {"imageUrl":"assets/images/wedding_venue_2.jpg","title":"Ramada Resort(MEXICO)"},
          {"imageUrl":"assets/images/wedding_venue_3.jpg","title":"Southern Start (CANADA)"},
          {"imageUrl":"assets/images/wedding_venue_4.jpg","title":"Ramada Resort(MEXICO)"},
          {"imageUrl":"assets/images/wedding_venue_5.jpg","title":"Southern Start (CANADA)"},
        ]
      }
    })
  }

  getForumsDiscussions(section:string){
    // TODO: need to implement actual service
    return of({
      data:{
        forums : [
          {"imageUrl":"assets/images/j-m.png","title":"Just Married","content":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.","author":"abc","date_time":"Today at 6:59 AM"},
          {"imageUrl":"assets/images/j-m.png","title":"Just Married","content":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.","author":"abc","date_time":"Today at 6:59 AM"},
          {"imageUrl":"assets/images/j-m.png","title":"Just Married","content":"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard.","author":"abc","date_time":"Today at 6:59 AM"},
        ],        
        discussion : [
          {"imageUrl":"assets/images/join-1.png","title":"Ask Questions"},
          {"imageUrl":"assets/images/join-2.png","title":"Get Advice"},
          {"imageUrl":"assets/images/join-3.png","title":"Get Advice"},
          {"imageUrl":"assets/images/join-4.png","title":"Ask Questions"},
          {"imageUrl":"assets/images/join-5.png","title":"Share Ideas"},
          {"imageUrl":"assets/images/join-6.png","title":"Message Boards"},
        ]        
      }      
    });
  }

  getAjaxCallTest(){
    
    return this.http.get("assets/json_files/main_slider.json");
  }
}
