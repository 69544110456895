import { Component, OnInit } from '@angular/core';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
//import { setTimeout } from 'timers';

declare var $: any;

@Component({
  selector: 'app-wedding-venues',
  templateUrl: './wedding-venues.component.html',
  styleUrls: ['./wedding-venues.component.css']
})
export class WeddingVenuesComponent implements OnInit {

  venueSlider:any = null;
  constructor(private placeholdersService:PlaceholdersService) { }
  

  ngOnInit() {  

    var owl = $('.owl-carousel');
    this.placeholdersService
      .getWeddingVenue('venue-slider')
      .subscribe((result) => {
        this.venueSlider = result.data.images;
        console.log(this.venueSlider);
        setTimeout(() => {
          owl.owlCarousel({
            margin: 30,
            nav: true,
            loop:true,
            autoplay:true,
            autoplayTimeout:3000,
            autoplayHoverPause:true,
            smartSpeed: 400,
            responsive: {
              0: {
                items: 1
              },
              600: {
                items: 3
              },
              1000: {
                items: 3
              }
            }
          })
        }, 1000);        
      });     
          
  }

}
