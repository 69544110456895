import { Component, OnInit } from '@angular/core';
import { PlaceholdersService } from '../modules/cms/services/placeholders.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DiscussionService } from '../modules/planners/services/discussions.service';
import { Config } from '../config';

@Component({
  selector: 'app-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.css']
})
export class ForumComponent implements OnInit {

  forums_discussions: any[] = [];
  discussionCategories: any[] = [];
  placeholderData: any;
  category_banner_image_url: string = Config.imageUrl + 'images/discussion_category_banner/';

  constructor(
    private discussionService: DiscussionService,
    private sanitizer: DomSanitizer,
    private placeholdersService:PlaceholdersService
  ) {}

  async ngOnInit() {
    let discussions = await this.discussionService.getPublicLatestDiscussion().toPromise();
    this.forums_discussions = discussions.data;
    console.log("Forum Discussion: ", this.forums_discussions);
    this.getDiscussionCategories();
  }
  async getDiscussionCategories() {
    let categories = await this.discussionService.getDiscussionCategories().toPromise();
    categories = categories.filter((cat: any, index: number)=>{ return index < 6 });
    this.discussionCategories = categories;
  }
  getRichTextContent(content: any) {
    let el = document.createElement("div");
    el.innerHTML = content;
    let tempcontent = el.outerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(tempcontent);
  }
}
