import { Deseriazable } from './deserializable.model';

export class PriceModel implements Deseriazable{
    
    id: number;
    token: string;
    price_per_guest: string;
    min_guest: string;
    max_guest: string;
    days_of_week: any;
    season: any;
    event_type: any;
    message: string;
    subject:string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}