import { Component,ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError, tap } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SettingsModel } from 'src/app/models/settings.model';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { LocationModel } from 'src/app/models/location.model';
import { CategoryModel } from 'src/app/models/category.model';
import { VendorsService } from '../services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';
import { MediadModel } from 'src/app/models/media.model';
import { PackageSubscriptionModel } from 'src/app/models/package.subscription.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SortablejsOptions } from 'angular-sortablejs';

declare var $: any;

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {

  submitted = false;
  model = new MediadModel();
  videoForm: any;
  videos: any;
  video_id: number;
  deletebulkvideo = [];
  degreevideo: string;
  myPlan: any;
  showDraggable = false;
  showTitle = true;
  uploadFiles: string;
  deviceInfo = null;
  mobile = false;
  desktop = false;
  fileSettings: any;
  filesQueue: any;
  totalFilesCount = 0;
  noImage = Config.noImage;
  video_url: string = Config.imageUrl;

  @ViewChild('fileUploadProgress') fileUploadProgress: any;
  @ViewChild('videoPreview') videoPreview:any;
  @ViewChild('canvasPreview') canvasPreview:any;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr: ToastrService,
    private deviceService: DeviceDetectorService,
    public router: Router) {
      this.deviceDetector();
  }

  ngOnInit() {
    this.selectedPlan();
    this.getMedia('videos');
    this.getDegreeVideo();
    window.scroll(10,10);
  }

  prepareUploadQueue(files:any){
    if(this.filesQueue.length>=files.length){
      this.fileUpload();
      return;
    }

    const reader = new FileReader();
    const currentIndex = this.filesQueue.length;
    reader.readAsDataURL(files[currentIndex]);
    reader.onload = () => {      
      $(this.videoPreview.nativeElement).find('source').attr('src',reader.result);
      let gContext = this.canvasPreview.nativeElement.getContext('2d');

      $(this.videoPreview.nativeElement).on('loadedmetadata', ()=>{
        this.canvasPreview.nativeElement.width = this.videoPreview.nativeElement.videoWidth;
        this.canvasPreview.nativeElement.height = this.videoPreview.nativeElement.videoHeight;
        this.videoPreview.nativeElement.currentTime = 10;
      });
      $(this.videoPreview.nativeElement).on('timeupdate', ()=>{
          console.log(this.videoPreview.nativeElement.currentTime);  
          if(this.videoPreview.nativeElement.currentTime > 0){
            gContext.drawImage(this.videoPreview.nativeElement, 0, 0, this.videoPreview.nativeElement.videoWidth, this.videoPreview.nativeElement.videoHeight);
            this.filesQueue[currentIndex]={
              base64:reader.result,
              size:files[currentIndex].size,
              type:files[currentIndex].type,
              name:files[currentIndex].name,
              preview64:this.canvasPreview.nativeElement.toDataURL()
            };
            this.prepareUploadQueue(files);
            $(this.videoPreview.nativeElement).unbind();
          }
      });
      
      this.videoPreview.nativeElement.load();
    };
    reader.onerror = error => {
      alert(error)
    };

  }

  onFileChanges(event: any) {
    let files = event.target.files;
    this.getFileSettings().subscribe(response=>{
      this.fileSettings = response;
      $(".overlayDiv").show();
      let valid = this.fileValidation(files);
      $(".overlayDiv").hide();
      if(valid){
        this.filesQueue = [];
        this.totalFilesCount = files.length;
        this.prepareUploadQueue(files);        
      }else{
        $("input[type=file]").val('');
      }
    });
  }

  fileValidation(files){
    var error_ext = [];
    //File extension checking
    var extarray = ["mp4"];
    /* for(var i=0;i<files.length;i++){
      var filetype = files[i].type.split("/");
      if(filetype[0]!="video"){
        error_ext.push(1);
        break;
      }
      if(extarray.indexOf(filetype[1])==-1){
        error_ext.push(1);
        break;
      }
    }
    if(error_ext.length>0){
      this.toastr.error("Wrong file format.");
      $("#input-file-now").val("");
      return false;
    } */
    var error_file_size = [];
    //Total count checking
    if(this.fileSettings.remaning>0 && this.fileSettings.remaning>=files.length){
       
      for(var i=0;i<files.length;i++){
        var convToKb = this.formatBytes(files[i].size);
        var filetype = files[i].type.split("/");
        if(filetype[0]!="video"){
          error_ext.push(1);
          break;
        }
        console.log(this.fileSettings);
        console.log(convToKb);
        if(convToKb<this.fileSettings.min_size || convToKb>this.fileSettings.max_size){
          error_file_size.push(1);
          break;
        }

      }
      if(error_ext.length>0){
        this.toastr.error("Wrong file format.");
        $("#input-file-now").val("");
        return false;
      }
      if(error_file_size.length>0){
        this.toastr.error("File size must be between 1MB to 5MB.");
        $("#input-file-now").val("");
        return false;
      }

    }else{
      this.toastr.error("Maximum number of videos for your account has been reached.");
      $("#input-file-now").val("");
      return false;
    }
    return true;
  }

  fileUpload(){
    if(this.filesQueue.length==0){
      this.fileUploadProgress.nativeElement.style.width = "0";
      this.fileUploadProgress.nativeElement.parentElement.style.display = "none";
      this.toastr.success("Media Saved");
      this.getMedia("videos");
      $("input[type=file]").val('');
      return;
    }
    this.fileUploadProgress.nativeElement.parentElement.style.display = "flex";
    let file = this.filesQueue.shift();
    this.vendorService
        .saveMedia([file])
        .subscribe(
          (response) => {
            let persentwidth = (((this.totalFilesCount - this.filesQueue.length)/this.totalFilesCount)*100);
            this.fileUploadProgress.nativeElement.style.width = persentwidth + "%";
            this.fileUploadProgress.nativeElement.innerHTML = persentwidth.toFixed(2) + "%";
            this.fileUpload();
          },
          error => {
            this.toastr.error(error.message);
          }
        );
  }

  deleteVideoModal() {
    $("#deletevideo").modal();
  }

  deleteVideos(ele:any) {
      
    if(ele.target.checked){
      this.deletebulkvideo.push(ele.target.value);
    }else{
      let index = this.deletebulkvideo.indexOf(ele.target.value);
      if (index > -1) {
        this.deletebulkvideo.splice(index, 1);
      }
    }
  }

  deleteMedia() {
    var delete_ids = [];
    $("input[type=checkbox]:checked").each(function(){
      delete_ids.push($(this).val());      
    });

    if(delete_ids.length==0){      
      this.toastr.error("Please select video(s) to delete.");
      return;
    }
    this.vendorService
      .deleteMedia(delete_ids)
      .subscribe(
        (response: MediadModel) => {
          $('#deletevideo').modal('toggle');
          this.toastr.success(response.message);
          this.getMedia("videos");
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

  getMedia(type: string) {
    this.vendorService
      .getMedia(type)
      .subscribe(
        (response: MediadModel[]) => {
          this.videos = response;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  saveVideoTitle(){
    var videotitle = [];
    /*$(".videotitle").each(function(i){
      //console.log($(this).val());
      //if($(this).val()){
        var id = $(this).attr("id");
        var val = $(this).val();
        var ord = i+1;
        videotitle.push({"id":id,"title":val,"ord": ord});
      //}      
    })*/
    for(let i=0;i<this.videos.length;i++){
      var ord = i+1;
      videotitle.push({"id":this.videos[i].id,"title":this.videos[i].title,"ord": ord});
    }

    this.vendorService
      .saveMediaTitle(videotitle)
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
         // this.getMedia("videos"); 
          //this.ngOnInit();        
          return false;
        },
        error => {
          this.toastr.error(error.message);
          return false;
        }
      )
    console.log(videotitle);
  }

  saveDegreeVideo(files: any){
    console.log(files[0]);
    this.vendorService
      .saveDegreeVideo(files[0])
      .subscribe(
        (response) => {
          this.toastr.success(response.message);
          this.getDegreeVideo();
          $("input[type=file]").val('');
          return false;
        },
        error => {
          this.toastr.error(error.message);
          $("input[type=file]").val('');
          console.log(error.message);
        }
      )
  }

  getDegreeVideo(){
    this.vendorService
      .getDegreeVideo()
      .subscribe(
        (response: any) => {
          //this.toastr.success(response.message);
          this.degreevideo = response.data.degree_video;         
          return false;
        }
      )
  }

  deleteDegreeVideo(){
    this.vendorService
      .deleteDegreeVideo()
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          this.degreevideo = "";
          return false;
        }
      )
  }

  
  onDrop(){
    this.saveVideoTitle();
  }

  showShort(ele:any){
    
    if(ele.target.value=="dragg"){
      this.showDraggable = true;
      this.showTitle = false;
    }else{
      this.showDraggable = false;
      this.showTitle = true;
    }
  }

  selectedPlan(){
    this.vendorService
      .vendorCurrentPlan()
      .subscribe(
        (response:PackageSubscriptionModel) => {          
          if(response.package_id){
            this.myPlan = response;
            console.log(response.package_id);
            if(response.package_id>1){
              this.uploadFiles = "Upload up to 10 videos and up to 5 different 360° virtual tours to you your profile.  File size should be between 1MB and 500MB";
            }else{
              this.uploadFiles= "Upgrade your account to a paid membership to add videos to your profile.";
            }
          }else{
            this.myPlan = {"title":"Basic","rate":"0.00","duration_in_month":"1"};
          }
          //console.log(response);
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  deviceDetector(){
    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();    
    if(isMobile){
      this.mobile = true;
      this.desktop = false;
    }else{
      this.mobile = false;
      this.desktop = true;
    }
  }
  
  options: SortablejsOptions = {
    onUpdate: (event: any)=>{
      this.saveVideoTitle();
    }
  }

  getFileSettings(){
    var data = {"type":"videos"};
    return this.vendorService
      .getFileSettings(data)
      .pipe(tap(
        response => {},
        error=> {
          this.toastr.error(error.message);
        }
      ));     
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return 0;
    const k = 1024;  
    const i = bytes/k;  
    return parseFloat(i.toFixed(2));
  }
  
}
