import { Deseriazable } from './deserializable.model';

export class VendorDetailModel implements Deseriazable{
    
    id: string;    
    business_name: string;
    business_type: string;
    category_id: string;    
    website: string;
    message: string; 

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}