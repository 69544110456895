import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LoginFormBuilder } from './login.form-builder';
import { LoginModel } from 'src/app/models/login.model';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { RouterExtService } from '../../../referrer'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted = false;
  model = new LoginModel();
  loginForm: any;
  isLoader = false;
  returnUrl: string;

  constructor(private usersService: UsersService,
    private formBuilder: LoginFormBuilder,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    //private routerService: RouterExtService,
    public router: Router) {
    //this.loginForm = formBuilder.getForm();
  }

  get f() { return this.loginForm.controls; }

  ngOnInit() {
    this.loginForm = this.formBuilder.getForm();
    this.resetForm();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/vendor/home';
    window.scroll(0, 0);
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.model.deserialize(this.loginForm.value);
    this.isLoader = true;
    this.usersService
      .authenticate(this.model)
      .subscribe(
        (response: AuthResponseModel) => {
          if (response.token) {
            this.toastr.success("Login Success");
            
            this.router.navigate([this.returnUrl], { replaceUrl: true });
            localStorage.setItem("dashboardMsg","1");
            return false;
          }
          else {
            this.isLoader = false;
            this.toastr.error("Login failure");
          }
        },
        error => {
          this.isLoader = false;
          this.toastr.error(error.message);
        }
      )
  }

  resetForm() {
    this.submitted = false;
    this.loginForm.reset();
  }

}
