import { Deseriazable } from './deserializable.model';

export class RequestPriceModel implements Deseriazable{
    
    token: string;
    name: string;
    email: string;
    phone: string;
    event_date: string;
    no_of_guest: string;
    message: string;
    preferred_contact: string;
    vendor_id : number;
    mode: string;
    sender_id: number;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}