import { Component, OnInit } from '@angular/core';
import { MemoryService } from '../services/memories.service';
import { Config } from '../../../config';

@Component({
  selector: 'app-wedding-memories',
  templateUrl: './wedding-memories.component.html',
  styleUrls: ['./wedding-memories.component.scss']
})
export class WeddingMemoriesComponent implements OnInit {
  memories: any[]= [];
  memoryPhotoUrl = Config.imageUrl + 'images/memory_photos/';
  constructor(
    private memoryService: MemoryService,
  ) { }

  ngOnInit() {
    this.getMemories();
  }

  getMemories(){
    let formData = {
      page_number: 1,
      items_in_page: 10,
    };
    this.memoryService.getMemories(formData).subscribe(res => {
      this.memories = res.data;
      console.log(this.memories);
    }, error => {
      console.log(error);
    });
  }

}
