import { Component,ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError, tap } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SettingsModel } from 'src/app/models/settings.model';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { LocationModel } from 'src/app/models/location.model';
import { CategoryModel } from 'src/app/models/category.model';
import { VendorsService } from '../services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';
import { MediadModel } from 'src/app/models/media.model';
import { PackageSubscriptionModel } from 'src/app/models/package.subscription.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SortablejsOptions } from 'angular-sortablejs';

declare var $: any;

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.css']
})
export class PhotosComponent implements OnInit {

  submitted = false;
  model = new MediadModel();
  photoForm: any;
  photos: any;
  photo_id: number;
  thumbimage: any;
  deletebulkphoto = [];
  panoramicimage: string;
  showDraggable = false;
  showTitle = true;
  myPlan: any;
  uploadFiles: string;
  deviceInfo = null;
  mobile = false;
  desktop = false;
  fileSettings: any;
  filesQueue: any;
  totalFilesCount = 0;
  noImage = Config.noImage;
  photo_url: string = Config.imageUrl;

  @ViewChild('fileUploadProgress') fileUploadProgress: any;
  getThumbPhoto: string;
  getPhoto: string;
  

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr: ToastrService,
    private deviceService: DeviceDetectorService,
    public router: Router) {      
      this.deviceDetector();
  }

  ngOnInit() {
    this.getMedia('photos');
    this.getPanoramicImage();
    this.getThumbImage();
    this.selectedPlan();    
    window.scroll(10,10);
    
  }

  droppedHorizontalItems(){
    console.log(123);
  }

  saveFeaturedImage(files: any) {
    var error = [];
    var extarray = ["png","jpg","jpeg"];
    for(var i=0;i<files.length;i++){
      var filetype = files[i].type.split("/");
      if(filetype[0]!="image"){
        error.push(1);
        $("#featured").val("");
        break;
      }
      if(extarray.indexOf(filetype[1])==-1){
        error.push(1);
        $("#featured").val("");
        break;
      }
    }
    if(error.length>0){
      this.toastr.error("Wrong file format.");
      return;
    }
    this.vendorService
      .saveFeaturedImage(files[0])
      .subscribe(
        (response) => {
          this.toastr.success(response.message);
          $("#featured").val("");
          this.getThumbImage();
          return false;
        },
        error => {
          this.toastr.error(error.message);
          $("#featured").val("");
        }
      )
  }

  onFileChanges(files: any) {
    this.getFileSettings().subscribe(response=>{
      this.fileSettings = response;
      $(".overlayDiv").show();
      let valid = this.fileValidation(files);
      $(".overlayDiv").hide();
      if(valid){
        this.filesQueue = [];
        this.totalFilesCount = files.length;
        for(var i=0;i<files.length;i++){
          this.filesQueue.push(files[i]);
        }
        this.fileUpload();
      }
    });
  }

  fileValidation(files){
    var error_ext = [];
    //File extension checking
    var extarray = ["png","jpg","jpeg"];
    /*for(var i=0;i<files.length;i++){
      var filetype = files[i].type.split("/");
      if(filetype[0]!="image"){
        error_ext.push(1);
        break;
      }
      if(extarray.indexOf(filetype[1])==-1){
        error_ext.push(1);
        break;
      }
    }
    if(error_ext.length>0){
      this.toastr.error("Wrong file format.");
      $("#input-file-now").val("");
      return false;
    }*/
    var error_file_size = [];
    //Total count checking
    if(this.fileSettings.remaning>0 && this.fileSettings.remaning>=files.length){
       
      for(var i=0;i<files.length;i++){

        var filetype = files[i].type.split("/");
        if(filetype[0]!="image"){
          error_ext.push(1);
          break;
        }
        if(extarray.indexOf(filetype[1])==-1){
          error_ext.push(1);
          break;
        }
        var convToKb = this.formatBytes(files[i].size);
        console.log(this.fileSettings);
        console.log(convToKb);
        if(!(convToKb>=this.fileSettings.min_size && convToKb<=this.fileSettings.max_size)){
          error_file_size.push(1);
          break;
        }
      }

      if(error_ext.length>0){
        this.toastr.error("Wrong file format.");
        $("#input-file-now").val("");
        return false;
      }

      if(error_file_size.length>0){
        this.toastr.error("File size must be between 100KB to 5MB.");
        $("#input-file-now").val("");
        return false;
      }

    }else{
      this.toastr.error("Maximum number of images for your account has been reached.");
      $("#input-file-now").val("");
      return false;
    }
    return true;
  }

  fileUpload(){
    if(this.filesQueue.length==0){
      this.fileUploadProgress.nativeElement.style.width = "0";
      this.fileUploadProgress.nativeElement.parentElement.style.display = "none";
      this.toastr.success("Media Saved");
      this.getMedia("photos");
      $("input[type=file]").val('');
      return;
    }
    this.fileUploadProgress.nativeElement.parentElement.style.display = "flex";
    let file = this.filesQueue.shift();
    this.vendorService
        .saveMedia([file])
        .subscribe(
          (response) => {
            let persentwidth = (((this.totalFilesCount - this.filesQueue.length)/this.totalFilesCount)*100);
            this.fileUploadProgress.nativeElement.style.width = persentwidth + "%";
            this.fileUploadProgress.nativeElement.innerHTML = persentwidth.toFixed(2) + "%";  
            this.fileUpload();
          },
          error => {
            this.toastr.error(error.message);
          }
        );
  }

   deletePhotoModal() {    
    $("#deletephoto").modal();
  } 

  deletePhotos(ele:any) {    
    if(ele.target.checked){
      this.deletebulkphoto.push(ele.target.value);
    }else{
      let index = this.deletebulkphoto.indexOf(ele.target.value);
      if (index > -1) {
        this.deletebulkphoto.splice(index, 1);
      }
    }
  }

  deleteMedia() {
    var delete_ids = [];
    $("input[type=checkbox]:checked").each(function(){
      delete_ids.push($(this).val());      
    });

    if(delete_ids.length==0){      
      this.toastr.error("Please select images(s) to delete.");
      return;
    }
    this.vendorService
      .deleteMedia(delete_ids)
      .subscribe(
        (response: MediadModel) => {
          $('#deletephoto').modal('toggle');
          this.toastr.success(response.message);
          this.getMedia("photos");
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

  getMedia(type: string) {
    this.vendorService
      .getMedia(type)
      .subscribe(
        (response: MediadModel[]) => {
          this.photos = response;
          // console.log(this.photos);
          // this.getPhoto = Config.imageUrl + 'images/vendor_thumb_images/' + this.photos[0].file_name;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  makeFeatured(id:number){
    this.vendorService
      .makeFeatured(id)
      .subscribe(
        (response: MediadModel) => {
          this.toastr.success(response.message);
          this.getMedia("photos");
          return false;
        },
        error => {
          this.toastr.error(error.message);
          $("input[type=file]").val('');
          console.log(error.message);
        }
      )
  }

  getThumbImage(){
    this.vendorService
      .getThumbImage()
      .subscribe(
        (response: any) => {
          //this.toastr.success(response.message);
          this.thumbimage = response.data.search_result_image;
          this.getThumbPhoto = Config.imageUrl + 'images/vendor_thumb_images/' + this.thumbimage;
          // console.log(this.thumbimage);
          
          //console.log(response.data.search_result_image);
          return false;
        }
      )
  }
  
  deleteThumbImage(){
    this.vendorService
      .deleteThumbImage()
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          this.thumbimage = "";
          //console.log(response.data.search_result_image);
          return false;
        }
      )
  }

  savePhotoTitle(){
    var phototitle = [];
    /*$(".phototitle").each(function(i){
      //console.log($(this).val());
      //if($(this).val()){
        var id = $(this).attr("id");
        var val = $(this).val();
        var ord = i+1;
        phototitle.push({"id":id,"title":val,"ord": ord});
      //}      
    })*/
    for(let i=0;i<this.photos.length;i++){
      var ord = i+1;
      phototitle.push({"id":this.photos[i].id,"title":this.photos[i].title,"ord": ord});
    }

    /* if(phototitle.length>0 && this.showTitle==true){
      this.toastr.error("Add at least one image title.");
      return;
    } */
      this.vendorService
      .saveMediaTitle(phototitle)
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          //this.getMedia("photos");
          //this.ngOnInit();
          return false;
        },
        error => {
          this.toastr.error(error.message);
          return false;
        }
      )
    
    console.log(phototitle);
  }

  generatePanoramicImage(){
    this.vendorService
      .generatePanoramicImage()
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          this.getPanoramicImage();          
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  getPanoramicImage(){
    this.vendorService
      .getAuthVendorDetail()
      .subscribe(
        (response: any) => {
          this.panoramicimage = response.data.center_stage_image;   
          console.log(response.data.center_stage_image);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  showShort(ele:any){
    console.log(ele.target.value);
    if(ele.target.value=="dragg"){
      this.showDraggable = true;
      this.showTitle = false;
    }else{
      this.showDraggable = false;
      this.showTitle = true;
    }
    
  }

  onDrop(){
    this.savePhotoTitle();
  }

  selectedPlan(){
    this.vendorService
      .vendorCurrentPlan()
      .subscribe(
        (response:PackageSubscriptionModel) => {          
          if(response.package_id){
            this.myPlan = response;
            console.log(response.package_id);
            if(response.package_id>1){
              this.uploadFiles = "A minimum of 6 higher resolution images are required.  File size should be between 100KB and 5MB.  Upload up to 100 images.";
            }else{
              this.uploadFiles= "A minimum of 6 higher resolution images are required.  Basic members are allowed a maximum of 10 images.  File size should be between 100KB and 5MB.  Upgrade your account to add more images.";
            }
          }else{
            this.myPlan = {"title":"Basic","rate":"0.00","duration_in_month":"1"};
          }
          //console.log(response);
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  deviceDetector(){
    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();    
    if(isMobile){
      this.mobile = true;
      this.desktop = false;
    }else{
      this.mobile = false;
      this.desktop = true;
    }
  }

  options: SortablejsOptions = {
    onUpdate: (event: any)=>{
      this.savePhotoTitle();
    }
  }

  getFileSettings(){
    var data = {"type":"photos"};
    return this.vendorService
      .getFileSettings(data)
      .pipe(tap(
        response => {},
        error=> {
          this.toastr.error(error.message);
        }
      ));     
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return 0;
    const k = 1024;  
    const i = bytes/k;  
    return parseFloat(i.toFixed(2));
  }

}

