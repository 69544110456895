import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../users/services/users.service';
import { VendorsService } from '../services/vendors.service';
import { MessageFormBuilder } from '../messages/message.form-builder';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.css']
})
export class HeaderMenuComponent implements OnInit {

activeProfile = false;
activeMessage = false;
activeAccountInfo = false;
access = localStorage.getItem("role");
showMenuItem = true;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: MessageFormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }

  ngOnInit() {

    $(".toggle_butt").click(function(){
      $(".show_hide_menu").toggle(700);
    });
    $(".toggle_butt2").click(function(){
      $(".show_hide_menu2").toggle(700);
    });
    $(".toggle_butt3").click(function(){
      $(".show_hide_menu3").toggle(700);
    });

    if(this.access == "Vendor User"){
      this.showMenuItem = false;
    }else{
      this.showMenuItem = true;
    }

    let getUrl = this.router.url;
    let explode = getUrl.split("/");
    //console.log(explode);
    if(explode[2]=="businessinfo" || explode[2]=="map" || explode[2]=="generaldetails"  
    || explode[2]=="deals"  || explode[2]=="photos"  || explode[2]=="videos" 
    || explode[2]=="events"  || explode[2]=="pricing" || explode[2]=="custom-faq" 
    || explode[2]=="availability" || explode[2]=="documents" || explode[2]=="social-media"){
      this.activeProfile = true;
    }else if(explode[2]=="authorized-users" || explode[2]=="settings" || explode[2]=="buttons" || explode[2]=="preferences" ){
      this.activeAccountInfo = true;
    }else if(explode[2]=="message-settings"){
      this.activeMessage = true;
    }
    this.route.params.subscribe(params => {
      if(params.name=="inbox" || params.name=="sent" || params.name=="read"  || params.name=="unread"  || params.name=="pending" || params.name=="booked" || params.name=="discarded" ){
        this.activeMessage = true;
      }
    });
  }

}
