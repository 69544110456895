import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { VendorBusinessInfoModel } from 'src/app/models/vendor.business.info.model';
import { SocialMediaFormBuilder } from './social.media.form-builder';
import { SocialMediaModel } from 'src/app/models/social.media.model';

declare var $: any;

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css']
})
export class SocialMediaComponent implements OnInit {

  submitted = false;
  model = new SocialMediaModel();
  socialMediaForm: any;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: SocialMediaFormBuilder,
    private toastr:ToastrService,
    public router: Router) {
  }

  ngOnInit() {
    this.socialMediaForm = this.formBuilder.getForm();
    //this.socialMediaForm.controls.mtype.setValue(2);     
    this.getSocialMedia();
    window.top.scroll(10,10);  
    
  }

  get f() { return this.socialMediaForm.controls; }

  onSubmit() {
    
    this.socialMediaForm.get('mode').setValue('social');
    this.submitted = true;
    //console.log(this.socialMediaForm.value);return;
    // stop here if form is invalid
    if (this.socialMediaForm.invalid) {
      return;
    }

    this.model.deserialize(this.socialMediaForm.value);

    var error = true;
    $(".form-control").each(function(){
      if($(this).val()){
        error = false;
      }
    })
    if(error){
      this.toastr.error("All fields are blank. Fill up at least one field."); 
      return false;
    }

    this.vendorService
      .updateSocialMedia(this.model)
      .subscribe(
        (response:SocialMediaModel) => {          
          this.toastr.success(response.message); 
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }
  
  getSocialMedia(){
    this.vendorService
      .getSocialMedia()
      .subscribe(
        (response:SocialMediaModel) => {
          this.formBuilder.setValues(response);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

}
