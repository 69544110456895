import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../password.match';
import { SettingsModel } from 'src/app/models/settings.model';

@Injectable({
    providedIn: 'root'
})
export class SettingsFormBuilder{

    private form;
    private validationRules = {        
        
        avalibility_update_reminder_frequency: ['',Validators.required],
        allow_feature_profile:['','']
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:SettingsModel){
        this.form.patchValue({
            avalibility_update_reminder_frequency:response.avalibility_update_reminder_frequency,
            allow_feature_profile:response.allow_feature_profile
        });
    }
}