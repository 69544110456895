import { Component, OnInit } from '@angular/core';
import { ChecklistService } from '../services/checklist.service';
import { Router } from '@angular/router';
import { PlannersService } from '../services/planners.service';

@Component({
  selector: 'app-checklist-print',
  templateUrl: './checklist-print.component.html',
  styleUrls: ['./checklist-print.component.scss']
})
export class ChecklistPrintComponent implements OnInit {
  activeUser: any;
  afterChecklist: any;
  befourChecklist: any;
  beforeChecklist: any;

  constructor(private checklistService: ChecklistService, public router: Router,
    private plannersService: PlannersService) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
  }

  ngOnInit() {
    if (this.activeUser && this.activeUser.id) {
      this.getBeforeChecklist();
      this.getAfterChecklist();

    }
  }


   getAfterChecklist() {
    let data = {
      wedding_id: this.activeUser.id,
      status: null,
      is_trash: null,
      due_now: null,
      after_wedding:1,
      catagory_id: null,
    };
    this.checklistService.filterCheckList(data).subscribe((result: any) => {
      this.afterChecklist = result.data;
    },
      error => {
        console.error(error);
        // this.toastr.error(error.message);
      })
  }

   getBeforeChecklist() {
    let data = {
      wedding_id: this.activeUser.id,
      status: null,
      is_trash: null,
      due_now: 1,
      after_wedding:null,
      catagory_id: null,
    };
    this.checklistService.filterCheckList(data).subscribe((result: any) => {
      this.beforeChecklist = result.data;
    },
      error => {
        // this.toastr.error(error.message);
        console.error(error);
      })
  }

  print() {
    window.print();
  }

  back() {
    this.router.navigate(['checklist']);
  }

  home() {
    this.router.navigate(['/']);
  }

}
