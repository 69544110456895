import { Component, OnInit } from '@angular/core';
import { PlaceholdersService } from '../modules/cms/services/placeholders.service';

@Component({
  selector: 'app-main-slider',
  templateUrl: './main-slider.component.html',
  styleUrls: ['./main-slider.component.css']
})
export class MainSliderComponent implements OnInit {

  mainSlider:any = null;
  constructor(private placeholdersService:PlaceholdersService) { }


  ngOnInit() {
    this.placeholdersService
      .getCenterStageImages('main-slider')
      .subscribe((result) => {
        this.mainSlider = result.data.images;
        console.log(this.mainSlider);
      });
  }

}
