import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule  }   from '@angular/forms';
import { NgSelect2Module } from 'ng-select2';
import { OwlDateTimeModule, OwlNativeDateTimeModule,OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE  } from 'ng-pick-datetime';
import { FrontPageComponent } from 'src/app/front-page/front-page.component';
import { RealWeddingComponent } from './real-wedding/real-wedding.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PlannerDashboardComponent } from './planner-dashboard/planner-dashboard.component';
import { ChecklistComponent } from './checklist/checklist.component';
import { PlannerNavigationComponent } from './planner-navigation/planner-navigation.component';
import { AuthGuardService } from 'src/app/_guards/auth-guard.service';
import { Config } from '../../config';
import { LoginGuardService } from 'src/app/_guards/login-guard.service';
import { GuestlistComponent } from './guestlist/guestlist.component';
import { BudgetComponent } from './budget/budget.component';
import { ManageSubEventsComponent } from './manage-sub-events/manage-sub-events.component';
import { AddEventComponent } from './add-event/add-event.component';
import { NgxDonutChartModule } from 'ngx-doughnut-chart';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ChecklistPrintComponent } from './checklist-print/checklist-print.component';
import { BudgetPrintComponent } from './budget-print/budget-print.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { GuestMissingInfoComponent } from './guest-missing-info/guest-missing-info.component';
import { GuestInvitationComponent } from './guest-invitation/guest-invitation.component';
import { CreateGuestIntitationsComponent } from './create-guest-intitations/create-guest-intitations.component';
import { GuestViewSummaryComponent } from './guest-view-summary/guest-view-summary.component';
import { GuestPrintComponent } from './guest-print/guest-print.component';
import { SeatingChartComponent } from './seating-chart/seating-chart.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material';
import { VendorManagerComponent } from './vendor-manager/vendor-manager.component';
import { VendorCategoryComponent } from './vendor-category/vendor-category.component';
import { VendorRatingsComponent } from './vendor-ratings/vendor-ratings.component';
import { VendorPrintComponent } from './vendor-print/vendor-print.component';
import { RequestPricingComponent } from '../vendors/request-pricing/request-pricing.component';
import { VendorsModule } from '../vendors/vendors.module';
import { SearchByCoupleComponent } from './search-by-couple/search-by-couple.component';
import { WeddingWebsiteSettingComponent } from './wedding-website-setting/wedding-website-setting.component';
import { ViewTemplateComponent } from './view-template/view-template.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { SortablejsModule } from 'angular-sortablejs';
import { RegistryComponent } from './registry/registry.component';
import { ReviewRequestComponent } from './review-request/review-request.component';
import { WeddingForumComponent } from './wedding-forum/wedding-forum.component';
import { DiscussionComponent } from './discussion/discussion.component';
import { CommentsComponent } from './comments/comments.component';
import { CreateDiscussionComponent } from './create-discussion/create-discussion.component';
import { CommunityGuestLoginModalComponent } from './community-guest-login-modal/community-guest-login-modal.component';
import { CommunityLoginPopupComponent } from './community-login-popup/community-login-popup.component';
import { MemoryEditComponent } from './memory-edit/memory-edit.component';
import { WeddingMemoryDetailsComponent } from './wedding-memory-details/wedding-memory-details.component';
import { WeddingMemoriesComponent } from './wedding-memories/wedding-memories.component';
import { WeddingIdeasComponent } from './wedding-ideas/wedding-ideas.component';
import { WeddingIdeasCategoryComponent } from './wedding-ideas-category/wedding-ideas-category.component';
import { WeddingIdeasDetailsComponent } from './wedding-ideas-details/wedding-ideas-details.component';
import { WeddingForumSearchComponent } from './wedding-forum-search/wedding-forum-search.component';
import { WeddingIdeasSearchComponent } from './wedding-ideas-search/wedding-ideas-search.component';

var planersRoutes = [
  { path: 'login', component: LoginComponent,canActivate: [LoginGuardService] },
  { path: 'register', component: RegisterComponent,canActivate: [LoginGuardService] },
  { path: 'terms', component: TermsComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'registry/search', component: SearchByCoupleComponent },

  // Need Authentication --
  { path: 'link-account/:id', component: RegisterComponent },
  { path: 'wedding/:id', component: RegisterComponent,canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles } },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'planner-dashboard', component: PlannerDashboardComponent,canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'checklist', component: ChecklistComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'guestlist', component: GuestlistComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'add-event', component: AddEventComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'manage-sub-events', component: ManageSubEventsComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'budget', component: BudgetComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'registry', component: RegistryComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'checklistPrint', component: ChecklistPrintComponent,canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'budgetPrint', component: BudgetPrintComponent ,canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'guest-missing-info/:id', component: GuestMissingInfoComponent },
  { path: 'guest-invitation', component: GuestInvitationComponent },
  { path: 'create-guest-invitation', component: CreateGuestIntitationsComponent },
  { path: 'guest-view-summary', component: GuestViewSummaryComponent },
  { path: 'guest-print', component: GuestPrintComponent },
  { path: 'vendor-manager', component: VendorManagerComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'vendor-manager/review-request', component: ReviewRequestComponent },
  { path: 'vendor-category/:id', component: VendorCategoryComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'vendor-ratings/:id', component: VendorRatingsComponent },
  { path: 'vendor-print/:id', component: VendorPrintComponent },
  { path: 'seating-chart', component: SeatingChartComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'website-dashboard', component: WeddingWebsiteSettingComponent, canActivate: [AuthGuardService], data: { roles: Config.serviceEndPoints.weddingUsers.roles }},
  { path: 'my-wedding/:pageName', component: ViewTemplateComponent },
  // Community --
  { path: 'wedding-community', component: WeddingForumComponent },
  { path: 'wedding-community-search/:search', component: WeddingForumSearchComponent },
  { path: 'community/:category', component: DiscussionComponent },
  { path: 'conversation/create', component: CreateDiscussionComponent },
  { path: 'conversation/:slug', component: CommentsComponent },
  // Memory --
  { path: 'memory/edit', component: MemoryEditComponent },
  { path: 'memory/:slug', component: WeddingMemoryDetailsComponent },
  { path: 'memories', component: WeddingMemoriesComponent },
  // Wedding Ideas --
  { path: 'ideas-and-tips', component: WeddingIdeasComponent },
  { path: 'ideas-and-tips-search/:searchType', component: WeddingIdeasSearchComponent },
  { path: 'ideas-and-tips/:slug', component: WeddingIdeasCategoryComponent },
  { path: 'ideas-and-tips/article/:slug', component: WeddingIdeasDetailsComponent },
];

@NgModule({
  declarations: [LoginComponent, RealWeddingComponent, SeatingChartComponent, 
    ForgotPasswordComponent, RegisterComponent, PlannerDashboardComponent, ChecklistComponent, 
    PlannerNavigationComponent, GuestlistComponent, BudgetComponent, ManageSubEventsComponent, 
    AddEventComponent, TermsComponent, PrivacyComponent, ChecklistPrintComponent, BudgetPrintComponent, 
    GuestMissingInfoComponent, GuestInvitationComponent, CreateGuestIntitationsComponent, 
    GuestViewSummaryComponent, GuestPrintComponent, VendorManagerComponent, VendorCategoryComponent, 
    VendorRatingsComponent, VendorPrintComponent, SearchByCoupleComponent, WeddingWebsiteSettingComponent, ViewTemplateComponent, RegistryComponent, ReviewRequestComponent, WeddingForumComponent, DiscussionComponent, CommentsComponent, CreateDiscussionComponent, CommunityGuestLoginModalComponent, CommunityLoginPopupComponent, MemoryEditComponent, WeddingMemoryDetailsComponent, WeddingMemoriesComponent, WeddingIdeasComponent, WeddingIdeasCategoryComponent, WeddingIdeasDetailsComponent, WeddingForumSearchComponent, WeddingIdeasSearchComponent],
  imports: [
    RouterModule.forChild(planersRoutes),
    CommonModule,
    SharedModule,
    FormsModule,
    NgSelect2Module,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxDonutChartModule,
    AlifeFileToBase64Module,
    ImageCropperModule,
    GoogleChartsModule,
    DragDropModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    VendorsModule,
    CKEditorModule,
    SortablejsModule.forRoot({ animation: 150 }),
  ],
  exports:[RealWeddingComponent],
  providers: [
    // use french locale
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'us'},
    //{provide: OWL_DATE_TIME_FORMATS, useValue: CUSTOM_DATE_TIME_FORMATS},
  ],
})
export class PlannersModule { }
