import { Component, OnInit } from '@angular/core';
import { VerifyPasswordModel } from 'src/app/models/verifypassword.model';
import { ResetPasswordModel } from 'src/app/models/resetpassword.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../../users/services/users.service';
import { ResetPasswordFormBuilder } from '../../planners/reset-password/reset-password.form-builder';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  submitted = false;
  resetSubmitted = false;
  isVerified = false;
  model = new VerifyPasswordModel();
  resetModel = new ResetPasswordModel();
  verifyPasswordForm: any;
  resetPasswordForm: any;

  constructor(private usersService: UsersService,
    private formBuilder: ResetPasswordFormBuilder,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }

  get f() { return this.verifyPasswordForm.controls; }

  get h() { return this.resetPasswordForm.controls; }

  ngOnInit() {
    this.verifyPasswordForm = this.formBuilder.getForm();
    this.resetPasswordForm = this.formBuilder.getResetForm();

    this.route.queryParams.subscribe((params: any) => {
      if (params.email) {
        this.verifyPasswordForm.patchValue({
          'email': params.email
        });
      }else{
        this.toastr.error("Reset Link is invalid, try forget password again.");
        setTimeout(()=>{    
          this.router.navigate(['login']);
        }, 1000);
      }
    });

    window.scroll(0,0);
  }

  onVerifySubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.verifyPasswordForm.invalid) {
      return;
    }

    this.model.deserialize(this.verifyPasswordForm.value);
    this.usersService
      .verifyPassword(this.model)
      .subscribe(
        (response:any) => {
          if(response){
            this.isVerified = true;
          }else{
            this.toastr.error("Otp is invalid, try again.");
          } 
        },
        error => { 
          this.toastr.error(error.message);
          //console.log(error.message);
        }
      )


    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value))
  }

  onSubmit() {
    this.resetSubmitted = true;
    this.resetPasswordForm.patchValue({
      'email': this.verifyPasswordForm.value.email
    });

    // stop here if form is invalid
    if (this.resetPasswordForm.invalid) {
      return;
    }

    if(this.resetPasswordForm.value.againPassword != '' && this.resetPasswordForm.value.newPassword != this.resetPasswordForm.value.againPassword){
      this.toastr.error("New password and confirm password doesn't match.");      
      return;
    }

    this.resetModel.deserialize(this.resetPasswordForm.value);
    this.usersService
      .resetPassword(this.resetModel)
      .subscribe(
        (response:ResetPasswordModel) => { 
          this.toastr.success("Password was reset successfully");
          setTimeout(()=>{    
            this.router.navigate(['login']);
          }, 1000);          
          return false;
          //console.log(response);
          //Config.storeValue("token",1234);
        },
        error => { 
          this.toastr.error(error.message);
          //console.log(error.message);
        }
      )


    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value))
  }
  
  resetForm(){
    this.submitted = false;
    this.resetSubmitted = false;
    this.verifyPasswordForm.reset();
    this.resetPasswordForm.reset();
  }

}
