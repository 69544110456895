import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { RequestPriceModel } from 'src/app/models/request.price.model';
import { RequestPriceFormBuilder } from './request.price.form-builder';
import { isNumber } from 'util';

declare var $: any;
@Component({
  selector: 'app-request-pricing',
  templateUrl: './request-pricing.component.html',
  styleUrls: ['./request-pricing.component.css']
})
export class RequestPricingComponent implements OnInit {

  @Input() vendor_id: number;

  submitted = false;
  model = new RequestPriceModel();
  requestPriceForm: any;
  vendorId: number;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: RequestPriceFormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router) {

  }
  ngOnInit() {
    this.requestPriceForm = this.formBuilder.getForm();
    this.requestPriceForm.controls.subject.setValue("Message from Profile");
  }

  ngOnChanges(change: SimpleChanges) {
    if (change['vendor_id']) {
      this.vendorId = this.vendor_id;
    }
  }

  get f() { return this.requestPriceForm.controls; }

  onSubmit() {
    console.log("this.vendorId", this.vendorId);
    if(!this.vendorId){
      return;
    }
    this.requestPriceForm.controls.vendor_id.setValue(this.vendorId);
    this.requestPriceForm.controls.preferred_contact.setValue($("input[name='preferred_contact']:checked").val());
    this.requestPriceForm.controls.mode.setValue('pricing');
    this.requestPriceForm.controls.subject.setValue($("#subject").val());
    this.submitted = true;
    // stop here if form is invalid
    this.requestPriceForm.value.phone = this.requestPriceForm.value.phone.replace("-","").replace("-","");
    if (this.requestPriceForm.invalid) {
      return;
    }
    this.model.deserialize(this.requestPriceForm.value);
    if (this.requestPriceForm.value.event_date) {
      this.model.event_date = this.usDateToDb(this.requestPriceForm.value.event_date);
    }
    this.vendorService
      .saveRequestPrice(this.model)
      .subscribe(
        (response: RequestPriceModel) => {
          this.toastr.success(response.message);
          $("#requestpricing").modal('hide');
          this.submitted = false;
          this.requestPriceForm.reset();
          this.requestPriceForm.controls.subject.setValue("Message from Profile");
          this.route.params.subscribe(params => {
            this.closeDiv();
          });
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  closeDiv() {
    $(".close_div").hide();
    $(".vendor_message").removeClass("divbackgroundcolor");

  }

  usDateToDb(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

}
