import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { SettingsFormBuilder } from './settings.form-builder';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SettingsModel } from 'src/app/models/settings.model';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { LocationModel } from 'src/app/models/location.model';
import { CategoryModel } from 'src/app/models/category.model';
import { VendorsService } from '../services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';
import { VendorBusinessInfoModel } from 'src/app/models/vendor.business.info.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  submitted = false;
  model = new SettingsModel();
  settingForm: any;
  settings: any;
  setSelectedKeys: any;
  setSelectedValue: any;
  access = localStorage.getItem("role");
  allow_feature_profile = 0;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private locationService: LocationsService,
    private formBuilder: SettingsFormBuilder,
    private toastr:ToastrService,
    public router: Router) {
    //this.loginForm = formBuilder.getForm();
  }
  
  ngOnInit() {
    if(this.access=="Vendor User"){
      this.toastr.error("You are not authorize to access this page");
      this.router.navigate(["vendor/home"]);
    }
    this.settingForm = this.formBuilder.getForm(); 
    this.getBusinessInfo();
    window.scroll(10,10);
  }

  get f() { return this.settingForm.controls; }

  onSubmit() {   
    this.submitted = true;
    // stop here if form is invalid
    if (this.settingForm.invalid) {
      return;
    }

    this.model.deserialize(this.settingForm.value);

    this.vendorService
      .saveAvailabilitySetting(this.model)
      .subscribe(
        (response:any) => {          
          this.toastr.success(response.message); 
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
}
getSettings(){
  this.vendorService
    .getSettings()
    .subscribe(
      (response:SettingsModel[]) => { 
        this.settings = response;
        this.getSettingsByVendor();
      },
      error => {
        this.toastr.error(error.message);
      }
    )
}
getSettingsByVendor(){
  this.vendorService
    .getSettingByVendor()
    .subscribe(
      (response:SettingsModel) => { 
        this.setSelectedValue =  response;
      },
      error => {
        this.toastr.error(error.message);
      }
    )
}
checked(key:any){
  
  if(this.setSelectedValue[key]==1){
    return 1;
  }    
}

getBusinessInfo(){
  this.vendorService
    .getBusinessInfo()
    .subscribe(
      (response:any) => {
        this.formBuilder.setValues(response);
        this.allow_feature_profile = response.allow_feature_profile;
        if(response.avalibility_update_reminder_frequency==null || response.avalibility_update_reminder_frequency==""){
          this.settingForm.controls['avalibility_update_reminder_frequency'].setValue('30');
        }
      }
    ) 
}


}
