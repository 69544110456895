import { Component, OnInit } from '@angular/core';
import { IdeaAndTipsService } from '../services/ideas-and-tips.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PlannersService } from '../../planners/services/planners.service';
import { Config } from '../../../config';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-wedding-ideas-search',
  templateUrl: './wedding-ideas-search.component.html',
  styleUrls: ['./wedding-ideas-search.component.scss']
})
export class WeddingIdeasSearchComponent implements OnInit {
  search_text: string = "";
  search_result: any[] = [];
  ideaAndTipsCategories: any;
  ideaAndTips: any;
  activeUser: any;
  current_page_number: number = 1;
  total_discussions: number = 0;
  searchType = 2;
  discussions_pagination_items: any[] = [];
  ideaAndTipsPhotoUrl = Config.imageUrl + 'images/idea_and_tips/';
  category_banner_image_url: string = Config.imageUrl + 'images/discussion_category_banner/';

  constructor(
    private ideaAndTipsService: IdeaAndTipsService,
    public router: Router,
    public route: ActivatedRoute,
    private plannersService: PlannersService,
  ) { 
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        if (params['searchType']) {
          if(params['searchType'] == "trending"){
            this.searchType = 1;
          } else if(params['searchType'] == "latest"){
            this.searchType = 2;
          } else if(params['searchType'] == "fav"){
            this.searchType = 3;
          }else{
            this.searchType = 2;
          }
        }
      }
    });
    this.route.queryParams.subscribe(params => {
      if(params['search'] && params['search'] != ''){
        this.search_text = params['search'];        ;
      }
    });
    this.getIdeaAndTips(1);
    await this.getIdeaAndTipsCategories();
  }
  async getIdeaAndTipsCategories() {
    var categories = await this.ideaAndTipsService.getIdeaAndTipsCategories().toPromise();
    this.ideaAndTipsCategories = categories.data;
  }

  async getIdeaAndTips(page_number: number) {
    let formData = {
      "type": this.searchType,
      "page": page_number,
      "search_text": this.search_text
    };
    var lists = await this.ideaAndTipsService.getIdeaAndTipsFilter(formData).toPromise();
    this.ideaAndTips = lists.data;
    this.total_discussions = lists.total_records;
    this.discussions_pagination_items = this.pagination(page_number, Math.ceil( this.total_discussions / 30));
  }
  async goToIdeaAndTipDetailsPage(slug: any) {
    this.router.navigate(['/ideas-and-tips/' + slug]);
  }
  goToCategory(cat: any){
    this.router.navigate(['/ideas-and-tips/' + cat.slug]);
  }

  columns(){
    return [
      this.ideaAndTipsCategories.slice(0, Math.ceil(this.ideaAndTipsCategories.length / 3)),
      this.ideaAndTipsCategories.slice(Math.ceil(this.ideaAndTipsCategories.length / 3), 2 * Math.ceil(this.ideaAndTipsCategories.length / 3)),
      this.ideaAndTipsCategories.slice(2 * Math.ceil(this.ideaAndTipsCategories.length / 3))
    ];  
  }

  pagination(current: number, last: number, delta = 2) {
    if (last === 1) return ["*" + 1 + "*"];

    const left = current - delta,
      right = current + delta + 1,
      range = [];

    if (last > 1 && current !== 1) {
      range.push("<");
    }

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        if (i === left && i > 2) {
          range.push("...");
        }

        if (i === current) {
          range.push("*" + i + "*");
        } else {
          range.push(i);
        }

        if (i === right - 1 && i < last - 1) {
          range.push("...");
        }
      }
    }

    if (last > 1 && current !== last) {
      range.push(">");
    }

    return range;
  }
  getPageItemText(pageItem: any) {
    if (isNaN(pageItem)) {
      if (pageItem.indexOf('*') === 0) {
        return pageItem.substr(1, (pageItem.length - 2));
      }
    } else {
      return pageItem;
    }
  }
  goPrev() {  
    this.current_page_number = this.current_page_number - 1;
    this.getIdeaAndTips(this.current_page_number);
  }
  goNext() {
    this.current_page_number = this.current_page_number + 1;
    this.getIdeaAndTips(this.current_page_number);
  }
  goToPage(pageItem: any) {
    this.current_page_number = Number(pageItem);
    this.getIdeaAndTips(this.current_page_number);
  }
  isNumber(value: any) {
    return !isNaN(value);
  }
}
