import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../password.match';
import { EventModel } from 'src/app/models/event.model';

@Injectable({
    providedIn: 'root'
})
export class EventFormBuilder{

    private form;
    private validationRules = {        
        id:['',''],
        event_name: ['',Validators.required],
        event_type_id: ['',Validators.required],
        start_date_time: ['',Validators.required],
        end_date_time: ['',Validators.required],
        city_id: ['',Validators.required],
        region_id: ['',Validators.required],
        address: ['',Validators.required],
        description: ['',''],
        image_name: ['',''],
        lat: ['', ''],
        long: ['', '']        
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:EventModel){
        this.form.patchValue({
            id:response.id,
            event_name:response.event_name,
            event_type_id:response.event_type_id,
            start_date_time: response.start_date_time,
            end_date_time: response.end_date_time,
            city_id:response.city_id,
            region_id:response.region_id,
            address:response.address,
            description:response.description,
            lat:response.lat,
            long:response.long
        });
    }
}