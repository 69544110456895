import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FrontPageComponent } from './front-page/front-page.component'; 
import { PlannersModule } from './modules/planners/planners.module';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { CommingSoonComponent } from './comming-soon/comming-soon.component';
import { MainSliderComponent } from './main-slider/main-slider.component';
import { QuickLinksComponent } from './quick-links/quick-links.component';
import { IdeasTipsComponent } from './ideas-tips/ideas-tips.component';
import { VendorsModule } from './modules/vendors/vendors.module';
import { ForumComponent } from './forum/forum.component';
import { BackendPageComponent } from './backend-page/backend-page.component';
import { UsersModule } from './modules/users/users.module';
import { AnonymousPageComponent } from './anonymous-page/anonymous-page.component';
import { CmsModule } from './modules/cms/cms.module';
import { FormsModule, ReactiveFormsModule  }   from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { SharedModule } from './shared/shared.module';
import { FrontNavComponent } from './front-nav/front-nav.component';
import { PaginationComponent } from './pagination/pagination.component';
import { WebServiceInterceptor } from './services/web-service-interceptor';
import { NetworkfailureComponent } from './networkfailure/networkfailure.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { SocialLoginModule, SocialAuthServiceConfig , GoogleLoginProvider, FacebookLoginProvider} from 'angularx-social-login';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    FrontPageComponent,
    CommingSoonComponent,
    MainSliderComponent,
    QuickLinksComponent,
    IdeasTipsComponent,
    ForumComponent,
    BackendPageComponent,
    AnonymousPageComponent,
    PaginationComponent,
    NetworkfailureComponent,
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBsiNBJPosctAUQ4-zb89NHARZyh37NCe8'
    }),
    FormsModule,   
    ReactiveFormsModule ,
    MDBBootstrapModule.forRoot(),
    UsersModule,
    VendorsModule,
    PlannersModule,
    AppRoutingModule,
    CmsModule,
    SharedModule,
    SocialLoginModule,
    HttpClientModule,
    DragDropModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
  ],
  exports:[
    NgxPaginationModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: WebServiceInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '283282558219-duoo7sng9p65vls2onmo3bacu37tr4u2.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('317863763195379')
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
