import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ForgotPasswordFormBuilder } from './forgot.password.form-builder';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ForgotPasswordModel } from 'src/app/models/forgotpassword.model';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  submitted = false;
  model = new ForgotPasswordModel();
  forgotPasswordForm: any;

  constructor(private usersService: UsersService,
    private formBuilder: ForgotPasswordFormBuilder,
    private toastr:ToastrService,
    public router: Router) {
  }

  get f() { return this.forgotPasswordForm.controls; }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.getForm();
    this.resetForm();
    window.scroll(0,0);
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.model.deserialize(this.forgotPasswordForm.value);

    this.usersService
      .resetPassword(this.model)
      .subscribe(
        (response:ForgotPasswordModel) => { 
          this.toastr.success("An email has been sent to your inbox.");
          setTimeout(()=>{    
            this.router.navigate(['vendor/login']);
          }, 1000);          
          return false;
          //console.log(response);
          //Config.storeValue("token",1234);
        },
        error => { 
          this.toastr.error(error.message);
          //console.log(error.message);
        }
      )


    //alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.loginForm.value))
  }
  signIn(){
    this.router.navigate(['/vendor/login']);
  }

  resetForm(){
    this.submitted = false;
    this.forgotPasswordForm.reset();
  }
}
