import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';

declare var $: any;

@Component({
  selector: 'app-message-settings',
  templateUrl: './message-settings.component.html',
  styleUrls: ['./message-settings.component.css']
})
export class MessageSettingsComponent implements OnInit {

  submitted = false;
  faqForm: any;
  faqs: any;
  faqLength: number;
  faqFieldValues = [];
  messageSettings = [];
  checkedval = [];
  selectedFaqs: any;
  deleteItem: any;
  emails = [];
  usersEmails = [];
  emailCheckbox = false;
  obj = {};

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }

  ngOnInit() {
    this.defaultEmails();
    //this.getMessageSettings();
    //this.emailCheckbox = true;
    window.scroll(10, 10);
  }

  onSubmit() {
    this.saveSettings();
  }

  getMessageSettings() {
    
    this.vendorService
      .getMessageSettings()
      .subscribe(
        (response) => {
          this.emailCheckbox = response.forward_vendor;
          for(var i=0;i<response.settings.length;i++){
            if(response.settings[i].user_enable_email){
              //console.log(response[i].email_id);
              //this.usersEmails[i] = response[i].email_id;
              let items = this.emails.filter((x)=>{return x.email == response.settings[i].email_id;});
              if(items && items.length>0){
                items[0].checked = true;
              }
            }else{
              this.messageSettings.push(response.settings[i]);
            }
          }
          
          /* if (this.messageSettings.length == 0) {
            this.addSettings();
          } */
        }
      )
  }

  selectFaqs(opVal: any, answer: any) {
    let index = answer.indexOf(opVal);
    if (index > -1) {
      return 1;
    }
  }

  addSettings() {
    if (this.messageSettings.length >= 3) {
      this.toastr.error("Sorry, You can not add more than 3 emails.");
      return;
    }
    this.messageSettings.push({ enable: '', email_id: '' });
  }

  saveSettings() {
    var settings = [];
    let selectedUserEmails = this.emails.filter((x) => {return x.checked;});
    
    /* if((this.messageSettings[0].email_id=="" || this.messageSettings[0].email_id==null)  && this.emailCheckbox==false){
      this.toastr.error("Please check checkbox or add email.");
      return;
    } */

    //console.log(this.messageSettings[0].email_id,this.emailCheckbox);return;
    for (let i = 0; i < this.messageSettings.length; i++) {
      /* if (this.messageSettings[i].email_id == "") {
        this.toastr.error(i + 1 + ".Email can not be blank.");
        return;
      } */
      if(this.messageSettings[i].email_id){
        if (this.validateEmail(this.messageSettings[i].email_id)) {
          this.toastr.error(i + 1 + ".Wrong email format.");
          return;
        }
      }

      settings.push({ "enable": true, "email_id": this.messageSettings[i].email_id, "user_enable_email": false });
    }

    this.obj['forward_vendor'] = this.emailCheckbox;
    this.obj['settings'] = settings;

    /* if(selectedUserEmails){
      for(let i=0; i<selectedUserEmails.length; i++){
        settings.push({ "enable": true, "email_id": selectedUserEmails[i].email, "user_enable_email": true });
      }
    } */

    //console.log(settings);return;
    this.vendorService
      .saveMessageSettings(this.obj)
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }


  deleteSetting(item: any) {
    this.deleteItem = item;
    /* if (this.messageSettings.length < 2) {
      this.toastr.error("Sorry you can not delete this.");
      return false;
    } else {
      $("#deleteemail").modal();
    } */
    $("#deleteemail").modal();
  }

  defaultEmails() {
    this.vendorService
      .defaultEmails()
      .subscribe(
        (response: any) => {
          this.emails = [];
          for(let i=0; i<response.length; i++){
            this.emails.push({email: response[i], checked:false});
          }
          this.getMessageSettings();
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  delete() {
    this.messageSettings.splice(this.messageSettings.indexOf(this.deleteItem), 1);
    $("#deleteemail").modal('hide');
  }

  validateEmail(email) {
    var reg = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    //var reg = /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    if (!reg.test(email)) {
      return true;
    }
    else {
      return false;
    }
  }

  toggleCheckbox(ele:any){
    if(ele.checked){
      this.emailCheckbox = true;
    }else{
      this.emailCheckbox = false;
    }
  }

}
