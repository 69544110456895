import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MustMatch } from '../../password.match';
import { EmployeeModel } from 'src/app/models/employee.model';

@Injectable({
    providedIn: 'root'
})
export class EmployeeFormBuilder{

    private form;
    private validationRules = {        
        id:['',''],
        firstname: ['',Validators.required],
        lastname: ['',Validators.required],
        user_type: ['',Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-8])(?=.*?[#?)+(!@$%^&*-]).{8,}$')]],
        allow_feature_profile: ['', ''],
        push_notification: ['', ''],
        confirm_password: ['', [Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-8])(?=.*?[#?)+(!@$%^&*-]).{8,}$')]]        
    }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        let pass = group.get('password').value;
        let confirmPass = group.get('confirm_password').value;

        return pass === confirmPass ? null : { notSame: true }     
     }
    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:EmployeeModel){
        this.form.patchValue({
            id:response.id,
            firstname:response.firstname,
            lastname:response.lastname,
            email:response.email,
            allow_feature_profile:response.allow_feature_profile,
            user_type:response.user_type
        });
    }
}