import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AvailabilityModel } from 'src/app/models/availability.model';
import { CustomValidator } from '../../../customvalidation';

@Injectable({
    providedIn: 'root'
})
export class AvailabilityFormBuilder{

    private form;
    private validationRules = {        
        id:['',''],
        booking_date_time: ['',Validators.required],
        fname: ['',Validators.required],
        lname: ['',''],
        email: ['',Validators.required],
        phone: ['',''],
        start_hour: ['',''],
        event_length: ['',''],
        event_type_id: ['',''],
        region_id: ['',''],
        city_id: ['',''],
        is_fullday: ['',''],
        is_block: ['',''],
        note: ['','']      
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:AvailabilityModel){
        let booking_time = response.booking_date_time ? response.booking_date_time.substring(0, 10) : '';
        this.form.patchValue({
            id:response.id,
            fname : response.fname,
            lname : response.lname,
            email : response.email,
            phone : response.phone,
            event_length : response.event_length,
            event_type_id : response.event_type_id,
            start_hour : response.start_hour ? response.start_hour.substring(0, 5) : '',
            region_id : response.region_id,
            city_id : response.city_id,
            is_fullday : response.is_fullday,
            is_block : response.is_block,
            booking_date_time: booking_time,
            note:response.note
        });
    }
}