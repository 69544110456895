import { Component, OnInit } from '@angular/core';
import { IdeaAndTipsService } from '../services/ideas-and-tips.service';
import { Router, ActivatedRoute } from '@angular/router';
import { PlannersService } from '../../planners/services/planners.service';
import { Config } from '../../../config';

@Component({
  selector: 'app-wedding-ideas-category',
  templateUrl: './wedding-ideas-category.component.html',
  styleUrls: ['./wedding-ideas-category.component.scss']
})
export class WeddingIdeasCategoryComponent implements OnInit {
  activeUser: any;
  category_slug: string;
  category_details: any;
  posts: any[] = [];
  current_active_tab: string = "All";
  ideaAndTipsPhotoUrl = Config.imageUrl + 'images/idea_and_tips/';

  constructor(
    private ideaAndTipsService: IdeaAndTipsService,
    public router: Router,
    private plannersService: PlannersService,
    public route: ActivatedRoute,
  ) { 
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        if (params['slug']) {
          this.category_slug = params['slug'];
          this.getCategoryDetails(this.category_slug);
        }
      }
    });
  }
  async getCategoryDetails(slug: string){
    let formData = {
      "slug": slug
    };
    var response = await this.ideaAndTipsService.getIdeaAndTipsCategoryDetails(formData).toPromise();
    this.category_details = response.data;
    this.getIdeaAndTips({
      'cat_id': this.category_details.id
    });
  }

  async getIdeaAndTips(formData: any){
    var response = await this.ideaAndTipsService.getIdeaAndTips(formData).toPromise();
    this.posts = response.data;
  }

  async getSubCategoryPosts(sub_cat: any){
    this.current_active_tab = sub_cat.name;
    let formData = {
      "sub_cat_id": sub_cat.id
    };
    this.getIdeaAndTips(formData);
  }

  getAllPosts(){
    this.current_active_tab = "All";
    this.getIdeaAndTips({
      'cat_id': this.category_details.id
    });
  }

}
