import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class LoginFormBuilder{

    private form;
    private validationRules = {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(6)]], 
    }
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }
    getForm(){
        return this.form;
    }
}