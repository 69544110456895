import { Deseriazable } from './deserializable.model';

export class MessageModel implements Deseriazable{
    
    id: number;
    token: string;
    name: string;
    email: number;
    phone: string;
    preferred_contact: string;
    subject: string;
    message: string;
    status: string;
    leadstatus: string;
    reply: any;
    type: string;
    data: any;
    created_at: string;
    start_date: string;
    end_date: string;
    show_in_inbox: any;
    search:any;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}