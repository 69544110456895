import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { UsersService } from '../../users/services/users.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class DiscussionService extends BaseService { 
  constructor(
    private http: HttpClient, 
    protected usersService: UsersService) {
    super(usersService);
  }

  getDiscussionCategories(){
    return this.http
      .get(Config.apiBaseUrl+"/discussion/categories", this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getDiscussionCategoryDetails(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/category-details", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  searchDiscussion(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/search", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  createDiscussion(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/edit", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteDiscussionImage(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/image/delete", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getDiscussionDetails(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/details", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  postComment(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/comment/edit", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  registerCommunityGuest(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/register-community-guest", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        let user = response.user;
        localStorage.setItem("USER_TOKEN", user.token);
        localStorage.setItem("UID", user.id);
        localStorage.setItem("fname", user.fname);
        localStorage.setItem("role", user.role);
        return response;
      }));
  }

  loginCommunityGuest(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/login-community-guest", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        let user = response.user;
        localStorage.setItem("USER_TOKEN", user.token);
        localStorage.setItem("UID", user.id);
        localStorage.setItem("fname", user.fname);
        localStorage.setItem("role", user.role);
        return response;
      }));
  }

  postReply(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/reply/edit", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getComments(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/comments/get", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteComment(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/comment/delete", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteReply(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/reply/delete", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteDiscussion(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/delete", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getDiscussionList(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/list", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getPublicLatestDiscussion(){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/public_latest_list", {}, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getDiscussionMedias(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/images", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getDiscussionMembers(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/members", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  markMemberParticipateStatusAsRead(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/community/mark_member_participate_status_as_read", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  edit(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/edit", data, httpOptions)
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  updateViews(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/discussion/update_view", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }
}
