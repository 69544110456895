import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { UsersService } from '../../users/services/users.service';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class WeddingWebsiteService extends BaseService{

  constructor(
    private http: HttpClient,
    protected usersService: UsersService) {
    super(usersService);
  }
  private appendUrl(geturl: any, data?: any) {
    if (typeof (data) === 'object') {
      geturl += '?';
      let i = 1;
      for (const field of Object.keys(data)) {
        geturl += field + '=' + data[field];
        if (i < Object.keys(data).length) {
          geturl += '&';
        }
        i++;
      }
    }
    return geturl;
  }
  getRetailerList(){
    var url= '?';
    return this.http
    .get(Config.apiBaseUrl+"/wedding-website/get_wedding_website_retailer_list" + url, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  editUserRetailer(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_edit_user_retailer" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  getUserRetailerList(wedding_id: number){
    var url= '?';
    if(wedding_id){
      url = url + "wedding_id=" + wedding_id;
    }
    return this.http
    .get(Config.apiBaseUrl+"/wedding-website/get_wedding_website_user_retailer_list" + url, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  deleteUserRetailer(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/remove_wedding_website_user_retailer" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  updateUserRetailersOrder(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/update_wedding_website_user_retailer_display_order" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;
    }));
  }
  getTemplateSettings(p1:any){
    var url= '?template_id='+p1;
    return this.http
      .get(Config.apiBaseUrl+"/wedding-website/template_setting"+url, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }
  getTemplateList(p1:any,p2:any,p3:any){
    var url= '?items_in_page='+p1+'&page='+p2;
    for (let index = 0; index < p3.length; index++) {
      url= url+"&tags[]="+p3[index];      
    }
    
    return this.http
    .get(Config.apiBaseUrl+"/wedding-website/get_website_templates" + url, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  getTagList(){
    return this.http
    .get(Config.apiBaseUrl+"/wedding-website/get_website_template_tags", this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  getLayoutList(){
    return this.http
    .get(Config.apiBaseUrl+"/wedding-website/get_wedding_website_base_template_list", this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  getWebsiteDesign(p1:any){
    var url= '?wedding_id='+p1;
    return this.http
    .get(Config.apiBaseUrl+"/wedding-website/get_wedding_website_details" + url, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  getEventListByGuest(wedding_id: number, guest_id: number){
    let formData = {
      wedding_id: wedding_id,
      guest_id: guest_id
    };
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/event_list_by_guest" , formData, this.getHttpHeaders())
    .pipe(map((response:any) => {
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;
    }));
  }
  getEventMenuList(wedding_id: number, event_id: number){
    let formData = {
      wedding_id: wedding_id,
      event_id: event_id
    };
    return this.http
    .post(Config.apiBaseUrl+"/guestlist/menu-list" , formData, this.getHttpHeaders())
    .pipe(map((response:any) => {
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;
    }));
  }
  getWebsiteDesignByUrlFragment(p1:any){
    var url= '?url_part='+p1;
    return this.http
    .get(Config.apiBaseUrl+"/wedding-website/get_wedding_website_details" + url, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  getWebsiteTemplatePages(p1:any){
    var url= '?wedding_id='+p1;
    return this.http
    .get(Config.apiBaseUrl+"/wedding-website/get_website_template_pages" + url, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  getWebsiteTemplateSavedPages(p1:any){
    var url= '?wedding_id='+p1;
    return this.http
    .get(Config.apiBaseUrl+"/wedding-website/get_website_template_saved_pages" + url, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  getWebsiteTemplateSavedPagesByURL(p1:any){
    var url= '?url_part='+p1;
    return this.http
    .get(Config.apiBaseUrl+"/wedding-website/get_website_template_saved_pages" + url, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  addWebsiteUrl(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/update_wedding_website_url" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  savedSelectedTemplate(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/update_wedding_website_theme" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  savedTemplatePages(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/update_wedding_website_pages" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  updateBoxThemeSetting(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/update_box_theme_setting" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  savedStoryContent(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/edit_wedding_website_our_story_story_item" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  savedfaqs(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_edit_faq" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  updateFaq(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_edit_faq" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  deleteFaqs(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_remove_faq" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  updateFaqOrder(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_update_display_order_of_faqs" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  
  uploadPhotos(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_add_photo" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  deletePhotos(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_remove_photo" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  removeCoverPhoto(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/remove_wedding_website_page_photo" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  updateWebsiteInfo(data: any){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_update_info" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  updateEvent(data: any){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_edit_event" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  deleteEvent(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_remove_event" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  updateEventOrder(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_update_display_order_of_event" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  updateTravel(data: any){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_edit_travel" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  deleteTravel(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_remove_travel" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  updateTravelOrder(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_update_display_order_of_travels" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;
    }));
  }

  updateThingsToDoOrder(data: any){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_update_display_order_of_things_to_do" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;
    }));
  }

  addPhotoAlbum(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_edit_photo_album" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  deletePhotoAlbum(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_remove_photo_album" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  updateAlbumOrder(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_update_display_order_of_albums" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;
    }));
  }
  updateAlbumImageOrder(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_update_display_order_of_album_images" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;
    }));
  }
  movePhotoToAlbum(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_move_to_photo_album" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  updateThingsToDo(data: any){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_edit_things_to_do" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  deleteThingsToDo(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_remove_things_to_do" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  searchGuest(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_search_rsvp_user" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  sendRsvp(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_submit_response_rsvp_user" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  deleteMultiplePhotos(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/wedding_website_remove_photo" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
   // timeline section 
   createStoryTimeline(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/create_wedding_website_our_story_timeline" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  savedTimeline(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/add_photo_wedding_website_our_story_timeline" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  removeTimelinePhoto(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/remove_photo_wedding_website_our_story_timeline" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  removeOurStoryItem(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/remove_wedding_website_our_story_content" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  removeOurStoryItemPhoto(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/remove_wedding_website_our_story_story_item_photo" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  updateOurStoryContentDisplayOrder(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/update_wedding_website_our_story_content_display_order" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;
    }));
  }

  updateOurStoryGalleryPhotosDisplayOrder(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/update_wedding_website_our_story_gallery_photos_display_order" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;
    }));
  }

  updateOurStoryTimelinePhotosDisplayOrder(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/update_wedding_website_our_story_timeline_photos_display_order" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;
    }));
  }

  // gallery 
  createStoryGallery(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/create_wedding_website_our_story_gallery" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  savedGallery(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/add_photo_wedding_website_our_story_gallery" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  removeGalleryPhotos(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/remove_photo_wedding_website_our_story_gallery" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  // add title 
  createTitle(data){
    return this.http
    .post(Config.apiBaseUrl+"/wedding-website/edit_wedding_website_our_story_title_block" , data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

    // add text block 
    createTextBlock(data){
      return this.http
      .post(Config.apiBaseUrl+"/wedding-website/edit_wedding_website_our_story_text_block" , data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
    }

    createGif(data){
      return this.http
      .post(Config.apiBaseUrl+"/wedding-website/edit_wedding_website_our_story_photo_block" , data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
    }
}
