import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { UsersService } from '../../users/services/users.service';
import { LoginModel } from 'src/app/models/login.model';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { ForgotPasswordModel } from 'src/app/models/forgotpassword.model';
import { RegisterModel } from 'src/app/models/register.model';
import { PlannerProfileModel } from 'src/app/models/planner.profile.model';
import { BehaviorSubject } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class GuestlistService extends BaseService {
  // guestMenu: any;
  guestMenu = new BehaviorSubject('Snapshot');
  currentGuestMenu = this.guestMenu.asObservable();
  
  guestSettingModel = new BehaviorSubject([]);
  currentGuestMenuSettingModel = this.guestSettingModel.asObservable();

  constructor(
    private http: HttpClient,
    protected usersService: UsersService) {
    super(usersService);

  }

  createEvent(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/event", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  addMenu(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/menu", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  addList(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/create-event-menu-list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getEventList(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/event-list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  addRelationship(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/relationship", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getEventDetails(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/guestlist/event-detail/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getMenusByEventId(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/menu-list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }
  getListsByEventId(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/event-menu-list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  updateEventByEventId(data: any) {
    return this.http
      .put(Config.apiBaseUrl + "/guestlist/event-update", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteEvent(id: any) {
    return this.http
      .delete(Config.apiBaseUrl + "/guestlist/event-delete/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getRelationshipList(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/relationship-list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  addGuest(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guest", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getGuestList(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guestListByRelationShip", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getEventGuestList(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guest/guestsNotInEvent", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }
  
  addGuestsToEvent(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guest/addGuestsToEvent", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  guestDetails(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/guestlist/guestDetail/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  guestDelete(id: any) {
    return this.http
      .delete(Config.apiBaseUrl + "/guestlist/guest/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  removeGuestsFromEvent(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guest/removeGuestsFromEvent",data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  updateGuest(data: any) {
    return this.http
      .put(Config.apiBaseUrl + "/guestlist/guestUpdate", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getRelationshipDetails(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/guestlist/relationship-detail/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  updateRelationship(data: any) {
    return this.http
      .put(Config.apiBaseUrl + "/guestlist/relationship-update", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  menuDelete(id: any) {
    return this.http
      .delete(Config.apiBaseUrl + "/guestlist/menu-delete/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  listDelete(id: any) {
    return this.http
      .delete(Config.apiBaseUrl + "/guestlist/event-menu-list-delete/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  eventGuestList(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guestByEvent", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  eventTableList(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/seatingChart/table-list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getAssignTableList(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/seatingChart/table-seat-assign", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  updateEventData(data: any) {
    return this.http
      .put(Config.apiBaseUrl + "/guestlist/guestEventUpdate", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getEventRelationData(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/event-by-guest", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  relationshipDelete(id: any) {
    return this.http
      .delete(Config.apiBaseUrl + "/guestlist/relationship-delete/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  updateMenu(data: any) {
    return this.http
      .put(Config.apiBaseUrl + "/guestlist/menu-update", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  updateList(data: any) {
    return this.http
      .put(Config.apiBaseUrl + "/guestlist/event-menu-list-update", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getAdditionalGuest(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/guestlist/additionalGuests/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  
  unlinkGuest(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/guestlist/unlinkGuest/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  saveInvitation(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guest-invitation", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getInvitationDetails(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guest-invitation-detail", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getInvitationList(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guest-invitation-list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  updateInvitation(data: any) {
    return this.http
      .put(Config.apiBaseUrl + "/guestlist/guest-invitation-update", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getGuestCount(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/event-header/list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getEventGuestCount(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/each-event-header", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

 imageUpload(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guest-invitation/photo", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  guestDownload(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/download_guest_list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  invitationDelete(id: any) {
    return this.http
      .delete(Config.apiBaseUrl + "/guestlist/guest-invitation/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  sendInvitation(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guest-invitation/invitation-email", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  guestImport(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/guestlist/guest-import", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

}
