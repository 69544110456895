import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../password.match';

@Injectable({
    providedIn: 'root'
})
export class RegisterFormBuilder{
    pwdPattern = "^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,12}$";
    private form;
    private validationRules = {
        business_name: ['',Validators.required],
        business_type: ['',Validators.required],
        category_id: ['',Validators.required],
        firstname: ['',Validators.required],
        lastname: ['',Validators.required],
        address: ['',Validators.required],
        city_id: ['',Validators.required],
        zip_code: ['',Validators.required],
        phone: ['',Validators.required],
        mobile: ['',''],
        website:['',''],
        role_id: ['',''],
        region_id: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        //password: ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-8])(?=.*["$@$!%*?&#^)(+"])[A-Za-z\d$@$!%*?&#)(+^].{8,}')]], 
        password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-8])(?=.*?[#?)+(!@$%^&*-]).{8,}$')]],       
        confirm_password: ['', [Validators.required]],
        terms: [false, Validators.requiredTrue]   
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    
}