import { Component, ElementRef, Input, OnInit, SimpleChanges} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { WeddingWebsiteService } from '../services/wedding-website.service';
import { ActivatedRoute } from '@angular/router';
import { GuestlistService } from '../services/guestlist.service';
import { PlannersService } from '../services/planners.service';
import { ChecklistService } from '../services/checklist.service';
import { ToastrService } from 'ngx-toastr';
import { Config } from '../../../config';
import { promise } from 'protractor';
declare var $ : any;
@Component({
  selector: 'app-view-template',
  templateUrl: './view-template.component.html',
  styleUrls: ['./view-template.component.scss']
})
export class ViewTemplateComponent implements OnInit {
  @Input() template_id: number;
  @Input() wedding_id: number;
  @Input() tabs: any[];
  @Input() cover_photo: any;
  @Input() our_story: any;
  @Input() info: any;
  @Input() input_faqs: any;
  @Input() refresh: number;

  currentTemplateSetting: any;
  template_name: any;
  url_part: string;
  weddingData: any;
  default_template_id: number = 1;
  allEventData: any;
  allEventData_backup: any;
  filterEvent: any;
  eventId: any;
  activeUser: any;
  statusText: string = 'To Do';
  ByWhenText: string = '';
  ByCategoryText: string = '';
  ByMonthText: string = '';
  listHeading: any = 'To Do';
  status: any = 0;
  isTrash: any = 0;
  dueNow: any = null;
  toDoStatus: boolean = true;
  afterWedding: null;
  selectedMonth: null;
  selectMonth: any[];
  categoryId: any = null;
  selectCategory: any[];
  pages: any;
  templateSavedPages: any[] = [];
  wedding_image: string = Config.imageUrl + 'images/wedding_website_photos/default_cover_image.jpg';
  travelData: any;
  thingsToDoList: any;
  album: any;
  filterGalleryPhotos: any;
  photoData: any;
  albumPhotos: any[];
  website_info: any;

  showEventInHome: boolean = false;
  showThingsToDoInHome: boolean = false;
  storyContent: any;
  faqs: any;
  galleryPhotos: any;
  pics: any;
  galleryImage: any;
  slideIndex = 1;
  imageindex: any;
  galleryImageData: any;
  
  nameList :Boolean = false;
  selectstatus: Boolean = false;
  searchGuestdiv :boolean = true;
  confirmRsvp: boolean;
  firstName:string = "";
  lastName:string = "";
  searchData: any;
  selectedOptionId: any;
  typeOfAttendance = [
    {
      id: 'Attending',
      value: 'I happily accept'
    },
    {
      id: 'Cancelled',
      value: "Sadly I won't be able to attend"
    },
    {
      id: 'Pending',
      value: "I'll let you know soon"
    }
  ];
  selectedType: any;
  selectFirstName: any;
  selectLastName: any;
  messageText: any;
  event_sort_value: string = "Sort Events";
  eventSortDropdownIsOpen: boolean = false;
  ourStoryData: any;
  storyImageData: any;
  storyImage: any;
  storyImageindex: any;
  imageData: any;
  storyPhoto: any;
  eventList: any[];
  eventMenuList: any[];
  eventDropDownIsOpened: boolean = false;
  menuListDropDownIsOpened: boolean = false;
  selectedRSVPEvent: any;
  selectedRSVPEventMenuItem: any;
  constructor(private sanitizer: DomSanitizer, public route: ActivatedRoute, private guestlistService: GuestlistService, private checklistService: ChecklistService,
    private plannersService: PlannersService, private toastr: ToastrService, private WeddingWebsiteService: WeddingWebsiteService, private elRef: ElementRef
   ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
  }

  menu_item_name: string = "home";
  active_base_template: number = 1;
  weddingImageUrl = Config.imageUrl + 'uploads/users/profile/';
  weddingWebsiteThemeImagePath = "/assets/images/wedding_website/";
  galleryPhotosUrl = Config.imageUrl + 'images/wedding_website_photos/';
  retailerLogoUrl = Config.imageUrl + 'images/retailer_logos/';
  base_url: string = Config.baseUrl;


  retailerList: any[] = [];
  backgroundObjStyle: any = {};

  async ngOnInit() {
    this.url_part = this.route.snapshot.paramMap.get('pageName');
    this.route.queryParams.subscribe(res=>{
      let tabname = res.tab;
      if(tabname){
        this.menu_item_name = tabname;
      }
    });

    if (this.wedding_id) {
      // Get Website Details --
      this.getWebsiteTemplateSavedPages(this.activeUser.id);
      this.getWebsiteDesign(this.wedding_id);
    } else if (this.url_part) {
      // Get Website Details --
      this.getWebsiteTemplateSavedPagesByURL(this.url_part);
      this.getWebsiteDesignByUrlFragment(this.url_part);
    }
    this.showSlides(this.slideIndex);
    document.addEventListener("click", (event: Event)=>{
      let tar = event.target as HTMLElement;
      if(!tar.closest(".dropdown_custom")){
        if(this.eventList && this.eventList.length > 0){
          this.eventList = this.eventList.map((event_item: any)=>{
            return {...event_item, ...{menu_list_dropdown_is_opened: false}};
          });
        }
      }
      if(!tar.closest(".x_dropdown")){
        this.eventSortDropdownIsOpen = false;
      }
    });
    window.addEventListener("scroll",()=>this.getThemeDetails(this.currentTemplateSetting));
  }
  ngOnChanges(changes: SimpleChanges) {
    // Template ID --
    if (changes.template_id && changes.template_id.currentValue) {
      let newTemplateId = changes.template_id.currentValue;
      this.apply_template(newTemplateId);
    }

    // Tabs --
    if (changes.tabs && changes.tabs.currentValue && changes.tabs.currentValue.length > 0) {
      this.templateSavedPages = changes.tabs.currentValue;
      if(this.url_part){
        this.getWebsiteTemplateSavedPagesByURL(this.url_part);
      }else if(this.wedding_id){
        this.getWebsiteTemplateSavedPages(this.activeUser.id);
      }      
    }

    // Cover Photo --
    if (changes.cover_photo && changes.cover_photo.currentValue) {
      this.wedding_image = changes.cover_photo.currentValue;
    }

    // Our Story --
    if (changes.our_story && changes.our_story.currentValue) {
      this.storyContent = this.getRichTextContent(changes.our_story.currentValue.content);
    }

    // Website Info Changes --
    if (changes.info && changes.info.currentValue) {
      this.website_info = changes.info.currentValue;
    }

    // Website Info Changes --
    if (changes.input_faqs && changes.input_faqs.currentValue) {
      this.faqs = changes.input_faqs.currentValue;
    }

    // Website Info Changes --
    if (changes.refresh && changes.refresh.currentValue) {
      if(changes.refresh.currentValue !== 1){
        this.getWebsiteDesign(this.wedding_id);
      }
    }
  }
  getRichTextContent(content: any) {
    let el = document.createElement("div");
    el.innerHTML = content;
    let tempcontent = el.outerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(tempcontent);
  }
  getMenuItemContent(content: any) {
    let el = document.createElement("div");
    el.innerHTML = content;
    let tempcontent = el.outerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(tempcontent);
  }
  getEventListByGuest(wedding_id: number, guest_id: number){
    this.WeddingWebsiteService.getEventListByGuest(wedding_id, guest_id).subscribe(res => {
      // this.eventList = res.data;
      let eventList = res.data;
      let promiseArr = [];
      eventList.forEach((item: any, index: number)=>{
        promiseArr.push(this.getMenuListByEvent(wedding_id, item.id));
      });
      Promise.all(promiseArr).then((values)=>{
        eventList.forEach((event: any, index: number) => {
          event.menu_items = values[index];
          event.selected_rsvp_response = event.previous_rsvp_response;
          event.selected_menu_id = event.previous_menu_id;
          if(event.previous_menu_id){
            let selected_menu_items = event.menu_items.filter((menu_item: any)=>{
              return menu_item.id === event.previous_menu_id;
            });
            event.selected_rsvp_menu_item = selected_menu_items[0];
          }
          event.menu_list_dropdown_is_opened = false;
          this.messageText = event.previous_rsvp_note;
        });
        this.eventList = eventList;
      });
    }, error => {
      this.toastr.error(error.message);
      console.log(error);
    });
  }
  getMenuListByEvent(wedding_id: number, event_id: number){
    return new Promise((resolve, reject)=>{
      this.WeddingWebsiteService.getEventMenuList(wedding_id, event_id).subscribe(res => {
        resolve(res.data);
      }, error => {
        this.toastr.error(error.message);
        console.log(error);
        reject(error);
      });
    });
  }
  getWebsiteDesign(wedding_id: number) {
    this.WeddingWebsiteService.getWebsiteDesign(wedding_id).subscribe(res => {
      this.currentTemplateSetting = this.getThemeDetails(res.theme_details);
      this.weddingData = res.wedding_details;
      this.allEventData = res.events;
      this.galleryPhotos = res.photos;
      this.travelData = res.travels;
      this.ourStoryData = res.our_story_details;
      this.album = res.albums;
      this.retailerList = [...res.retailers];
      for (let index = 0; index < this.album.length; index++) {
        this.albumPhotos = [];
        for (let i = 0; i < this.galleryPhotos.length; i++) {
          if (this.galleryPhotos[i].album_id == this.album[index].id) {
            this.albumPhotos.push(this.galleryPhotosUrl + this.galleryPhotos[i].image_name)
          }
        }
        this.album[index].photos = this.albumPhotos;
      }

      this.filterGalleryPhotos = this.galleryPhotos.filter((res) => res.album_id == this.album.id);
      this.thingsToDoList = res.things_to_do;
      if (res.cover_photo && res.cover_photo.image_name) {
        this.updateCoverImageForIndividualPage(this.galleryPhotosUrl + res.cover_photo.image_name);
      }else{
        this.updateCoverImageForIndividualPage();
      }
      this.website_info = res.website_info;
      // Our Story --
      this.faqs = res.faqs;
      if (this.template_id) {
        this.apply_template(this.template_id);
      } else {
        if (this.currentTemplateSetting) {
          this.active_base_template = this.currentTemplateSetting.suitable_for_base_template_id;
          if (this.currentTemplateSetting.css) {
            this.apply_css(this.currentTemplateSetting.css);
          }
        } else {
          this.apply_template(this.default_template_id);
        }
      }
    }, error => {
      console.log(error);
    });
  }
  openGallery(img, index){    
    this.galleryImageData = img; 
    this.galleryImage = img[index]
    this.imageindex = index;
  }
  plusSlides(n) {
    if(this.imageindex < this.galleryImageData.length - 1){
      this.imageindex = this.imageindex + 1
      this.galleryImage = this.galleryImageData[this.imageindex]
    }
  }
  minussSlides(n) {
    if(this.imageindex > 0){
      this.imageindex = this.imageindex - 1
      this.galleryImage = this.galleryImageData[this.imageindex]
    }
  }
  showSlides(n) {
 
  }
  // our story lightbox 
  imageArrayData=[]
  openStoryGallery(img, index){
    this.imageArrayData=[];
    this.storyImageData = img ;   
    this.storyImageindex = index;    
    for (let index = 0; index < this.storyImageData.length; index++) {
      this.imageData =  this.storyImageData[index].image_file_name;
      this.imageArrayData.push(this.imageData)
    }
    this.storyImage = this.imageArrayData[index];
  }
  storyPlusSlides(n) {
    if(this.storyImageindex < this.imageArrayData.length - 1){
      this.storyImageindex = this.storyImageindex + 1
      this.storyImage = this.imageArrayData[this.storyImageindex]
    }
  }
  storyMinussSlides(n) { 
    if(this.storyImageindex > 0){
      this.storyImageindex = this.storyImageindex - 1
      this.storyImage = this.imageArrayData[this.storyImageindex]
    }
  }
  openStoryphotos(img){
    $('#myPhotoModal').modal('show');
    this.storyPhoto = img;    
  }
  getWebsiteDesignByUrlFragment(url_part: string) {
    this.WeddingWebsiteService.getWebsiteDesignByUrlFragment(url_part).subscribe(res => {
      this.currentTemplateSetting = this.getThemeDetails(res.theme_details);
      this.weddingData = res.wedding_details;
      this.allEventData = res.events;
      this.website_info = res.website_info;
      this.retailerList = [...res.retailers];
      // Photos --
      this.galleryPhotos = res.photos;
      this.album = res.albums;
      for (let index = 0; index < this.album.length; index++) {
        this.albumPhotos = [];
        for (let i = 0; i < this.galleryPhotos.length; i++) {
          if (this.galleryPhotos[i].album_id == this.album[index].id) {
            this.albumPhotos.push(this.galleryPhotosUrl + this.galleryPhotos[i].image_name)
          }
        }
        this.album[index].photos = this.albumPhotos;
      }
      this.filterGalleryPhotos = this.galleryPhotos.filter((res) => res.album_id == this.album.id);
      // Our Story --
      this.ourStoryData = res.our_story_details;
      // Q + A --
      this.faqs = res.faqs;
      // Travels --
      this.travelData = res.travels;
      // Things to Do --
      this.thingsToDoList = res.things_to_do;
      if (res.cover_photo && res.cover_photo.image_name) {
        this.updateCoverImageForIndividualPage(this.galleryPhotosUrl + res.cover_photo.image_name);
      }else{
        this.updateCoverImageForIndividualPage();
      }
      if (this.template_id) {
        this.apply_template(this.template_id);
      } else {
        if (this.currentTemplateSetting) {
          this.active_base_template = this.currentTemplateSetting.suitable_for_base_template_id;
          if (this.currentTemplateSetting.css) {
            this.apply_css(this.currentTemplateSetting.css);
          }
        } else {
          this.apply_template(this.default_template_id);
        }
      }
    }, error => {
      console.log(error);
    })
  }
  getThemeDetails(theme: any) {
    let background: string[] = [];
    var offset = this.elRef.nativeElement.parentNode.getBoundingClientRect();
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    let top_image = "";
    let bottom_image = "";
    let background_image = "";

    if (width <= 480) {
      background_image = theme.background_image_at_480 ? theme.background_image_at_480 : (theme.background_image_at_768 ? theme.background_image_at_768 : (theme.background_image_at_1024 ? theme.background_image_at_1024 : theme.background_image));
      bottom_image = theme.bottom_image_at_480 ? theme.bottom_image_at_480 : (theme.bottom_image_at_768 ? theme.bottom_image_at_768 : (theme.bottom_image_at_1024 ? theme.bottom_image_at_1024 : theme.bottom_image));
      top_image = theme.top_image_at_480 ? theme.top_image_at_480 : (theme.top_image_at_768 ? theme.top_image_at_768 : (theme.top_image_at_1024 ? theme.top_image_at_1024 : theme.top_image));
    } else if (width <= 768) {
      background_image = theme.background_image_at_768 ? theme.background_image_at_768 : (theme.background_image_at_1024 ? theme.background_image_at_1024 : theme.background_image),
      bottom_image = theme.bottom_image_at_768 ? theme.bottom_image_at_768 : (theme.bottom_image_at_1024 ? theme.bottom_image_at_1024 : theme.bottom_image),
      top_image = theme.top_image_at_768 ? theme.top_image_at_768 : (theme.top_image_at_1024 ? theme.top_image_at_1024 : theme.top_image)
    } else if (width <= 1024) {
      background_image = theme.background_image_at_1024 ? theme.background_image_at_1024 : theme.background_image;
      bottom_image = theme.bottom_image_at_1024 ? theme.bottom_image_at_1024 : theme.bottom_image;
      top_image = theme.top_image_at_1024 ? theme.top_image_at_1024 : theme.top_image;
    }else{
      background_image = theme.background_image;
      bottom_image = theme.bottom_image;
      top_image = theme.top_image;
    }

    if(top_image){
      if(this.url_part){
        background.push(`url(${this.weddingWebsiteThemeImagePath + top_image}) 0px 0px no-repeat fixed`);
      }else{
        background.push(`url(${this.weddingWebsiteThemeImagePath + top_image}) ${offset.left}px ${offset.top}px no-repeat fixed`);
      }
    }
    if(bottom_image){
      if(this.url_part){
        background.push(`url(${this.weddingWebsiteThemeImagePath + bottom_image}) bottom right/100% auto no-repeat fixed`);
      }else{
        background.push(`url(${this.weddingWebsiteThemeImagePath + bottom_image}) bottom right/100% auto no-repeat fixed`);
      }
    }
    if(background_image){
      if(this.url_part){
        // PreviewPage -
        background.push(`url(${this.weddingWebsiteThemeImagePath + background_image}) 0px 0px / cover no-repeat fixed`);
      }else{
        // RightSidebar --
        background.push(`url(${this.weddingWebsiteThemeImagePath + background_image}) ${offset.left}px ${offset.top}px / cover no-repeat fixed`);
      }
    }
    this.backgroundObjStyle["background"] = background.join(", ");
    if(theme.background_color){
      this.backgroundObjStyle["background-color"] = theme.background_color;
    }
    return theme;
  }
  updateCoverImageForIndividualPage(fallbackImageURL: string = null){
    let match = false;
    for(let i=0; i<this.templateSavedPages.length; i++){
      let savedPage = this.templateSavedPages[i];
      if(savedPage.slug === this.menu_item_name){
        if(savedPage.cover_image){
          match = true;
          this.wedding_image = this.galleryPhotosUrl + savedPage.cover_image;
        }
        break;
      }
    }
    if(!match){
      if(fallbackImageURL){
        this.wedding_image = fallbackImageURL;
      }else{
        this.wedding_image = Config.imageUrl + 'images/wedding_website_photos/default_cover_image.jpg';
      }
    }
  }
  menu_item_click_handler(name: string) {
    this.menu_item_name = name;
    if(this.url_part){
      this.getWebsiteDesignByUrlFragment(this.url_part);
    }else{
      this.getWebsiteDesign(this.wedding_id);
    }
  }
  async apply_template(template_id: number) {
    var templateSetting: any = await this.WeddingWebsiteService.getTemplateSettings(template_id).toPromise();
    this.currentTemplateSetting = this.getThemeDetails(templateSetting);
    this.active_base_template = templateSetting.suitable_for_base_template_id;
    // Apply CSS --
    if (templateSetting.css) {
      this.apply_css(templateSetting.css);
    }
  }
  apply_css(css: string) {
    let cssEl = document.getElementById("css_patch");
    if (cssEl) {
      cssEl.innerHTML = "";
      let styleEl = document.createElement("style");
      styleEl.textContent = css;
      cssEl.appendChild(styleEl);
    }
  }
  async clickStatus(status: null) {
    if (status == 1) {
      this.statusText = 'Completed';
      this.toDoStatus = false;
    } else {
      this.statusText = 'To Do';
      this.toDoStatus = true;
    }
    this.status = status;
    this.isTrash = 0;
  }
  async clickDueNow(d: any) {
    this.ByWhenText = 'Due Now';
    this.dueNow = d;
    this.afterWedding = null;
    this.selectedMonth = null;
    this.selectMonth = [];
    this.ByMonthText = '';
  }
  async clickCategory(e: any, j: number) {
    this.categoryId = e.category_id;
    this.selectCategory = [];
    this.selectCategory[this.categoryId] = !this.selectCategory[this.categoryId];
    if (e.name) {
      this.ByCategoryText = '(' + e.name + ')';
    } else {
      this.ByCategoryText = '(' + 'Other' + ')';
    }
  }
  getWebsiteTemplateSavedPages(wedding_id) {
    this.WeddingWebsiteService.getWebsiteTemplateSavedPages(wedding_id).subscribe(res => {
      this.templateSavedPages = res.pages;      
      this.templateSavedPages.sort((a, b) => {
        return a.display_order - b.display_order;
      });
      // Check if event is on --
      this.showEventInHome = false;
      for (let i = 0; i < this.templateSavedPages.length; i++) {
        if (this.templateSavedPages[i].slug == "events") {
          this.showEventInHome = true;
          break;
        }
      }
      // Check if things to do is on --
      this.showThingsToDoInHome = false;
      for (let i = 0; i < this.templateSavedPages.length; i++) {
        if (this.templateSavedPages[i].slug == "things_to_do") {
          this.showThingsToDoInHome = true;
          break;
        }
      }
      this.updateCoverImageForIndividualPage();
    }, error => {
      console.log(error);
    });
  }
  getWebsiteTemplateSavedPagesByURL(url_part: string) {
    this.WeddingWebsiteService.getWebsiteTemplateSavedPagesByURL(url_part).subscribe(res => {
      this.templateSavedPages = res.pages;
      this.templateSavedPages.sort((a, b) => {
        return a.display_order - b.display_order;
      });
      // Check if event is on --
      this.showEventInHome = false;
      for (let i = 0; i < this.templateSavedPages.length; i++) {
        if (this.templateSavedPages[i].slug == "events") {
          this.showEventInHome = true;
          break;
        }
      }
      // Check if things to do is on --
      this.showThingsToDoInHome = false;
      for (let i = 0; i < this.templateSavedPages.length; i++) {
        if (this.templateSavedPages[i].slug == "things_to_do") {
          this.showThingsToDoInHome = true;
          break;
        }
      }
      this.updateCoverImageForIndividualPage();
    }, error => {
      console.log(error);
    });
  }
  getWeddingDateString(date: string) {
    let d = new Date(date.substring(0, 10));
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let month = d.getMonth();
    let monthName = months[month];

    let day = d.getDate();
    let year = d.getFullYear();

    return monthName + " " + day + "," + year;
  }
  searchGuest(){ 
    let firstnameLength = this.firstName.length;
    let lastnameLength = this.lastName.length;
    if(firstnameLength < 2 || lastnameLength < 2 ) { 
      this.toastr.error('Please enter at least 2 letters for first and last name');
      return; 
    }  
    if (this.firstName == null || this.firstName == '') {
      this.toastr.error('Please enter first name');
      this.searchGuestdiv= true;
      this.selectstatus = false;
      return;
    }
   
    let data ={
      wedding_id:this.activeUser.id,
      first_name:this.firstName,
      last_name:this.lastName
    }
    this.WeddingWebsiteService.searchGuest(data).subscribe(res => {
      this.searchData = res.result;      
      this.nameList = true;
      this.selectstatus = false;
      this.searchGuestdiv = false;      
    }, error => {
      console.log(error);
    })
  }
  searchList(){
    // Check if the guest is selected --
    if(!this.selectedOptionId){
      this.toastr.error('Please select guest to continue');
      return;
    }
    this.nameList = false;
    this.selectstatus = true;
    this.searchGuestdiv = false;    
    if(this.searchData == null || this.searchData == ''){
      this.toastr.error('Please select guest name');
      return;
    }
  }
  handleRSVPEventSelected(event: any){
    this.selectedRSVPEvent = event;
    this.eventDropDownIsOpened = !this.eventDropDownIsOpened;
    // Get menu list of that event --
    this.getEventMenuList(event.wedding_id, event.id);
  }
  getEventMenuList(wedding_id: number, event_id: number){
    this.WeddingWebsiteService.getEventMenuList(wedding_id, event_id).subscribe(res => {
      this.eventMenuList = res.data;

      // Populate RSVP form --
      this.messageText = this.selectedRSVPEvent.previous_rsvp_note;
      this.selectedType = this.selectedRSVPEvent.previous_rsvp_response;
      this.eventMenuList.forEach((item: any)=>{
        if(this.selectedRSVPEvent.previous_menu_id === item.id){
          this.selectedRSVPEventMenuItem = item;
        }
      });
    }, error => {
      console.log(error);
    });
  }
  handleRSVPEventMenuSelected(menuItem: any, event: any){
    this.eventList = this.eventList.map((event_item: any)=>{
      if(event_item.id === event.id){
        return {
          ...event_item,
          ...{selected_rsvp_menu_item: menuItem, menu_list_dropdown_is_opened: !event.menu_list_dropdown_is_opened}
        };
      }else{
        return {...event_item};
      }
    });
  }
  searchNew(){
    this.searchGuestdiv = true;
    this.nameList = false;
    this.selectstatus = false;
    this.confirmRsvp = false;
    this.firstName="";
    this.lastName="";
  }
  sendRsvp(){
    // Validation --
    let error = false;
    for(let i=0; i<this.eventList.length; i++){
      if(!this.eventList[i].selected_rsvp_response){
        this.toastr.error(`Please select your response for the event: ${this.eventList[i].event_name}`);
        error = true;
        break;
      }
      if(this.eventList[i].selected_rsvp_response !== 'Cancelled' && this.eventList[i].menu_items && this.eventList[i].menu_items.length > 0 && !this.eventList[i].selected_rsvp_menu_item){
        this.toastr.error(`Please select your menu item for the event: ${this.eventList[i].event_name}`);
        error = true;
        break;
      }
    }
    if(error) return;
    let rsvp_response = this.eventList.map((event_item: any)=>{
      let menu_id = null;
      if(event_item.selected_rsvp_menu_item){
        menu_id = event_item.selected_rsvp_menu_item.id;
      }
      let response: any = {
        event_id: event_item.id,
        rsvp_response_status: event_item.selected_rsvp_response
      };
      if(menu_id){
        response.menu_id = menu_id;
      }
      return response;
    });
    let data: any ={
      wedding_id: this.activeUser.id,
      guest_id: parseInt(this.selectedOptionId),
      note: this.messageText,
      rsvp_response: rsvp_response
    }
    this.WeddingWebsiteService.sendRsvp(data).subscribe(res => {
      this.toastr.success(res.message); 
      this.confirmRsvp = true;
      this.selectstatus = false;
      this.getWebsiteDesign(this.activeUser.id);
    }, error => {
      console.log(error);
    })
  }
  radioChangeHandler(event:any, data: any){
    this.selectedOptionId = event.target.value;
    this.selectFirstName = data.first_name;
    this.selectLastName = data.last_name;

    // Get all events which are assigned to that guest --
    this.getEventListByGuest(this.weddingData.id, this.selectedOptionId);
  }
  selectItem(response: any, event:any){
    this.eventList = this.eventList.map((event_item: any)=>{
      if(event_item.id === event.id){
        return {
          ...event_item,
          ...{selected_rsvp_response: response.id}
        };
      }else{
        return {...event_item};
      }
    });
  }
  toggleEventSortDropDown(){
    this.eventSortDropdownIsOpen = !this.eventSortDropdownIsOpen;
  }
  convertFormatedTimeTo24HourTime(time_string: any){
    let time_format = time_string.substr(6, 2);
    let event_time = time_string.substr(0, 5);
    let hour = event_time.split(":")[0];
    let minute = event_time.split(":")[1];
    if(time_format === "pm"){
      hour = Number(hour) + 12;
    }
    let time = hour + ":" + minute + ":00";
    return time;
  }
  sortEventListByLatest(){
    this.event_sort_value = "Upcoming";
    this.allEventData_backup = [...this.allEventData];
    let allData = this.allEventData.map((item: any)=>{
      let time = this.convertFormatedTimeTo24HourTime(item.event_time);
      return {...item, event_date_time: item.event_date + " " + time};
    });
    let sortedData = allData.sort(function(a: any, b: any) { return (new Date(a.event_date_time)).getTime() - (new Date(b.event_date_time)).getTime() });

    for(let i=0; i<sortedData.length; i++){
      let date = (new Date(sortedData[i].event_date_time)).getTime();
      let now = (new Date()).getTime();
      if(date < now){
        let el = sortedData.splice(i, 1);
        sortedData.push(el[0]);
      }
    }

    this.allEventData = sortedData;

    this.eventSortDropdownIsOpen = !this.eventSortDropdownIsOpen;
  }
  sortEventListByNone(){
    this.event_sort_value = "None";
    this.allEventData = [...this.allEventData_backup];
    this.eventSortDropdownIsOpen = !this.eventSortDropdownIsOpen;
  }
  handleCopyURLButtonClick(url: string){
    // Get the text field
    var copyText = document.getElementById("copyToClipboard") as HTMLInputElement;
    copyText.value = url;

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    window.navigator['clipboard'].writeText(copyText.value);

    this.toastr.success("Copied to Clipboard!");
  }
  getElementCustomStyle(style: string, key_name: string){
    if(style){
      let customeStyle = JSON.parse(style);
      let defaultStyle = this.getDefaultFontStyle(key_name);
      if(this.currentTemplateSetting){
        defaultStyle = this.getDefaultFontStyle(key_name, this.currentTemplateSetting.default_color_scheme);
      }
      if(customeStyle && customeStyle[key_name]){
        let style = customeStyle[key_name];
        let finalStyleObject = {};
        Object.keys(style).forEach((item)=>{
          if(style[item] == null || style[item] == "null" || style[item] === '')  {
          }else{
            if(item === 'font-size'){
              let font_size = parseInt(style[item]);
              var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
              if(font_size > 30 && font_size <= 40){
                if (width <= 480) {
                  font_size = font_size - 10;
                } else if (width <= 768) {
                  font_size = font_size - 7;
                } else if (width <= 1024) {
                  font_size = font_size - 4;
                }
              }else if(font_size > 20 && font_size <= 30){
                if (width <= 480) {
                  font_size = font_size - 10;
                } else if (width <= 768) {
                  font_size = font_size - 7;
                } else if (width <= 1024) {
                  font_size = font_size - 4;
                }
              }
              finalStyleObject['font-size'] = font_size + "px";
            }else{
              finalStyleObject[item] = style[item];
            }
          }
        });
        return {...defaultStyle, ...finalStyleObject};
      }else{
        if(this.currentTemplateSetting){
          return this.getDefaultFontStyle(key_name, this.currentTemplateSetting.default_color_scheme);
        }else{
          return this.getDefaultFontStyle(key_name);
        }
      }
    }else{
      if(this.currentTemplateSetting){
        return this.getDefaultFontStyle(key_name, this.currentTemplateSetting.default_color_scheme);
      }else{
        return this.getDefaultFontStyle(key_name);
      }
    }
  }
  getElementCustomStyleForIcon(style: string, key_name: string){
    let iconStyle = {};
    let customeStyle = {};
    if(style && style !== "null"){
      let parsedStyle = JSON.parse(style);
      if(parsedStyle[key_name]){
        customeStyle = parsedStyle[key_name];
      }else{
        customeStyle = {};
      }
    }
    let defaultStyle: any = {};
    if(this.currentTemplateSetting){
      defaultStyle = this.getDefaultFontStyle(key_name, this.currentTemplateSetting.default_color_scheme);
    }else{
      defaultStyle = this.getDefaultFontStyle(key_name);
    }

    if(customeStyle["color"] && customeStyle["color"] !== "null"){
      iconStyle["color"] = customeStyle["color"];
    }else{
      iconStyle["color"] = defaultStyle["color"];
    }

    if(customeStyle["font-size"] && customeStyle["font-size"] !== "null"){
      let font_size = parseInt(customeStyle["font-size"] || 0);
      if(font_size > 18){
        iconStyle["line-height"] = font_size + "px";
      }
    }else{
      let font_size = parseInt(defaultStyle["font-size"] || 0);
      if(font_size > 18){
        iconStyle["line-height"] = font_size + "px";
      }
    }
    return iconStyle;
  }
  getDefaultFontStyle(key_name: string, color_scheme: string = "black"){ 
    let finalStyleObject = {
      "font-family": "Times New Roman"
    };
    if(color_scheme === 'white'){
      finalStyleObject["color"] = "#FFFFFF";
    }else{
      finalStyleObject["color"] = "#000000";
    }
    if(key_name === 'page_title' || key_name === 'timeline_date'){
      finalStyleObject['font-size'] = "16px";
      finalStyleObject['font-weight'] = "600";
    }else if(key_name === 'website_info_groomName' || key_name === 'website_info_brideName'){
      finalStyleObject['font-size'] = "40px";
      finalStyleObject['font-weight'] = "600";
    }else if(key_name === 'website_info_weddingDate' || key_name === 'website_info_city' || key_name === 'website_info_state' || key_name === 'faqs_question' || key_name === 'event_title' || key_name === 'travel_name_of_accommodation' || key_name === 'things_to_do' || key_name === 'story_item_title' || key_name === 'story_title_block' || key_name === "photos_albumName"){
      finalStyleObject['font-size'] = "25px";
      finalStyleObject['font-weight'] = "600";
    }else{
      finalStyleObject['font-size'] = "20px";
      finalStyleObject['font-weight'] = "400";
    }
    return finalStyleObject;
  }
  getTimeDisplayFormat(time: string){
    let timeSplit = time.split(":");
    let hour = timeSplit[0];
    let hourint = parseInt(hour);
    return [hourint, timeSplit[1]].join(":");
  }
  getBoxSettingBackground(key: string){
    if(this.website_info && this.website_info.box_setting){
      let box_setting = JSON.parse(this.website_info.box_setting);
      if(key === 'text_box'){
        let backgroundColor = box_setting.text_box_color;
        let opacity = box_setting.text_box_opacity;
        let rgba_background_color = this.convertToRGBAColor(backgroundColor, opacity);
        if(!rgba_background_color){
          rgba_background_color = "rgba(42, 46, 42, 0.16)"; // Default background color
        }
        return {
          "background-color": rgba_background_color
        };
      }else if(key === 'text_header_box'){
        let backgroundColor = box_setting.text_header_box_color;
        let opacity = box_setting.text_header_box_opacity;
        let rgba_background_color = this.convertToRGBAColor(backgroundColor, opacity);
        if(!rgba_background_color){
          rgba_background_color = "rgba(42, 46, 42, 0.16)"; // Default background color
        }
        return {
          "background-color": rgba_background_color
        };
      }else if(key === 'media_box'){
        let backgroundColor = box_setting.media_box_color;
        let opacity = box_setting.media_box_opacity;
        let rgba_background_color = this.convertToRGBAColor(backgroundColor, opacity);
        if(!rgba_background_color){
          rgba_background_color = "rgba(0, 0, 0, 0.0)"; // Default background color
        }
        return {
          "background-color": rgba_background_color
        };
      }
    }else{
      if(key === 'text_box'){
        return {
          "background-color": "rgba(42, 46, 42, 0.16)"
        };
      }else if(key === 'text_header_box'){
        return {
          "background-color": "rgba(42, 46, 42, 0.16)"
        };
      }else if(key === 'media_box'){
        return {
          "background-color": "rgba(0, 0, 0, 0.0)"
        };
      }
    }
  }
  convertToRGBAColor(color: string, opacity: number = 1){
    if(color){
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
      let rbaObject = result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        opacity: opacity
      } : null;
      let rgba_color = `rgba(${rbaObject.r}, ${rbaObject.g}, ${rbaObject.b}, ${rbaObject.opacity})`;
      return rgba_color;
    }else{
      return null;
    }
  }
  getCompactURL(url: string){
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width <= 900){
      if(url.startsWith("https://www.")){
        return url.substring(12);
      }else if(url.startsWith("https://")){
        return url.substring(8);
      }else{
        return url;
      }
    }else{
      return url;
    }
  }
}