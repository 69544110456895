import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { VendorManagerService } from '../services/vendor-manager.service';
import { LocationsService } from '../../locations/services/locations.service';
import { LocationModel } from 'src/app/models/location.model';
import { ToastrService } from 'ngx-toastr';
import { Config } from '../../../config';
import { RegionModel } from 'src/app/models/region.model';

declare var $: any;
@Component({
  selector: 'app-search-by-couple',
  templateUrl: './search-by-couple.component.html',
  styleUrls: ['./search-by-couple.component.scss']
})
export class SearchByCoupleComponent implements OnInit {
  wedding_website_url = location.origin + "/my-wedding/";
  firstNameSearchText: string;
  lastNameSearchText:string;
  searchData: any;
  searchDataArray: any = [];
  pageIndex:number = 1;
  daysCount: number;
  cities: any;
  city: any;
  category: any;
  selectedState: any = null;
  total_search_records = 0;
  years = [
    {
      id: 1,
      year: 2022
    },
    {
      id: 2,
      year: 2023
    },
    {
      id: 3,
      year: 2024
    },
    {
      id: 4,
      year: 2025
    }
  ];
  months = [
    {
      id: 1,
      month: 'January'
    },
    {
      id: 2,
      month: 'February'
    },
    {
      id: 3,
      month: 'March'
    },
    {
      id: 4,
      month: 'April'
    },
    {
      id: 5,
      month: 'May'
    },
    {
      id: 6,
      month: 'June'
    },{
      id: 7,
      month: 'July'
    },
    {
      id: 8,
      month: 'August'
    },
    {
      id: 9,
      month: 'September'
    },
    {
      id: 10,
      month: 'October'
    },
    {
      id: 11,
      month: 'November'
    },
    {
      id: 12,
      month: 'December'
    }
  ];
  selectedYear: any;
  selectedMonthIndex: any;
  selectedCityId: any;
  profileImage: string;
  regions: any;
  regionCities: any;
  regionId: number;
  selectCityId: any;
  searchLength: any;
  sortValue: any;
  valueOrder: any;
  constructor(private VendorManagerService: VendorManagerService,private locationService: LocationsService,
    private toastr: ToastrService, private router: Router, private activatedRoute: ActivatedRoute,) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
        if(params['first_name']){
          this.firstNameSearchText = params['first_name'];  
        }
        if(params['last_name']){
          this.lastNameSearchText = params['last_name'];
        }
        if(params['year']){
          $("#year").val(params['year']);
          this.selectedYear = Number(params['year']);
        }
        if(params['month']){
          this.selectedMonthIndex = Number(params['month']);
        }
        if(this.selectedMonthIndex){
          let selectedMonth = this.months.filter((month: any)=>{
            if(month.id === this.selectedMonthIndex){
              return true;
            }else{
              return false;
            }
          })[0].month;
          $("#month").val(selectedMonth);
        }
        if(params['state_id']){
          this.regionId = Number(params['state_id']);
          this.selectedState = Number(params['state_id']);
        }
        if(params['sort_by']){
          this.sortValue = params['sort_by'];
        }
        if(params['sort_direction']){
          this.valueOrder = params['sort_direction'];
        }
        if(params['page']){
          this.pageIndex = Number(params['page']);  
        }
    });
    setTimeout(()=>this.search(), 500);
    $(".search_div").click(function (event) {
      event.preventDefault();
      $("#open_1").toggle(200);
      $("#open_2").hide(200);
    });

    $(".search_div_2").click(function (event) {
      event.preventDefault();
      $("#open_1").hide(200);
      $("#open_2").toggle(200);
    });

    $(".search_div_3").click(function (event) {
      event.preventDefault();
      $("#open_1").hide(200);
      $("#open_2").hide(200);
      $("#open_3").toggle(200);
    });
    this.locationService.getRegions("")
    .subscribe(
      (response: RegionModel[]) => {
        for (var i = 0; i < response.length; i++) {
          response[i]['text'] = response[i].name;
        }
        this.regions = response;
      },
      error => {
        this.toastr.error(error.message);
      }
    )
  }
  searchNewFilter(){
    if(!this.firstNameSearchText){
      this.toastr.error('First name is required');
      return;
    }
    if(!this.lastNameSearchText){
      this.toastr.error('Last name is required');
      return;
    }
    if(!this.selectedYear){
      this.toastr.error('Year is required');
      return;
    }

    this.searchDataArray = [];
    this.pageIndex = 1;

    let data = {
      first_name: this.firstNameSearchText,
      last_name:this.lastNameSearchText,
      state_id:this.regionId,
      city_id:this.selectCityId,
      year:this.selectedYear,
      month:this.selectedMonthIndex,
      sort_by:this.sortValue,
      sort_direction:this.valueOrder,
      page: this.pageIndex,
      items_in_page: 10
    };

    // Update query params --
    let queryParams = {};
    Object.keys(data).forEach((item: any)=>{
      if(data[item]){
        queryParams[item] = data[item];  
      }
    });

    this.changeQueryParam(queryParams);
    this.search();
  }
  search() {
    let data = {
      first_name: this.firstNameSearchText,
      last_name:this.lastNameSearchText,
      state_id:this.regionId,
      city_id:this.selectCityId,
      year:this.selectedYear,
      month:this.selectedMonthIndex,
      sort_by:this.sortValue,
      sort_direction:this.valueOrder,
      page: this.pageIndex,
      items_in_page: 10
    };
    this.VendorManagerService.searchByouple(data).subscribe((result: any) => {
      this.searchData = result.data.results;
      this.searchLength = this.searchData.length;
      this.total_search_records = result.data.total_records;

      // Update query params --
      let queryParams = {};
      Object.keys(data).forEach((item: any)=>{
        if(data[item]){
          queryParams[item] = data[item];  
        }
      });

      for (let index = 0; index < this.searchData.length; index++) {
        if(this.searchData[0].file_name != null){
          this.searchData[0].file_name = Config.imageUrl + 'uploads/users/profile/' + this.searchData[0].file_name;
        }        
        let date_1 = new Date(this.searchData[index].start_datetime);
        let date_2 = new Date();
        const days = (date_1, date_2) => {
          let difference =   date_1.getTime() - date_2.getTime();
          let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
          return TotalDays;
        }   
        this.daysCount = days(date_1, date_2);
        this.searchData[index].dateCount = this.daysCount
        this.searchDataArray.push(this.searchData[index]);
      }
    })
  }
  changeQueryParam(params: Params) {
    const queryParams: Params = params;
    this.router.navigate(
      [], 
      {
        relativeTo: this.activatedRoute,
        queryParams: params
      }
    );
  }
  loadMore() {
    this.pageIndex = this.pageIndex + 1;
    let data = {
      first_name: this.firstNameSearchText,
      last_name:this.lastNameSearchText,
      state_id:this.regionId,
      city_id:this.selectCityId,
      year:this.selectedYear,
      month:this.selectedMonthIndex,
      sort_by:this.sortValue,
      sort_direction:this.valueOrder,
      page: this.pageIndex,
      items_in_page: 10
    };
    // Update query params --
    let queryParams = {};
    Object.keys(data).forEach((item: any)=>{
      if(data[item]){
        queryParams[item] = data[item];  
      }
    });
    this.changeQueryParam(queryParams);
    this.search();
  }
  getLocations(ele: any) {
    if (ele.target.value.charAt(0) == " ") {
      return;
    }
    if (ele.target.value.length < 1) {
      this.cities = "";
      return;
    }
    this.city = ele.target.value;    
    this.locationService
      .getCities(this.city)
      .subscribe(
        (response: LocationModel[]) => {

          for (var k = 0; k < response.length; k++) {
            response[k]['citystate'] = response[k].name + ", " + response[k].state_id;
          }
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      );
  }
  selectVenuVendor(ele: any, type: any , cityId) {    
    if (type == 'city') {
      this.city = ele;
      this.selectedCityId = cityId;      
      $("#city").val(ele);
      $("#open_2").hide();
    }
  }
  getRegionWiseCities(region_id: number) {
    if(region_id == null){
      this.regionId = null;
      this.selectedState = null;  
    }else{
      this.regionId = region_id;
      this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response: LocationModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.regionCities = response;          
        },
        error => {
          this.toastr.error(error.message);
        }
      )
    }
    
  }
  onMonthTextChange(event :any){
    if(event.target.value){
      let matchFound = false;
      this.months.forEach((month: any)=>{
        if(month.month.toLowerCase() === event.target.value.toLowerCase()){
          this.selectedMonthIndex = month.id;
          $("#month").val(month.month);
          matchFound = true;
        }
      });
      if(!matchFound){
        this.selectedMonthIndex = undefined;
      }
    }else{
      this.selectedMonthIndex = undefined;
      $("#month").val("");
    }
  }
  onYearTextChange(event :any){
    if(event.target.value){
      let matchFound = false;
      this.years.forEach((year: any)=>{
        if(year.year === Number(event.target.value)){
          this.selectedYear = year.year;
          $("#year").val(this.selectedYear);
          matchFound = true;
        }
      });
      if(!matchFound){
        this.selectedYear = undefined;
      }
    }else{
      this.selectedYear = undefined;
      $("#year").val("");
    }
  }
  geteCities(city){
    this.selectCityId = city;
  }
  selectYear(year){
    this.selectedYear = year;
    $("#open_1").hide();
    $("#year").val(year);
  }
  selectMonth(monthIndex,month){
    this.selectedMonthIndex = monthIndex;
    $("#open_3").hide();  
    $("#month").val(month);  
  }
  getArrowValue(order,sortValue){
    this.sortValue = sortValue;
    this.valueOrder = order;
    this.searchDataArray = [];
    this.pageIndex = 1;

    if(!this.firstNameSearchText){
      this.toastr.error('First name is required');
      return;
    }
    if(!this.lastNameSearchText){
      this.toastr.error('Last name is required');
      return;
    }
    if(!this.selectedYear){
      this.toastr.error('Year is required');
      return;
    }

    let data = {
      first_name: this.firstNameSearchText,
      last_name:this.lastNameSearchText,
      state_id:this.regionId,
      city_id:this.selectCityId,
      year:this.selectedYear,
      month:this.selectedMonthIndex,
      sort_by:this.sortValue,
      sort_direction:this.valueOrder,
      page: this.pageIndex,
      items_in_page: 10
    };

    // Update query params --
    let queryParams = {};
    Object.keys(data).forEach((item: any)=>{
      if(data[item]){
        queryParams[item] = data[item];  
      }
    });

    this.changeQueryParam(queryParams);
    this.search();
  }
}


