import { Deseriazable } from './deserializable.model';

export class SettingsModel implements Deseriazable{
    
    token: string;
    avalibility_update_reminder_frequency: number;
    allow_feature_profile: number;
    message: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}