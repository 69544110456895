import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SortablejsOptions } from 'angular-sortablejs';
import { WeddingWebsiteService } from '../services/wedding-website.service';
import { Config } from '../../../config';
import { PlannersService } from '../services/planners.service';
declare var $: any;

@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.scss']
})
export class RegistryComponent implements OnInit {

  constructor(
    private weddingWebsiteService: WeddingWebsiteService,
    private plannersService: PlannersService,
    private toastr: ToastrService,
  ) { }

  retailerList: any[] = [];
  retailerListOfUser: any[] = [];
  number_of_retailers_first_show: number = 6;
  show_more_retailer = true;
  retailerLogoUrl = Config.imageUrl + 'images/retailer_logos/';
  selected_retailer: any;
  showDropdownRetailer: boolean = false;
  website_url: string = "";
  activeUser: any;
  currentRegistryLinkResponse: any;
  selected_user_registry: any;
  foundOnWeddingDiveLink: string = Config.baseUrl + "/website-dashboard";
  otherRegistryName: string = "";
  isRegistryLisSortDisabled:boolean = true;

  registryListOrderOptions: SortablejsOptions = {
    disabled: this.isRegistryLisSortDisabled,
    onUpdate: (event: any)=>{
      this.updateDisplayOrderOfUserRetailers();
    }
  }
  toggleRegistryLisSort(){
    this.isRegistryLisSortDisabled = !this.isRegistryLisSortDisabled;
    this.registryListOrderOptions = {
      disabled: this.isRegistryLisSortDisabled,
      onUpdate: (event: any) => {
        this.updateDisplayOrderOfUserRetailers();
      }
    }
  }
  ngOnInit() {
    this.getAllRetailerList();

    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    },(err) => {
        console.error(err);
    });

    this.getUserRetailerList();

    document.addEventListener("click", (event: Event)=>{
      let tar = event.target as HTMLElement;
      if(!tar.closest(".dropdown_custom")){
        this.showDropdownRetailer = false;
      }
    });
  }
  getUserRetailerList(){
    this.weddingWebsiteService.getUserRetailerList(this.activeUser.id).subscribe(res => {
      this.retailerListOfUser = res.retailers;
    }, error => {
      console.log(error);
    });
  }
  getAllRetailerList(){
    this.weddingWebsiteService.getRetailerList().subscribe(res => {
      let retailerList = res.retailers;
      retailerList.push({
          "id": 0,
          "title": "Other",
          "logo": null,
          "created_at": "",
          "website_url": ""
      });
      this.retailerList = retailerList;
    }, error => {
      console.log(error);
    });
  }
  handleRetailerSelected(data: any){
    this.selected_retailer = data;
    this.showDropdownRetailer = !this.showDropdownRetailer;
  }
  saveRegistryURL(){
    // Validation --
    if(!this.selected_retailer){
      this.toastr.error("Please select retailer from the dropdown!");
      return;
    }
    if(!this.website_url){
      this.toastr.error("Please provide registry URL!");
      return;
    }
    if(this.selected_retailer.id === 0){
      $('#otherRegistryAddModal').modal('toggle');
      return;
    }
    let formData = {
      wedding_id: this.activeUser.id,
      retailer_id: this.selected_retailer.id,
      website_url: this.website_url,
      is_hidden: 0
    };
    this.weddingWebsiteService.editUserRetailer(formData).subscribe(res => {
      // Clear form --
      this.selected_retailer = null;
      this.website_url = "";
      this.otherRegistryName = "";

      // Show success modal --
      $('#addRegistrySuccessModal').modal('toggle');

      this.currentRegistryLinkResponse = res.retailer;
      this.getUserRetailerList();
      
    }, error => {
      this.toastr.error(error.message);
      console.log(error);
    });
  }
  copyRegistryLink(){
    // Get the text field
    var copyText = document.getElementById("copyToClipboard") as HTMLInputElement;
    copyText.value = this.currentRegistryLinkResponse.website_url;

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    // Copy the text inside the text field
    window.navigator['clipboard'].writeText(copyText.value);

    this.toastr.success("Copied to Clipboard!");
  }
  handleUserRegistryRemoveClick(registry: any){
    this.selected_user_registry = registry;
    $('#removeRegistryConfirmModal').modal('toggle');
  }
  removeRegistry(){
    let formData = {
      "user_retailer_id": this.selected_user_registry.id,
      "wedding_id": this.selected_user_registry.wedding_id
    };
    this.weddingWebsiteService.deleteUserRetailer(formData).subscribe(res => {
      this.toastr.success(res.message);
      this.getUserRetailerList();
      $('#removeRegistryConfirmModal').modal('toggle');
    }, error => {
      console.log(error);
    });
  }
  handleUserRegistryHideClick(registry: any){
    let is_hidden = 0;
    if(registry.is_hidden === 0){
      is_hidden = 1;
    }else if(registry.is_hidden === 1){
      is_hidden = 0;
    }
    let formData = {
      id: registry.id,
      wedding_id: registry.wedding_id,
      retailer_id: registry.retailer_id,
      website_url: registry.website_url,
      is_hidden: is_hidden,
      retailer_name: registry.retailer_name
    };
    this.weddingWebsiteService.editUserRetailer(formData).subscribe(res => {
      if(is_hidden === 1){
        this.toastr.success("Registry is now hidden from public view !");
      }else{
        this.toastr.success("Registry is now visible to public !");
      }
      this.getUserRetailerList();
    }, error => {
      console.log(error);
    });
  }
  updateDisplayOrderOfUserRetailers(){
    let newOrder = this.retailerListOfUser.map((retailer: any, index: number)=>{
      return {"retailer_id": retailer.id, "display_order": index}
    });
    let data = {
      wedding_id: this.activeUser.id,
      display_order: newOrder
    }
    this.weddingWebsiteService.updateUserRetailersOrder(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getUserRetailerList();
    }, error => {
      console.log(error);
    })
  }
  addOtherRetailer(){
    if(!this.otherRegistryName){
      this.toastr.error("Please provide retailer name!");
      return;
    }
    let formData = {
      wedding_id: this.activeUser.id,
      retailer_id: 0,
      retailer_name: this.otherRegistryName,
      website_url: this.website_url,
      is_hidden: 0
    };
    this.weddingWebsiteService.editUserRetailer(formData).subscribe(res => {
      // Clear form --
      this.selected_retailer = null;
      this.website_url = "";
      this.otherRegistryName = "";
      // Hide add Other retailer name modal --
      $('#otherRegistryAddModal').modal('toggle');
      // Show success modal --
      $('#addRegistrySuccessModal').modal('toggle');

      this.currentRegistryLinkResponse = res.retailer;
      this.getUserRetailerList();
      
    }, error => {
      this.toastr.error(error.message);
      console.log(error);
    });
  }
  getRetailerName(data: any){
    if(data.added_by_user === 1){
      return data.retailer_name;
    }else{
      return data.retailer_details.title;
    }
  }
  getCompactURL(url: string){
    var width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
    if(width <= 900){
      if(url.startsWith("https://www.")){
        return url.substring(12);
      }else if(url.startsWith("https://")){
        return url.substring(8);
      }else{
        return url;
      }
    }else{
      return url;
    }
  }
}
