import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute  } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';

declare var $: any;

@Component({
  selector: 'app-faqs', 
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {

  submitted = false;
  faqForm: any;
  faqs: any;
  faqLength: number;
  faqFieldValues = [];
  customFaqs = [];
  checkedval = [];
  selectedFaqs: any;

  faqsCurrentPage : number = 1;
  pagination_per_Page:number = Config.pagination_per_Page;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }
  ngOnInit() {
    
    this.getFaqs();
    window.scroll(0,0);
    
  }

  ngAfterViewInit(){
    //alert(this.faqLength);
  }

  onSubmit() {
    var fieldArr = [];
    $(".req").each(function(){
      var ftype = $(this).attr("id");
      if(ftype=='text'){
        //console.log($(this).find("input[type=text]").val());
        if(!$(this).find("input[type=text]").val()){
          fieldArr.push($(this).find("label").text());
        }
      }else if(ftype=='textarea'){
        //console.log($(this).find("textarea").val());
        if(!$(this).find("textarea").val()){
          fieldArr.push($(this).find("label").text());
        }
      }else if(ftype=='radio'){
        //console.log($(this).find("input[type=radio]:checked").val());
        if(!$(this).find("input[type=radio]:checked").val()){
          fieldArr.push($(this).find("label").text());
        }
      }else if(ftype=='checkbox'){
        //console.log($(this).find("input[type=checkbox]:checked").length);
        if(!$(this).find("input[type=checkbox]:checked").length){
          fieldArr.push($(this).find("label").text());
        }
      }
    })
    
    if(fieldArr.length>0){
      for(var m=0;m<fieldArr.length;m++){
        this.toastr.error(fieldArr[m]+" required");
      }
      return;
    }   
    
    if(this.faqFieldValues.length){
      this.vendorService
      .saveFaq(this.faqFieldValues)
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          //this.customFaq();
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
    }
    else{
      this.toastr.error("Empty form submission.");
    }
    
    //console.log(customQuestionAnswer);return; 
  }
  
  getFaqs(){
    //this.faqFieldValues = [];
     this.vendorService
      .getFaqs()
      .subscribe(
        (response) => {  
          this.faqs = response;
          //console.log(response.length);
          var txt = [];
           for(var i=0;i<response.length;i++){
            //alert(response[i].id);
            this.checkedval[response[i].id] = [];
            if(response[i].question_type=='checkbox'){
              if(response[i].answer!=""){
                this.checkedval[response[i].id] = response[i].answer;
              }              
            }
            if(response[i].question_type=='range'){
              //txt.push(response[i].id);
              //$("txt"+response[i].id).val(120)
            }
            if(response[i].answer!=""){
              this.faqFieldValues.push({"id":response[i].id,"option_value":response[i].answer});
            }
          }
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  selectFaqs(opVal:any, answer:any){
    let index = answer.indexOf(opVal);
        if (index > -1) {
          return 1;
        }
  }

  inputValue(ele:any,qid:number){
    //console.log(ele.target.value);return;
    var fieldType = ele.target.type;
    for(var i = 0; i < this.faqFieldValues.length; i++) {
        if(this.faqFieldValues[i].id == qid) {
          this.faqFieldValues.splice(i, 1);
            break;
        }
    }
    if(fieldType=='range'){      
      this.faqFieldValues.push({"id":qid,"option_value":[ele.target.value]});
      $("#txt"+qid).html(ele.target.value);
      //console.log(ele.target.parentElement.find(".tx"))
    }
    else if(fieldType=='text'){      
      this.faqFieldValues.push({"id":qid,"option_value":[ele.target.value]});
      //this.faqFieldValues.push({"id":qid,"option_value":[$("#ex"+qid).val()]});
    }
    else if(fieldType=='textarea'){      
      this.faqFieldValues.push({"id":qid,"option_value":[ele.target.value]});
      //this.faqFieldValues.push({"id":qid,"option_value":[$("#ex"+qid).val()]});
    }
    else if(fieldType=='radio'){      
      this.faqFieldValues.push({"id":qid,"option_value":[ele.target.value]})
    }
    else if(fieldType=='checkbox'){    
      
      if(ele.target.checked){
        this.checkedval[qid].push(ele.target.value);
      }
      else{
        let index = this.checkedval[qid].indexOf(ele.target.value);
        if (index > -1) {
          this.checkedval[qid].splice(index, 1);
        }
      } 
      this.faqFieldValues.push({"id":qid,"option_value":this.checkedval[qid]});

      //console.log(this.checkedval);
    }
    console.log(this.faqFieldValues);
  }

  mandetoryCheck(id:number){
    for(var k=0;k<this.faqFieldValues.length;k++){
      if(this.faqFieldValues[k].id==id && this.faqFieldValues[k].option_value[0]==""){
        return true;
      }
    }
  }  
}
