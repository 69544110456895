import { Deseriazable } from './deserializable.model';

export class PackageSubscriptionModel implements Deseriazable{
    
    id: number;
    user_id: number;
    package_id: number;
    order_invoice_number: string;
    is_emi: string;
    number_of_emi: string;
    is_cancled: string;
    cancel_reasons: string;
    cancel_by: string;
    cancel_on: string;
    subscribe_on: string;
    start_date: string;
    expiry_date: string;
    duration_in_month: number;
    category_region_rate_subscription_id: number;
    deal_id: string;
    status: string;
    period: number;
    message: string;
    data: any;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}