import { Component, OnInit } from '@angular/core';
import { PlannersService } from '../services/planners.service';
import { GuestlistService } from '../services/guestlist.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-planner-navigation',
  templateUrl: './planner-navigation.component.html',
  styleUrls: ['./planner-navigation.component.css']
})
export class PlannerNavigationComponent implements OnInit {
  activeUser: any;
  allEvent: any;
  filterEvent: any;

  constructor(
    public router: Router, private _toastr: ToastrService,
    private plannersService: PlannersService, private guestlistService: GuestlistService,
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.activeUser["role"] = "Wedding Users";
      } else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Vendor"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
      }else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Community Guest"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
        console.log(this.activeUser);
      }else{
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
   }

   async ngOnInit() {
    await this.getEventList(this.activeUser.id);
  }

  async getEventList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var eventList = await this.guestlistService.getEventList(data).toPromise();
    this.allEvent = eventList.data;    
    this.filterEvent = this.allEvent .filter((e) => e.is_seating_chart);
  }
}
