import { Deseriazable } from './deserializable.model';

export class EventModel implements Deseriazable{
    
    id: number;
    token: string;
    event_name: string;
    event_type_id: number;
    start_date_time: string;
    end_date_time: string;
    city_id: number;
    region_id: number;
    address: string;
    lat: string;
    long: string;
    description: string;
    message: string;
    image_name: string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}