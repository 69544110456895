import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FrontNavComponent } from '../front-nav/front-nav.component';
import { FooterComponent } from '../footer/footer.component';

@NgModule({
  declarations: [
    FrontNavComponent,
    FooterComponent
  ],
  imports: [
    CommonModule
  ],
  exports:[
    FrontNavComponent,
    FooterComponent
  ]
})
export class SharedModule { }
