import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { UsersService } from '../../users/services/users.service';
import { LoginModel } from 'src/app/models/login.model';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { ForgotPasswordModel } from 'src/app/models/forgotpassword.model';
import { RegisterModel } from 'src/app/models/register.model';
import { PlannerProfileModel } from 'src/app/models/planner.profile.model';
import { BehaviorSubject } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class PlannersService extends BaseService {

  private _selectedPlannerWedding: any;
  public onPlannerWeddingUpdate = new Subject();
  private _weddings: any;
  assignWedding = new Subject<string>();

  public setSocialData = new BehaviorSubject({data : false});

  private addActiveUserSubject: BehaviorSubject<any>;
  public addActiveUserClick: Observable<any>;
  private weddingSubject: BehaviorSubject<any>;
  public weddingClick: Observable<any>;
  
  constructor(
    private http: HttpClient, 
    protected usersService: UsersService) {
    super(usersService);
    this.addActiveUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('addActiveUserClick')));
    this.addActiveUserClick = this.addActiveUserSubject.asObservable();
    this.weddingSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('weddingClick')));
    this.weddingClick = this.weddingSubject.asObservable();
    
  }

  setNewWedding(weddings: any) {
    this.assignWedding.next(weddings);
  }

  getCurrentUserToken(): string{
    return localStorage.getItem("USER_TOKEN");
  }

  getCurrentUserFname(): string{
    return localStorage.getItem("fname");
  }

  private setCurrentUserToken(token:string){
    localStorage.setItem("USER_TOKEN",token);
  }

  private setCurrentUserId(id:string){
    localStorage.setItem("UID",id);
  }

  private setCurrentUserName(fname:string){
    localStorage.setItem("fname",fname);
  }

  getCurrentUid(){
    return localStorage.getItem("UID");
  }

  private setCurrentUserRoles(){
    //localStorage.setItem("role",fname);
  }

  isAuthenticated(){
      if(this.getCurrentUserToken()) {
        return true;
      }
      return false;
  }

  getCurrentUserRole(): string{
      return localStorage.getItem("role");
  }

  authenticate(data: LoginModel): Observable<AuthResponseModel> {    
    return this.http
      .post(Config.serviceEndPoints.weddingUsers.authenticate, data, httpOptions)
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }
        console.log(response); 
        this.setCurrentUserToken(response.data.token); 
        this.setCurrentUserId(response.data.id); 
        this.setCurrentUserName(response.data.fname);
        localStorage.setItem("role",response.data.claims.roles[0].name);
        localStorage.setItem("parent_id",response.data.parent_id);
        return new AuthResponseModel().deserialize(response.data);        
      }));       
  }

  resetPassword(data: ForgotPasswordModel): Observable<ForgotPasswordModel> {
    return this.http
      .post(Config.serviceEndPoints.weddingUsers.resetpassword, data, httpOptions)
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return new ForgotPasswordModel().deserialize(response.data);         
      }));       
  }

  create(data: RegisterModel): Observable<RegisterModel> {
    return this.http
      .post(Config.serviceEndPoints.weddingUsers.register, data, httpOptions)
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return new RegisterModel().deserialize(response.data);         
      }));       
  }

  getProfileData(){
    var uId = localStorage.getItem("UID");
    return this.http
      .get(Config.serviceEndPoints.weddingUsers.profile+"/"+uId, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  saveProfileData(data:PlannerProfileModel):  Observable<PlannerProfileModel> {
    return this.http
      .put(Config.serviceEndPoints.weddingUsers.updateProfile, data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  saveProfilePhoto(data:any) {
    return this.http
      .post(Config.serviceEndPoints.weddingUsers.profilephoto, data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  getProfilePhoto(photoId:any){
    return this.http
      .get(Config.serviceEndPoints.weddingUsers.getprofilephoto+"/"+photoId, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteProfilePhoto(photoId:any){
    return this.http
      .delete(Config.serviceEndPoints.weddingUsers.deleteprofilephoto+"/"+photoId, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  linkToProfile(data:any) {
    return this.http
      .post(Config.serviceEndPoints.weddingUsers.linktoprofile, data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  submitLinkProfile(data:any) {
    return this.http
      .post(Config.serviceEndPoints.weddingUsers.submitlinkprofile, data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  newWedding(data:any) {
    return this.http
      .post(Config.serviceEndPoints.weddingUsers.newwedding, data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  getWeddings(){
    return this.http
      .get(Config.serviceEndPoints.weddingUsers.getweddings, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  activeWedding(data:any) {
    return this.http
      .put(Config.serviceEndPoints.weddingUsers.activewedding, data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;         
      }));
  }

  storeSocialData(Value : any){
    this.setSocialData.next(Value);
  }

  getLinkData(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/users/invitation-key", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  addPlannerNewWedding(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/weddingUsers/planner", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  storeActiveUser(data: any) {
    localStorage.setItem('addActiveUserClick', JSON.stringify(data));
    this.addActiveUserSubject.next(data);
  }

  storeWeddings(data: any) {
    localStorage.setItem('weddingClick', JSON.stringify(data));
    this.weddingSubject.next(data);
  }

  removeActiveUser() {
    localStorage.removeItem('addActiveUserClick');
    this.addActiveUserSubject.next(null);
    localStorage.removeItem('weddingClick');
    this.weddingSubject.next(null);
  }

  getWebsiteContent(data:any){
    return this.http
      .post(Config.apiBaseUrl+"/website-content/get", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;
    }));
  }
}
