import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GuestlistService } from '../services/guestlist.service';
import { PlannersService } from '../services/planners.service';
declare var $: any;

@Component({
  selector: 'app-manage-sub-events',
  templateUrl: './manage-sub-events.component.html',
  styleUrls: ['./manage-sub-events.component.css']
})
export class ManageSubEventsComponent implements OnInit, OnChanges {
  originalMenus = [
    'Beef',
    'Chicken',
    'Fish',
    'Lamb',
    'Vegetarian',
    'Child Meal',
    'Other'
  ];
  originalMenus1 = [
    'Beef',
    'Chicken',
    'Fish',
    'Lamb',
    'Vegetarian',
    'Child Meal',
    'Other'
  ];
  originalLists = [
    'A-List',
    'B-List',
    'C-List'
  ];
  eventName: any;
  activeUser: any;
  newEvent: string;
  newList: string;
  isTrackMeals: number = 1;
  isSeatingChart: number = 1;
  showMeals: boolean = true;
  editMeals: boolean = false;
  editLists: boolean = false;
  invalidMenu: boolean = true;
  invalidList: boolean = true;
  menus: any = [];
  lists: any = [];
  @Output() clickEvent = new EventEmitter();
  showMore: boolean = false;
  allEvent: any;
  event: any;
  @Input() eventEdit: any;
  trackMeals: boolean = true;
  seatingChart: boolean = true;
  eventTitle: string = 'Add Event';
  type: number;
  selectEvent: boolean[] = [];
  addActive: boolean = true;
  showEventRemove: boolean = false;

  @Input() eventData: any;
  activeMenu: any = '';
  constructor(public router: Router,
    private plannersService: PlannersService, private guestlistService: GuestlistService,
    private _toastr: ToastrService) {

    // $('#addEvent22').on('hidden.bs.modal', () => {
    //   console.log(4);
    //   this.menus = this.originalMenus;
    //   this.lists = this.OriginalLists;
    //   this.eventName = '';

    // });
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
    // this.menus = this.originalMenus;
    // this.lists = this.originalLists;guestSettingModel
  }

  ngAfterViewInit() {

  }
  async ngOnInit() {


    $('#addEvent22').on('hidden.bs.modal', async () => {
      await this.reset();
    });

    await this.getEventList(this.activeUser.id);
    this.guestlistService.currentGuestMenu.subscribe(res => {
      this.activeMenu = res;
      this.guestlistService.currentGuestMenuSettingModel.subscribe(data => {
        this.event = data;
      }, error => {
        console.log(error)
      });
      // this.addActive = true;
      // if (res == 'Snapshot' || res == undefined) {
      //   this.addActive = true;
      // } else {
      //   this.addActive = false;
      // }
    }, error => {
      console.log(error)
    })

  }

  async ngOnChanges() {
    if (this.eventData) {
      if (this.eventData == 2) {
        this.addActive = true;
      } else {
        this.addActive = false;
      }

      this.selectEvent = [];
      this.selectEvent[this.eventData.id] = !this.selectEvent[this.eventData.id];
    }
    if (this.eventEdit) {
      this.type = 2;
      this.showEventRemove = true;
      await this.getEventData();
      $('#addEvent22').modal('toggle');
    }

    // this.disableEditMode = true;
    // await this.getUser();
    // await this.getAllDoctors(this.filterParams);
    // await this.getDetails();

  }

  showEventModal() {
    this.newEvent = null;
    this.type = 1;
    this.menus = this.originalMenus;
    this.lists = this.originalLists;
    this.eventName = null;
    this.clickEvent.emit(2);
    this.trackMeals = true;
    this.seatingChart = true;
    this.showEventRemove = false;
    this.eventTitle = 'Add Event';
    $('#addEvent22').modal('toggle');
  }

  async reset() {
    this.menus = [];
    this.lists = [];
    this.newEvent = null;
    this.menus = [
      'Beef',
      'Chicken',
      'Fish',
      'Lamb',
      'Vegetarian',
      'Child Meal',
      'Other'
    ];
    this.lists = [
      'A-List',
      'B-List',
      'C-List'
    ];
    this.eventName = null;
    this.editMeals = false;
    this.editLists = false;
  }

  async getEventList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var eventList = await this.guestlistService.getEventList(data).toPromise();
    this.allEvent = eventList.data;
  }

  async addEvent() {
    try {
      if (this.eventName == null || this.eventName == '') {
        this._toastr.error('Please enter event name');
        return;
      }
      if (this.type == 1) {
        let data = {
          wedding_id: this.activeUser.id,
          event_name: this.eventName,
          is_track_meals: this.isTrackMeals,
          is_seating_chart: this.isSeatingChart,
        };
        var addEvent = await this.guestlistService.createEvent(data).toPromise();
      } else {
        let data = {
          id: this.event.id,
          event_name: this.eventName,
          is_track_meals: this.isTrackMeals,
          is_seating_chart: this.isSeatingChart,
        };
        var addEvent = await this.guestlistService.updateEventByEventId(data).toPromise();
      }
      if (addEvent.hasError) {
        let err = addEvent.message;
        throw err;
      } else {
        if (this.isTrackMeals == 1) {
          let data2 = {
            wedding_id: this.activeUser.id,
            wed_event_id: addEvent.data ? addEvent.data.id : this.event.id,
            names: this.menus,
            is_single: false
          };
          var addMiles = await this.guestlistService.addMenu(data2).toPromise();
        } else {
          let data2 = {
            wedding_id: this.activeUser.id,
            wed_event_id: addEvent.data ? addEvent.data.id : this.event.id,
            names: [],
            is_single: false
          };
          var addMiles = await this.guestlistService.addMenu(data2).toPromise();
        }
        if (addMiles.hasError) {
          let err = addMiles.message;
          throw err;
        } else {
          this.menus = [];
          let data3 = {
            wedding_id: this.activeUser.id,
            wed_event_id: addEvent.data ? addEvent.data.id : this.event.id,
            names: this.lists,
            is_single: false
          };
          var addLists = await this.guestlistService.addList(data3).toPromise();
          if (addLists.hasError) {
            let err = addLists.message;
            throw err;
          } else {
            this._toastr.success(addEvent.message);
            await this.getEventList(this.activeUser.id);
            await this.reset();
            if (this.type == 2) {
              this.clickEvent.emit(this.event);
            } else if (this.type == 1) {
              this.clickEvent.emit(3);
            }
            $('#addEvent22').modal('toggle');
          }
        }

      }
    }
    catch (e) {
      this._toastr.error(e);
    }

  }

  addNewMenu() {
    this.menus.push(this.newEvent);
    this.newEvent = '';
    this.invalidMenu = true;
  }
  removeEvent(index: any) {
    this.menus.splice(index, 1);
  }

  addNewList() {
    this.lists.push(this.newList);
    this.newList = '';
    this.invalidList = true;
  }
  removeList(index: any) {
    this.lists.splice(index, 1);
  }
  clickMeals(e: any) {
    if (e.target.checked == false) {
      this.isTrackMeals = 0;
      this.showMeals = false;
    } else {
      this.menus = this.originalMenus;
      this.isTrackMeals = 1;
      this.showMeals = true;
    }
  }
  clickChart(e: any) {
    if (e.target.checked == false) {
      this.isSeatingChart = 0;
    } else {
      this.isSeatingChart = 1;
    }
  }
  editMeal() {
    this.editMeals = true;
  }
  removeEditMeal() {
    this.editMeals = false;
  }
  editList() {
    this.editLists = true;
  }
  removeEditList() {
    this.editLists = false;
  }
  checkMenu(e: any) {
    if (e.target.value.trim() != '') {
      this.invalidMenu = false;
    } else {
      this.invalidMenu = true;
    }
  }

  checkList(e: any) {
    if (e.target.value.trim() != '') {
      this.invalidList = false;
    } else {
      this.invalidList = true;
    }
  }

  async toggleStatus(showMore: any, event: any) {

    this.showMore = !showMore;
    this.addActive = false;
    this.event = event;
    this.clickEvent.emit(event);
    this.selectEvent = [];
    this.selectEvent[event.id] = !this.selectEvent[event.id];
  }

  async toggleStatus1(event: any) {
    if (event == 2) {
      this.addActive = true;
      this.activeMenu = ""
    } else {
      this.addActive = false;
    }
    this.showMore = false;
    this.event = event;
    this.clickEvent.emit(event);
    this.selectEvent = [];
    this.selectEvent[event.id] = !this.selectEvent[event.id];
  }
  async getEventData() {
    let data = {
      wedding_id: this.activeUser.id,
      event_id: this.event.id
    };
    var event = await this.guestlistService.getEventDetails(this.event.id).toPromise();
    var menus = await this.guestlistService.getMenusByEventId(data).toPromise();
    var lists = await this.guestlistService.getListsByEventId(data).toPromise();
    this.lists = [];
    this.menus = [];
    this.eventTitle = event.event_name + ' event setting';
    this.eventName = event.event_name;
    this.trackMeals = event.is_track_meals;
    this.seatingChart = event.is_seating_chart;
    if (event.is_track_meals == 0) {
      this.showMeals = false;
    }
    lists.data.forEach((o: any, i: number) => {
      this.lists.push(o.list_name);
    });
    menus.data.forEach((o: any, i: number) => {
      this.menus.push(o.menu_name);
    });
  }

  toggleStatusShow(showMore: any) {
    this.showMore = !showMore;
  }
  async deleteEvent() {
    this.guestlistService.deleteEvent(this.event.id).subscribe((result: any) => {
      this.set();
      this._toastr.success('Event was deleted');
      $('#addEvent22').modal('toggle');
    },
      error => {
        this._toastr.error(error.message);
      })
  }

  async set() {
    await this.getEventList(this.activeUser.id);
    await this.reset();
  }

}
