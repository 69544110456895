import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../users/services/users.service';
import { VendorsService } from '../services/vendors.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-preference',
  templateUrl: './preference.component.html',
  styleUrls: ['./preference.component.css']
})
export class PreferenceComponent implements OnInit {

  pushNotification = 0;
  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }

  ngOnInit() {

    if (localStorage.getItem("pushnotification_" + window.btoa(localStorage.getItem("UID")))) {
      this.pushNotification = 1;
    }
  }
  notifyMe(ele: any) {
    localStorage.removeItem("pushnotification_" + window.btoa(localStorage.getItem("UID")));
    if (ele.checked) {
      localStorage.setItem("pushnotification_" + window.btoa(localStorage.getItem("UID")), "1");
      Notification.requestPermission();
    }
    console.log(Notification);
  }

}
