import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute  } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { AvailabilityModel } from 'src/app/models/availability.model';
import { AvailabilityFormBuilder } from './availability.form-builder';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { LocationsService } from '../../locations/services/locations.service';
import { EventTypeModel } from 'src/app/models/eventtype.model';
import { LocationModel } from 'src/app/models/location.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RegionModel } from 'src/app/models/region.model';


declare var $: any;
@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.css']
})
export class AvailabilityComponent implements OnInit {

  submitted = false;
  model = new AvailabilityModel();
  availabilityForm: any;
  availabilitys: any;
  availability_id = 0;
  bid = 0;
  goal_bookins_start: number;
  goal_bookins_end: number;
  daysVals = [];
  daysArray = [];
  selectedDays = [];
  bookings: any;
  eventTypes: any;
  cities : any;
  regions : any;
  bookinsCurrentPage : number = 1;
  pagination_per_Page:number = Config.pagination_per_Page;
  min : any;
  datefilter : any;
  monthNames : any;
  showMonth = false;
  searchBtn = false;
  deviceInfo = null;
  mobile = false;
  desktop = false;
  timeArray : any;
  searchObj = {"year":"","month":""};

  @ViewChild('calender') calender: any;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: AvailabilityFormBuilder,
    private locationService: LocationsService,
    dateTimeAdapter: DateTimeAdapter<any>,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    public router: Router) {
      dateTimeAdapter.setLocale('en-US');
      this.deviceDetector();
  }
  ngOnInit() {
    this.availabilityForm = this.formBuilder.getForm();
    //this.getBookings(this.searchObj);
    this.getEventType();
    var getDate = new Date();
    this.goal_bookins_start = getDate.getFullYear();
    this.goal_bookins_end = getDate.getFullYear() + 1; 
    this.daysVals = Config.daysArray; 
    this.min = new Date();
    this.timeArray = Config.timeArray;
    this.monthNames = Config.monthNames;
    this.allRegions();    
    this.getAvailability();
    
    $('form, input, select').attr('autocomplete', 'nope');
    if(!$("input[name='auto_blocked']:checked"). val()){
      $("#updateNo"). prop("checked", true);
    }
    
    window.top.scroll(10,10); 
  }

  get f() { return this.availabilityForm.controls; }

  onSubmit() {
    
    if(!this.selectedDays.length){
      //this.toastr.error("Please save settings data first.");
     // return;
    }
    
    this.submitted = true;    
    // stop here if form is invalid
    if (this.availabilityForm.invalid) {
      return;
    }

    

    this.model.deserialize(this.availabilityForm.value);
    this.model.booking_date_time = this.usDateToDb($("#booking_date").val());
    this.vendorService
      .saveBooking(this.model)
      .subscribe(
        (response:AvailabilityModel) => {          
          this.toastr.success(response.message);
          this.resetForm();
          this.getBookings(this.searchObj);
          $("#bookingForm").modal('hide');
          this.calender.fillWeekWiseDateRenge();
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  getBookings(params){
    this.vendorService
      .getBookings(params)
      .subscribe(
        (response:any) => {  
          this.bookings = response;
          console.log(this.bookings);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

  getBooking(id:number){
    this.vendorService
      .getBooking(id)
      .subscribe(
        (response:AvailabilityModel) => {  
          this.formBuilder.setValues(response);
          this.getRegionWiseCities(response.region_id);
          this.dbToUsDate(response.booking_date_time.substring(0, 10));
          console.log(response.is_fullday);
          this.bid = response.id;
          if(response.is_fullday){
            $("#time").attr("disabled",true);
          }else{
            $("#time").attr("disabled",false);
          }
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  } 

  deleteBookingModal(){
    $("#bookingForm").modal('hide');
    $("#deletebooking").modal();    
  } 

  deleteBooking(bid:any){
    this.vendorService
      .deleteBooking(bid)
      .subscribe(
        (response:any) => { 
          $('#deletebooking').modal('toggle'); 
          this.toastr.success(response.message);
          this.getBookings(this.searchObj);
          this.calender.fillWeekWiseDateRenge();
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

  saveSettings(){
    var settingObj = {};
    var booking_per_day = $("#booking_per_day").val();
    if(booking_per_day=="")
    {
      this.toastr.error("Booking per day is required");
      return;
    }
    var daysAr = [];
    $(".week input[type=checkbox]").each(function(){
      if($(this).prop("checked")){
        daysAr.push($(this).val());
      }
    })
    if(daysAr.length==0){
      this.toastr.error("Select Days Of The Week.");
      return;
    }

    this.model.auto_blocked = $("input[name='auto_blocked']:checked"). val();

    this.model.available_week_days = daysAr;
    this.model.booking_per_day = booking_per_day;
    this.model.goal_year = this.goal_bookins_start;
    
    this.vendorService
      .saveAvailability(this.model)
      .subscribe(
        (response:AvailabilityModel) => {          
          this.toastr.success(response.message);
          this.getAvailability();
          $("#setting").modal('hide');
          this.calender.fillWeekWiseDateRenge();
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )

  }
  
    getAvailability(){
    this.vendorService
      .getAvailability()
      .subscribe(
        (response:AvailabilityModel) => {  
          this.formBuilder.setValues(response);
          this.selectedDays = response.available_week_days;
          if(response.auto_blocked){
            $("#updateYes"). prop("checked", true);
          }else{
            $("#updateNo"). prop("checked", true);
          }
          $("#booking_per_day").val(response.booking_per_day);
        },
        error => {
          this.toastr.error(error.message);
        }
      ) 
  }

  daysCheck(sVal:any, isChecked: boolean) {    
    if (isChecked) {
      this.daysArray.push(sVal);
    } 
    if (!isChecked) {
      let index = this.daysArray.indexOf(sVal);
      if (index > -1) {
        this.daysArray.splice(index, 1);
      }
    } 
    this.availabilityForm.controls.available_week_days.setValue(this.daysArray);
  }

  daysOfWeekSelected(days_id:number){
    if(this.selectedDays.length>0){
      let index = this.selectedDays.indexOf(days_id);
      if (index > -1) {
        return 1;
      } 
    }else{
      return 1;
    }
    
  }

  resetForm(){
    this.submitted = false;   
    this.availabilityForm.reset();
  }

  changeYear(arg:number){
    if(arg>0){
      this.showMonth = true;
      this.searchBtn = true;
    }else{
      this.showMonth = false;
      this.searchBtn = false;
    }    
  }

  search(){
    this.searchObj = {"year":$("#year").val(),"month":$("#month").val()};
    this.getBookings(this.searchObj);    
    
  }

  getRegionWiseCities(region_id:number){  
    //alert(region_id);  
    this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response:LocationModel[]) => {          
          for(var i=0;i<response.length;i++){
            response[i]['text'] = response[i].name;
          }          
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      ) 
  }

  getEventType(){
    this.vendorService
      .getEventType()
      .subscribe(
        (response:EventTypeModel[]) => {  
          this.eventTypes = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      ) 
  }

  allRegions(){
    this.locationService
      .getRegions("")
      .subscribe(
        (response:RegionModel[]) => {          
          for(var i=0;i<response.length;i++){
            response[i]['text'] = response[i].name;
          }
          this.regions = response;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  usDateToDb(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  dbToUsDate(date){
    var exp = date.split("-");
    var year = exp[0];
    var month = exp[1];
    var day = exp[2];
    var convertDate = [month, day, year].join('/');
    
    $("#booking_date").val(convertDate);
  }

  fullDayBooked(event:any){
    if(event.target.checked){
      $('#time').attr("disabled", true); 
    }else{
      $("#time").attr("disabled",false);
    }
  }

  onAddBooking(date:any){
    //console.log(date);
    this.bid = 0;
    this.resetForm();
    this.availabilityForm.controls.booking_date_time.setValue(date);
    //$("#booking_date").val(dateFormate);
    $("#bookingForm").modal();
  }
  onEditBooking(bookingDetail:any){
    console.log(bookingDetail);
    this.resetForm();
    this.getBooking(bookingDetail.id);
    $("#bookingForm").modal();
  }
  onBlockDay(param:any){
    console.log(param);
    let data = {date:this.usDateToDb(param.date),isBlocked:param.isBlocked};
    this.vendorService
      .setBlockDay(data)
      .subscribe(
        (response:any) => { 
          this.toastr.success(response.message); 
          this.calender.fillWeekWiseDateRenge();
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  deviceDetector(){
    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();    
    if(isMobile){
      this.mobile = true;
      this.desktop = false;
    }else{
      this.mobile = false;
      this.desktop = true;
    }
  }
}


