import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vendor-footer',
  templateUrl: './vendor-footer.component.html',
  styleUrls: ['./vendor-footer.component.css']
})
export class VendorFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
