import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { DiscussionService } from '../services/discussions.service';

@Component({
  selector: 'app-community-guest-login-modal',
  templateUrl: './community-guest-login-modal.component.html',
  styleUrls: ['./community-guest-login-modal.component.scss']
})
export class CommunityGuestLoginModalComponent implements OnInit {
  @Output() forgot_password= new EventEmitter();

  display_name: string = "";
  user_email: string = "";
  user_password: string = "";
  confirm_password: string = "";
  terms_and_condition: boolean = false;

  login_email: string = "";
  login_password: string = "";

  constructor(
    private toastr: ToastrService,
    private discussionService: DiscussionService,
    public router: Router,
    public route: ActivatedRoute
    ) { }

  ngOnInit() {
  }

  async register(){
    if(!this.display_name){
      this.toastr.error("Please provide display name!");
      return;
    }
    if(!this.user_email){
      this.toastr.error("Please provide your email!");
      return;
    }
    if(!this.user_email){
      this.toastr.error("Please provide your email!");
      return;
    }
    if(!(this.user_password === this.confirm_password)){
      this.toastr.error("Password do not match!");
      return;
    }
    if(!this.terms_and_condition){
      this.toastr.error("Please accept our terms and conditions!");
      return;
    }

    let formData = {
      "display_name": this.display_name,
      "email": this.user_email,
      "password": this.user_password,
    };

    try{
      var response = await this.discussionService.registerCommunityGuest(formData).toPromise();
      this.toastr.success(response.message);
      setTimeout(()=>{
        window.location.reload();
      }, 500);
      
    }catch(error){
      this.toastr.error(error.message);
    }
  }
  async loginUser(){
    if(!this.login_email){
      this.toastr.error("Please provide your email!");
      return;
    }
    if(!this.login_password){
      this.toastr.error("Please provide password!");
      return;
    }

    let formData = {
      "login_email": this.login_email,
      "login_password": this.login_password
    };

    try{
      var response = await this.discussionService.loginCommunityGuest(formData).toPromise();
      this.toastr.success(response.message);
      setTimeout(()=>{
        window.location.reload();
      }, 500);
    }catch(error){
      this.toastr.error(error.message);
    }
  }
  forgotPassword(){
    this.forgot_password.emit();
    this.router.navigate(["forgot-password"], {queryParams: {returnUrl: location.pathname}});
  }
}
