import { Deseriazable } from './deserializable.model';

export class MediadModel implements Deseriazable{
    
    id: string;
    file_name: string;
    file_type: string;
    message: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}