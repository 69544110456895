import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { UsersService } from '../../users/services/users.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SeatingChartService extends BaseService{

  constructor(
    private http: HttpClient, 
    protected usersService: UsersService) {
      super(usersService);
     }

  // create table 
  createTable(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/seatingChart/table", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

   // create table 
   getTableList(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/seatingChart/table-list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }
  // delete table 
  deleteTable(id){
    return this.http.delete(Config.apiBaseUrl + "/seatingChart/table-delete/" + id, this.getHttpHeaders())
  }

  // get event guest list 
  getEventGuestList(data: any){
    return this.http.post(Config.apiBaseUrl + "/seatingChart/guest-list" , data, this.getHttpHeaders())
    .pipe(map((response: any) => {
      if (response.hasError) {
        throw new Error(response.message);
      }
      return response;
    }));
  }

  editTableApi(data){
    return  this.http.put(Config.apiBaseUrl + "/seatingChart/table-update" , data, this.getHttpHeaders())
  }

  assignSeats(data){
    return  this.http.post(Config.apiBaseUrl + "/seatingChart/seat-assign" , data, this.getHttpHeaders())
  }

  unassignSeats(data){
    return  this.http.post(Config.apiBaseUrl + "/seatingChart/seat-unassign" , data, this.getHttpHeaders())
  }
}
