import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { LoginModel } from 'src/app/models/login.model';
import { ForgotPasswordModel } from 'src/app/models/forgotpassword.model';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { Config } from '../../../config';
import { LocationModel } from 'src/app/models/location.model';
import { RegionModel } from 'src/app/models/region.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private http: HttpClient) { }

  getRegionWiseCities(id: number): Observable<LocationModel[]> {
    return this.http
    .get(Config.serviceEndPoints.regions.getcities+"/"+id, httpOptions)
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }
      var locations:LocationModel[] = [];
      response.data.forEach(item => {
        locations.push((new LocationModel).deserialize(item));
      });
      return locations;      
    }));
}

  getCities(name: string): Observable<LocationModel[]> {   
      var data = {
        search_value : name        
      };
      return this.http
      .post(Config.serviceEndPoints.locations.location, data, httpOptions)
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }
        var locations:LocationModel[] = [];
        response.data.forEach(item => {
          locations.push((new LocationModel).deserialize(item));
        });
        return locations;      
      }));
  }

  getTopCities(): Observable<LocationModel[]> {   
    var data = {
            
    };
    return this.http
    .post(Config.serviceEndPoints.locations.toplocation, data, httpOptions)
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }
      var locations:LocationModel[] = [];
      response.data.forEach(item => {
        locations.push((new LocationModel).deserialize(item));
      });
      return locations;      
    }));
}

  getRegions(name: string): Observable<RegionModel[]> {
   
    var data = {
      
    };

    return this.http
    .post(Config.serviceEndPoints.regions.region, data, httpOptions)
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }
      var regions:RegionModel[] = [];
      response.data.forEach(item => {
        regions.push((new RegionModel).deserialize(item));
      });
      return regions;      
    }));
}
}
