import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GuestlistService } from '../services/guestlist.service';
import { PlannersService } from '../services/planners.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-guest-print',
  templateUrl: './guest-print.component.html',
  styleUrls: ['./guest-print.component.scss']
})
export class GuestPrintComponent implements OnInit {
  wedEventId: any;
  activeUser: any;
  apiGuestList: any;
  allGuest: any;

  constructor(private _route: ActivatedRoute, public router: Router,
    private plannersService: PlannersService, private guestlistService: GuestlistService,
    private _toastr: ToastrService) { 
      this.plannersService.addActiveUserClick.subscribe((userData) => {
        if (userData) {
          this.activeUser = userData;
        } else {
          this.activeUser = null;
        }
      },
        (err) => {
          console.error(err);
        });
    this._route.queryParams.subscribe((params: any) => {
     this.wedEventId=params.event_id;
    });
  }

 async ngOnInit() {

    let data = {
      wedding_id: this.activeUser.id,
      wed_event_id: this.wedEventId,
    };
    var guestList = await this.guestlistService.eventGuestList(data).toPromise();
    this.apiGuestList = guestList.data.filter((e) => e.event.length > 0);
    var grouped = this.groupBy(this.apiGuestList, false, (relation: { relationship_name: any; }) => relation.relationship_name);
    this.allGuest = this.mapEntries(grouped);
    console.log(this.apiGuestList);
  }

  mapEntries(grouped: any) { return Array.from(grouped.entries()); }

  groupBy(list: any, checked: boolean, keyGetter: any) {
    const map = new Map();
    var temp = [];
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      const data = {
        item: item,
        showEventAttend: null,
        isChecked: checked

      };
      if (!collection) {
        map.set(key, [data]);
      } else {
        collection.push(data);
      }
    });
    return map;
  }

  print() {
    window.print();
  }

}
