import { Deseriazable } from './deserializable.model';

export class DealModel implements Deseriazable{
    
    id: number;
    token: string;
    deal_name: string;
    deal_type: string;
    deal_value: string;
    description: string;
    expire_date: string;
    image_name: any;
    message: string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}