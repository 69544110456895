import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GuestlistService } from '../services/guestlist.service';
import { PlannersService } from '../services/planners.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-guest-missing-info',
  templateUrl: './guest-missing-info.component.html',
  styleUrls: ['./guest-missing-info.component.scss']
})
export class GuestMissingInfoComponent implements OnInit {
  sub: any;
  id: any;
  guestDetails: any;
  editAgeType: any;
  editHotelType: any;
  editSelectAge: boolean[] = [];
  editSelectHotel: boolean[] = [];
  typeOfAge = [
    {
      id: 1,
      value: 'Adult'
    },
    {
      id: 2,
      value: 'Child'
    },
    {
      id: 3,
      value: 'Baby'
    }

  ];
  typeOfHotel = [
    {
      id: 1,
      value: 'Yes'
    },
    {
      id: 2,
      value: 'No'
    }

  ];
  addGuestInfoForm: FormGroup;
  submitted: boolean = false;
  guestId: any;

  constructor(public router: Router,
    private plannersService: PlannersService, private guestlistService: GuestlistService,
    private _toastr: ToastrService, public route: ActivatedRoute, public formBuilder: FormBuilder) {
    this.sub = this.route.params.subscribe(params => {
      if (params) {
        this.id = params['id'];
        if (params['id']) {
        }
      }
    });
  }

  async ngOnInit() {

    this.addGuestInfoForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      mobile: [''],
      city: [''],
      state: [''],
      address: [''],
      zip: [''],
    });

    var a= this.id.replaceAll("$*", "/");
    var id = await this.decryptData(a);
    this.guestId=id;
    await this.getGuestData(id);

  }

  async decryptData(data: any) {

    try {
      const bytes = CryptoJS.AES.decrypt(data, 'Secret Passphrase');
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  get f() {
    return this.addGuestInfoForm.controls;

  }

  async getGuestData(guestId: any) {
    var guestDetails = await this.guestlistService.guestDetails(guestId).toPromise();
    this.guestDetails = guestDetails[0];
    this.editAgeType = this.guestDetails.age_type;
    this.editHotelType = this.guestDetails.need_hotel;
    this.editSelectAge[this.guestDetails.age_type] = !this.editSelectAge[this.guestDetails.age_type];
    this.editSelectHotel[this.guestDetails.need_hotel] = !this.editSelectHotel[this.guestDetails.need_hotel];

    this.addGuestInfoForm.get('first_name').setValue(this.guestDetails.first_name);
    this.addGuestInfoForm.get('last_name').setValue(this.guestDetails.last_name);
    this.addGuestInfoForm.get('phone').setValue(this.guestDetails.phone_number);
    this.addGuestInfoForm.get('email').setValue(this.guestDetails.email);
    this.addGuestInfoForm.get('mobile').setValue(this.guestDetails.mobile_number);
    this.addGuestInfoForm.get('city').setValue(this.guestDetails.city);
    this.addGuestInfoForm.get('state').setValue(this.guestDetails.state);
    this.addGuestInfoForm.get('address').setValue(this.guestDetails.address);
    this.addGuestInfoForm.get('zip').setValue(this.guestDetails.zip_code);
  }

  editClickAge(age: any, i: number) {
    this.editAgeType = age.id;
    this.editSelectAge = [];
    this.editSelectAge[age.id] = !this.editSelectAge[age.id];
  }
  editClickHotel(hotel: any, i: number) {
    this.editHotelType = hotel.id;
    this.editSelectHotel = [];
    this.editSelectHotel[hotel.id] = !this.editSelectHotel[hotel.id];
  }

  submit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addGuestInfoForm.invalid) {
      console.log('invalid');
      return;
    }

    let data = {
      guest_id: this.guestId,
      wedding_id: this.guestDetails.wedding_id,
      relationship_id: this.guestDetails.relationship_id,
      first_name: this.addGuestInfoForm.value.first_name,
      last_name: this.addGuestInfoForm.value.last_name,
      email: this.addGuestInfoForm.value.email,
      phone_number: this.addGuestInfoForm.value.phone,
      mobile_number: this.addGuestInfoForm.value.mobile,
      address: this.addGuestInfoForm.value.address,
      city: this.addGuestInfoForm.value.city,
      state: this.addGuestInfoForm.value.state,
      zip_code: this.addGuestInfoForm.value.zip,
      need_hotel: this.editHotelType,
      age_type: this.editAgeType,
      guest_of: this.guestDetails.guest_of,
      note: this.guestDetails.note

    };
    this.guestlistService.updateGuest(data).subscribe((result: any) => {

      this._toastr.success(result.message);

    },
      error => {
        this._toastr.error(error.message);
      });

  }

  updateGuest() {

  }

}
