import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorManagerService } from '../services/vendor-manager.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { GuestlistService } from '../services/guestlist.service';
import { PlannersService } from '../services/planners.service';

@Component({
  selector: 'app-vendor-print',
  templateUrl: './vendor-print.component.html',
  styleUrls: ['./vendor-print.component.scss']
})
export class VendorPrintComponent implements OnInit {
  sub: any;
  id: any;
  vendorListData: any;
  allVendors: any;
  vendorCategorys: any;
  headerVenderName: any;
  allEvent: any;
  activeUser: any;
  weddings: any;

  constructor(public route: ActivatedRoute, private VendorManagerService: VendorManagerService,
    private toastr: ToastrService, private guestlistService: GuestlistService, private plannersService: PlannersService) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.getEventList(this.activeUser);
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.sub = this.route.params.subscribe(params => {
      if (params) {
        this.id = params['id'];
        if (params['id']) {
        }
      }
    });
    if (this.activeUser === null) {
    this.getWeddings();
    }
    this.getVendorList(this.id);
    this.getVendorCategorys(this.activeUser.id);
  }
  async getWeddings() {
    var allUsers = await this.plannersService.getWeddings().toPromise();
    this.weddings = allUsers;
    this.plannersService.storeWeddings(this.weddings);
    var weddings = this.weddings.filter((x: any) => x.is_active == 1);
    var result = null;
    if (weddings.length > 0) {
      result = weddings[0];
      this.activeUser = result;      
      this.plannersService.storeActiveUser(result);
    }
  }

  async getEventList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var eventList = await this.guestlistService.getEventList(data).toPromise();
    this.allEvent = eventList.data;
    return eventList.data;
  }
  async getVendorCategorys(weddingId: any) {
    var vendorCategory = await this.VendorManagerService.getVendorCategorys(weddingId).toPromise();
    this.vendorCategorys = vendorCategory.data;
    let headerVenderName = this.vendorCategorys.filter(res => res.id == this.id);
    this.headerVenderName = headerVenderName[0].name;
  }
  // get vendor list categorywise
  getVendorList(categoryId) {
    this.VendorManagerService.getSavedVendorsList(categoryId, null, this.activeUser.id).subscribe((result: any) => {
      this.vendorListData = result;
      for (let index = 0; index < this.vendorListData.length; index++) {
        this.vendorListData[index].vendorImage = Config.imageUrl + 'images/vendor_thumb_images/' + this.vendorListData[index].search_thumbs_image;
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }

  // get all categories vendor list 
  async filterVendors() {
    var getAllVendors = await this.VendorManagerService.getAllVendorList().toPromise();
    this.allVendors = getAllVendors;
    for (let index = 0; index < getAllVendors.length; index++) {
      getAllVendors[index].vendorImage = Config.imageUrl + 'images/vendor_thumb_images/' + getAllVendors[index].search_thumbs_image;
    }
  }

  print() {
    window.print();
  }

  back(){
    window.history.back();
  }
}
