import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsModel } from 'src/app/models/settings.model';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { LocationModel } from 'src/app/models/location.model';
import { CategoryModel } from 'src/app/models/category.model';
import { VendorsService } from '../services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';
import { RequestPricingComponent } from '../request-pricing/request-pricing.component';
import { AgmCoreModule, MapsAPILoader, GoogleMapsAPIWrapper, AgmMap } from '@agm/core';

declare var $: any;
declare var google: any;
@Component({
  selector: 'app-vendor-listing-map',
  templateUrl: './vendor-listing-map.component.html',
  styleUrls: ['./vendor-listing-map.component.css']
})
export class VendorListingMapComponent implements OnInit {

  vendors : any;
  args: any;

  geocoder:any;
  submitted = false;
  cities: any;
  lat: number;
  long: number;
  zoom : number;
  iconUrl : string;
  @ViewChild(AgmMap) map: AgmMap;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private locationService: LocationsService,
    private toastr:ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute,
    public router: Router) {
    //this.loginForm = formBuilder.getForm();
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }
  
  ngOnInit() {
    this.lat = 22.5726;
    this.long = 88.3639;
    this.zoom = 16;
    this.iconUrl = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";

    this.route.params.subscribe(params => {
      this.args = params;
      //console.log(params);
      this.vendors = this.searchVendor(this.args);
      console.log(this.vendors);
    })
    
    
  }

  searchVendor(args){
    /* var markers = [
      { lat: 22.5000, long: 88.0000 },
      { lat: 22.2000, long: 77.1000 },
      { lat: 22.3000, long: 77.2500 }
    ] 
    return markers;*/
    this.vendorService
      .searchVendor(args)
      .subscribe(
        (response:[]) => { 
          this.vendors = response;
          console.log(this.vendors);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

  requestPricing(vendor_id:number){
    //this.vendor_id = vendor_id;
    $("#vendor_id").val(vendor_id);
    $("#requestpricing").modal();
  }

}
