import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingsModel } from 'src/app/models/settings.model';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { LocationModel } from 'src/app/models/location.model';
import { CategoryModel } from 'src/app/models/category.model';
import { VendorsService } from '../services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';

declare var $: any;
@Component({
  selector: 'app-top-vendor-search',
  templateUrl: './top-vendor-search.component.html',
  styleUrls: ['./top-vendor-search.component.css']
})
export class TopVendorSearchComponent implements OnInit, OnDestroy {

  venue: string;
  vendors: any;
  cities: any;
  city: any;
  categories: any;
  category: any;
  recieveCategory: any;
  route: any;
  recieveCity: any;


  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private locationService: LocationsService,
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute,
    public router: Router) {
    //this.loginForm = formBuilder.getForm();
    this.city =null;
    this.category =null;
    this.vendorService.searchClick.subscribe(
      res => {
        this.recieveCategory = res;
        if(res){
          if (this.recieveCategory.data != false) {
            this.category=this.recieveCategory.category;
            this.city=this.recieveCategory.city;
  
          }
        }      

      },
      error => {
      });
  }

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      if(params.category && params.city){
        this.category=params.category;
        this.city=params.city;  
      }
    });

    $(".search_icon").click(function (event) {
      event.preventDefault();
      $("#open_search").toggle(200);
      $("#open_1").hide(200);
      $("#open_2").hide(200);
    });

    $(".search_div").click(function (event) {
      event.preventDefault();
      $("#open_1").toggle(200);
      $("#open_2").hide(200);

    });

    $(".search_div_2").click(function (event) {
      event.preventDefault();
      $("#open_1").hide(200);
      $("#open_2").toggle(200);
    });

    this.locationService
      .getTopCities()
      .subscribe(
        (response: LocationModel[]) => {
          for (var k = 0; k < response.length; k++) {
            response[k]['citystate'] = response[k].name + ", " + response[k].state_id;
          }
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      );

    this.vendorService
      .allCategories("")
      .subscribe(
        (response: CategoryModel[]) => {
          this.categories = response;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  searchVendor() {
    let filter ={
      category:this.category,
      city:this.city
    };
    this.vendorService.setSearchFilter(filter);
    //alert($("#city").val()+" = "+$("#category").val());
    // console.log(this.city.charAt(0));
    if (this.category == "") {
      this.toastr.error("Wedding category can not be blank.");
      return false;
    }
    if (this.city.trim() == "") {
      this.toastr.error("Wedding city can not be blank.");
      return false;
    }
    else {

      this.router.navigate(['vendor/listings/' + this.category + '/' + this.city + '/']);
    }
  }

  getCategories(ele: any) {
    this.category = ele.target.value.trim();
    this.vendorService
      .allCategories(this.category)
      .subscribe(
        (response: CategoryModel[]) => {
          this.categories = response;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  getLocations(ele: any) {
    //console.log(ele.target.value.charAt(0));return;
    //alert(ele.target.value.trim());
    if (ele.target.value.charAt(0) == " ") {
      return;
    }
    if (ele.target.value.length < 1) {
      this.cities = "";
      //this.toastr.error("Minimum 3 character required for city.");
      return;
    }
    this.city = ele.target.value;
    this.locationService
      .getCities(this.city)
      .subscribe(
        (response: LocationModel[]) => {

          for (var k = 0; k < response.length; k++) {
            response[k]['citystate'] = response[k].name + ", " + response[k].state_id;
          }
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      );
  }

  selectVenuVendor(ele: any, type: any) {
    if (type == 'city') {
      this.city = ele;
      $("#city").val(ele);
      $("#open_2").hide();
    }
    if (type == 'category') {
      this.category = ele;
      $("#category").val(ele);
      $("#open_1").hide();
    }


  }

  ngOnDestroy(): void {
    this.vendorService.removeFilter();
  }
  showSeachIcon(){

  }
}
