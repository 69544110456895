
import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { MustMatch } from '../../password.match';
import { EmployeeModel } from 'src/app/models/employee.model';
import { PriceModel } from 'src/app/models/price.model';
import { RequestPriceModel } from 'src/app/models/request.price.model';
import { CustomValidator } from '../../../customvalidation';

@Injectable({
    providedIn: 'root'
})
export class RequestPriceFormBuilder{ 

    private form;
    PhoneRegEx = "[0-9]{3}-[0-9]{3}-[0-9]{4}";
    private validationRules = {      

        name: ['',Validators.required],
        email: ['',Validators.required],
        phone: ['',Validators.compose([Validators.required, Validators.pattern(this.PhoneRegEx)])],
        event_date: ['',Validators.required],
        no_of_guest: ['',Validators.required,[CustomValidator.numeric]],
        message: ['', Validators.required],
        preferred_contact: ['',Validators.required],
        vendor_id: ['',''],
        sender_id: ['','']  ,
        mode: ['',''],
        subject: ['','']
    }

    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }
   
}