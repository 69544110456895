import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class MessageFormBuilder{

    private form;
  
    private validationRules = {        
        search:['',''],
        start_date: ['',''],
        end_date: ['','']       
    }

    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }
    
}