import { Component, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { Router } from '@angular/router';
import { BudgetlistService } from '../services/budgetlist.service';
import { PlannersService } from '../services/planners.service';

@Component({
  selector: 'app-budget-print',
  templateUrl: './budget-print.component.html',
  styleUrls: ['./budget-print.component.scss']
})
export class BudgetPrintComponent implements OnInit {
  activeUser: any;
  printData: any;
  weddings: any;

  constructor(public router: Router,
    private plannersService: PlannersService, private budgetListService: BudgetlistService) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
  }

  async ngOnInit() {
    if (this.activeUser === null) {
      await this.getWeddings();
    }
    await this.getPrintData();
  }

  async getWeddings() {
    var allUsers = await this.plannersService.getWeddings().toPromise();
    this.weddings = allUsers;
    this.plannersService.storeWeddings(this.weddings);
    var weddings = this.weddings.filter((x: any) => x.is_active == 1);
    var result = null;
    if (weddings.length > 0) {
      result = weddings[0];
      this.activeUser = result;
      this.plannersService.storeActiveUser(result);
    }
  }

  async getPrintData() {
    var result = await this.budgetListService.getPrintData(this.activeUser.id).toPromise();
    this.printData = result;
  }
  elements: any = [
    { id: 1, first: 'Mark', last: 'Otto', handle: '@mdo' },
    { id: 2, first: 'Jacob', last: 'Thornton', handle: '@fat' },
    { id: 3, first: 'Larry', last: 'the Bird', handle: '@twitter' },
  ];

  headElements = ['ID', 'First', 'Last', 'Handle'];

  print() {
    window.print();
  }

  back(){
    window.history.back();
  }
}
