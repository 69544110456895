import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { BackendPageComponent } from 'src/app/backend-page/backend-page.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { AnonymousPageComponent } from 'src/app/anonymous-page/anonymous-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { MenuLeftComponent } from './menu-left/menu-left.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { RegisterComponent } from './register/register.component';

var usersRoutes = [
  { path: 'user/login', component: LoginComponent },
        { path: 'admin/login', component: AnonymousPageComponent, 
          children:[
            {path: '', component: LoginComponent, outlet: "anonymous-area"}
          ]
        },
        { path: 'admin/forgot-password', component: AnonymousPageComponent, 
          children:[
            {path: '', component: ForgotPasswordComponent, outlet: "anonymous-area"}
          ]
        },
        { path: 'admin/dashboard', component: BackendPageComponent, 
          children:[
            {path: '', component: DashboardComponent, outlet: "admin-area"}
          ]
        },
        { path: 'admin/profile', component: BackendPageComponent, 
          children:[
            {path: '', component: ProfileComponent, outlet: "admin-area"}
          ]
        }
      ];

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, DashboardComponent, ProfileComponent, MenuLeftComponent, TopNavComponent, RegisterComponent],
  imports: [
    RouterModule.forChild(usersRoutes),
    CommonModule
  ],
  exports:[MenuLeftComponent,TopNavComponent]
})
export class UsersModule { }
