import { Deseriazable } from './deserializable.model';

export class ChangePasswordModel implements Deseriazable{
    
    id: string;
    token: string;
    old_password: string;
    new_password: string;
    confirm_password: string;
    message: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}