import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PlannersService } from '../../planners/services/planners.service';
import { DiscussionService } from '../services/discussions.service';
import { Config } from '../../../config';
import { SortablejsOptions } from 'angular-sortablejs';
declare var $: any;

@Component({
  selector: 'app-create-discussion',
  templateUrl: './create-discussion.component.html',
  styleUrls: ['./create-discussion.component.scss']
})
export class CreateDiscussionComponent implements OnInit {

  activeUser: any = null;
  category_details: any;
  config = {
    height: '6em',
    toolbar: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'links', items: ['Link', 'Unlink','Blockquote'] },
      { name: 'insert' },
      { name: 'styles', items: ['Styles', 'Format', 'Font'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
    ],
    toolbarHiddenButtons: [
      ['fontName', 'insertUnorderedList',
        'insertOrderedList', 'Table', 'FontSize', 'Strike', 'Subscript',
        'Superscript', '-', 'CopyFormatting', 'RemoveFormat', 'CreateDiv', 'Anchor']
    ],
  };
  // Mini CK Editor Config --
  miniCKEditorConfig = {
    height: '6em',
    toolbar: [
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Link', 'Unlink', 'EmojiPanel'] },
      // { name: 'links', items: ['Link'] },
      // { name: 'paragraph', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
    ]
  };
  discussion_image_url: string = Config.imageUrl + 'images/discussion_media_images/';
  isGalleryPhotosSortDisabled:boolean = true;
  galleryPhotoOrderOptions: SortablejsOptions = {
    disabled: this.isGalleryPhotosSortDisabled,
    onEnd: () => {
      this.discussion_images = this.discussion_images.filter(image => image !== null && image !== undefined);
    }
  }
  checkedPhotos: any = [];
  singleDeleteImg: any;
  showRemoveButton: boolean = false;
  editorVisible: boolean = false;
  discussionCategories: any[] = [];
  current_accordion: number = 0;

  // Discussion form --
  discussion_category_id: number = 0;
  discussion_title: string = "";
  discussion_comment: string = "";
  discussion_images: any[] = [];

  isModalVisible = false;
  newSlug = '';
  constructor(
    private plannersService: PlannersService,
    private discussionService: DiscussionService,
    public route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  async ngOnInit() {
    await this.getDiscussionCategories();
    setTimeout(()=>{this.editorVisible = true}, 1000);
    $('#confirmDeleteModal').modal('hide');
  }

  async postDiscussion(){
    if(!this.discussion_title){
      this.toastr.error("Please provide title!");
      return;
    }
    if(Number(this.discussion_category_id) === 0){
      this.toastr.error("Please choose a topic");
      return;
    }

    this.discussion_images.forEach((image, index) => {
      image.display_order = index + 1;
    });

    let formData = {
      "discussion_title": this.discussion_title,
      "discussion_details": this.discussion_comment,
      "wed_category_id": Number(this.discussion_category_id),
      "discussion_images": this.discussion_images
    };

    try{
      var response = await this.discussionService.createDiscussion(formData).toPromise();
      this.isModalVisible = true;
      this.newSlug = response.data.slug;
      this.toastr.success(response.message);
      this.resetCreateDiscussionForm();
    }catch(error){
      this.toastr.error(error.message);
    }
  }
  resetCreateDiscussionForm(){
    this.discussion_title = "";
    this.discussion_comment = "";
    this.discussion_images = [];
    this.discussion_category_id = 0;
  }
  getSRCofImageData(discussion_image: any){
    if(discussion_image.imageData){
      return discussion_image.imageData;
    }else if(discussion_image.file_name){
      return this.discussion_image_url + discussion_image.file_name;
    }else{
      return "";
    }
  }
  fileChangeEvent(event: any): void {
    var allowed_file_types = ["image/jpeg", "image/jpg", "image/gif", "image/png"];
    var multipleFiles = event.target.files;
    if (multipleFiles) {
      let image_skipped = 0;
      for (var file of multipleFiles) {
        if (!(file.size > (1024 * 100) && file.size < (1024 * 1024 * 5))) {
          image_skipped++;
          continue;
        }
        if (!allowed_file_types.includes(file.type)) {
          image_skipped++;
          continue;
        }
        var multipleReader = new FileReader();
        multipleReader.onload = (e: any) => {
          this.discussion_images = [...this.discussion_images, {
            'type': 'image',
            'imageData': e.target.result
          }];
        }
        multipleReader.readAsDataURL(file);
      }

      if (image_skipped > 0) {
        if (image_skipped == 1) {
          this.toastr.error(`${image_skipped} image failed to upload. Images must be jpeg, jpg, gif, or png files, and between 100KB and 5MB`);
        } else {
          this.toastr.error(`${image_skipped} images failed to upload. Images must be jpeg, jpg, gif, or png files, and between 100KB and 5MB`);
        }
      }
    }
  }
  openImageUploader(){
    $("#upload_file_input").click();
  }
  async getDiscussionCategories() {
    var categories = await this.discussionService.getDiscussionCategories().toPromise();
    this.discussionCategories = categories;
  }
  openAccordion(number: number){
    if(this.current_accordion === number){
      this.current_accordion = 0;
    }else{
      this.current_accordion = number;
    }
  }

  toggleGalleryPhotosSort(){
    this.isGalleryPhotosSortDisabled = !this.isGalleryPhotosSortDisabled;
    this.galleryPhotoOrderOptions = {
      disabled: this.isGalleryPhotosSortDisabled,
      onEnd: () => {
        this.discussion_images = this.discussion_images.filter(image => image !== null && image !== undefined);
      }
    }
  }
  singleSavedChecked(e: any, id: any) {
    if (e.target.checked == true) {
      this.checkedPhotos.push(id);
      this.showRemoveButton = true;
    } else {
      const index = this.checkedPhotos.indexOf(id);
      this.checkedPhotos.splice(index, 1);
      if (this.checkedPhotos.length == 0) {
        this.showRemoveButton = false;
      }
    }
  }

  openConfirmDeleteModal(file: any) {
    if(file != -1){
      this.singleDeleteImg = file;
    }
    $('#confirmDeleteModal').modal('show');
  }
  deleteDiscussionImage(){
    if(this.singleDeleteImg){
      let index = this.discussion_images.indexOf(this.singleDeleteImg);
      this.discussion_images.splice(index, 1);
      this.singleDeleteImg = undefined;
    }else{
      this.checkedPhotos.forEach(e => {
        let index = this.discussion_images.indexOf(e);
        this.discussion_images.splice(index, 1);
      });
      this.checkedPhotos = [];
    }
    $('#confirmDeleteModal').modal('hide');
  }
}
