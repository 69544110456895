import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../password.match';
import { DealModel } from 'src/app/models/deal.model';
import { CustomValidator } from '../../../customvalidation';

@Injectable({
    providedIn: 'root'
})
export class DealFormBuilder{

    private form;
    private validationRules = {        
        id:['',''],
        deal_name: ['',Validators.required],
        deal_type: ['',Validators.required],
        deal_value: ['', [CustomValidator.numeric]],
        image_name: ['',''],
        description: ['', Validators.required],
        expire_date: ['', Validators.required],
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:DealModel){
        this.form.patchValue({
            id:response.id,
            deal_name:response.deal_name,
            deal_type:response.deal_type,
            deal_value:response.deal_value,
            description:response.description,
            expire_date:response.expire_date,
        });
    }
}