import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrontPageComponent } from './front-page/front-page.component';
import { CommingSoonComponent } from './comming-soon/comming-soon.component';
import { BackendPageComponent } from './backend-page/backend-page.component';

const routes: Routes = [
  { path: '', component: FrontPageComponent },
  { path: 'biz', component: FrontPageComponent,
    children:[
      { path:':id',component: BackendPageComponent }
    ]
  },
  { path:'admin',component: BackendPageComponent },
  { path: '**', component: CommingSoonComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
