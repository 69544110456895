import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from '../modules/users/services/users.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private _usersService: UsersService, 
    private _router: Router, 
    private _tosterService:ToastrService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._usersService.isAuthenticated()) {
      const user_role = this._usersService.getCurrentUserRole();
      if(next.data.roles.indexOf(user_role)>-1){
        return true;
      }else{
        this._tosterService.error("Sorry! Your are not authorize to access this page.")
        this._router.navigate(['/']);
        return false;
      }
    }    
    this._router.navigate(['login'],{ queryParams: { returnUrl: state.url }});
    return false;
    
  }
}
