import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';
import { Config } from '../config';
import { UsersService } from '../modules/users/services/users.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService implements CanActivate {

  constructor(private _usersService: UsersService, private _router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this._usersService.isAuthenticated()) {
      //window.location.href=Config.baseUrl+"vendor/home";
      this._router.navigate(['/']);
      return false;
    }
    return true;
  }
}
