import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ChangePasswordFormBuilder } from './change-password.form-builder';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ChangePasswordModel } from 'src/app/models/change-password.model';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  submitted = false;
  model = new ChangePasswordModel();
  changePasswordForm: any;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: ChangePasswordFormBuilder,
    private toastr:ToastrService,
    public router: Router) {
  }
  ngOnInit() {
    this.changePasswordForm = this.formBuilder.getForm();
    window.scroll(10,10);            
  }

  get f() { return this.changePasswordForm.controls; }

  onSubmit() {   

    this.submitted = true;
    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
      return;
    }

    this.model.deserialize(this.changePasswordForm.value);

    this.vendorService
      .changePassword(this.model)
      .subscribe(
        (response:ChangePasswordModel) => { 
          this.toastr.success(response.message);           
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

}
