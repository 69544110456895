import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { MessageModel } from 'src/app/models/message.model';
import { MessageFormBuilder } from './message.form-builder';

declare var $: any;


@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  messages: any;
  submitted = false;
  model = new MessageModel();
  messageForm: any;
  args: any;
  showMessage: any;
  messageStatus: string;
  mid: number;
  leadid: number;
  replyMessage: string;
  messageDetails = { "id": "", "created_at": "", "lead_name": "", "email": "", "phone": "", "event_date": "", "no_of_guest": "", "preferred_contact": "", "show_in_inbox": "", "status": "", "message": "", "forward_messge_flag": "" };
  total_records: number = 0;
  btnReset: boolean = false;

  replyTexts: any;
  messageCurrentPage: number = 1;
  pagination_per_Page: number = Config.pagination_per_Page;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: MessageFormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }

  ngOnInit() {

    this.fetchParams();
    this.messageForm = this.formBuilder.getForm();
    this.getInboxMessage();
    this.replyMessage = "n/a";
    this.btnReset = false;
  }

  get f() { return this.messageForm.controls; }

  fetchParams() {
    this.route.params.subscribe(params => {
      this.args = params['name'];
      if (this.args == 'read' || this.args == 'unread') {
        this.model.status = "";
        this.model.type = this.args;
      } else if (this.args == 'pending' || this.args == 'replied' || this.args == 'booked' || this.args == 'discarded' || this.args == 'sent') {
        this.model.status = this.args;
        if (this.args == 'sent') {
          this.model.status = "replied";
        }
        this.model.type = "";
      } else {
        this.model.status = "";
        this.model.type = "";
      }
      this.btnReset = false;
      $(".navbar-collapse").removeClass('show');
      this.getInboxMessage();
    });
  }
  onSubmit() {

    this.model.deserialize(this.messageForm.value);
    if (this.messageForm.value.start_date) {
      this.model.start_date = this.usDateToDb(this.messageForm.value.start_date);
    }
    if (this.messageForm.value.end_date) {
      this.model.end_date = this.usDateToDb(this.messageForm.value.end_date);
    }

    if (this.messageForm.value.start_date && this.messageForm.value.end_date) {
      if (this.messageForm.value.start_date > this.messageForm.value.end_date) {
        this.toastr.error("End date can't be earlier than start date");
        return;
      }
    }


    this.vendorService
      .getInboxMessage(this.model)
      .subscribe(
        (response: MessageModel[]) => {
          this.messages = response;
          this.total_records = response.length;
          this.btnReset = true;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  getInboxMessage() {
    this.vendorService
      .getInboxMessage(this.model)
      .subscribe(
        (response: MessageModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i].subject = response[i].subject.replace("Message from ", "").toUpperCase();
          }
          this.messages = response;

        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  showMessageModal(obj: any) {
    //console.log(1111,obj.status);
    this.replyTexts = obj.reply.messages;
    this.messageDetails = obj;
    this.showMessage = obj.message;
    this.messageStatus = obj.leadstatus;
    this.mid = obj.id;
    var data = {
      lead_id: obj.lead_id
    }
    this.vendorService
      .lastReply(data)
      .subscribe(
        (response: MessageModel) => {
          //this.toastr.success(response.message);
          if (response.created_at) {
            this.replyMessage = response.created_at;
          } else {
            this.replyMessage = "n/a";
          }
          this.getInboxMessage();
          $('input[value=' + this.messageStatus + ']').prop("checked", true);
          $('input[value=' + obj.status + ']').prop("checked", true);
          $("#messagedetails").modal('show');
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )

  }

  messageModal(obj: any, arg: string) {
    this.leadid = obj.lead_id;
    this.messageDetails = obj;
    $("#messagedetails").toggle();
    if (arg == "reply") {
      $("#replyMessage").toggle();
    } else if (arg == "forward") {
      $("#forwardMessage").toggle();
    }

  }

  changeStatus(ele: any, id: number) {
    var data = {
      message_id: id,
      status: ele.target.value
    }
    this.vendorService
      .changeStatus(data)
      .subscribe(
        (response: MessageModel) => {
          this.toastr.success(response.message);
          this.getInboxMessage();
          $("input[type='radio']").prop("checked", false);
          $("#messagedetails").modal('hide');
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  changeType(obj: any, id: number) {
    //console.log(obj.target.value);return;
    this.showMessage = obj.message;
    this.messageStatus = obj.leadstatus;
    var data = {
      message_id: id,
      type: obj.target.value
    }
    this.vendorService
      .changeType(data)
      .subscribe(
        (response: MessageModel) => {
          // console.log(response.data);return;
          this.toastr.success(response.message);
          //console.log(obj.message+"=="+obj.leadstatus);return;
          this.showMessage = response.data.message;
          this.messageStatus = response.data.leadstatus;
          this.getInboxMessage();
          $("input[type='radio']").prop("checked", false);
          $("#messagedetails").modal('hide');
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  sendMessage(lead_id: number) {
    if ($("#message").val() == "") {
      this.toastr.error("Message can not be blank.");
      return;
    }
    //alert(lead_id+"=="+$("#message").val());       
    var data = {
      lead_id: this.leadid,
      message: $("#message").val(),
      receiver_id: 0
    }
    this.vendorService
      .replyMessage(data)
      .subscribe(
        (response: MessageModel) => {
          this.toastr.success(response.message);
          this.getInboxMessage();
          $("#message").val('');
          this.closePopup('reply');
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }


  sendFowardMessage() {
    if ($("#emails").val() == "") {
      this.toastr.error("Emails can not be blank.");
      return;
    }

    var emails = $("#emails").val().replace(/\s/g, '').split(",");
    var valid = true;
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (var i = 0; i < emails.length; i++) {
      if (emails[i] == "" || !regex.test(emails[i])) {
        valid = false;
      }
    }

    if (!valid) {
      this.toastr.error("Please check the email format.");
      return;
    }


    var data = {
      lead_id: this.leadid,
      thread: this.messageDetails,
      emails: $("#emails").val(),
      message: $("#fmessage").val()
    }

    this.vendorService
      .fowardMessage(data)
      .subscribe(
        (response: MessageModel) => {
          this.toastr.success(response.message);
          this.getInboxMessage();
          $("#fmessage").val('');
          $("#emails").val('');
          $("#messagedetails").modal('hide');
          $("#forwardMessage").modal('hide');
          $("#forwardMessage").hide();
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  showInbox(mid, ele: any) {
    var data = { mid: mid, show_in_inbox: ele.target.checked };
    this.vendorService
      .showInbox(data)
      .subscribe(
        (response: MessageModel) => {
          //this.toastr.success(response.message);
          $("#messagedetails").modal('hide');
          this.getInboxMessage();
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  usDateToDb(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  resetForm() {
    this.submitted = false;
    this.btnReset = false;
    this.messageForm.reset();
    this.model.start_date = "";
    this.model.end_date = "";
    this.model.search = "";
    this.fetchParams();
  }

  showDeletePopup() {
    $("#deletemessage").modal();
  }

  deleteMessage() {
    var delete_ids = [];
    $("tbody input[type=checkbox]:checked").each(function () {
      delete_ids.push($(this).val());
    });
    //console.log(delete_ids);
    if (delete_ids.length == 0) {
      this.toastr.error("Please select message(s) to delete.");
      return;
    }
    //console.log(delete_ids);return;
    this.vendorService
      .deleteMessage(delete_ids)
      .subscribe(
        (response: MessageModel) => {
          $('#deletemessage').modal('toggle');
          this.toastr.success(response.message);
          this.fetchParams();
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  closePopup(arg: string) {
    $("#messagedetails").modal('hide');
    if (arg == 'forward') {
      $("#forwardMessage").modal('hide');
      $("#forwardMessage").hide();
    } else if (arg == 'reply') {
      $("#replyMessage").modal('hide');
      $("#replyMessage").hide();
    }

  }
}
