import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PlannersService } from '../services/planners.service';
import { GuestlistService } from '../services/guestlist.service';
import { VendorManagerService } from '../services/vendor-manager.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LocationsService } from '../../locations/services/locations.service';
import { LocationModel } from 'src/app/models/location.model';
import { RegionModel } from 'src/app/models/region.model';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { VendorsService } from '../../vendors/services/vendors.service';
import { Location } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-vendor-category',
  templateUrl: './vendor-category.component.html',
  styleUrls: ['./vendor-category.component.scss']
})
export class VendorCategoryComponent implements OnInit {

  notes_mode: boolean = false;
  view_mode: boolean = true;
  phone_mode: boolean = false;
  phone_hide: boolean = false;
  sub: any;
  id: any;
  activeUser: any;
  allEvent: any;
  vendorCategorys: any;
  vendorName: any;
  vendorCategoryCount: any;
  totalVendorsHiredCount: any;
  vendorListData: any;
  headerVenderName: any
  totalVendorInCategory: number = 0;
  totalHiredVendorInCategory: number = 0;
  vendorCategoryId: any;
  searchVendorName: any;
  selectVendorId: any;
  toggle: boolean = false;
  VendorCategoryDrop: boolean = true;
  VendorCategory: boolean = false;
  VendorCategoryName: any = '';
  searchValue: any;
  selectedVendor: any;
  searchDiv: boolean = false;
  selectedSaveVendor: any;
  saveVendorList: boolean = false;
  selectedHairedVendor: any;
  saveVendorIsPresent: boolean = false;
  hairedVendorIsPresent: boolean = false;
  showSelectedVendor: boolean = false;
  VendorChecked: any = 2;
  hired: number;
  chooseVendor: boolean[] = [];
  openConfirm: boolean = false;
  vendor_submitted: boolean = false;
  submitted = false;
  selectCategoryId: any;
  public AddVendorForm: FormGroup;
  emailRegEx = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  cities: any;
  regions: any;
  totalVendorCategoryCount: any;
  totalActiveVendorCount: any;
  totalCategoryHiredCount: any;
  filterVendorCategorys: any;
  vendorCategoryData: any;
  HiredVendorData: any;
  completed: number;
  vendor: boolean = false;
  savedVendor: boolean = false;
  vendorSelectImage: string;
  statusListData: any;
  selectedId: any;
  selectedSavedVendorId: any;
  city: any;
  category: string;
  showAllVendors: boolean = false;
  showVendors: boolean = true;
  allVendors: Promise<any>;
  statusCountData: object;
  price: any;
  vendorCategoryIds: any;
  contactData: any;
  contactVendorName: any;
  vendorIcon: any;
  editReviewButton: boolean = false;
  disabledData: boolean = false;
  vendorNotNeeded: boolean = false;
  
  vendorPhotosUrl = Config.imageUrl + 'images/vendor_thumb_images/';
  constructor(public router: Router, private toastr: ToastrService, public route: ActivatedRoute, private plannersService: PlannersService,
    private guestlistService: GuestlistService, private locationService: LocationsService, private _location: Location,
    private VendorManagerService: VendorManagerService, public formBuilder: FormBuilder, private vendorService: VendorsService,
  ) {
    this.AddVendorForm = this.formBuilder.group({
      vendor_name: ['', Validators.required],
      category_name: ['', Validators.required],
      city_id: ['', Validators.required],
      state_id: ['', Validators.required],
      phone_number: [''],
      email: ['', Validators.compose([Validators.pattern(this.emailRegEx)])],
    });
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.getEventList(this.activeUser);
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.sub = this.route.params.subscribe(params => {
      if (params) {
        this.id = params['id'];
        if (params['id']) {
          this.statusCount(this.activeUser.id);
        }
      }
    });

    this.getVendorList(this.id)
    this.getVendorCategorys(this.activeUser.id);
    this.vendorStatusList();
    $('#addVendorModal').on('hidden.bs.modal', () => {
      this.searchVendorName = null;
      this.searchVendorName = '';
      this.toggle = false;
    });
    this.locationService
      .getRegions("")
      .subscribe(
        (response: RegionModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.regions = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  getLocations(ele: any) {
    //console.log(ele.target.value.charAt(0));return;
    //alert(ele.target.value.trim());
    if (ele.target.value.charAt(0) == " ") {
      return;
    }
    if (ele.target.value.length < 1) {
      this.cities = "";
      //this.toastr.error("Minimum 3 character required for city.");
      return;
    }
    this.city = ele.target.value;
    this.locationService
      .getCities(this.city)
      .subscribe(
        (response: LocationModel[]) => {

          for (var k = 0; k < response.length; k++) {
            response[k]['citystate'] = response[k].name + ", " + response[k].state_id;
          }
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      );
  }
  async vendorNotNeededCheckboxClick(){
    let formData = {
      vendorNotNeeded: this.vendorNotNeeded,
      categoryId: parseInt(this.id),
      weddingId: this.activeUser.id
    };
    var result = await this.VendorManagerService.updateVendorNotNeededStatus(formData).toPromise();
    this.getVendorCategorys(this.activeUser.id);
    this.toastr.success(result.message);
  }
  searchVendorWithLocation(categoryName: string, cityName: string) {
    let filter = {
      category: categoryName,
      city: cityName
    };
    this.vendorService.setSearchFilter(filter);
    if (categoryName == "") {
      this.toastr.error("Wedding category can not be blank.");
      return false;
    }
    if (cityName.trim() == "") {
      this.toastr.error("Wedding city can not be blank.");
      return false;
    }
    else {

      this.router.navigate([]).then(result => {  window.open('vendor/listings/' + categoryName + '/' + cityName + '/', '_blank'); });;
    }
  }
  async getEventList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var eventList = await this.guestlistService.getEventList(data).toPromise();
    this.allEvent = eventList.data;
    return eventList.data;
  }
  // search vendor with category id 
  searchVendorWithId(id: number) {
    this.router.navigate(['/vendor-category', id]);

    this.getVendorCategorys(this.activeUser.id);
    this.getVendorList(id);
    this.showAllVendors = false;
    this.showVendors = true;

    // Vendor not needed --
    if(this.vendorCategorys[id].vendor_not_needed === null || this.vendorCategorys[id].vendor_not_needed === 0){
      this.vendorNotNeeded = false;
    }else if(this.vendorCategorys[id].vendor_not_needed === 1){
      this.vendorNotNeeded = true;
    }
  }
  // get vendor category list 
  async getVendorCategorys(weddingId: any) {
    var vendorCategory = await this.VendorManagerService.getVendorCategorys(weddingId).toPromise();
    this.vendorCategorys = vendorCategory.data;
    let headerVenderName = this.vendorCategorys.filter((res: any) => res.id == this.id);
    this.headerVenderName = headerVenderName[0].name;
    this.vendorCategoryIds = headerVenderName[0].id;
    this.vendorIcon = Config.imageUrl + 'images/vendor_category_icons/black_icons/' + headerVenderName[0].icon_name;

    if(this.showVendors){
      this.totalVendorInCategory = headerVenderName[0].total_vendors;
      this.totalHiredVendorInCategory = headerVenderName[0].total_vendors_hired_by_user;
    }

    // Vendor not needed --
    this.vendorCategorys.forEach((category: any)=>{
      if(category.id === parseInt(this.id)){
        if(category.vendor_not_needed === null || category.vendor_not_needed === 0){
          this.vendorNotNeeded = false;
        }else if(category.vendor_not_needed === 1){
          this.vendorNotNeeded = true;
        }
      }
    });
  }
  async getVendorCategorysCount(weddingId: any) {
    var Count = await this.VendorManagerService.getVendorCategorysCount(weddingId).toPromise();
    this.vendorCategoryCount = Count;
    this.totalVendorCategoryCount = this.vendorCategoryCount.total_vendor_category;
    this.totalActiveVendorCount = this.vendorCategoryCount.total_active_vendor;
    this.totalVendorsHiredCount = this.vendorCategoryCount.total_vendors_saved_by_user;
    this.totalCategoryHiredCount = this.vendorCategoryCount.total_category_hired_by_user;
    if (this.vendorCategoryCount.total_vendor_category != 0) {
      var p = this.vendorCategoryCount.total_category_hired_by_user / this.vendorCategoryCount.total_vendor_category * 100;
    } else {
      var p = 0;
    }
    this.completed = Math.round(p);
  }
  getVendorList(categoryId: number) {    
    this.VendorManagerService.getSavedVendorsList(categoryId, null, this.activeUser.id).subscribe((result: any) => {
      this.vendorListData = result;      
      for (let index = 0; index < this.vendorListData.length; index++) {
        this.vendorListData[index].vendorImage = Config.imageUrl + 'images/vendor_thumb_images/' + this.vendorListData[index].search_thumbs_image;
        this.vendorListData[index].vendor_notes = false;
        this.vendorListData[index].vendor_contact = false;
        this.vendorListData[index].vendor_hide = false;
        this.vendorListData[index].vendor_price = true;
        this.vendorListData[index].phone_number = this.formatPhoneNumber(this.vendorListData[index].phone_number)
        if (this.vendorListData == 0) {
          this.disabledData = true;
        }
        if(this.vendorListData[index].hired == 1){
          var data = {
            vendors: [this.vendorListData[index].vendor_id],
            toStatus: 6
          }
          this.VendorManagerService.vendorUpdateStatus(data).subscribe((result: any) => {
            this.getVendorCategorys(this.activeUser.id);
            this.getVendorCategorysCount(this.activeUser.id);
            this.statusCount(this.activeUser.id);
          }, error => {
            this.toastr.error(error.message);
          })
        }
      }
    }, error => {
      this.toastr.error(error.message);
    });
  }
  async showAllVendorsList() {
    this.showAllVendors = true;
    this.showVendors = false;
    this.getVendorList(null);
  }
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  }
  vendorProfile(data: any) {
    if (data.vendor_details && data.vendor_details.slug) {
      this.router.navigate([]).then(result => {  window.open('/profile' + '/' + data.vendor_details.slug ); });;

      // this.router.navigate(['/profile/', data.vendor_details.slug]);
    } else {
      $('#profileModal').modal('toggle'); 
    }
  }
  async vendorStatusList() {
    var statusList = await this.VendorManagerService.getVendorStatusList().toPromise();
    this.statusListData = statusList;
    this.statusListData = this.statusListData.filter(e => e.name != 'Saved')
  }
  statusCount(weddingId) {
    let data = {
      weddingId: weddingId,
      categoryId: parseInt(this.id)
    }
    this.VendorManagerService.vendorStatusCount(data).subscribe((result: any) => {
      this.statusCountData = result.data;
    }, error => {
      this.toastr.error(error.message);
    })
  }
  changeVendorStatus(savedVendor: any, event: any) {
    let selectedHiredStatus = event.target.value;
    var formData = {
      vendors: [savedVendor.id],
      toStatus: parseInt(selectedHiredStatus),
      price: savedVendor.price,
      note: savedVendor.note,
      categories: savedVendor.category_id
    };
    this.VendorManagerService.vendorUpdateStatus(formData).subscribe((result: any) => {
      this.getVendorCategorys(this.activeUser.id);
      this.getVendorCategorysCount(this.activeUser.id);
      this.statusCount(this.activeUser.id);
      this.getVendorList(this.id);
      this.toastr.success("Status Updated!");
    }, error => {
      this.toastr.error(error.message);
    })
  }
  removeVendor(vendorDetails: any) {
    this.selectedSavedVendorId = vendorDetails.id;
    $('#deleteVendor').modal('toggle');
  }
  removeSaveVendor() {
    var data = {
      vendors: [this.selectedSavedVendorId],
      toStatus: -1
    };
    this.VendorManagerService.vendorUpdateStatus(data).subscribe((result: any) => {
      $('#deleteVendor').modal('toggle');
      this.getVendorCategorys(this.activeUser.id);
      this.getVendorCategorysCount(this.activeUser.id);
      this.statusCount(this.activeUser.id);
      this.getVendorList(this.id);
    }, error => {
      this.toastr.error(error.message);
    })
  }
  download1(categoryId) {
    var authkey = localStorage.getItem("USER_TOKEN");
    window.open(`${Config.apiBaseUrl}/vendors/categories/vendors/download/${categoryId}?token=${authkey}`);
    return;

  }
  download(categoryId): void {
    var authkey = localStorage.getItem("USER_TOKEN");
    var hired = 3;
    window.open(`${Config.apiBaseUrl}/vendors/categories/vendors/download?categoryId=${categoryId}&token=${authkey}&hired=${hired}`);
    return;
  }

  print(categoryId) {
    this.router.navigate(['/vendor-print', categoryId]);
  }

  addNotes(index: number) {
    this.vendorListData[index].isVendorNoteFormActive = true;
  }
  addVendorNotes(data, index) {
    this.allVendors[index].vendor_price = false;
    this.allVendors[index].vendor_notes = true;
  }
  goTo_VendorviewMode(data, index) {
    if (data.note == null && data.price == null) {
      this.toastr.error('Please enter notes and price');
      this.allVendors[index].vendor_price = true;
      this.allVendors[index].vendor_notes = false;
      return;
    }
    let updateData = {
      price: parseInt(data.price),
      note: data.note,
      vendors: [data.vendor_id],
      categories: data.category_id,
      toStatus: parseInt(data.hired)
    }
    // return
    this.VendorManagerService.vendorUpdateStatus(updateData).subscribe((result: any) => {
      this.getVendorCategorys(this.activeUser.id);
      this.getVendorCategorysCount(this.activeUser.id);
      this.statusCount(this.activeUser.id)
      this.getVendorList(this.id);
    }, error => {
      this.toastr.error(error.message);
    })
    this.allVendors[index].vendor_price = true;
    this.allVendors[index].vendor_notes = false;
  }
  deactivateVendorNoteForm(index: number) {
    this.vendorListData[index].isVendorNoteFormActive = false;
  }
  saveVendorNote(savedVendor: any){
    if (savedVendor.note == null) {
      this.toastr.error('Please enter note');
      return;
    }else{
      let updateData = {
        price: savedVendor.price,
        note: savedVendor.note,
        vendors: [savedVendor.id],
        categories: savedVendor.category_id,
        toStatus: parseInt(savedVendor.hired)
      };
      // return
      this.VendorManagerService.vendorUpdateStatus(updateData).subscribe((result: any) => {
        this.toastr.success("Note Updated!");
      }, error => {
        this.toastr.error(error.message);
      });
    }
  }
  savePrice(savedVendor: any, index: number){
    if (savedVendor.note == null && savedVendor.price == null) {
      this.toastr.error('Please enter notes and price');
      return;
    }
    if (savedVendor.note != null && savedVendor.price != null) {
      let updateData = {
        price: parseInt(savedVendor.price),
        note: savedVendor.note,
        vendors: [savedVendor.id],
        categories: savedVendor.category_id,
        toStatus: parseInt(savedVendor.hired)
      }
      this.VendorManagerService.vendorUpdateStatus(updateData).subscribe((result: any) => {
        this.getVendorCategorys(this.activeUser.id);
        this.getVendorCategorysCount(this.activeUser.id);
        this.statusCount(this.activeUser.id)
        this.getVendorList(this.id);
        this.toastr.success("Price Updated!");
      }, error => {
        this.toastr.error(error.message);
      })
    }
  }
  reviewVendorWithId(savedVendor: any) {
    if (savedVendor.vendor_details == null) {
      $('#profileModal').modal('toggle');
      return;
    }
    this.router.navigate(['/vendor-ratings', savedVendor.vendor_id]);
  }
  vendorManagerReturnBtn() {
    // this._location.back();
    this.router.navigate(['/vendor-manager'], { queryParams: { value: 'saved' } });
  }
  show_phoneNumber(index: number) {
    this.vendorListData[index].isPhoneNumberAreaActive = true;
  }
  show_phoneNumber1(data, index) {
    this.allVendors[index].vendor_price = false;
    this.allVendors[index].vendor_contact = true;
    this.allVendors[index].vendor_hide = true;
  }
  hide_phoneNumber(index: number) {
    this.vendorListData[index].isPhoneNumberAreaActive = false;
  }
  hide_phoneNumber1(data, index) {
    this.allVendors[index].vendor_price = true;
    this.allVendors[index].vendor_contact = false;
    this.allVendors[index].vendor_hide = false;
  }
  contactModalOpen(savedVendor: any) {
    this.contactVendorName = savedVendor.vendor_details.business_name;
    this.selectedSavedVendorId = savedVendor.vendor_details.vendor_id;
    $('#contactModal').modal('toggle');
  }
  // add vendor
  get g() {
    return this.AddVendorForm.controls;
  }
  addVendorModal() {
    this.VendorCategoryDrop = true;
    $('#addVendorModal').modal('toggle');
  }

  async modelOpen() {
    this.openConfirm = false;
    await this.changeVendorCategory(this.AddVendorForm.value.category_name);
  }
  async changeVendorCategory(e: any) {
    this.vendorCategoryId = e;
    this.VendorCategory = true;
    // this.VendorCategoryDrop = false;
    this.getCategoryName(this.vendorCategoryId);
    // await this.getSavedVendorList(e);
    // await this.getHairedVendorList(e);
  }

  showVendor(e: any) {
    this.vendorCategoryId = e;
    this.showSelectedVendor = true;
    this.VendorCategoryDrop = false;
    this.getCategoryName(this.vendorCategoryId);
  }

  getCategoryName(id: any) {
    var category = this.vendorCategorys.filter((x: any) => x.id == id);
    this.VendorCategoryName = category[0].name;
  }

  vendorYes(e: number) {
    this.VendorChecked = e;
    this.hired = e;
  }
  vendorNo(e: number) {
    this.VendorChecked = e;
    this.hired = e;
  }

  search(e: any) {
    if (e.target.value.length > 2) {
      this.toggle = true;
      this.vendorName = e.target.value;
      this.getSearchValue(e.target.value);
    } else {
      this.toggle = false;
    }
  }

  getSearchValue(val: any) {
    if (!isNaN(val)) {
      var data = {
        search_value: null,
        category_id: null,
        vendor_detail_id: val
      };
    } else {
      if (this.vendorCategoryId) {
        var vendorCategoryId = this.vendorCategoryId;
        // console.log(vendorCategoryId);
      } else {
        // var vendorCategoryId = this.updateData.category_id;
      }
      var data = {
        search_value: val,
        category_id: vendorCategoryId,
        vendor_detail_id: null
      };
    }
    this.VendorManagerService.searchValue(data).subscribe((result: any) => {
      this.searchValue = result.data;
      const venLength = this.searchValue.length;
      for (let idx = 0; idx < venLength; idx++) {
        this.chooseVendor[idx] = false;
      }
      if (isNaN(val)) {
        this.searchDiv = true;
      }
    },
      error => {
        this.toastr.error(error.message);
      })
  }

  selectVendor(e: any, index: number) {
    this.chooseVendor = [];
    this.selectedVendor = e;
    this.selectCategoryId = this.selectedVendor.category_id;
    this.searchVendorName = this.selectedVendor.business_name;
    this.chooseVendor[index] = !this.chooseVendor[index];
    this.vendorSelectImage = Config.imageUrl + 'images/vendor_thumb_images/' + this.selectedVendor.search_thumbs_image;
    // this.searchDiv = false;
  }

  saveVendor(show_public: any) {
    $('#basicExampleModal').modal('toggle');
    if (this.VendorChecked == 1) {
      let data = {
        category_id: this.selectCategoryId,
        event_id: this.activeUser.id,
        vendor_id: this.selectedVendor.vendor_id,
        show_public: show_public,
        hired: 3,
      };
      this.VendorManagerService.addWeddingVendor(data).subscribe((result: any) => {
        if (this.VendorChecked == 2) {
          $('#addVendorModal').modal('toggle');
          $('#vendorConfirmModal').modal('toggle');
        }
        this.vendor_submitted = false;
        this.VendorChecked = 2;
        this.toastr.success('Vendor has been added');

        this.getVendorCategorys(this.activeUser.id);
        this.getVendorCategorysCount(this.activeUser.id);
        this.statusCount(this.activeUser.id);
        $('#addVendorModal').modal('toggle');
        // this.filterChecklist();
        // this.resetLeftPanel(1);
      },
        error => {

          this.toastr.error(error.message);
        })
    } else if (this.VendorChecked == 2) {
      this.openConfirm = true;
      $('#addVendorModal').modal('toggle');
      $('#vendorConfirmModal').modal('toggle');
    }
  }

  addVendor(showPublic: any) {
    let data = {
      category_id: this.selectedVendor.category_id,
      event_id: this.activeUser.id,
      vendor_id: this.selectedVendor.vendor_id,
      show_public: showPublic,
      hired: this.VendorChecked,
    };

    this.VendorManagerService.addWeddingVendor(data).subscribe((result: any) => {
      this.vendor_submitted = false;
      this.VendorChecked = 2;
      this.toastr.success('Vendor has been added');
      $('#vendorConfirmModal').modal('toggle');
      this.getVendorCategorysCount(this.activeUser.id);
      this.getVendorCategorys(this.activeUser.id);
      this.statusCount(this.activeUser.id);
      this.getVendorList(this.id);
      // this.filterChecklist();
      // this.resetLeftPanel(1);
    },
      error => {
        $('#vendorConfirmModal').modal('toggle');
        // this.toastr.error(error.message);
        this.toastr.success("Already saved vendor");
      })
  }

  SearchselectVendor(e: any) {
    this.selectedVendor = e;
    this.searchVendorName = this.selectedVendor.business_name;
    this.searchDiv = false;
    this.getCategoryName(this.vendorCategoryId);
  }

  addVendorClick() {
    this.openConfirm = true;
    $('#addVendorModal').modal('toggle');
    this.AddVendorForm.get('vendor_name').setValue(this.vendorName);
    $('#addnewVendor').modal('toggle');

  }

  getRegionWiseCities(region_id: number) {
    this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response: LocationModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }
  submitVendor(type: any) {
    this.vendor_submitted = true;
    if (this.AddVendorForm.invalid) {
      return;
    }
    let data = {
      category_id: this.vendorCategoryId,
      event_id: this.activeUser.id,
      vendor_catagory_id: null,
      vendor_id: null,
      show_public: 1,
      hired: this.VendorChecked,
      vendor_name: this.AddVendorForm.value.vendor_name,
      state_id: this.AddVendorForm.value.state_id,
      city_id: this.AddVendorForm.value.city_id,
      phone_number: this.AddVendorForm.value.phone_number,
      email: this.AddVendorForm.value.email,
    };
    this.VendorCategory = false;
    this.saveVendorList = false;
    this.VendorManagerService.addWeddingVendor(data).subscribe((result: any) => {
      this.vendor_submitted = false;
      this.VendorChecked = 2;
      this.AddVendorForm.reset();
      $('#addnewVendor').modal('toggle');
      this.getVendorCategorys(this.activeUser.id);
      this.getVendorCategorysCount(this.activeUser.id);
      this.toastr.success('Vendor has been added');
      // $('#addVendor').modal('toggle');
      // this.filterChecklist();
      // this.resetLeftPanel(1);
    },
      error => {
        this.toastr.error(error.message);
      })
  }
  getWidth(number: any) {
    // console.log(Math.floor(number*10*2)+'%');
    return Math.floor(number * 10 * 2) + '%';
  }
}
