import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute  } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';

@Component({
  selector: 'app-account-info-sidebar',
  templateUrl: './account-info-sidebar.component.html',
  styleUrls: ['./account-info-sidebar.component.css']
})
export class AccountInfoSidebarComponent implements OnInit {

  access = localStorage.getItem("role");
  showMenuItem = true;
  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }

  ngOnInit() {
    if(this.access == "Vendor User"){
      this.showMenuItem = false;
    }else{
      this.showMenuItem = true;
    }
  }

}
