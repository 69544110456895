import { Component, OnInit } from '@angular/core';
import { VendorManagerService } from '../services/vendor-manager.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PlannersService } from '../services/planners.service';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorsService } from '../../vendors/services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';
import { LocationModel } from 'src/app/models/location.model';
import { RegionModel } from 'src/app/models/region.model';
import { Config } from '../../../config';
import { GuestlistService } from '../services/guestlist.service';
import { log } from 'console';
declare var $: any;

@Component({
  selector: 'app-vendor-manager',
  templateUrl: './vendor-manager.component.html',
  styleUrls: ['./vendor-manager.component.scss']
})
export class VendorManagerComponent implements OnInit {

  favSection: boolean = true;
  hiredSection: boolean = false;
  vendorCategorys: any;
  searchVendorName: any;
  toggle: boolean = false;
  VendorCategoryDrop: boolean = true;
  vendorCategoryId: any;
  VendorCategory: boolean = false;
  VendorCategoryName: any = '';
  searchValue: any;
  selectedVendor: any;
  searchDiv: boolean = false;
  selectedSaveVendor: any;
  saveVendorList: boolean = false;
  activeUser: any;
  vendorName: any;
  selectedHairedVendor: any;
  saveVendorIsPresent: boolean = false;
  hairedVendorIsPresent: boolean = false;
  showSelectedVendor: boolean = false;
  VendorChecked: any = 2;
  hired: number;
  chooseVendor: boolean[] = [];
  openConfirm: boolean = false;
  vendor_submitted: boolean = false;
  submitted = false;
  selectCategoryId: any;
  public AddVendorForm: FormGroup;
  emailRegEx = "^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$";
  cities: any;
  regions: any;
  vendorCategoryCount: any;
  totalVendorCategoryCount: any;
  totalActiveVendorCount: any;
  totalCategoryEnabledByUser: number = 0;
  allEvent: any;
  totalVendorsHiredCount: any;
  totalCategoryHiredCount: any;
  filterVendorCategorys: any;
  sub: any;
  id: any;
  vendorCategoryData: any;
  HiredVendorData: any;
  completed: number;
  vendor: boolean = false;
  savedVendor: boolean = false;
  checkedVendor: any = [];
  checkedSavedVendor: any = [];
  hiredVendorStatus = [
    "Vendor not needed or will be taken care of by family/friends",
    "Remove hire status"
  ];

  savedVendorStatus = [
    // "Vendor not needed or will be taken care of by family/friends",
    "Remove saved status"
  ];
  selectedOption: any;
  showSelectstatus: boolean = false;
  showSavedSelectstatus: boolean = false;
  hiredVendorImage: string;
  categoryId: any;
  vendorListData: any;
  vendorCardImage: any;
  vendorSelectImage: string;
  total_review_requests: number = 0;

  constructor(
    private VendorManagerService: VendorManagerService, private toastr: ToastrService,
    public formBuilder: FormBuilder, private locationService: LocationsService,
    private plannersService: PlannersService, public router: Router, public route: ActivatedRoute,
    private vendorService: VendorsService,
    private guestlistService: GuestlistService,) {
    this.AddVendorForm = this.formBuilder.group({
      vendor_name: ['', Validators.required],
      category_name: ['', Validators.required],
      city_id: ['', Validators.required],
      state_id: ['', Validators.required],
      phone_number: [''],
      email: ['', Validators.compose([Validators.pattern(this.emailRegEx)])],
    });
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.getEventList(this.activeUser);
      } else {
        this.activeUser = null;
      }

    },
      (err) => {
        console.error(err);
      });


  }

  ngOnInit() {
    window.scrollTo(0, 0);
     this.route.queryParams.subscribe(res=>{
      this.sub = res.value;
      if(this.sub == "saved"){
        this.getVendorCategorys(this.activeUser.id);
        this.favSection=true;
        this.hiredSection=false;
      }else{
        this.getHiredVendorDetails(this.activeUser.id);
        this.favSection=false;
        this.hiredSection=true;
      }
      
    })
    $('#addVendorModal').on('hidden.bs.modal', () => {
      this.searchVendorName = null;
      this.searchVendorName = '';
      this.toggle = false;
    });
    this.getVendorCategorys(this.activeUser.id);
    this.getVendorCategorysCount(this.activeUser.id);
    this.locationService
      .getRegions("")
      .subscribe(
        (response: RegionModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.regions = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  
  async getEventList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var eventList = await this.guestlistService.getEventList(data).toPromise();
    this.allEvent = eventList.data;
    return eventList.data;
  }
  get g() {
    return this.AddVendorForm.controls;
  }
  favourite() {
    this.favSection = true;
    this.hiredSection = false;
  }

  hiredList() {
    this.favSection = false;
    this.hiredSection = true;
    this.getHiredVendorDetails(this.activeUser.id);
  }

  // search vendor with category id 
  searchVendorWithId(id) {
    window.scrollTo(500, 0);
    this.router.navigate(['/vendor-category',id]);
  }

  goToRequestReview(){
    this.router.navigate(['/vendor-manager/review-request']);
  }

  // get vendor category list 

  async getVendorCategorys(weddingId: any) {
    var vendorCategory = await this.VendorManagerService.getVendorCategorys(weddingId).toPromise();
    this.vendorCategorys = vendorCategory.data;        
    for (let index = 0; index < this.vendorCategorys.length; index++) {
      this.categoryId = this.vendorCategorys[index].id;
      this.vendorCategorys[index].image = Config.imageUrl + 'images/vendor_thumb_images/' + this.vendorCategorys[index].search_thumbs_image;
      this.vendorCategorys[index].vendorBlackIcon = Config.imageUrl + 'images/vendor_category_icons/black_icons/' + this.vendorCategorys[index].icon_name;
      this.vendorCategorys[index].vendorWhiteIcon = Config.imageUrl + 'images/vendor_category_icons/white_icons/' + this.vendorCategorys[index].icon_name;
      //  this.getVendorList(this.categoryId);
    }
    this.filterVendorCategorys = this.vendorCategorys.filter((e) => e.total_vendors_hired_by_user == '0');
    this.getHiredVendorDetails(this.activeUser.id);
    this.vendorCategorys.sort((a: any, b: any) => (a.hired > b.hired,a.total_vendors_hired_by_user > b.total_vendors_hired_by_user) ? -1 : 1);
    // var other = {
    //   id: 0,
    //   name: 'Other',
    //   type: 'vendor'
    // };
    // this.vendorCategorys.push(other);
  }

  //  get vendor list 
  // getVendorList(categoryId){
  //   this.VendorManagerService.getVendorLists(categoryId).subscribe((result: any) => {
  //   this.vendorListData = result.data;
  //   console.log(this.vendorListData);
  //   for (let index = 0; index < this.vendorListData.length; index++){
  //   }

  //   });
  // }

  // get total vendor category count and total active vendor count

  async getVendorCategorysCount(weddingId: any) {
    var Count = await this.VendorManagerService.getVendorCategorysCount(weddingId).toPromise();
    this.vendorCategoryCount = Count;
    this.totalVendorCategoryCount = this.vendorCategoryCount.total_vendor_category;
    this.totalActiveVendorCount = this.vendorCategoryCount.total_active_vendor;
    this.totalVendorsHiredCount = this.vendorCategoryCount.total_vendors_hired_by_user;
    this.totalCategoryHiredCount = this.vendorCategoryCount.total_category_hired_by_user;
    this.totalCategoryEnabledByUser = this.vendorCategoryCount.total_category_enabled_by_user;
    if (this.vendorCategoryCount.total_vendor_category != 0) {
      var p = this.vendorCategoryCount.total_category_hired_by_user / this.vendorCategoryCount.total_category_enabled_by_user * 100;
    } else {
      var p = 0;
    }
    this.completed = Math.round(p);
  }

  // get hired vendor details 

  async getHiredVendorDetails(weddingId: any) {
    var HiredVendorDetails = await this.VendorManagerService.getHiredVendorData(weddingId).toPromise();
    this.HiredVendorData = HiredVendorDetails.data;
    for (let index = 0; index < this.HiredVendorData.length; index++) {
      this.HiredVendorData[index].hiredImage = Config.imageUrl + 'images/vendor_thumb_images/' + this.HiredVendorData[index].search_thumbs_image;
    }
  }

  reviewVendorWithId(id) {
    this.router.navigate(['/vendor-ratings', id]);
  }
  // add vendor 

  addVendorModal() {
    this.VendorCategoryDrop = true;
    $('#addVendorModal').modal('toggle');
  }

  async modelOpen() {
    this.openConfirm = false;
    await this.changeVendorCategory(this.AddVendorForm.value.category_name);
  }
  async changeVendorCategory(e: any) {
    this.vendorCategoryId = e;
    this.VendorCategory = true;
    // console.log(this.vendorCategoryId);

    // this.VendorCategoryDrop = false;
    this.getCategoryName(this.vendorCategoryId);
    // await this.getSavedVendorList(e);
    // await this.getHairedVendorList(e);
  }

  // async getSavedVendorList(catId: number) {
  //   // console.log(catId);

  //   let data = {
  //     event_id: this.activeUser.id,
  //     category_id: Number(catId),
  //     is_hired: 2

  //   };
  //   var vendorList = await this.VendorManagerService.getWeddingVendor(data).toPromise();
  //   console.log('vendorList', vendorList);
  //   this.selectedSaveVendor = vendorList.data;
  //   console.log(this.selectedSaveVendor);

  //   if (this.selectedSaveVendor.length > 0) {
  //     this.saveVendorIsPresent = true;
  //   } else {
  //     this.saveVendorIsPresent = false;
  //   }


  // }

  // async getHairedVendorList(catId: number) {
  //   let data = {
  //     event_id: this.activeUser.id,
  //     category_id: Number(catId),
  //     is_hired: 1

  //   };
  //   var vendorList = await this.VendorManagerService.getWeddingVendor(data).toPromise();
  //   this.selectedHairedVendor = vendorList.data;
  //   if (this.selectedHairedVendor.length == 0) {
  //     this.hairedVendorIsPresent = false;
  //     this.VendorCategory = true;
  //   } else {
  //     this.VendorCategory = false;
  //     this.hairedVendorIsPresent = true;
  //   }
  // }

  showVendor(e: any) {
    this.vendorCategoryId = e;
    this.showSelectedVendor = true;
    this.VendorCategoryDrop = false;
    this.getCategoryName(this.vendorCategoryId);
  }

  getCategoryName(id: any) {
    var category = this.vendorCategorys.filter((x: any) => x.id == id);
    this.VendorCategoryName = category[0].name;
  }

  vendorYes(e: number) {
    this.VendorChecked = e;
    this.hired = e;
  }
  vendorNo(e: number) {
    this.VendorChecked = e;
    this.hired = e;
  }

  search(e: any) {
    if (e.target.value.length > 2) {
      this.toggle = true;
      this.vendorName = e.target.value;
      this.getSearchValue(e.target.value);
    } else {
      this.toggle = false;
    }
  }

  getSearchValue(val: any) {
    if (!isNaN(val)) {
      var data = {
        search_value: null,
        category_id: null,
        vendor_detail_id: val
      };
    } else {
      if (this.vendorCategoryId) {
        var vendorCategoryId = this.vendorCategoryId;
        // console.log(vendorCategoryId);
      } else {
        // var vendorCategoryId = this.updateData.category_id;
      }
      var data = {
        search_value: val,
        category_id: vendorCategoryId,
        vendor_detail_id: null
      };
    }
    this.VendorManagerService.searchValue(data).subscribe((result: any) => {
      this.searchValue = result.data;
      const venLength = this.searchValue.length;
      for (let idx = 0; idx < venLength; idx++) {
        this.chooseVendor[idx] = false;
      }
      if (isNaN(val)) {
        this.searchDiv = true;
      }
    },
      error => {
        this.toastr.error(error.message);
      })
  }

  selectVendor(e: any, index: number) {
    this.chooseVendor = [];
    this.selectedVendor = e;
    this.selectCategoryId = this.selectedVendor.category_id;
    this.searchVendorName = this.selectedVendor.business_name;
    this.chooseVendor[index] = !this.chooseVendor[index];
    this.vendorSelectImage = Config.imageUrl + 'images/vendor_thumb_images/' + this.selectedVendor.search_thumbs_image;
    // this.searchDiv = false;
  }

  saveVendor(show_public: any) {
    $('#basicExampleModal').modal('toggle');
    if (this.VendorChecked == 1) {
      let data = {
        category_id: this.selectCategoryId,
        event_id: this.activeUser.id,
        vendor_id: this.selectedVendor.vendor_id,
        show_public: show_public,
        hired: false,
      };
      this.VendorManagerService.addWeddingVendor(data).subscribe((result: any) => {
        if (this.VendorChecked == 2) {
          $('#addVendorModal').modal('toggle');
          $('#vendorConfirmModal').modal('toggle');
        }
        this.vendor_submitted = false;
        this.VendorChecked = 2;
        this.toastr.success('Vendor has been added');

        this.getVendorCategorys(this.activeUser.id);
        this.getVendorCategorysCount(this.activeUser.id);
        $('#addVendorModal').modal('toggle');
        // this.filterChecklist();
        // this.resetLeftPanel(1);
      },
        error => {

          this.toastr.error(error.message);
        })
    } else if (this.VendorChecked == 2) {
      this.openConfirm = true;
      $('#addVendorModal').modal('toggle');
      $('#vendorConfirmModal').modal('toggle');
    }
  }

  addVendor(showPublic: any) {    
    let data = {
      category_id: this.selectedVendor.category_id,
      event_id: this.activeUser.id,
      vendor_id: this.selectedVendor.vendor_id,
      show_public: showPublic,
      hired: this.VendorChecked ,
    };   
    
    this.VendorManagerService.addWeddingVendor(data).subscribe((result: any) => {
      this.vendor_submitted = false;
      this.VendorChecked = 2;
      this.toastr.success('Vendor has been added');
      $('#vendorConfirmModal').modal('toggle');
      var data = {
        vendors: [this.selectedVendor.vendor_id],
        toStatus: 2
      }    
      this.VendorManagerService.vendorUpdateStatus(data).subscribe((result: any) => {
        this.getVendorCategorys(this.activeUser.id);
        this.getVendorCategorysCount(this.activeUser.id);
      },error =>{
        this.toastr.error(error.message);
      })
      this.getVendorCategorysCount(this.activeUser.id);
      this.getVendorCategorys(this.activeUser.id);
      this.getHiredVendorDetails(this.activeUser.id);
      // this.filterChecklist();
      // this.resetLeftPanel(1);
    },
      error => {
        $('#vendorConfirmModal').modal('toggle');
        var data = {
          vendors: [this.selectedVendor.vendor_id],
          toStatus: 2
        }      
        this.VendorManagerService.vendorUpdateStatus(data).subscribe((result: any) => {
          this.getVendorCategorys(this.activeUser.id);
          this.getVendorCategorysCount(this.activeUser.id);
          this.getHiredVendorDetails(this.activeUser.id);
        })
        // this.toastr.error(error.message);
        
        this.toastr.success("Vendor already hired");
      })
  }

  SearchselectVendor(e: any) {
    this.selectedVendor = e;
    this.searchVendorName = this.selectedVendor.business_name;
    this.searchDiv = false;
    this.getCategoryName(this.vendorCategoryId);
  }

  addVendorClick() {
    this.openConfirm = true;
    $('#addVendorModal').modal('toggle');
    this.AddVendorForm.get('vendor_name').setValue(this.vendorName);
    $('#addnewVendor').modal('toggle');

  }

  getRegionWiseCities(region_id: number) {
    this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response: LocationModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }
  submitVendor(type: any) {
    this.vendor_submitted = true;
    if (this.AddVendorForm.invalid) {
      return;
    }
    let data = {
      category_id: this.vendorCategoryId,
      event_id: this.activeUser.id,
      vendor_catagory_id: null,
      vendor_id: null,
      show_public: 1,
      hired: this.VendorChecked,
      vendor_name: this.AddVendorForm.value.vendor_name,
      state_id: this.AddVendorForm.value.state_id,
      city_id: this.AddVendorForm.value.city_id,
      phone_number: this.AddVendorForm.value.phone_number,
      email: this.AddVendorForm.value.email,
    };    
    this.VendorCategory = false;
    this.saveVendorList = false;
    this.VendorManagerService.addWeddingVendor(data).subscribe((result: any) => {      
      this.vendor_submitted = false;
      this.VendorChecked = 2;
      this.AddVendorForm.reset();
      $('#addnewVendor').modal('toggle');
      this.getVendorCategorys(this.activeUser.id);
      this.getVendorCategorysCount(this.activeUser.id);
      this.toastr.success('Vendor has been added');
      // $('#addVendor').modal('toggle');
      // this.filterChecklist();
      // this.resetLeftPanel(1);
    },
      error => {
        this.toastr.error(error.message);
      })

  }



  //  select all  hired vendor 
  selectAll(e: any, list) {
    this.checkedVendor = [];
    if (e.target.checked == true) {
      for (let index = 0; index < list.length; index++) {
        list[index].isSelected = true;
        this.checkedVendor.push(list[index].vendor_id);
      }
      this.vendor = true;
    } else {
      this.showSelectstatus = false;
      for (let index = 0; index < list.length; index++) {
        list[index].isSelected = false;
        this.checkedVendor = [];
      }
      this.vendor = false;
    }
  }
  // single hired vendor select 
  singleChecked(e: any, id: any) {
    if (e.target.checked == true) {
      this.checkedVendor.push(id);
      this.showSelectstatus = true;
    } else {
      this.showSelectstatus = false;
      const index = this.checkedVendor.indexOf(id);
      this.checkedVendor.splice(index, 1);
    }
  }
  radioChangeHandler(event: any) {
    this.selectedOption = event.target.value;
    if (this.selectedOption == "Vendor not needed or will be taken care of by family/friends") {
      $('#hireVendorNotNeed').modal('toggle');
    } else {
      $('#removeHireVendor').modal('toggle');
    }
  }

  dismissModal(){
    this.vendor = false;
    this.showSelectstatus = false;    
    for (let index = 0; index < this.HiredVendorData.length; index++) {
      this.HiredVendorData[index].isSelected = false;
    }
    for (let index = 0; index < this.vendorCategorys.length; index++) {
      this.vendorCategorys[index].isSelected = false;
    }
    this.showSavedSelectstatus = false;
  }
  hireVendorToSaved() {
    var data = {
      vendors: this.checkedVendor,
      toStatus: 1
    }
    this.VendorManagerService.vendorUpdateStatus(data).subscribe((result: any) => {
      $('#removeHireVendor').modal('toggle');
      this.getVendorCategorys(this.activeUser.id);
      this.getVendorCategorysCount(this.activeUser.id);
      this.getHiredVendorDetails(this.activeUser.id);
      this.showSelectstatus = false;
    }, error => {
      this.toastr.error(error.message);
    })
  }

  // saved vendor selection 
  selectAllSaved(e: any, list) {
    this.checkedSavedVendor = [];
    if (e.target.checked == true) {
      for (let index = 0; index < list.length; index++) {
        list[index].isSelected = true;
        this.checkedSavedVendor.push(list[index].id);
      }
      this.savedVendor = true;
    } else {
      this.showSelectstatus = false;
      for (let index = 0; index < list.length; index++) {
        list[index].isSelected = false;
        this.checkedSavedVendor = [];
      }
      this.showSavedSelectstatus = false;
      this.savedVendor = false;
    }
  }
  // single hired vendor select 
  singleSavedChecked(e: any, id: any) {
    if (e.target.checked == true) {
      this.checkedSavedVendor.push(id);
    } else {
      const index = this.checkedSavedVendor.indexOf(id);
      this.checkedSavedVendor.splice(index, 1);
    }
    if(this.checkedSavedVendor.length === 0){
      this.showSavedSelectstatus = false;
    }else{
      this.showSavedSelectstatus = true;
    }
  }
  radioSavedChangeHandler(event: any) {
    this.selectedOption = event.target.value;
    if (this.selectedOption == "Vendor not needed or will be taken care of by family/friends") {
      $('#saveVendorNotNeed').modal('toggle');
    } else {
      $('#removeSaveVendor').modal('toggle');
    }
  }
  //  change vendor status
  removeSaveVendor() {
    var data = {
      categories: this.checkedSavedVendor,
      toStatus: -1
    }
    this.VendorManagerService.vendorUpdateStatus(data).subscribe((result: any) => {
      $('#removeSaveVendor').modal('toggle');
      this.getVendorCategorys(this.activeUser.id);
      this.getVendorCategorysCount(this.activeUser.id);
      this.showSavedSelectstatus = false;
    }, error => {
      this.toastr.error(error.message);
    })

  }

   //  change vendor status
   removeHiredVendor() {
    var data = {
      vendors: this.checkedVendor,
      toStatus: -1
    }
    this.VendorManagerService.vendorUpdateStatus(data).subscribe((result: any) => {
      $('#hireVendorNotNeed').modal('toggle');
      this.getVendorCategorys(this.activeUser.id);
      this.getVendorCategorysCount(this.activeUser.id);
      this.showSelectstatus = false;
    }, error => {
      this.toastr.error(error.message);
    })

  }

  vendorProfile(data) {
    if (data.slug == null) {
      $('#profileModal').modal('toggle');
    } else {
      this.router.navigate(['/profile/', data.slug]);
    }
    if (data.vendor_id == null) {
      $('#profileModal').modal('toggle');
    } else {
      this.router.navigate(['/profile/', data.slug]);
    }
  }

}
