import { Component, OnInit } from '@angular/core';
import { LocationModel } from 'src/app/models/location.model';
import { RegisterModel } from 'src/app/models/register.model';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { RegionModel } from 'src/app/models/region.model';
import { UsersService } from '../../users/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { LocationsService } from '../../locations/services/locations.service';
import { RegisterFormBuilder } from '../../planners/register/register.form-builder';
import { PlannersService } from '../../planners/services/planners.service';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { Validators } from '@angular/forms';
import * as moment from 'moment';

declare var $: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public min = new Date();
  submitted = false;
  model = new RegisterModel();
  locationModel = new LocationModel();
  registerForm: any;
  cities: any;
  type: any;
  categories: any;
  regions: any;
  argsWiseContent = {};
  isLinkAccount: boolean = false;
  weddingId = 0;
  isNewWedding: boolean = false;
  weddings: any;
  googleUser: any;
  socialType: number;
  socialData: any;
  activeUser: any;
  headingType: number = 0;
  linkData: any;
  btnText:string='Create Account';
  passwordStatus: boolean = false;
  constructor(private usersService: UsersService,
    private locationService: LocationsService,
    private plannersService: PlannersService,
    private formBuilder: RegisterFormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router,

    private authService: SocialAuthService) {
    //this.loginForm = formBuilder.getForm();


  }

  async ngOnInit() {
    this.registerForm = this.formBuilder.getForm();
    this.socialType = 0;
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        if (params.id == 'new') {
          this.weddingId = 0;
          this.isNewWedding = true;
          this.passwordStatus=true;
          this.registerForm.controls['email'].clearValidators();
          this.registerForm.controls['email'].setErrors(null);
          this.registerForm.controls['email'].setValidators(null);

          this.registerForm.controls['password'].clearValidators();
          this.registerForm.controls['password'].setErrors(null);
          this.registerForm.controls['password'].setValidators(null);

          this.registerForm.controls['terms'].clearValidators();
          this.registerForm.controls['terms'].setErrors(null);
          this.registerForm.controls['terms'].setValidators(null);
          this.getWeddings();
          // this.plannersService.assignWedding.subscribe((result: any) => {
          //   //console.log("subscribe",result);
          //   this.weddings = result;

          // });

        } else {
          localStorage.setItem("USER_TOKEN", '');
          localStorage.setItem("UID", '');
          localStorage.setItem("fname", '');
          localStorage.setItem("role", '');
          this.isLinkAccount = true;
          this.btnText='Create Linked Account';
          this.weddingId = params.id;
          console.log('gg', this.weddingId);
          this.getLinkData(this.weddingId);
          this.registerForm.controls['state_id'].clearValidators();
          this.registerForm.controls['state_id'].setErrors(null);
          this.registerForm.controls['state_id'].setValidators(null);

          this.registerForm.controls['city_id'].clearValidators();
          this.registerForm.controls['city_id'].setErrors(null);
          this.registerForm.controls['city_id'].setValidators(null);

          this.registerForm.controls['start_datetime'].clearValidators();
          this.registerForm.controls['start_datetime'].setErrors(null);
          this.registerForm.controls['start_datetime'].setValidators(null);
        }
      } else {
        this.registerForm.controls['password'].clearValidators();
        this.registerForm.controls['password'].setValidators([Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-8])(?=.*?[#?)+(!@$%^&*-]).{8,}$')]);
        this.registerForm.controls['email'].clearValidators();
        this.registerForm.controls['email'].setValidators([Validators.required, Validators.email]);
      }
    });

    this.locationService
      .getRegions("")
      .subscribe(
        (response: RegionModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.regions = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
    this.resetForm();
    this.registerForm.get('role_id').setValue(3);
    setTimeout(() => {
      $('form, input,textarea, select').attr('autocomplete', 'nope');
    }, 1000);

    window.scroll(0, 0);



    // this.plannersService.setSocialData.subscribe(
    //   res => {
    //     this.socialData = res;
    //   if(this.socialData.data!=false){
    //     const credentials = {
    //       firstName: this.socialData.firstName,
    //       lastName: this.socialData.lastName,
    //       email: this.socialData.email,
    //       provider_id: this.socialData.provider_id,
    //       provider_name: this.socialData.provider_name
    //     };
    //     this.setSignupValues(credentials);
    //   }

    //   },
    //   error => {
    //   });
  }

  get f() { return this.registerForm.controls; }


  myProfileData(allweddings: any) {
    var weddings = allweddings.filter((x: any) => x.is_active == 1);
    if (weddings.length > 0) {
      this.activeUser = weddings[0];
      if (this.activeUser.is_planner == 1) {
        this.socialType = 1;
        this.headingType = 2;
        this.registerForm.get('first_couple_type').setValue(4);
      }

      console.log(' this.activeUser', this.activeUser);

    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    // if (this.registerForm.value.first_couple_type != 3 && (this.registerForm.value.start_datetime == "" || this.registerForm.value.start_datetime == null)) {
    //   this.toastr.error("Wedding date can not be blank");
    //   return false;
    // }
    if (this.registerForm.value.start_datetime) {
      var date = moment(this.registerForm.value.start_datetime).format("YYYY-MM-DD HH:mm:ss");
      this.registerForm.value.start_datetime = date;
    }
   
    this.model.deserialize(this.registerForm.value);
    if (this.isLinkAccount) {
      this.plannersService.submitLinkProfile(this.model).subscribe((result: any) => {
        //console.log(result);return;
        this.plannersService.authenticate(this.model).subscribe((response: AuthResponseModel) => {
          if (response.token) {
            this.toastr.success("Login Success");
            this.router.navigate(['planner-dashboard']);
            return false;
          }
          else {
            this.toastr.error("Login failure");
            return false;
          }
        },
          error => {
            this.toastr.error(error.message);
          })
      },
        error => {
          this.toastr.error(error.message);
        })
    } else if (this.isNewWedding) {

      if (this.headingType == 2) {
        this.plannersService.addPlannerNewWedding(this.model).subscribe((result: any) => {
          this.toastr.success(result.message);
          this.getWeddings();
          setTimeout(() => {
            this.plannersService.setNewWedding(this.weddings);
            this.router.navigate(["planner-dashboard"]);
          }, 100);
          return;
        },
          error => {
            this.toastr.error(error.message);
          })
      } else {
        this.plannersService.newWedding(this.model).subscribe((result: any) => {
          this.toastr.success(result.message);
          this.getWeddings();
          setTimeout(() => {
            this.plannersService.setNewWedding(this.weddings);
            this.router.navigate(["planner-dashboard"]);
          }, 100);
          return;
        },
          error => {
            this.toastr.error(error.message);
          })
      }
    }
    else {
      this.plannersService
        .create(this.model)
        .subscribe(
          (response: RegisterModel) => {
            this.toastr.success("Registration Success");
            this.plannersService
              .authenticate(this.model)
              .subscribe(
                (response: AuthResponseModel) => {
                  if (response.token) {
                    this.toastr.success("Login Success");
                    this.router.navigate(['planner-dashboard']);
                    return false;
                  }
                  else {
                    this.toastr.error("Login failure");
                  }
                },
                error => {
                  this.toastr.error(error.message);
                }
              )
            return false;
          },
          error => {
            this.toastr.error(error.message);
            if(error.message=='Email already exits'){
              this.router.navigate(["/login"]);
            }
          }
        )
    }

  }

  getLinkData(weddingId: any) {
    let data = {
      key: weddingId,
    };
    this.plannersService.getLinkData(data).subscribe((result: any) => {
      this.linkData = result.data;
      this.headingType = 1;
      this.registerForm.get('email').setValue(this.linkData.email);
    },
      error => {
        this.toastr.error(error.message);
      })
  }

  getRegionWiseCities(region_id: number) {
    this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response: LocationModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  resetForm() {
    this.submitted = false;
    this.registerForm.reset();
  }

  usDateToDb(date: any) {
    var d = date.split("/"),
      month = d[1],
      day = d[0],
      year = d[2];

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  getWeddings() {
    this.plannersService.getWeddings().subscribe((result: any) => {
      this.weddings = result;
      this.myProfileData(this.weddings);
      console.log('this.weddings', this.weddings);
    })
  }

  googleSignUp() {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      this.googleUser = user;
      if (user.authToken) {
        const credentials = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          provider_id: user.id,
          provider_name: user.provider
        };
        this.setSignupValues(credentials);
      }
    }, (err) => {
      console.log('error', err);
    });
  }

  fbSignUp() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      this.googleUser = user;
      console.log('***** In Facebook signin ******', user);
      if (user.authToken) {
        const credentials = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: (user.email === undefined) ? '' : user.email,
          provider_id: user.id,
          provider_name: user.provider
        };
        this.setSignupValues(credentials);

      }
    }, (err) => {
      console.log('fberror', err);
    });
  }


  setSignupValues(credentials: any) {
    this.registerForm.get('firstname').setValue(credentials.firstName);
    this.registerForm.get('lastname').setValue(credentials.lastName);
    this.registerForm.get('email').setValue(credentials.email);

    this.registerForm.controls['password'].clearValidators();
    this.registerForm.controls['password'].setErrors(null);
    this.registerForm.controls['password'].setValidators(null);

    this.socialType = 1;
    this.headingType = 3;
    this.passwordStatus=true;
    this.btnText='Create Account';
    if (credentials.provider_name == 'GOOGLE') {
      this.registerForm.get('google_provider_id').setValue(credentials.provider_id);
    } else if (credentials.provider_name == 'FACEBOOK') {
      this.registerForm.get('fb_provider_id').setValue(credentials.provider_id);
    }


  }

  goTerms() {
    this.router.navigate([]).then(result => { window.open('/terms/', '_blank'); });
  }

  goPrivacy() {
    this.router.navigate([]).then(result => { window.open('/privacy/', '_blank'); });
  }

  changeDateFormat(date: any) {
    var d = date.split("-"),
      day = d[1],
      year = d[0],
      month = d[2];

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

}
