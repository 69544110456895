import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Config } from '../../../config';
import { DiscussionService } from '../services/discussions.service';
import { PlannersService } from '../../planners/services/planners.service';
declare var $: any;

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.scss']
})
export class DiscussionComponent implements OnInit {
  activeUser: any = null;
  category_name: string = "";
  category_details: any;
  user_category_status: any = null;
  category_user_details: any = null;
  categoryBannerStyle: any = {};
  category_banner_image_url: string = Config.imageUrl + 'images/discussion_category_banner/';
  discussion_image_url: string = Config.imageUrl + 'images/discussion_media_images/';
  total_members = "0";
  total_photos = "0";
  total_videos = "0";
  viewMode = 'discussionSection';
  eventSortDropdownIsOpen: boolean = false;
  category_banner_text: string = "";
  readMoreDetails = true;
  showCreateDiscussionForm: boolean = false;
  discussion_topics: any[] = [];

  // all discussions --
  discussions: any[] = [];
  current_page_number: number = 1;
  total_discussions: number = 0;

  // Photos List --
  discussion_photos: any[] = [];
  current_page_number_photo_tab: number = 1;
  total_discussion_photos: number = 0;

  // Members list --
  discussion_members: any[] = [];

  constructor(
    private discussionService: DiscussionService,
    private plannersService: PlannersService,
    public route: ActivatedRoute,
    public router: Router,
    private toastr: ToastrService,
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.activeUser["role"] = "Wedding Users";
      } else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Vendor"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
      }else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Community Guest"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
        console.log(this.activeUser);
      }else{
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        if (params['category']) {
          this.category_name = params['category'];
          this.getDiscussionCategoryDetails();
        }
      }
    });
  }
  goToTab(tabname: string){
    this.viewMode = tabname;
    if(tabname === 'photosSection'){
      this.discussion_photos = [];
      this.getDiscussionMedias(1);
    }else if(tabname === 'memberSection'){
      this.discussion_members = [];
      this.getDiscussionMembers(1);
    }
  }
  showEditor(){
    if(this.activeUser){
      if(this.activeUser.role === "Wedding Users"){
        this.router.navigate(["conversation", "create"]);
      }
    }else{
      $('#login_modal').modal('toggle');
    }
  }
  async getDiscussionList(page_number: number){
    let formData = {
      "category_id": this.category_details.id,
      "page_number": page_number
    };
    var response = await this.discussionService.getDiscussionList(formData).toPromise();
    this.discussions = response.data;
    this.total_discussions = response.total_records;
  }
  async getDiscussionMedias(page_number: number){
      let formData = {
        "category_id": this.category_details.id,
        "page_number": page_number
      };
      var response = await this.discussionService.getDiscussionMedias(formData).toPromise();
      this.discussion_photos = [...this.discussion_photos, ...response.data];
      this.total_discussion_photos = response.total_records;
  }
  async getDiscussionMembers(page_number: number){
    let formData = {
      "category_id": this.category_details.id,
      "page_number": page_number
    };
    var response = await this.discussionService.getDiscussionMembers(formData).toPromise();
    this.discussion_members = [...this.discussion_members, ...response.data];
}
  async getDiscussionCategoryDetails() {
    let formData = {
      category_name : this.category_name
    };
    var response = await this.discussionService.getDiscussionCategoryDetails(formData).toPromise();
    this.category_details = response.category_details;
    this.total_members = response.total_members;
    this.total_photos = response.total_photos;
    this.total_videos = response.total_videos;
    this.user_category_status = response.category_status;
    this.showLessDetails();
    this.categoryBannerStyle["background-image"] = "url(" + this.category_banner_image_url + this.category_details.community_banner + ")";
    this.getDiscussionList(1);
    this.category_user_details = response.user_details;
    if(this.category_user_details.user_type == "Vendor"){
      if(this.user_category_status === null || this.user_category_status.vendor_participate_info_check === null || this.user_category_status.vendor_participate_info_check === 0){
        $('#user_participate_modal').modal('toggle');
      } 
    }
    if(this.activeUser){
      if(this.category_user_details.user_type == "Wedding Users"){
        this.showCreateDiscussionForm = true;
      }else{
        this.showCreateDiscussionForm = false;
      }
    }else{
      this.showCreateDiscussionForm = true;
    }
    
    var categories = await this.discussionService.getDiscussionCategories().toPromise();
    this.discussion_topics = categories;
  }
  toggleEventSortDropDown(){
    this.eventSortDropdownIsOpen = !this.eventSortDropdownIsOpen;
  }
  async loadMore(){
    this.current_page_number = this.current_page_number + 1;
    this.getDiscussionList(this.current_page_number);
  }
  showMoreDetails(){
    this.category_banner_text = this.category_details.community_details;
    this.readMoreDetails = false;
  }
  showLessDetails(){
    if(this.category_details.community_details){
      this.category_banner_text = this.category_details.community_details.substring(0, 200);
      this.readMoreDetails = true;
    }else{
      this.category_banner_text = "";
    }
  }
  getSRCofImageData(discussion_image: any){
    if(discussion_image.imageData){
      return discussion_image.imageData;
    }else if(discussion_image.file_name){
      return this.discussion_image_url + discussion_image.file_name;
    }else{
      return "";
    }
  }
  goToDiscussion(discussion_image: any){
    this.router.navigate(["conversation", discussion_image.discussion_slug]);
  }
  async markStatusAsRead(){
    let formData = {
      category_id : this.category_details.id
    };
    var response = await this.discussionService.markMemberParticipateStatusAsRead(formData).toPromise();
    if(response.hasError){
      this.toastr.error(response.message);
      $('#user_participate_modal').modal('hide');
    }else{
      this.toastr.success(response.message);
      this.getDiscussionCategoryDetails();
      $('#user_participate_modal').modal('hide');
    }
  }
  goToTopic(topic: any){
    this.router.navigate(["community", topic.name]);
  }
  closeLoginModalPopup(){
    $('#login_modal').modal('hide');
  }
}
