import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PlannersService } from '../services/planners.service';
import { MemoryService } from '../services/memories.service';
import { Config } from '../../../config';
import { SortablejsOptions } from 'angular-sortablejs';

declare var $: any;

@Component({
  selector: 'app-memory-edit',
  templateUrl: './memory-edit.component.html',
  styleUrls: ['./memory-edit.component.scss']
})
export class MemoryEditComponent implements OnInit {

  memory_title: string = "";
  memory_photos: any[] = [];
  activeUser: any;
  coverPhotoImageData: any = Config.imageUrl + 'images/wedding_website_photos/default_cover_image.jpg';
  memoryPhotoUrl = Config.imageUrl + 'images/memory_photos/';
  selected_memory_photo: any;
  memory_details: any;
  searched_vendors: any[] = [];
  vendorPhotosUrl = Config.imageUrl + 'images/vendor_thumb_images/';
  selected_vendor: any;

  temp_interval: any;

  constructor(
    private toastr: ToastrService,
    private plannersService: PlannersService,
    private memoryService: MemoryService,
    public router: Router,
  ) { 
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    }, (err) => {
      console.error(err);
    });
  }

  memoryPhotosOrderConfig: SortablejsOptions = {
    disabled: false,
    onUpdate: (event: any) => {
      this.updateMemoryPhotosOrder();
    }
  }

  ngOnInit() {
    this.getWeddingMemoryDetails();
  }

  editMemory(){
    let data = {
      id: this.memory_details.id,
      wedding_id: this.activeUser?this.activeUser.id:'',
      memory_title: this.memory_title,
    };
    this.memoryService.editMemory(data).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingMemoryDetails();
    }, error => {
      this.toastr.error(error.message);
      console.log(error);
    });
  }

  previewMemory(){
    this.router.navigate(['memory', this.memory_details.slug]);
  }

  getWeddingMemoryDetails(){
    let data = {
      wedding_id: this.activeUser?this.activeUser.id:''
    };
    this.memoryService.getWeddingMemoryDetails(data).subscribe(res => {
      console.log(res);
      this.memory_details = res.data;
      this.memory_title = this.memory_details.title ? this.memory_details.title : this.memory_title;
      if(this.memory_details.cover_image){
        this.coverPhotoImageData = Config.imageUrl + 'images/memory_photos/' + this.memory_details.cover_image;
      }
      this.memory_photos = this.memory_details.photos;
    }, error => {
      console.log(error);
    });
  }

  coverImageChangeEvent(event: any): void {
    var files = event.target.files;
    let file: File = files[0];
    if (!(file.size > (1024 * 100) && file.size < (1024 * 5 * 1024))) {
      this.toastr.error("Image file size should be between 100KB and 5MB");
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (e: any) => {
      const imgBase64Path = e.target.result;
      this.coverPhotoImageData = imgBase64Path;

      let data = {
        memory_id: this.memory_details.id,
        base64: this.coverPhotoImageData
      };
      this.memoryService.updateCoverImageOfMemory(data).subscribe(res => {
        this.toastr.success(res.message);
      }, error => {
        console.log(error);
      });
    };
  }

  uploadMemoryPhotos(event: any): void {
    var allowed_file_types = ["image/jpeg", "image/jpg", "image/gif", "image/png"];
    var multipleFiles = event.target.files;

    if (multipleFiles) {
      let image_skipped = 0;
      let images = [];
      let i = 0;
      for (var file of multipleFiles) {
        i++;
        let timelineFileType = file.type;
        // if (!allowed_file_types.includes(timelineFileType)) {
        //   image_skipped++;
        //   continue;
        // }
        // if (!(file.size > (1024 * 100) && file.size < (1024 * 500))) {
        //   image_skipped++;
        //   continue;
        // }
        var multipleReader = new FileReader();
        let photo_index = i;
        multipleReader.onload = (e: any) => {
          let data = {
            memory_id: this.memory_details.id,
            base64: e.target.result,
            photo_index: photo_index
          };
          this.memoryService.uploadPhotosOfMemory(data).subscribe(res => {
            this.memory_photos.unshift({base64: e.target.result});
            if(this.temp_interval){
              clearTimeout(this.temp_interval);
              this.temp_interval = setTimeout(()=>{
                this.toastr.success(`Photos uploaded`);
                this.getWeddingMemoryDetails();
              }, 1000);
            }else{
              this.temp_interval = setTimeout(()=>{
                this.toastr.success(`Photos uploaded`);
                this.getWeddingMemoryDetails();
              }, 1000);
            }
            
          }, error => {
            console.log(error);
          });
        }
        multipleReader.readAsDataURL(file);
      }
      
      if (image_skipped > 0) {
        if (image_skipped == 1) {
          this.toastr.error(`${image_skipped} image was failed to upload. Please upload a jpeg, jpg, gif or png image and file size should be between 100KB and 500KB`);
        } else {
          this.toastr.error(`${image_skipped} images were failed to upload. Please upload a jpeg, jpg, gif or png image and file size should be between 100KB and 500KB`);
        }
      }
    }
  }

  updateMemoryPhotosOrder() {
    let newOrder = this.memory_photos.map((content: any, index: number) => {
      return { "gallery_photo_id": content.id, "display_order": index }
    });
    let data = {
      wedding_id: this.activeUser?this.activeUser.id:'',
      display_order: newOrder
    }
    this.memoryService.editMemory(data).subscribe(res => {
      this.toastr.success(res.message);
    }, error => {
      console.log(error);
    });
  }

  openConfirmMemoryPhotoDeleteModal(memory_photo: any) {
    this.selected_memory_photo = memory_photo;
    $('#confirmDeleteModal').modal('show');
  }

  confirmDeletePhotoOfMemory(){
    let formData = {
      "photo_id": this.selected_memory_photo.id
    };
    this.memoryService.deletePhotosOfMemory(formData).subscribe(res => {
      $('#confirmDeleteModal').modal('hide');
      this.getWeddingMemoryDetails();
    }, error => {
      console.log(error);
    });
  }

  openAddVendorModal(){
    this.searched_vendors = [];
    $('#addVendorModal').modal('toggle');
  }

  searchVendors($event: any) {
    let search_text = $event.target.value;
    if(search_text.length <= 2){
      return; 
    }
    if(search_text.length === 0){
      this.searched_vendors = [];
      return;
    }
    let formData = {
      "search_text": search_text
    };
    this.memoryService.searchVendors(formData).subscribe(res => {
      console.log(res);
      this.searched_vendors = res.data;
    }, error => {
      console.log(error);
    });
  }
  selectVendor(vendor: any){
    this.selected_vendor = vendor;
  }
  addVendorToMemory(){
    let formData = {
      "vendor_id": this.selected_vendor.id,
      "memory_id": this.memory_details.id
    };
    this.memoryService.addVendorToMemory(formData).subscribe(res => {
      $('#addVendorModal').modal('hide');
      this.toastr.success(res.message);
      this.getWeddingMemoryDetails();
    }, error => {
      console.log(error);
      $('#addVendorModal').modal('hide');
      this.toastr.error(error.message);
    });
  }
  removeVendorFromMemory(vendor: any){
    let formData = {
      "vendor_id": vendor.id,
      "memory_id": this.memory_details.id
    };
    this.memoryService.removeVendorFromMemory(formData).subscribe(res => {
      this.toastr.success(res.message);
      this.getWeddingMemoryDetails();
    }, error => {
      console.log(error);
      this.toastr.error(error.message);
    });
  }
  goToVendorProfile(vendor: any){
    this.router.navigate(['profile', vendor.slug]);
  }
}
