import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';

declare var $: any;

@Component({
  selector: 'app-custom-faq',
  templateUrl: './custom-faq.component.html',
  styleUrls: ['./custom-faq.component.css']
})
export class CustomFaqComponent implements OnInit {

  submitted = false;
  faqForm: any;
  faqs: any;
  faqLength: number;
  faqFieldValues = [];
  customFaqs = [];
  checkedval = [];
  selectedFaqs: any;
  deleteItem: any;

  customFaqCurrentPage: number = 1;
  pagination_per_Page: number = Config.pagination_per_Page;


  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }

  ngOnInit() {
    this.getFaqs();
    window.scroll(10, 10);
  }

  onSubmit() {
    this.customFaq();
  }

  getFaqs() {
    this.vendorService
      .getCustomFaqs()
      .subscribe(
        (response) => {
          this.customFaqs = response;
          if (this.customFaqs.length == 0) {
            this.addCustomField();
          }
          console.log(this.customFaqs);
        }
      )
  }

  selectFaqs(opVal: any, answer: any) {
    let index = answer.indexOf(opVal);
    if (index > -1) {
      return 1;
    }
  }

  addCustomField() {
    //$(".custom").append("<div class='row p3'><div class='col-sm-7 mb-2'><textarea  class='question form-control p-2' placeholder='Question'></textarea></div><div class='col-sm-4 mb-2'><textarea  class='answer form-control p-2' placeholder='Answer'></textarea></div><div onclick='$($(this).parent()).remove();' class='btn-sm col-sm-1 mb-2 remove_button'><i class='fa fa-trash'></i></div></div>");
    this.customFaqs.push({ questions: '', answer: '' });
  }

  customFaq() {
    var customQuestionAnswer = [];
    
    for (let i = 0; i < this.customFaqs.length; i++) {
      if (this.customFaqs[i].questions == "") {
        this.toastr.error(i + 1 + ".Question can not be blank.");
        return;
      }
      if (this.customFaqs[i].answer == "") {
        this.toastr.error(i + 1 + ".Answer can not be blank.");
        return;
      }
      customQuestionAnswer.push({ "question": this.customFaqs[i].questions, "answer": this.customFaqs[i].answer });
    }

    this.vendorService
      .saveFaqCustom(customQuestionAnswer)
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          //this.getFaqs();                     
          return false;
        }
      )
  }

  deleteQuestion(item: any) {
    this.deleteItem = item;
    if (this.customFaqs.length < 2) {
      this.toastr.error("Sorry you can not delete question.");
      return false;
    }else{
      $("#deletequestion").modal();
    }  
  }

  delete(){
    this.customFaqs.splice(this.customFaqs.indexOf(this.deleteItem), 1);
    $("#deletequestion").modal('hide');
  }
}
