import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { UsersService } from '../../users/services/users.service';
import { LoginModel } from 'src/app/models/login.model';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { ForgotPasswordModel } from 'src/app/models/forgotpassword.model';
import { RegisterModel } from 'src/app/models/register.model';
import { PlannerProfileModel } from 'src/app/models/planner.profile.model';
import { BehaviorSubject } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class BudgetlistService extends BaseService {


  constructor(
    private http: HttpClient,
    protected usersService: UsersService) {
    super(usersService);

  }

  geBudgetCategoryWishCount(id: any) {

    return this.http
      .get(Config.apiBaseUrl + "/budget/budget_catagory_wise_total/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  geBudgetCategory(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/category/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  addBudget(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/budget/budget_catagory", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getEstimatedData(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/budget/budget_expences_list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  submitEstimatedData(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/budget/expences", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }


  getSingleExpenceData(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/expence/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }


  updateEstimatedData(data: any) {
    return this.http
      .put(Config.apiBaseUrl + "/budget/expences", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  expenceDelete(id: any) {
    return this.http
      .delete(Config.apiBaseUrl + "/budget/expence/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  submitPayment(data: any) {
    return this.http
      .post(Config.apiBaseUrl + "/budget/budget_expences_payment", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getPaymentData(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/expence-paymentList/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getSinglePaymentData(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/expence-payment-detail/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  updatePayment(data: any) {
    return this.http
      .put(Config.apiBaseUrl + "/budget/expence-payment", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  paymentDelete(id: any) {
    return this.http
      .delete(Config.apiBaseUrl + "/budget/expence-payment-delete/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getPrintData(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/print_budget/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  geBudgetCost(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/budget_dashboard_total_info/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getPaymentList(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/expence-paymentList-by-event/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  budgetAmountEdit(data: any) {
    return this.http
      .put(Config.apiBaseUrl + "/budget/update-overall-mybudget", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  expenseAddToTrash(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/expence-thrush/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }
  paymentAddToTrash(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/expence-payment-thrush/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getExpenseTrashList(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/expence-thrush-list/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  undoExpence(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/expence-undo/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  undoPayment(id: any) {
    return this.http
      .get(Config.apiBaseUrl + "/budget/expence-payment-undo/" + id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }
}