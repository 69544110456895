import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute  } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';

declare var $: any;

@Component({
  selector: 'app-service-attributes',
  templateUrl: './service-attributes.component.html',
  styleUrls: ['./service-attributes.component.css']
})
export class ServiceAttributesComponent implements OnInit {

  attributes: any;
  selattrs : any;
  selectedattributes: any;
  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }

  ngOnInit() {
     this.getAttributes();
     this.selectedAttributes();
  }

  getAttributes(){
    this.vendorService
      .getAttributes()
      .subscribe(
        (response: any) => {
          //this.toastr.success(response.message);
          this.attributes = response;          
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )


    /*this.attributes = [
      {"id":1,"name":"photography","attrs":[{"id":1,"name":"Contemporary"},{"id":2,"name":"Flim"},{"id":3,"name":"Natural"},{"id":4,"name":"Traditional"}]},
      {"id":2,"name":"Djs","attrs":[{"id":5,"name":"Additional Hours"},{"id":6,"name":"Video"}]},
    ]*/
  }

  selectedAttribute(attrid:number){    
    if(this.selectedattributes.indexOf(attrid)>-1){
      return 1;
    }
  }

  onSubmit() {    
  var choose_services = [];
  $(".faq_ques input[type=checkbox]").each(function(){
    if($(this).prop("checked")){
      choose_services.push($(this).val());
    }
  })
    //console.log(choose_services);return;
    if(choose_services.length){
      this.vendorService
      .saveAttribute(choose_services)
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          //this.customFaq();
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
    }
    else{
      this.toastr.error("Empty form submission.");
    } 
  }

  selectedAttributes(){
    this.vendorService
      .selectedAttributes()
      .subscribe(
        (response: any) => {
          //this.toastr.success(response.message);
          this.selectedattributes = response;
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

}
