import { Deseriazable } from './deserializable.model';

export class EventTypeModel implements Deseriazable{
    
    id: string;
    name: string;
    status: string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}