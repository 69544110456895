import { Component, HostListener, OnInit } from '@angular/core';
import { PlannersService } from '../../planners/services/planners.service';
import { IdeaAndTipsService } from '../services/ideas-and-tips.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../../config';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

declare var $: any;


@Component({
  selector: 'app-wedding-ideas-details',
  templateUrl: './wedding-ideas-details.component.html',
  styleUrls: ['./wedding-ideas-details.component.scss']
})
export class WeddingIdeasDetailsComponent implements OnInit {
  isEditorVisible:boolean = false;
  activeUser: any;
  post_slug: string;
  post_details: any;
  posts: any[] = [];
  displayedPosts: any[] = [];
  articlesPerPage = 5; // Set the number of articles to load per request
  currentPage = 0;
  page_number: number = 1;
  ideaAndTipsPhotoUrl = Config.imageUrl + 'images/idea_and_tips/';

  // Mini CK Editor Config --
  miniCKEditorConfig = {
    height: '6em',
    toolbar: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'links', items: ['Link', 'Unlink','Blockquote'] },
      { name: 'insert' },
      { name: 'styles', items: ['Styles', 'Format', 'Font'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
    ],
    toolbarHiddenButtons: [
      ['fontName', 'insertUnorderedList',
        'insertOrderedList', 'Table', 'FontSize', 'Strike', 'Subscript',
        'Superscript', '-', 'CopyFormatting', 'RemoveFormat', 'CreateDiv', 'Anchor']
    ],
  };

  my_comment: string = "";
  allComments: any[] = [];
  comments: any[] = [];
  total_comments: number = 0;
  selected_comment: any = {};
  isRightAligned = false;
  showComment = false;

  // Edit Form --
  editted_comment: any;
  edit_mode: boolean = false;
  edit_my_comment = "";

  // Reply Form --
  isReplyEditorVisible: boolean = false;
  my_reply: string = "";
  edit_my_reply = "";
  selected_reply: any = null;

  constructor(
    private plannersService: PlannersService,
    public route: ActivatedRoute,
    private ideaAndTipsService: IdeaAndTipsService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.activeUser["role"] = "Wedding Users";
      } else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Vendor"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
      }else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Community Guest"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
        console.log(this.activeUser);
      }else{
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        if (params['slug']) {
          this.post_slug = params['slug'];
          this.getPostDetails(this.post_slug);
        }
      }
    });
  }

  async getPostDetails(slug: string){
    let formData = {
      "slug": slug
    };
    var response = await this.ideaAndTipsService.getIdeaAndTipsArticleDetails(formData).toPromise();
    this.post_details = response.data;
    this.allComments = this.post_details.comments;
    this.comments = this.allComments.slice(0, 5);
    this.allComments.forEach(e => {
      e.showReply = false;
    });
    this.total_comments = this.post_details.total_comments;
    this.page_number = 1;
    this.getIdeaAndTips({
      'cat_id': this.post_details.cat_details.id
    });
  }

  getRichTextContent(content: any) {
    let el = document.createElement("div");
    el.innerHTML = content;
    let tempcontent = el.outerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(tempcontent);
  }

  async getIdeaAndTips(formData: any){
    var response = await this.ideaAndTipsService.getIdeaAndTips(formData).toPromise();
    this.posts = response.data.filter(p => p.slug !== this.post_slug);
    this.loadMoreArticles();
  }

  loadMoreArticles(): void {
    if (this.currentPage < this.articlesPerPage) {
      this.displayedPosts.push(this.posts[this.currentPage]);
      this.currentPage++;
    }
  }

  isLongComment(comment: string): boolean {
    return this.removeHtmlTags(comment).length > 400;
  }

  getTruncatedComment(comment: string): string {
    const plainText = this.removeHtmlTags(comment);
    return plainText.length > 400 ? plainText.slice(0, 400) + '...' : plainText;
  }

  removeHtmlTags(comment: string): string {
    const doc = new DOMParser().parseFromString(comment, 'text/html');
    return doc.body.textContent || "";
  }

  toggleCommentVisibility(commentId: number) {
    const comment = this.allComments.find(c => c.id === commentId);
    if (comment) {
      comment.showFull = !comment.showFull;
    }
  }

  toggleReplyVisibility(commentId: number, replyId: number) {
    const comment = this.allComments.find(c => c.id === commentId);
    if (comment) {
      const reply = comment.replies.find(c => c.id === replyId);
      if(reply){
        reply.showFull = !reply.showFull;
      }
    }
  }

  isFullVisible(commentId: number): boolean {
    const comment = this.allComments.find(c => c.id === commentId);
    return comment ? comment.showFull : false;
  }

  isFullReplyVisible(commentId: number, replyId: number): boolean {
    const comment = this.allComments.find(c => c.id === commentId);
    const reply = comment.replies.find(c => c.id === replyId);
    return reply ? reply.showFull : false;
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight - 100) {
      this.loadMoreArticles(); // Load more articles when scrolling close to the bottom
    }
  }

  getUserName(userDetails: any){
    let name = userDetails.firstname;
    if(userDetails.lastname){
      return name + " " + userDetails.lastname;
    }
    return name;
  }
  canModify(user_id: any){
    const currentUserId = localStorage.getItem("UID");
    if(currentUserId && (parseInt(currentUserId) === parseInt(user_id))){
      return true;
    }
    return false;
  }
  showEditor(){
    if(this.activeUser){
      this.isEditorVisible = true;
      this.selected_comment = null;
      this.my_comment = "";
    }else{
      $('#login_modal').modal('toggle');
    }
  }
  hideEditor(){
    this.isEditorVisible = false;
  }
  showReplyEditor(comment: any){
    if(this.activeUser){
      this.selected_comment = comment;
      this.isReplyEditorVisible = true;
      this.selected_reply = null;
      this.my_reply = "";
    }else{
      $('#login_modal').modal('toggle');
    }
  }
  hideReplyEditor(){
    this.isReplyEditorVisible = false;
  }

  async postComment(){
    let formData = {};
    if(this.selected_comment){
      let editorData = this.edit_my_comment.trim();  // Getting the content and trimming spaces

      let strippedContent = editorData.replace(/<\/?[^>]+(>|$)/g, '').trim();
  
      // Check if content is empty
      if (!strippedContent || strippedContent === '<p>&nbsp;</p>' || strippedContent === '<p><br></p>') {
        this.toastr.error("Comment may not be blank");
        return false;
      }
  
      if (strippedContent.length > 5000) {
        this.toastr.error("5,000 character maximum exceeded");
        return false;  // Prevent form submission or further processing
      }
      formData = {
        "id": this.selected_comment.id,
        "comment": this.edit_my_comment,
        "article_id": this.post_details.id,
      };
    }else{
      let editorData = this.my_comment.trim();  // Getting the content and trimming spaces

      let strippedContent = editorData.replace(/<\/?[^>]+(>|$)/g, '').trim();
  
      // Check if content is empty
      if (!strippedContent || strippedContent === '<p>&nbsp;</p>' || strippedContent === '<p><br></p>') {
        this.toastr.error("Comment may not be blank");
        return false;
      }
  
      if (strippedContent.length > 5000) {
        this.toastr.error("5,000 character maximum exceeded");
        return false;  // Prevent form submission or further processing
      }
      formData = {
        "comment": this.my_comment,
        "article_id": this.post_details.id,
      };
    }
    var response = await this.ideaAndTipsService.postComment(formData).toPromise();

    if(response.hasError){
      this.toastr.error(response.message);
    }else{
      if(this.selected_comment){
        this.edit_my_comment = "";
        this.edit_mode = false;
        $('#update_comment_modal').modal('toggle');
      }else{
        this.my_comment = "";
        this.isEditorVisible = false;  
      }
      this.getPostDetails(this.post_details.slug);
      this.toastr.success(response.message);
    }
  }
  async postReply(){
    let formData = {};
    if(this.selected_reply){
      let editorData = this.edit_my_reply.trim();  // Getting the content and trimming spaces

      let strippedContent = editorData.replace(/<\/?[^>]+(>|$)/g, '').trim();
  
      // Check if content is empty
      if (!strippedContent || strippedContent === '<p>&nbsp;</p>' || strippedContent === '<p><br></p>') {
        this.toastr.error("Reply may not be blank");
        return false;
      }
  
      if (strippedContent.length > 5000) {
        this.toastr.error("5,000 character maximum exceeded");
        return false;  // Prevent form submission or further processing
      }
      formData = {
        "id": this.selected_reply.id,
        "comment_id": this.selected_comment.id,
        "reply_message": this.edit_my_reply
      };
    }else{
      let editorData = this.my_reply.trim();  // Getting the content and trimming spaces

      let strippedContent = editorData.replace(/<\/?[^>]+(>|$)/g, '').trim();
  
      // Check if content is empty
      if (!strippedContent || strippedContent === '<p>&nbsp;</p>' || strippedContent === '<p><br></p>') {
        this.toastr.error("Reply may not be blank");
        return false;
      }
  
      if (strippedContent.length > 5000) {
        this.toastr.error("5,000 character maximum exceeded");
        return false;  // Prevent form submission or further processing
      }
      formData = {
        "comment_id": this.selected_comment.id,
        "reply_message": this.my_reply
      };
    }
    var response = await this.ideaAndTipsService.postReply(formData).toPromise();
    if(response.hasError){
      this.toastr.error(response.message);
    }else{
      if(this.selected_reply){
        this.edit_my_reply = "";
        this.edit_mode = false;
        $('#update_reply_modal').modal('toggle');  
      }else{
        this.my_reply = "";
        this.isReplyEditorVisible = false;
      }
      this.getPostDetails(this.post_details.slug);
      this.toastr.success(response.message);
    }
  }

  async load_more_comments(){
    const currentLength = this.comments.length;
    if(this.allComments.length >= currentLength){
      this.comments = this.allComments.slice(0, (currentLength + 5));
    }
  }

  async edit_comment(comment: any){
    this.edit_mode = true;
    this.selected_comment = comment;
    this.edit_my_comment = comment.comment;
    $('#update_comment_modal').modal('show');
  }

  edit_reply(comment: any, reply: any){
    this.edit_mode = true;
    this.selected_comment = comment;
    this.selected_reply = reply;
    this.edit_my_reply = reply.reply_message;
    $('#update_reply_modal').modal('show');
  }

  confirm_delete_comment(comment: any){
    this.selected_comment = comment;
    $('#delete_comment_modal').modal('show');
  }

  confirm_delete_reply(reply: any){
    this.selected_reply = reply;
    $('#delete_reply_modal').modal('show');
  }

  async confirmDeleteComment(){
    console.log(this.selected_comment);
    let formData = {
      "comment_id": this.selected_comment.id
    };
    try{
      var response = await this.ideaAndTipsService.deleteComment(formData).toPromise();
      console.log(response);
      if(response.hasError === false){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
      }

      // this.comments = this.comments.filter((comment)=>{
      //   return this.selected_comment.id !== comment.id
      // });

      this.selected_comment = null;
      $('#delete_comment_modal').modal('toggle');
      this.getPostDetails(this.post_details.slug);
    }catch(error){
      this.toastr.error(error.message);
      $('#delete_comment_modal').modal('toggle');
    }
  }

  async deleteReply(){
    let formData = {
      "reply_id": this.selected_reply.id
    };
    try{
      var response = await this.ideaAndTipsService.deleteReply(formData).toPromise();
      console.log(response);
      if(response.hasError === false){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
      }
      this.selected_reply = null;
      $('#delete_reply_modal').modal('toggle');
      this.getPostDetails(this.post_details.slug);
    }catch(error){
      this.toastr.error(error.message);
      $('#delete_reply_modal').modal('toggle');
    }
  }
}
