import { Component, OnInit } from '@angular/core';
import { BudgetlistService } from '../services/budgetlist.service';
import { PlannersService } from '../services/planners.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { async } from '@angular/core/testing';
import { Config } from '../../../config';
import { ActivatedRoute, Router } from '@angular/router';



declare var $: any;
@Component({
  selector: 'app-budget',
  templateUrl: './budget.component.html',
  styleUrls: ['./budget.component.css']
})
export class BudgetComponent implements OnInit {

  showDropdown: boolean = false
  donutChartData: any;
  deleteModalTitle: string;
  deleteModalbody: string;
  activeUser: any;
  categorys: any;
  budgetCategoryWishCount: any;
  budgetCategory: any;
  budgetCategoryId: any = null;
  budgetCategoryName: any;
  category_name: any;
  createLevel: string;
  category: any;
  expense: any;
  completed: number;
  public AddBudgetForm: FormGroup;
  submitted = false;
  numberRegEx = /^[0-9]*\.[0-9]{2}$/;
  formData: any;
  progressValue: number;
  expenseId: any;
  showDiv: boolean = false;
  selectCategory: boolean[] = [];
  public AddPaymentForm: FormGroup;
  expensesId: any;
  paymentData: any;
  singlePaymentData: any;
  editPayment: boolean = false;

  selectBudgetCategory: boolean[] = [];

  chartData: any = [];
  totalBudgetAmount: any;
  allPaymentData: any;
  weddingBudgetCost: any;
  allApiPaymentData: any;
  orderText: any = 'Date of payment';
  showSelected: number = 1;
  openElement: HTMLElement;
  summary: any;
  overAllSummary: number;
  overAll: boolean;
  weddings: any;
  modalLavel: string;
  modalText: string;
  expenseIndex: any;
  deleteType: any;
  isSame: boolean = true;
  clickElement: HTMLElement;
  budgetExpense: number;
  budgetStatus: boolean;
  budgetValueEdit: boolean = true;
  invalidAmmount: boolean = false;
  collectionSummaryData = {
    title: "",
    type: "PieChart",
    data: [],
    columnNames: ['Item', 'Percentage', 'Id'],
    options: {
      theme: 'maximized',
      colors: ['#7cb342', '#e67e22', '#16d729', '#3498db', '#c64191', '#face75', '#EF9A9A', '#F06292', '#8E24AA', '#512DA8', '#283593',
        '#1565C0', '#0288D1', '#00838F', '#00695C', '#9E9D24', '#FFF59D', '#FFCC80'],
      pieHole: 0.5,
      backgroundColor: { fill: 'transparent' },
      legend: { position: 'none' }
    }
  };
  budgetShorting: boolean = false;
  actualCostShorting: boolean = false;
  paidShorting: boolean = false;
  dueShorting: boolean = false;
  filterSortLabel: string = 'My Budget';
  constructor(private plannersService: PlannersService,
    private budgetListService: BudgetlistService, public router: Router, private toastr: ToastrService,
    public formBuilder: FormBuilder, private _route: ActivatedRoute) {

    this.AddBudgetForm = this.formBuilder.group({
      name: ['', Validators.required],
      my_budget: ['', Validators.compose([Validators.required])],
      total_cost: [''],
      total_paid: [''],
      // total_due: ['', Validators.pattern(this.numberRegEx)],
      note: [''],

    });

    this.AddPaymentForm = this.formBuilder.group({
      payment_amount: ['', Validators.compose([Validators.required])],
      date_of_payment: ['', Validators.required],
      payment_due_by: [''],
      payment_method: [''],
      payer: [''],
      is_paid: [''],
    });

    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }

    },
      (err) => {
        console.error(err);
      });

    this._route.params.subscribe((params: any) => {

      this.budgetCategoryId = params.id;
      this.budgetCategoryName = params.name;
      if (this.budgetCategoryId && this.budgetCategoryId != '-99') {
        this.selectBudgetCategory = [];
        this.selectBudgetCategory[this.budgetCategoryId] = !this.selectBudgetCategory[this.budgetCategoryId];
        this.getCategoryData();
      } else if (params.id === '-99') {
        this.clickTrash(-99)
      }
      // else if(params.id === 'payment'){
      //   $('#payments-tab').show();
      // }
    });
  }

  async ngOnInit() {
    if (this.activeUser === null) {
      await this.getWeddings();
    }

    await this.defaultBudgetCategorys();
    await this.totalBudgetCost();
    await this.allPaymentList(0);
    // await this.budgetCategoryCount();
    $('#noteGroup').on('hidden.bs.modal', () => {
      this.clickElement = document.getElementById('samePaid');
      this.AddBudgetForm.reset();
      this.submitted = false;

    });

    $('#totalDue').on('hidden.bs.modal', () => {
      this.AddPaymentForm.reset();
      this.submitted = false;
    });

  }

  budgetEditClick() {
    this.budgetValueEdit = false;
  }
  cancelEditBudget() {
    this.budgetValueEdit = true;
  }

  async getWeddings() {
    var allUsers = await this.plannersService.getWeddings().toPromise();
    this.weddings = allUsers;
    this.plannersService.storeWeddings(this.weddings);
    var weddings = this.weddings.filter((x: any) => x.is_active == 1);
    var result = null;
    if (weddings.length > 0) {
      result = weddings[0];
      this.activeUser = result;
      this.plannersService.storeActiveUser(result);
    }
  }


  get f() {
    return this.AddBudgetForm.controls;
  }
  get g() {
    return this.AddPaymentForm.controls;
  }

  async allPaymentList(type: any) {
    var payment = await this.budgetListService.getPaymentList(this.activeUser.id).toPromise();
    this.allApiPaymentData = payment;
    this.allPaymentData = this.allApiPaymentData;
    if (type == 0) {
      for (let i = 0; i < this.allApiPaymentData.length; i++) {
        var shortingData = this.allApiPaymentData.filter((x: any) => x.is_soft_delete == 0);
      }
      this.allPaymentData = shortingData;
    } else {
      for (let i = 0; i < this.allApiPaymentData.length; i++) {
        var shortingData = this.allApiPaymentData.filter((x: any) => x.is_soft_delete == 1);
      }
      this.allPaymentData = shortingData;
    }
  }

  async totalBudgetCost() {
    var cost = await this.budgetListService.geBudgetCost(this.activeUser.id).toPromise();
    this.totalBudgetAmount = cost;
    this.weddingBudgetCost = this.totalBudgetAmount.total_budget_cost.toFixed(2);
    this.summary = this.totalBudgetAmount.total_budget_cost - this.totalBudgetAmount.total_actual_cost;
    if (this.summary > 0 || this.summary == 0) {
      this.overAll = true;
    } else {
      this.overAll = false;
    }
    this.overAllSummary = Math.abs(this.summary);
  }
  async defaultBudgetCategorys() {
    var budget = await this.budgetListService.geBudgetCategory(this.activeUser.id).toPromise();
    this.budgetCategory = budget;
    var collectionData = [];
    const catLength = this.budgetCategory.length;
    for (let idx = 0; idx < catLength; idx++) {
      this.selectCategory[idx] = false;
    }
    this.budgetCategory.forEach((o: any, i: any) => {
      if (o.total_actual_cost > 0) {
        collectionData.push([this.transform(o.name), Math.round(o.total_actual_cost), o.id]);
      } else if (o.total_my_budget > 0) {
        collectionData.push([this.transform(o.name), Math.round(o.total_my_budget), o.id]);
      }
      this.collectionSummaryData.data = collectionData;
    });

  }
  onBudgetKeyDown(e: any) {

    var p2 = e.target.value.split('.')[1];
    if (p2 && p2.length > 2) {
      this.invalidAmmount = true;
    } else {
      this.invalidAmmount = false;
    }
    if (e.which == 13) {
      this.editBudgetSubmit();
    }

  }

  myActualCost(e: any) {
    var p2 = e.target.value.split('.')[1];
    if (p2 && p2.length > 2) {
      this.AddBudgetForm.controls['total_cost'].setErrors({ 'incorrect': true });
    }

    if (e.target.value == this.AddBudgetForm.value.my_budget) {
      this.isSame = true;
    } else {
      this.isSame = false;
    }
  }

  myBudgetAmount(e: any) {
    var p2 = e.target.value.split('.')[1];
    var regex = /^[a-zA-Z]*$/;
    if (p2 && p2.length > 2) {
      this.AddBudgetForm.controls['my_budget'].setErrors({ 'incorrect': true });
      this.AddBudgetForm.controls['total_cost'].setErrors({ 'incorrect': true });
    }
    if (this.isSame == true) {
      this.AddBudgetForm.get('total_cost').setValue(e.target.value);
    }

  }

  transform(value: string) {
    let first = value.substr(0, 1).toUpperCase();
    return first + value.substr(1).toLowerCase();
  }

  async budgetCategoryCount() {
    var countCategory = await this.budgetListService.geBudgetCategoryWishCount(this.activeUser.id).toPromise();
    this.budgetCategoryWishCount = countCategory;

  }


  clickCategory(data: any, i: number) {
    window.scroll(0, 0);
    this.router.navigate(['budget', { id: data.id, name: data.name }]);
    this.selectBudgetCategory = [];
    this.selectBudgetCategory[data.id] = !this.selectBudgetCategory[data.id];

    // var chart = new google.visualization.PieChart(document.getElementById('chartCollection'));

    // var selectedItem = chart.getSelection()[i];
    // console.log('The user selectedItem ' , selectedItem);
    // if (selectedItem) {
    //   var value = data.getValue(selectedItem.row, selectedItem.column);
    //   console.log('The user selected ' , value);
    // }

    this.budgetCategoryId = data.id;
    this.budgetCategoryName = data.name;
    this.getCategoryData();
  }

  clickTrash(data: any) {
    window.scroll(0, 0);
    this.router.navigate(['budget', { id: data, name: 'In Trash' }]);
    this.budgetCategoryId = data;
    this.budgetCategoryName = 'In Trash';
    this.expense = [];
    this.budgetListService.getExpenseTrashList(this.activeUser.id).subscribe((result: any) => {
      this.expense = result;
    },
      error => {
        this.toastr.error(error.message);
      })
  }

  clickPaymentCategory(data: any, i: number) {
    this.openElement = document.getElementById('overview-tab');
    this.openElement.click();
    this.budgetCategoryId = data.expense.budget_catagory_id;
    this.budgetCategoryName = data.expense.catagory.name;
    this.getCategoryData();
  }

  getCategoryData() {
    let submitData = {
      wedding_id: this.activeUser.id,
      budget_catagory_id: this.budgetCategoryId
    };
    this.budgetListService.getEstimatedData(submitData).subscribe((result: any) => {
      this.category = result.data;
      this.progressValue = this.category.total_budget_cost - this.category.total_actual_cost;
      if (this.progressValue < 0) {
        this.budgetStatus = true;
      } else {
        this.budgetStatus = false;
      }
      var p = 100 / this.category.total_budget_cost * this.progressValue;
      this.completed = Math.round(p);
      this.completed = Math.abs(this.completed);
      if (this.completed > 100) {
        this.completed = 100;
      }
      this.budgetExpense = Math.abs(this.progressValue);

      this.expense = this.category.list;
    },
      error => {
        this.toastr.error(error.message);
      })

  }

  removeCategory() {
    // this.budgetCategoryId = null;
    this.router.navigate(['budget']);
  }

  addBudget() {
    let data = {
      wedding_id: this.activeUser.id,
      category_name: this.category_name
    };
    this.budgetListService.addBudget(data).subscribe((result: any) => {
      this.toastr.success(result.message);
      this.setData();
    },
      error => {
        this.toastr.error(error.message);
      })
  }

  async setData() {
    await this.defaultBudgetCategorys();
  }

  async addNew(type: any, id: number) {

    if (type == 0) {
      this.createLevel = 'Create';
      this.isSame = true;
      $('#noteGroup').modal('toggle');
    } else {
      this.createLevel = 'Update';
      if (id > 0) {
        await this.getSingleData(id);
        $('#noteGroup').modal('toggle');
      }
    }

  }

  async getSingleData(id: number) {
    var singleData = await this.budgetListService.getSingleExpenceData(id).toPromise();
    await this.setFormData(singleData);
  }

  async setFormData(data: any) {
    this.formData = data;
    this.AddBudgetForm.get('name').setValue(this.formData.expense);
    this.AddBudgetForm.get('my_budget').setValue(this.formData.estimeted_cost);
    this.AddBudgetForm.get('total_cost').setValue(this.formData.final_cost);
    this.AddBudgetForm.get('total_paid').setValue(this.formData.paid);

    if (this.formData.estimeted_cost == this.formData.final_cost) {
      this.isSame = true;
    } else {
      this.isSame = false;
    }
    // if (this.formData.final_cost > 0) {
    //   this.AddBudgetForm.get('total_due').setValue(this.formData.final_cost - this.formData.paid);
    // } else {
    //   this.AddBudgetForm.get('total_due').setValue(this.formData.total_due);
    // }

    this.AddBudgetForm.get('note').setValue(this.formData.remarks);
  }

  sameAs(e: any) {
    if (e.target.checked == true) {
      this.isSame = true;
      this.AddBudgetForm.get('total_cost').setValue(this.AddBudgetForm.value.my_budget);
    } else {
      this.isSame = false;
      this.AddBudgetForm.get('total_cost').setValue('');
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.AddBudgetForm.invalid) {
      return;
    }
    var estimeted_cost = this.replaceAmount(this.AddBudgetForm.value.my_budget);
    var total_cost = this.replaceAmount(this.AddBudgetForm.value.total_cost);
    if (isNaN(estimeted_cost)) {
      this.AddBudgetForm.controls['my_budget'].setErrors({ 'incorrect': true });
    }
    if (isNaN(total_cost)) {
      this.AddBudgetForm.controls['total_cost'].setErrors({ 'incorrect': true });
    }
    if (this.AddBudgetForm.invalid) {
      return;
    }
    let submitData = {
      event_id: this.activeUser.id,
      budget_catagory_id: this.budgetCategoryId,
      expense: this.AddBudgetForm.value.name,
      estimeted_cost: this.AddBudgetForm.value.my_budget ? estimeted_cost : 0,
      final_cost: this.AddBudgetForm.value.total_cost ? total_cost : 0,
      paid: this.AddBudgetForm.value.total_paid ? this.AddBudgetForm.value.total_paid : 0,
      due_amount: this.AddBudgetForm.value.total_due ? this.AddBudgetForm.value.total_due : 0,
      remarks: this.AddBudgetForm.value.note,
      is_paid: 0,
      payment_due_by: null,
      date_of_payment: null,
      payer: null,
      payment_method: null,
    };

    this.budgetListService.submitEstimatedData(submitData).subscribe((result: any) => {
      this.toastr.success(result.message);
      $('#noteGroup').modal('toggle');
      this.submitted = false;
      this.pageReset();

    },
      error => {
        this.toastr.error(error.message);
      });
  }

  checkAmount(e: any) {
    var p2 = e.target.value.split('.')[1];
    if (p2 && p2.length > 2) {
      this.AddPaymentForm.controls['payment_amount'].setErrors({ 'incorrect': true });
    }

  }

  replaceAmount(e: any) {
    var n = e.replaceAll("$", "");
    var n = n.replaceAll(",", "");
    var cost = Number(n);
    return cost;
  }

  onUpdate() {
    this.submitted = true;
    if (this.AddBudgetForm.invalid) {
      return;
    }
    var cost = this.AddBudgetForm.value.my_budget.toString();
    var total = this.AddBudgetForm.value.total_cost.toString();
    var estimeted_cost = this.replaceAmount(cost);
    var total_cost = this.replaceAmount(total);
    if (isNaN(estimeted_cost)) {
      this.AddBudgetForm.controls['my_budget'].setErrors({ 'incorrect': true });
    }
    if (isNaN(total_cost)) {
      this.AddBudgetForm.controls['total_cost'].setErrors({ 'incorrect': true });
    }
    if (this.AddBudgetForm.invalid) {
      return;
    }
    let submitData = {
      id: this.formData.id,
      event_id: this.activeUser.id,
      budget_catagory_id: this.formData.budget_catagory_id,
      expense: this.AddBudgetForm.value.name,
      estimeted_cost: this.AddBudgetForm.value.my_budget ? estimeted_cost : 0,
      final_cost: this.AddBudgetForm.value.total_cost ? total_cost : 0,
      paid: this.AddBudgetForm.value.total_paid ? Number(this.AddBudgetForm.value.total_paid) : 0,
      due_amount: this.AddBudgetForm.value.total_due ? Number(this.AddBudgetForm.value.total_due) : 0,
      remarks: this.AddBudgetForm.value.note,
      is_paid: this.formData.is_paid,
      payment_due_by: this.formData.payment_due_by,
      date_of_payment: this.formData.date_of_payment,
      payer: this.formData.payer,
      payment_method: this.formData.payment_method,
    };

    this.budgetListService.updateEstimatedData(submitData).subscribe((result: any) => {
      this.toastr.success(result.message);
      $('#noteGroup').modal('toggle');
      this.submitted = false;
      this.pageReset();

    },
      error => {
        this.toastr.error(error.message);
      });
  }

  async pageReset() {
    this.chartData = [];
    this.getCategoryData();
    await this.defaultBudgetCategorys();
    await this.totalBudgetCost();
    await this.allPaymentList(0);
  }

  expDelete(data: any, type: any, index: any) {
    this.deleteType = type;
    if (type == 0) {
      this.modalLavel = 'Remove Expense';
      this.modalText = 'Are you sure you want to remove this expense?';
    } else {
      this.expenseIndex = index;
      this.modalLavel = 'Delete';
      this.modalText = 'Are you sure that you want to permanently delete this expense?';
    }
    $('#DeleteCategory').modal('toggle');
    this.expenseId = data.id;
  }

  undoExpense(index: any, id: any) {
    this.budgetListService.undoExpence(id).subscribe((result: any) => {
      this.toastr.success('Successfully restored to expense');
      this.expense.splice(index, 1);
      this.defaultBudgetCategorys();
      this.totalBudgetCost();
    },
      error => {
        this.toastr.error(error.message);
      });
  }
  permanentDeleteExp(index: any, data: any) {
    this.budgetListService.expenceDelete(data.id).subscribe((result: any) => {

      this.toastr.success('Expence was deleted');
      this.expense.splice(index, 1);
    },
      error => {
        this.toastr.error(error.message);
      })
  }

  deletePaymentPermanently(id: any, index: any) {
    this.budgetListService.paymentDelete(id).subscribe((result: any) => {
      this.toastr.success('Payment was deleted');
      this.allPaymentList(1);
      this.allPaymentData.splice(index, 1);
      this.paymentShort(1);
    },
      error => {
        this.toastr.error(error.message);
      });
  }

  undoPayment(id: any, index: any) {
    this.budgetListService.undoPayment(id).subscribe((result: any) => {
      this.toastr.success('Successfully moved to payment');
      this.allPaymentData.splice(index, 1);
      this.allPaymentList(1);
    },
      error => {
        this.toastr.error(error.message);
      });

  }

  delete() {
    if (this.deleteType == 0) {
      this.budgetListService.expenseAddToTrash(this.expenseId).subscribe((result: any) => {
        this.pageReset();
        this.toastr.success('Successfully moved to trash');

      },
        error => {
          this.toastr.error(error.message);
        })
    } else {
      this.budgetListService.expenceDelete(this.expenseId).subscribe((result: any) => {
        this.toastr.success('Expense was deleted');
        this.expense.splice(this.expenseIndex, 1);
      },
        error => {
          this.toastr.error(error.message);
        })
    }
    $('#DeleteCategory').modal('toggle');

  }

  async paid(data: any, j: number) {
    this.selectCategory = [];
    this.expensesId = data.id;
    await this.getPaymentDetails(j);
  }

  async getPaymentDetails(j: any) {
    var result = await this.budgetListService.getPaymentData(this.expensesId).toPromise();
    this.paymentData = result;

    if (this.paymentData.length > 0) {

      this.selectCategory[j] = !this.selectCategory[j];
    } else {
      var now = new Date();
      this.AddPaymentForm.get('payment_due_by').setValue(now);
      $('#totalDue').modal('toggle');
    }
  }

  removePayment() {
    this.selectCategory = [];
  }

  async addPayment(type: any, id: number) {
    var now = new Date();
    this.AddPaymentForm.reset();
    if (type > 0 && id > 0) {
      this.editPayment = true;
      await this.getSinglePaymentDetails(id);
    } else {
      this.AddPaymentForm.get('payment_due_by').setValue(now);
    }
    $('#totalDue').modal('toggle');
  }

  async getSinglePaymentDetails(id: number) {
    var result = await this.budgetListService.getSinglePaymentData(id).toPromise();
    this.singlePaymentData = result;
    var event_date = new Date(this.datetimeToDate(this.singlePaymentData.date_of_payment));
    if (this.singlePaymentData.payment_due_by) {
      var due_date = new Date(this.datetimeToDate(this.singlePaymentData.payment_due_by));
      this.AddPaymentForm.get('payment_due_by').setValue(due_date);
    }

    this.AddPaymentForm.get('date_of_payment').setValue(event_date);
    this.AddPaymentForm.get('payment_amount').setValue(this.singlePaymentData.payment_amount);
    this.AddPaymentForm.get('payment_method').setValue(this.singlePaymentData.payment_method);
    this.AddPaymentForm.get('payer').setValue(this.singlePaymentData.payer);
    this.AddPaymentForm.get('is_paid').setValue(this.singlePaymentData.is_paid);
  }

  datetimeToDate(datetime: any) {
    if (!datetime) return;
    var dateTime = datetime.split(" ");
    var exp = dateTime[0].split("-");
    var year = exp[0];
    var month = exp[1];
    var day = exp[2];
    return [month, day, year].join('/');
  }



  submitPayment() {
    this.submitted = true;
    if (this.AddPaymentForm.invalid) {
      return;
    }
    var payment_amount = this.replaceAmount(this.AddPaymentForm.value.payment_amount);
    if (isNaN(payment_amount)) {
      this.AddPaymentForm.controls['payment_amount'].setErrors({ 'incorrect': true });
      return;
    }

    if (this.AddPaymentForm.invalid) {
      return;
    }

    if (this.AddPaymentForm.value.date_of_payment) {
      var date_of_payment = this.usDateToDb($("#date_of_payment").val());
      var date_of_payment = this.changeDateFormat(date_of_payment);
    } else {
      var date_of_payment = '';
    }
    if (this.AddPaymentForm.value.payment_due_by) {
      var payment_due_by = this.usDateToDb($("#payment_due_by").val());
      var payment_due_by = this.changeDateFormat(payment_due_by);
    } else {
      var payment_due_by = '';
    }

    let submitData = {
      expenses_id: this.expensesId,
      // is_paid: this.AddPaymentForm.value.is_paid ? 1 : 0,
      is_paid: 1,
      payment_amount: payment_amount,
      date_of_payment: date_of_payment,
      payment_due_by: payment_due_by,
      payment_method: this.AddPaymentForm.value.payment_method,
      payer: this.AddPaymentForm.value.payer,
      event_id: this.activeUser.id
    };

    this.budgetListService.submitPayment(submitData).subscribe((result: any) => {
      this.toastr.success(result.message);
      $('#totalDue').modal('toggle');
      this.submitted = false;
      this.editPayment = false;
      this.pageReset();

    },
      error => {
        this.toastr.error(error.message);
      });


  }

  updatePayment() {
    this.submitted = true;
    if (this.AddPaymentForm.invalid) {
      return;
    }
    var amount = this.AddPaymentForm.value.payment_amount.toString();
    var payment_amount = this.replaceAmount(amount);
    if (isNaN(payment_amount)) {
      this.AddPaymentForm.controls['payment_amount'].setErrors({ 'incorrect': true });
      return;
    }

    if (this.AddPaymentForm.invalid) {
      return;
    }
    var date_of_payment = this.usDateToDb($("#date_of_payment").val());
    var date_of_payment = this.changeDateFormat(date_of_payment);

    var payment_due_by = this.usDateToDb($("#payment_due_by").val());
    var payment_due_by = this.changeDateFormat(payment_due_by);

    let submitData = {
      id: this.singlePaymentData.id,
      expenses_id: this.singlePaymentData.expenses_id,
      is_paid: this.AddPaymentForm.value.is_paid ? 1 : 0,
      payment_amount: payment_amount,
      date_of_payment: date_of_payment,
      payment_due_by: payment_due_by,
      payment_method: this.AddPaymentForm.value.payment_method,
      payer: this.AddPaymentForm.value.payer,
      event_id: this.activeUser.id
    };

    this.budgetListService.updatePayment(submitData).subscribe((result: any) => {
      this.toastr.success(result.message);
      $('#totalDue').modal('toggle');
      this.submitted = false;
      this.editPayment = false;
      this.pageReset();
    },
      error => {
        this.toastr.error(error.message);
      });


  }


  changeDateFormat(date: any) {
    var d = date.split("-"),
      day = d[1],
      year = d[0],
      month = d[2];
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }


  usDateToDb(date: any) {
    var d = date.split("/"),
      month = d[1],
      day = d[0],
      year = d[2];
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }


  deletePayment(id: any, index: any, type: any) {
    this.budgetListService.paymentAddToTrash(id).subscribe((result: any) => {
      this.toastr.success('Payment moved to trash');
      this.allPaymentList(0);
      if (type == 1) {
        this.paymentData.splice(index, 1);
        this.getCategoryData();
      } else {
        this.allPaymentData.splice(index, 1);
        this.paymentShort(0);
      }

    },
      error => {
        this.toastr.error(error.message);
      });
  }



  download() {
    var authkey = localStorage.getItem("USER_TOKEN");
    window.open(`${Config.apiBaseUrl}/budget/download_budget/${this.activeUser.id}?token=${authkey}`);
    return;
  }

  randomColorGenerator() {
    return '#' + (Math.random().toString(16) + '0000000').slice(2, 8);
  }

  showEditBudget() {
    $('#editBudget').modal('toggle');
  }



  editBudgetSubmit() {
    var n = this.weddingBudgetCost.replaceAll("$", "");
    var n = n.replaceAll(",", "");
    // var n = n.replaceAll(".00", "");
    var cost = Number(n);
    if (isNaN(cost)) {
      this.invalidAmmount = true;
      return;
    } else {
      let submitData = {
        overall_target: cost,
        event_id: this.activeUser.id
      };
      this.budgetListService.budgetAmountEdit(submitData).subscribe((result: any) => {
        this.toastr.success(result.message);
        this.budgetValueEdit = true;
        this.invalidAmmount = false;
        this.pageReset();
      },
        error => {
          this.toastr.error(error.message);
        });
    }

  }

  paymentShort(isPayed: any) {
    if (isPayed < 0) {
      this.showSelected = 1;
      this.allPaymentData = this.allApiPaymentData;
    } else {
      if (isPayed == 0) {
        this.showSelected = 1;
      } else {
        this.showSelected = 3;
      }

      for (let i = 0; i < this.allApiPaymentData.length; i++) {
        var shortingData = this.allApiPaymentData.filter((x: any) => x.is_soft_delete == isPayed);
      }
      this.allPaymentData = shortingData;
      if (this.allPaymentData && this.allPaymentData.length > 0) {
        this.allPaymentData.sort((a: any, b: any) => (a.date_of_payment > b.date_of_payment) ? 1 : -1);
      }

    }

  }

  orderBy(type: any, lavel: any) {
    this.showDropdown = !this.showDropdown;
    this.orderText = lavel;
    if (type == 1) {
      this.allPaymentData.sort((a: any, b: any) => (a.payer > b.payer) ? 1 : -1);
    } else if (type == 2) {
      this.allPaymentData.sort((a: any, b: any) => (a.expense.budget_catagory_id > b.expense.budget_catagory_id) ? 1 : -1);
    }
    else if (type == 3) {
      this.allPaymentData.sort((a: any, b: any) => (a.date_of_payment > b.date_of_payment) ? 1 : -1);
    }
    else if (type == 4) {
      this.allPaymentData.sort((a: any, b: any) => (a.payment_amount > b.payment_amount) ? 1 : -1);
    }
    else if (type == 5) {
      this.allPaymentData.sort((a: any, b: any) => (a.payment_method > b.payment_method) ? 1 : -1);
    }

  }

  toggleStatus() {
    this.showDropdown = !this.showDropdown;
  }

  closeDropdown() {
    this.showDropdown = !this.showDropdown;
  }


  budgetShort(e: any) {
    this.budgetShorting = !e;
    this.filterSortLabel = 'My Budget';
    if (this.expense && this.expense.length > 0) {
      if (this.budgetShorting === false) {
        this.expense.sort((a: any, b: any) => (a.estimeted_cost > b.estimeted_cost) ? -1 : 1);
      } else {
        this.expense.sort((a: any, b: any) => (a.estimeted_cost > b.estimeted_cost) ? 1 : -1);
      }
    }
  }

  actualCostShort(e: any) {
    this.actualCostShorting = !e;
    this.filterSortLabel = 'Actual Cost';
    if (this.expense && this.expense.length > 0) {
      if (this.actualCostShorting === false) {
        this.expense.sort((a: any, b: any) => (a.final_cost > b.final_cost) ? -1 : 1);
      } else {
        this.expense.sort((a: any, b: any) => (a.final_cost > b.final_cost) ? 1 : -1);
      }
    }
  }
  paidShort(e: any) {
    this.paidShorting = !e;
    this.filterSortLabel = 'Paid';
    if (this.expense && this.expense.length > 0) {
      if (this.paidShorting === false) {
        this.expense.sort((a: any, b: any) => (a.paid > b.paid) ? -1 : 1);
      } else {
        this.expense.sort((a: any, b: any) => (a.paid > b.paid) ? 1 : -1);
      }
    }
  }
  dueShort(e: any) {
    this.dueShorting = !e;
    this.filterSortLabel = 'Due';
    if (this.expense && this.expense.length > 0) {
      if (this.dueShorting === false) {
        this.expense.sort((a: any, b: any) => (a.final_cost - a.paid > b.final_cost - b.paid) ? -1 : 1);
      } else {
        this.expense.sort((a: any, b: any) => (a.final_cost - a.paid > b.final_cost - b.paid) ? 1 : -1);
      }
    }
  }

  clickPayment() {
    // this.router.navigate(['budget', { id: 'payment', name:'payment'}]);
  }

  chartItemSelect(e: any) {
    let data = {
      id: this.collectionSummaryData.data[e[0].row][2],
      name: this.collectionSummaryData.data[e[0].row][0]
    };
    this.clickCategory(data, 3);

  }

  filterStyle() {
    $('#filterStyle').modal('toggle');
  }
}
