import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { UsersService } from '../../users/services/users.service';

@Injectable({
  providedIn: 'root'
})
export class MemoryService extends BaseService { 
  constructor(
    private http: HttpClient, 
    protected usersService: UsersService) {
    super(usersService);
  }

  editMemory(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/memory/edit", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  updateCoverImageOfMemory(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/memory/update_cover_image", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  uploadPhotosOfMemory(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/memory/upload_images", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deletePhotosOfMemory(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/memory/delete_photo", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  addVendorToMemory(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/memory/add_vendor", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  removeVendorFromMemory(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/memory/remove_vendor", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getWeddingMemoryDetails(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/memory/details", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getMemories(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/memories", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  searchVendors(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/vendors/search", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }
}
