import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute  } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { LocationModel } from 'src/app/models/location.model';
import { VendorsService } from '../services/vendors.service';
import { EventModel } from 'src/app/models/event.model';
import { EventFormBuilder } from './event.form-builder';
import { LocationsService } from '../../locations/services/locations.service';
import { EventTypeModel } from 'src/app/models/eventtype.model';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { RegionModel } from 'src/app/models/region.model';
import { AgmCoreModule, MapsAPILoader, GoogleMapsAPIWrapper, AgmMap } from '@agm/core';

declare var $: any;
declare var google: any;
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  geocoder:any;
  submitted = false;
  model = new EventModel();
  eventForm: any;
  events: any;
  event_id = 0;
  eid = 0;
  cities: any;
  eventTypes: any;
  title = "Add Event";
  button = "Create Event";
  regions: any;
  latitude: any;
  longitude: any;
  zoom : number;
  image_name : any;
  selectImage: any;
  currentUrl: string;
  eventsCurrentPage : number = 1;
  pagination_per_Page:number = Config.pagination_per_Page;
  @ViewChild(AgmMap) map: AgmMap;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: EventFormBuilder,
    private locationService: LocationsService,
    dateTimeAdapter: DateTimeAdapter<any>,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private currentroute: ActivatedRoute,
    public router: Router) {
      dateTimeAdapter.setLocale('en-US');
      this.mapsAPILoader.load().then(() => {
        this.geocoder = new google.maps.Geocoder();
      });
      
  }
  ngOnInit() {
    this.eventForm = this.formBuilder.getForm();
    this.currentUrl = Config.baseUrl+this.router.url; 
    this.getEventType();
    this.resetForm();
    this.locationService
      .getRegions("")
      .subscribe(
        (response:RegionModel[]) => {          
          for(var i=0;i<response.length;i++){
            response[i]['text'] = response[i].name;
          }
          this.regions = response;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
    this.latitude = Config.globalLat;
    this.longitude = Config.globalLong;
    this.zoom = 7; 
    $('form, input,textarea, select').attr('autocomplete', 'nope');
    this.getEvents();
  }

  get f() { return this.eventForm.controls; }

  onSubmit() {
    
    this.submitted = true;

    if($("#startdate_time").val()){
      this.eventForm.controls['start_date_time'].markAsTouched();
      this.eventForm.controls['start_date_time'].setErrors(null);
    }
    if($("#enddate_time").val()){
      this.eventForm.controls['end_date_time'].markAsTouched();
      this.eventForm.controls['end_date_time'].setErrors(null);
    }

    //console.log(this.eventForm);return;
    // stop here if form is invalid
    if (this.eventForm.invalid) {
      return;
    }

    this.model.deserialize(this.eventForm.value);
    //console.log(this.model);return;
    var start_date_time = this.usDateTimeToDb($("#startdate_time").val());
    var end_date_time = this.usDateTimeToDb($("#enddate_time").val());
    this.model.start_date_time = start_date_time;
    this.model.end_date_time = end_date_time; 

    //console.log(start_date_time+" "+end_date_time);return;

    this.vendorService
      .saveEvent(this.model)
      .subscribe(
        (response:EventModel) => {          
          this.toastr.success(response.message);
          $("#eventphoto").val('');
          this.eventForm.controls['start_date_time'].markAsUntouched();
          this.eventForm.controls['end_date_time'].markAsUntouched(); 
          this.resetForm();
          this.getEvents();
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }
  
  getEvents(){
    this.vendorService
      .getEvents()
      .subscribe(
        (response:EventModel[]) => {  
          this.events = response;
          console.log(this.events);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

    getEvent(id:number){
    this.vendorService
      .getEvent(id)
      .subscribe(
        (response:EventModel) => {  
          this.formBuilder.setValues(response);

          //alert(response.lat+"="+response.long);
          this.getRegionWiseCities(response.region_id);
          this.searchLocation();
          this.latitude = response.lat;
          this.longitude = response.long;
          this.event_id = response.id;
          this.title = "Edit Event";
          this.button = "Save Event";
          this.selectImage = response.image_name;

          $("#startdate_time").val(this.dbToUsDateTime(response.start_date_time));
          $("#enddate_time").val(this.dbToUsDateTime(response.end_date_time));
          
          window.top.scroll(10,10);
        },
        error => {
          this.toastr.error(error.message);
        }
      ) 
  } 

  deleteEventModal(id:number){
    this.eid = id;
    $("#deleteevent").modal();    
  } 

  deleteEvent(eid:any){
    this.vendorService
      .deleteEvent(eid)
      .subscribe(
        (response:EventModel) => { 
          $('#deleteevent').modal('toggle'); 
          this.toastr.success(response.message);
          this.getEvents();
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      ) 
  }

  getEventType(){
    this.vendorService
      .getEventType()
      .subscribe(
        (response:EventTypeModel[]) => {  
          this.eventTypes = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      ) 
  }
  resetForm(){
    this.submitted = false;
    this.selectImage = "";
    $("#eventphoto").val('');    
    this.eventForm.reset();
    this.latitude = Config.globalLat;
    this.longitude = Config.globalLong;
    this.zoom = 7;
    window.top.scroll(10,10);
  }

  getRegionWiseCities(region_id:number){    
    this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response:LocationModel[]) => {          
          for(var i=0;i<response.length;i++){
            response[i]['text'] = response[i].name;
          }          
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      ) 
  }

  searchLocation() {
    //alert(123);
    var address="";
    if($("#address").val()){
      address += $("#address").val()+",";
    }
    if($('#state :selected').text()){
      address += $('#state :selected').text()+",";
    }
    if($('#city :selected').text()){
      address += $('#city :selected').text()+",";
    }
    if(address.length>0){
      address = address+"usa";
    }else{
      address = "florida,usa";
    }
    console.log(address);

    if (!this.geocoder) this.geocoder = new google.maps.Geocoder()
    this.geocoder.geocode({
      'address': address
    }, (results, status) => {
      //console.log(results);
      if (status == google.maps.GeocoderStatus.OK) {
            var setlat = results[0].geometry.location.lat();
            var setlng = results[0].geometry.location.lng();
            this.latitude = setlat;
            this.longitude = setlng;
            this.eventForm.get('lat').setValue(setlat);
            this.eventForm.get('long').setValue(setlng);
            this.zoom = 16;
            if(results[0]['partial_match']){
              this.toastr.error("Partial Match");
            }
      } else {
        this.toastr.error("Sorry, Google can't find an address.");
      }
    })
  }

  onFileChanges(files: any) {
    console.log(files[0]);
    this.image_name = files[0];
    var error = [];
    var extarray = ["png","jpg","jpeg"];
    for(var i=0;i<files.length;i++){
      var filetype = files[i].type.split("/");
      if(filetype[0]!="image"){
        error.push(1);
        $("#eventphoto").val("");
        break;
      }
      if(extarray.indexOf(filetype[1])==-1){
        error.push(1);
        $("#eventphoto").val("");
        break;
      }
    }
    if(error.length>0){
      this.toastr.error("Wrong file format.");
      return;
    }
    //this.model.image_name = files[0];
    this.eventForm.controls.image_name.setValue(files[0]);
  }

  deleteEventPhoto(){
    //alert(this.event_id);
    this.vendorService
      .deleteEventPhoto(this.event_id)
      .subscribe(
        (response:EventModel) => {          
          this.toastr.success(response.message);
          this.getEvent(this.event_id);
          return false;
        }
      ) 
  }

  resetMap(){
    this.latitude = 0;
    this.longitude = 0;
    this.searchLocation();
  }

  usDateTimeToDb(datetime) { 
    /* var dateTime = datetime.replace(",","");
    var d = new Date(dateTime),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
     
    return [year, month, day].join('-') + " "+d.getHours() + ':' + d.getMinutes(); */
    
    var dateTime = datetime.split(", ");
    var date = dateTime[0];
    var time = dateTime[1];

    var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
    var hours = Number(time.match(/^(\d+)/)[1]);
    var minutes = Number(time.match(/:(\d+)/)[1]);
    var AMPM = time.match(/\s(.*)$/)[1];
    if(AMPM == "PM" && hours<12) hours = hours+12;
    if(AMPM == "AM" && hours==12) hours = hours-12;
    var sHours = hours.toString();
    var sMinutes = minutes.toString();
    if(hours<10) sHours = "0" + sHours;
    if(minutes<10) sMinutes = "0" + sMinutes;
    
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
       
      return [year, month, day].join('-') + " "+sHours + ':' + sMinutes;

  }

  dbToUsDateTime(datetime){
    var explode = datetime.split(" ");
    var date = explode[0];
    var time = explode[1];

    var exp = date.split("-");
    var year = exp[0];
    var month = exp[1];
    var day = exp[2];
	
	  var strTime = time;
	  var Hours = +strTime.substr(0, 2);
	  var hour = (Hours % 12) || 12;
	  var hourstring = (hour < 10)?("0"+hour):hour;  // leading 0 at the left for 1 digit hours
	  var ampm = Hours < 12 ? " AM" : " PM";
	  strTime = hourstring + strTime.substr(2, 3) + ampm;

    var convertDateTime = [month, day, year].join('/')+", "+strTime;
    return convertDateTime;
  }

}
