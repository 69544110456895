import { Deseriazable } from './deserializable.model';

export class CategoryModel implements Deseriazable{
    
    id: string;
    name: string;
    business_type: string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}