import { Deseriazable } from './deserializable.model';

export class VendorLocationInfoModel implements Deseriazable{
    
    id: string;    
    location_address: string;
    location_phone: string;
    location_city_id: string; 
    location_region_id: number;   
    location_mobile: string; 
    business_hours: string; 
    location_zip_code: string; 
    lat: string; 
    long: string;
    message: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}