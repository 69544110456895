import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MemoryService } from '../services/memories.service';
import { Config } from '../../../config';
declare var $: any;

@Component({
  selector: 'app-wedding-memory-details',
  templateUrl: './wedding-memory-details.component.html',
  styleUrls: ['./wedding-memory-details.component.scss']
})

export class WeddingMemoryDetailsComponent implements OnInit {

  memory_slug: string = "";
  memory_details: any = null;
  memoryPhotoUrl = Config.imageUrl + 'images/memory_photos/';
  photos: any[] = [];
  vendorPhotosUrl = Config.imageUrl + 'images/vendor_thumb_images/';
  selected_image_index: number = 0;
  selected_lighthouseImageData: string = "";

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    private memoryService: MemoryService,
  ) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        if (params['slug']) {
          this.memory_slug = params['slug'];
          this.getWeddingMemoryDetails(this.memory_slug);
        }
      }
    });
  }

  getWeddingMemoryDetails(memory_slug: string){
    let formData = {
      slug: memory_slug
    };
    this.memoryService.getWeddingMemoryDetails(formData).subscribe(res => {
      this.memory_details = res.data;
      this.photos = this.memory_details.photos.filter((item: any, index: number) => index > 1);
    }, error => {
      console.log(error);
    });
  }
  openMemoryImageLightBox(index: number){
    $('#photo_lightbox').modal('toggle');
    this.setSlide(index, this.photos);
  }
  openCoverImageLightBox(index: number){
    $('#photo_lightbox').modal('toggle');
    this.setSlide(index, this.memory_details.photos);
  }
  setSlide(index: number, images: any){
    this.selected_image_index = index;
    this.selected_lighthouseImageData = this.memoryPhotoUrl + images[this.selected_image_index].photo_file_name;
  }
  slideNext() {
    if(this.selected_image_index < this.photos.length - 1){
      this.selected_image_index = this.selected_image_index + 1;
      this.setSlide(this.selected_image_index, this.photos);
    }else{
      this.selected_image_index = 0;
      this.setSlide(this.selected_image_index, this.photos);
    }
  }
  slidePrev() {
    if(this.selected_image_index > 0){
      this.selected_image_index = this.selected_image_index - 1;
      this.setSlide(this.selected_image_index, this.photos);
    }else{
      this.selected_image_index = this.photos.length - 1;
      this.setSlide(this.selected_image_index, this.photos);
    }
  }
  goToVendorProfile(vendor: any){
    this.router.navigateByUrl('/profile/' + vendor.slug);
  }

}
