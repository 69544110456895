import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { UsersService } from '../../users/services/users.service';

@Injectable({
  providedIn: 'root'
})
export class IdeaAndTipsService extends BaseService { 
  constructor(
    private http: HttpClient, 
    protected usersService: UsersService) {
    super(usersService);
  }

  getLatestIdeaAndTips(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/memories", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  searchIdeasAndTips(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/idea_and_tips/search", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getIdeaAndTipsCategories(){
    return this.http
      .get(Config.apiBaseUrl+"/idea_and_tips/get_categories", this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getIdeaAndTipsCategoryDetails(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/idea_and_tips/get_category_details", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getIdeaAndTips(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/idea_and_tips/get_ideas_and_tips", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getIdeaAndTipsFilter(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/idea_and_tips/get_ideas_tips_filter", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getIdeaAndTipsList(){
    return this.http
      .get(Config.apiBaseUrl+"/idea_and_tips/get_ideas_and_tips_list", this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getIdeaAndTipsArticleDetails(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/idea_and_tips/get_article", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  postComment(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/idea_and_tips/comment/edit", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  getComments(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/idea_and_tips/comments/get", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteComment(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/idea_and_tips/comment/delete", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  deleteReply(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/idea_and_tips/reply/delete", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }

  postReply(data: any){
    return this.http
      .post(Config.apiBaseUrl+"/idea_and_tips/reply/edit", data, this.getHttpHeaders())
      .pipe(map((response:any) => {
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;
      }));
  }
}
