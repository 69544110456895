import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { RegisterFormBuilder } from './register.form-builder';
import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError, count } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RegisterModel } from 'src/app/models/register.model';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { LocationModel } from 'src/app/models/location.model';
import { CategoryModel } from 'src/app/models/category.model';
import { VendorsService } from '../services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';
import { RegionModel } from 'src/app/models/region.model';
import { Select2OptionData } from 'ng-select2';

declare var $: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  
  submitted = false;
  model = new RegisterModel();
  locationModel = new LocationModel();
  categoryModel = new CategoryModel();
  registerForm: any;
  cities: any;
  type: any;
  categories: any;
  regions : any;

  faqs: any;
  faqLength: number;
  faqFieldValues = [];
  customFaqs = [];
  checkedval = [];
  selectedFaqs: any;
  showQuestion = false;
  showRegister = true;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private locationService: LocationsService,
    private formBuilder: RegisterFormBuilder,
    private toastr:ToastrService,
    public router: Router) {
    //this.loginForm = formBuilder.getForm();
  }
  
  ngOnInit() {
    
    this.registerForm = this.formBuilder.getForm();
        
    
      this.locationService
      .getRegions("")
      .subscribe(
        (response:RegionModel[]) => {       
          for(var i=0;i<response.length;i++){
            response[i]['text'] = response[i].name;
          }
          this.regions = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
      this.resetForm();
      this.registerForm.get('role_id').setValue(2);
      setTimeout(()=>{
        $('form, input,textarea, select').attr('autocomplete', 'nope');
      },1000);
      
      window.scroll(0,0);
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {   

    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.showRegister = true;
    this.showQuestion = false;
    //console.log(this.faqFieldValues);return;
    this.model.deserialize(this.registerForm.value);
    //console.log(this.model);return;
    this.usersService
      .create(this.model)
      .subscribe(
        (response:RegisterModel) => {          
          this.toastr.success("Registration Success");                    
          this.usersService
          .authenticate(this.model) 
          .subscribe(
            (response:AuthResponseModel) => {          
              if(response.token){  
                localStorage.setItem("dashboardMsg","1");          
                this.toastr.success("Login Success");
                this.router.navigate(['vendor/home']);
                return false;
              }
              else
              {
                this.toastr.error("Login failure");
              }          
            },
            error => {
              this.toastr.error(error.message);
            }
          )          
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )    
  }

  save(){
    var fieldArr = [];
    $(".req").each(function(){
      var ftype = $(this).attr("id");
      if(ftype=='text'){
        //console.log($(this).find("input[type=text]").val());
        if(!$(this).find("input[type=text]").val()){
          fieldArr.push($(this).find("label").text());
        }
      }else if(ftype=='textarea'){
        //console.log($(this).find("textarea").val());
        if(!$(this).find("textarea").val()){
          fieldArr.push($(this).find("label").text());
        }
      }else if(ftype=='radio'){
        //console.log($(this).find("input[type=radio]:checked").val());
        if(!$(this).find("input[type=radio]:checked").val()){
          fieldArr.push($(this).find("label").text());
        }
      }else if(ftype=='checkbox'){
        //console.log($(this).find("input[type=checkbox]:checked").length);
        if(!$(this).find("input[type=checkbox]:checked").length){
          fieldArr.push($(this).find("label").text());
        }
      }
    })
    
    if(fieldArr.length>0){
      for(var m=0;m<fieldArr.length;m++){
        this.toastr.error(fieldArr[m]+" required");
      }
      return;
    }
    this.model.question = this.faqFieldValues;
    this.model.deserialize(this.registerForm.value);
    //console.log(this.model);return;
    this.usersService
      .create(this.model)
      .subscribe(
        (response:RegisterModel) => {          
          this.toastr.success("Registration Success");                    
          this.usersService
          .authenticate(this.model) 
          .subscribe(
            (response:AuthResponseModel) => {          
              if(response.token){            
                this.toastr.success("Login Success");
                this.router.navigate(['/vendor/home'],{ replaceUrl: true });
                return false;
              }
              else
              {
                this.toastr.error("Login failure");
              }          
            },
            error => {
              this.toastr.error(error.message);
            }
          )          
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  onChangeBusinessType(event: any){
    //alert(this.f.business_type.value);
    this.vendorService
      .getCategories(this.f.business_type.value)
      .subscribe(
        (response:CategoryModel[]) => { 
          this.categories = response;       
          console.log(response);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  signIn(){
    this.router.navigate(['/vendor/login']);
  }

  getRegionWiseCities(region_id:number){      
    this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response:LocationModel[]) => {
          for(var i=0;i<response.length;i++){
            response[i]['text'] = response[i].name;
          }          
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      ) 
  }

  setCategory(arg:number){
    var data = {"category_id":arg};
    this.vendorService
      .getRegFaqs(data)
      .subscribe(
        (response) => {  
          this.faqs = response; 
          console.log(response);
          //console.log(response.length);
          var txt = [];
           for(var i=0;i<response.length;i++){
            //alert(response[i].id);
            this.checkedval[response[i].id] = [];
            if(response[i].question_type=='checkbox'){
              if(response[i].answer!=""){
                this.checkedval[response[i].id] = response[i].answer;
              }              
            }
          }         
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  selectFaqs(opVal:any, answer:any){
    let index = answer.indexOf(opVal);
        if (index > -1) {
          return 1;
        }
  }

  inputValue(ele:any,qid:number){
    //console.log(ele.target.value);return;
    var fieldType = ele.target.type;
    for(var i = 0; i < this.faqFieldValues.length; i++) {
        if(this.faqFieldValues[i].id == qid) {
          this.faqFieldValues.splice(i, 1);
            break;
        }
    }
    if(fieldType=='range'){      
      this.faqFieldValues.push({"id":qid,"option_value":[ele.target.value]});
      $("#txt"+qid).html(ele.target.value);
      //console.log(ele.target.parentElement.find(".tx"))
    }
    else if(fieldType=='text'){      
      this.faqFieldValues.push({"id":qid,"option_value":[ele.target.value]});
      //this.faqFieldValues.push({"id":qid,"option_value":[$("#ex"+qid).val()]});
    }
    else if(fieldType=='textarea'){      
      this.faqFieldValues.push({"id":qid,"option_value":[ele.target.value]});
      //this.faqFieldValues.push({"id":qid,"option_value":[$("#ex"+qid).val()]});
    }
    else if(fieldType=='radio'){      
      this.faqFieldValues.push({"id":qid,"option_value":[ele.target.value]})
    }
    else if(fieldType=='checkbox'){    
      
      if(ele.target.checked){
        this.checkedval[qid].push(ele.target.value);
      }
      else{
        let index = this.checkedval[qid].indexOf(ele.target.value);
        if (index > -1) {
          this.checkedval[qid].splice(index, 1);
        }
      } 
      this.faqFieldValues.push({"id":qid,"option_value":this.checkedval[qid]});

      //console.log(this.checkedval);
    }
    console.log(this.faqFieldValues);
  }

  mandetoryCheck(id:number){
    for(var k=0;k<this.faqFieldValues.length;k++){
      if(this.faqFieldValues[k].id==id && this.faqFieldValues[k].option_value[0]==""){
        return true;
      }
    }
  }

  resetForm(){
    this.submitted = false;
    this.registerForm.reset();
  }

}
