import { Component, OnInit } from '@angular/core';
import { Config } from '../config';

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.css']
})
export class FrontPageComponent implements OnInit {
  lat : any;
  long: any;
  constructor() { 
    if (navigator)
    {
      navigator.geolocation.getCurrentPosition( pos => {
          
        this.lat = pos.coords.latitude;  
        this.long = pos.coords.longitude;          
          //Config.globalLong = pos.coords.latitude;
          console.log(pos);
          localStorage.setItem("globalLat",this.lat);
          localStorage.setItem("globalLong",this.long);

        });
    }
  }

  ngOnInit() {
    
  }

}
