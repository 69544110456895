import { Component, HostListener, OnInit } from '@angular/core';
import { PlannersService } from '../../planners/services/planners.service';
import { IdeaAndTipsService } from '../services/ideas-and-tips.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Config } from '../../../config';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';

declare var $: any;


@Component({
  selector: 'app-wedding-ideas-details',
  templateUrl: './wedding-ideas-details.component.html',
  styleUrls: ['./wedding-ideas-details.component.scss']
})
export class WeddingIdeasDetailsComponent implements OnInit {
  activeUser: any;
  post_slug: string;
  post_details: any;
  posts: any[] = [];
  displayedPosts: any[] = [];
  articlesPerPage = 5; // Set the number of articles to load per request
  currentPage = 0;
  page_number: number = 1;
  ideaAndTipsPhotoUrl = Config.imageUrl + 'images/idea_and_tips/';

  // Mini CK Editor Config --
  miniCKEditorConfig = {
    height: '6em',
    toolbar: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', 'Underline'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'], items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
      { name: 'links', items: ['Link', 'Unlink','Blockquote'] },
      { name: 'insert' },
      { name: 'styles', items: ['Styles', 'Format', 'Font'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
    ],
    toolbarHiddenButtons: [
      ['fontName', 'insertUnorderedList',
        'insertOrderedList', 'Table', 'FontSize', 'Strike', 'Subscript',
        'Superscript', '-', 'CopyFormatting', 'RemoveFormat', 'CreateDiv', 'Anchor']
    ],
  };

  isEditorVisible:boolean = false;
  my_comment: string = "";
  comments: any[] = [];
  total_comments: number = 0;
  selected_comment: any = {};

  // Edit Form --
  editted_comment: any;
  edit_mode: boolean = false;

  // Reply Form --
  isReplyEditorVisible: boolean = false;
  my_reply: string = "";
  reply_edit_mode: boolean = false;
  selected_reply: any = null;

  constructor(
    private plannersService: PlannersService,
    public route: ActivatedRoute,
    private ideaAndTipsService: IdeaAndTipsService,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer,
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.activeUser["role"] = "Wedding Users";
      } else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Vendor"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
      }else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Community Guest"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
        console.log(this.activeUser);
      }else{
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        if (params['slug']) {
          this.post_slug = params['slug'];
          this.getPostDetails(this.post_slug);
        }
      }
    });
  }

  async getPostDetails(slug: string){
    let formData = {
      "slug": slug
    };
    var response = await this.ideaAndTipsService.getIdeaAndTipsArticleDetails(formData).toPromise();
    this.post_details = response.data;
    this.getIdeaAndTips({
      'cat_id': this.post_details.cat_details.id
    });
  }

  getRichTextContent(content: any) {
    let el = document.createElement("div");
    el.innerHTML = content;
    let tempcontent = el.outerHTML;
    return this.sanitizer.bypassSecurityTrustHtml(tempcontent);
  }

  async getIdeaAndTips(formData: any){
    var response = await this.ideaAndTipsService.getIdeaAndTips(formData).toPromise();
    this.posts = response.data.filter(p => p.slug !== this.post_slug);
    this.load_comments();
    this.loadMoreArticles();
  }

  loadMoreArticles(): void {
    if (this.currentPage < this.articlesPerPage) {
      this.displayedPosts.push(this.posts[this.currentPage]);
      this.currentPage++;
    }
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: Event): void {
    const element = event.target as HTMLElement;
    if (element.scrollTop + element.clientHeight >= element.scrollHeight - 100) {
      this.loadMoreArticles(); // Load more articles when scrolling close to the bottom
    }
  }

  async load_comments(){
    let formData = {
      "article_id": this.post_details.id,
      "page_number": this.page_number
    };
    var response = await this.ideaAndTipsService.getComments(formData).toPromise();

    if(response.hasError){
      this.toastr.error(response.message);
    }else{
      this.comments = [...response.data];
      this.total_comments = response.total_comment;
    }
  }

  getUserName(userDetails: any){
    let name = userDetails.firstname;
    if(userDetails.lastname){
      return name + " " + userDetails.lastname;
    }
    return name;
  }
  canModify(user_id: any){
    if(this.activeUser && (parseInt(this.activeUser.id) === parseInt(user_id))){
      console.log("here");
      return true;
    }
    return false;
  }
  showEditor(){
    if(this.activeUser){
      if(this.activeUser.role !== "Vendor"){
        this.isEditorVisible = true;
        this.edit_mode = false;
        this.selected_comment = null;
        this.my_comment = "";
      }else{
        this.toastr.error("Vendors may only leave comments in the Community");
      }
    }else{
      $('#login_modal').modal('toggle');
    }
  }
  hideEditor(){
    this.isEditorVisible = false;
  }
  showReplyEditor(comment: any){
    if(this.activeUser){
      this.selected_comment = comment;
      this.isReplyEditorVisible = true;
      this.reply_edit_mode = false;
      this.selected_reply = null;
      this.my_reply = "";
    }else{
      $('#login_modal').modal('toggle');
    }
  }
  hideReplyEditor(){
    this.isReplyEditorVisible = false;
  }

  async postComment(){
    let formData = {
      "comment": this.my_comment,
      "article_id": this.post_details.id,
    };
    if(this.selected_comment){
      formData["id"] = this.selected_comment.id;
    }
    var response = await this.ideaAndTipsService.postComment(formData).toPromise();

    if(response.hasError){
      this.toastr.error(response.message);
    }else{
      this.my_comment = "";
      this.isEditorVisible = false;
      this.getPostDetails(this.post_details.slug);
      this.toastr.success(response.message);
    }

    console.log(response);
  }
  async postReply(){
    let formData = {
      "comment_id": this.selected_comment.id,
      "reply_message": this.my_reply
    };
    if(this.selected_reply){
      formData["id"] = this.selected_reply.id;
    }
    var response = await this.ideaAndTipsService.postReply(formData).toPromise();
    console.log(response);
    if(response.hasError){
      this.toastr.error(response.message);
    }else{
      this.my_reply = "";
      this.isReplyEditorVisible = false;
      this.getPostDetails(this.post_details.slug);
      this.toastr.success(response.message);
    }
  }

  async edit_comment(comment: any){
    this.selected_comment = comment;
    this.my_comment = comment.comment;
    this.isEditorVisible = true;
    this.edit_mode = true;
    $('html, body').animate({
      scrollTop: $("#comment_form").offset().top
    }, 500);
  }

  edit_reply(comment: any, reply: any){
    this.selected_comment = comment;
    this.isReplyEditorVisible = true;
    this.reply_edit_mode = true;
    this.selected_reply = reply;
    this.my_reply = reply.reply_message;
    $('html, body').animate({
      scrollTop: $("#form_"+comment.id).offset().top
    }, 500);
  }

  confirm_delete_comment(comment: any){
    // this.selected_comment = comment;
    $('#delete_comment_modal').modal('show');
  }

  confirm_delete_reply(reply: any){
    this.selected_reply = reply;
    $('#delete_reply_modal').modal('show');
  }

  async confirmDeleteComment(){
    console.log(this.selected_comment);
    let formData = {
      "comment_id": this.selected_comment.id
    };
    try{
      var response = await this.ideaAndTipsService.deleteComment(formData).toPromise();
      console.log(response);
      if(response.hasError === false){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
      }

      this.comments = this.comments.filter((comment)=>{
        return this.selected_comment.id !== comment.id
      });

      this.selected_comment = null;
      $('#delete_comment_modal').modal('toggle');
      this.total_comments--;
    }catch(error){
      this.toastr.error(error.message);
      $('#delete_comment_modal').modal('toggle');
    }
  }

  async deleteReply(){
    let formData = {
      "reply_id": this.selected_reply.id
    };
    try{
      var response = await this.ideaAndTipsService.deleteReply(formData).toPromise();
      console.log(response);
      if(response.hasError === false){
        this.toastr.success(response.message);
      }else{
        this.toastr.error(response.message);
      }
      this.selected_reply = null;
      $('#delete_reply_modal').modal('toggle');
      this.getPostDetails(this.post_details.slug);
    }catch(error){
      this.toastr.error(error.message);
      $('#delete_reply_modal').modal('toggle');
    }
  }
}
