import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { DealModel } from 'src/app/models/deal.model';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  graphTitle: string;
  graphType: string;
  graphData: any;
  graphColumnNames: any;
  graphOptions: any;
  graphWidth: number;
  graphHeight: number;
  searchParams = {};
  leadCount: number = 0;
  messageCount: number = 0;
  profileCount: number = 0;
  websiteCount: number = 0;
  phoneCount: number = 0;
  years = [];
  showMonthYear: string;
  analytics: any;
  profileArray = [];
  leadsArray = [];
  phoneArray = [];
  websiteArray = [];
  messageArray = [];
  analyticsData: any;
  analyticsParams = {};
  hideMsg = false;
  curDate: string;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }
  ngOnInit() {

    this.graphTitle = 'Vendor Profile';
    this.graphType = 'LineChart';
    this.graphData = [[0, 0]];
    this.graphColumnNames = ['Browser', ''];
    this.graphOptions = {
      //legend: { position: 'none' },
      legend: { position: 'bottom', alignment: 'start' },
      pointsVisible: false,
      width: "100%", vAxis: {
        format: '0',
        viewWindow: {
          min: 0,
          max: 10
        },
        //ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10] 
      }
    };
    this.graphWidth = 750;
    this.graphHeight = 300;
    this.tabSelectedDefault();
    this.graph('detail');
    this.yearDropdown();
    this.downloadAnalytics();
    let dt = new Date();
    this.curDate = (dt.getMonth() + 1) + "/" + dt.getDate() + "/" + dt.getFullYear();
    if (localStorage.getItem("dashboardMsg")) {
      this.hideMsg = true;
    }
    $(".tabs-left").click(function () {
      $("#collapseExample1").removeClass("show")
    })
    window.scroll(0, 0);
  }

  graph(arg: any) {
    this.searchParams["mode"] = arg;
    if ($("#period").val() == 12) {
      this.searchParams["month"] = $("#period").val();
      this.searchParams["year"] = "";
      this.showMonthYear = "in the last 12 months";
    } else {
      this.searchParams["year"] = $("#period").val();
      this.searchParams["month"] = "";
      this.showMonthYear = $("#period").val();
    }
    //console.log(this.searchParams);
    this.showCount(this.searchParams);
    this.showGraph(arg);
  }

  analyticsCall(arg: any) {
    this.analyticsParams["mode"] = arg;
    if ($("#period").val() == 12) {
      this.analyticsParams["month"] = $("#period").val();
      this.analyticsParams["year"] = "";
    } else {
      this.analyticsParams["year"] = $("#period").val();
      this.analyticsParams["month"] = "";
    }
    this.showAnalyticsData(arg);
  }

  selectPeriod(ele: any) {
    if (ele == 12) {
      this.searchParams["month"] = $("#period").val();
      this.searchParams["year"] = "";
      this.showMonthYear = "in the last 12 months";
      var dt = new Date();
      this.curDate = (dt.getMonth() + 1) + "/" + dt.getDate() + "/" + dt.getFullYear();
    } else {
      this.searchParams["year"] = $("#period").val();
      this.searchParams["month"] = "";
      this.showMonthYear = $("#period").val();
      this.curDate = "";
    }
    this.searchParams["mode"] = 'detail';
    this.tabSelectedDefault();
    this.showCount(this.searchParams);
    this.showGraph("detail");
    this.downloadAnalytics();

  }

  showGraph(arg) {
    this.vendorService
      .showGraph(this.searchParams)
      .subscribe(
        (response: any) => {
          if (response.length > 0) {
            this.graphData = response;
          } else {
            this.graphData = [[0, 0]];
          }
          var maxVal = response.reduce(function (accumulator: any, pilot: any) {
            return pilot[1] > accumulator ? pilot[1] : accumulator;
          }, 3);
          //console.log(11,maxVal);
          //if(maxVal%3!=0){
          maxVal += Math.floor(maxVal / 3);
          //}
          this.graphOptions.vAxis.viewWindow.max = maxVal;
          if (arg == 'detail') {
            this.graphColumnNames = ['Browser', 'Profile Visits'];
            this.graphTitle = 'View Total Number Of Times That Your Profile Was Viewed By Potential Clients';
            this.analytics = this.profileCount;
          } else if (arg == 'leads') {
            this.graphColumnNames = ['Browser', 'Leads'];
            this.graphTitle = 'View Total Leads That Were Referred To You By Weddingdive';
            this.analytics = this.leadCount;
          } else if (arg == 'mobile') {
            this.graphColumnNames = ['Browser', 'Phone Number Views'];
            this.graphTitle = 'View Total Number Of Times That Profile Visitors Clicked To View Your Phone Number';
            this.analytics = this.phoneCount;
          } else if (arg == 'website') {
            this.graphColumnNames = ['Browser', 'Website Clicks'];
            this.graphTitle = 'View Total Number Of Times That Potential Clients Clicked Through To Your Website From Weddingdive';
            this.analytics = this.websiteCount;
          } else if (arg == 'message') {
            this.graphColumnNames = ['Browser', 'Messages Received'];
            this.graphTitle = 'View Total Number Of Messages That You Received From Potential Clients';
            this.analytics = this.messageCount;
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  showAnalyticsData(arg) {
    this.vendorService
      .showGraph(this.analyticsParams)
      .subscribe(
        (response: any) => {
          if (response.length > 0) {
            this.analyticsData = response;
          } else {
            this.analyticsData = [[0, 0]];
          }
          if (arg == 'detail') {
            this.profileArray = this.analyticsData;
          } else if (arg == 'leads') {
            this.leadsArray = this.analyticsData;
          } else if (arg == 'mobile') {
            this.phoneArray = this.analyticsData;
          } else if (arg == 'website') {
            this.websiteArray = this.analyticsData;
          } else if (arg == 'message') {
            this.messageArray = this.analyticsData;
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  tabSelectedDefault() {
    $(".tabs-left li a").each(function (i) {
      if (i == 0) {
        $(this).addClass('active');
      } else {
        $(this).removeClass('active');
      }
    });
  }

  showCount(params: any) {
    //console.log(params);
    this.vendorService
      .showCount(params)
      .subscribe(
        (response: any) => {
          //console.log(response);
          this.leadCount = response.LeadRecived > 1000 ? "1k+" : response.LeadRecived;
          this.messageCount = response.MessageRecive > 1000 ? "1k+" : response.MessageRecive;
          this.phoneCount = response.PhoneNoVisit > 1000 ? "1k+" : response.PhoneNoVisit;
          this.profileCount = response.ProfleVisit > 1000 ? "1k+" : response.ProfleVisit;
          this.websiteCount = response.WebsiteVisit > 1000 ? "1k+" : response.WebsiteVisit;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  yearDropdown() {
    let date = new Date();
    let year = date.getFullYear();

    for (var i = 2019; i <= year; i++) {
      this.years.push(i);
    }
  }

  downloadCSV(csv, filename) {
    var csvFile;
    var downloadLink;

    // CSV file
    csvFile = new Blob([csv], { type: "text/csv" });

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = "none";

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
  }

  exportTableToCSV(filename) {
    var csv = [];
    var rows = $("#exportData tr");

    for (var i = 0; i < rows.length; i++) {
      var row = [], cols = rows[i].querySelectorAll("td, th");

      for (var j = 0; j < cols.length; j++)
        row.push(cols[j].innerHTML);

      csv.push(row.join(","));
    }

    // Download CSV file
    this.downloadCSV(csv.join("\n"), filename);
  }

  downloadAnalytics() {
    this.analyticsCall('detail');
    this.analyticsCall('leads');
    this.analyticsCall('mobile');
    this.analyticsCall('website');
    this.analyticsCall('message');
  }

  closeMessage() {
    localStorage.removeItem("dashboardMsg");
    this.hideMsg = false;
  }

  upgradeBtn() {
    this.router.navigate(['vendor/membership']);
  }
}
