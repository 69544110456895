import { Component, OnInit,ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { VendorBusinessInfoModel } from 'src/app/models/vendor.business.info.model';
import { LocationFormBuilder } from './location.form-builder';
import { VendorLocationInfoModel } from 'src/app/models/vendor.location.info.model';
import { LocationsService } from '../../locations/services/locations.service';
import { LocationModel } from 'src/app/models/location.model';
import { AgmCoreModule, MapsAPILoader, GoogleMapsAPIWrapper, AgmMap } from '@agm/core';
import { RegionModel } from 'src/app/models/region.model';

declare var $ :any;
declare var google: any;
@Component({
  selector: 'app-location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.css']
})
export class LocationInfoComponent implements OnInit {

  geocoder:any;
  submitted = false;
  cities: any;
  model = new VendorLocationInfoModel();
  locationModel = new LocationModel();
  locationForm: any;
  latitude: any;
  longitude: any;
  zoom : number;
  regions: any;
  @ViewChild(AgmMap) map: AgmMap;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: LocationFormBuilder,
    private locationService: LocationsService,
    private toastr:ToastrService,
    private mapsAPILoader: MapsAPILoader,
    public router: Router) {     
    
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  ngOnInit() {
    this.locationForm = this.formBuilder.getForm();    
    this.latitude = Config.globalLat;
    this.longitude = Config.globalLong;
    this.zoom = 7;
      this.locationService
      .getRegions("")
      .subscribe(
        (response:RegionModel[]) => {          
          //this.regions = response;
          for(var i=0;i<response.length;i++){
            response[i]['text'] = response[i].name;
          }
          this.regions = response;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
    this.getLocationInfo();
    setTimeout(() => {
      this.searchLocation();
    }, 1000);

    $('form, input,textarea, select').attr('autocomplete', 'nope');
    window.scroll(0,0);
  }

  get f() { return this.locationForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.locationForm.invalid) {
      return;
    }

    this.model.deserialize(this.locationForm.value);

    this.vendorService
      .updateLocationInfo(this.model)
      .subscribe(
        (response:VendorLocationInfoModel) => {                
          this.toastr.success(response.message);
            this.getLocationInfo();
          
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }
  
  getLocationInfo(){
    this.vendorService
      .getLocationInfo()
      .subscribe(
        (response:VendorLocationInfoModel) => {          
          console.log(response);
          this.formBuilder.setValues(response);
          this.getRegionWiseCities(response.location_region_id);       
                                   
        },
        error => {
          this.toastr.error(error.message);
          //console.log(error.message);
        }
      ) 
  }

  searchLocation() {
    var address="";
    if($("#address").val()){
      address += $("#address").val()+",";
    }
    if($('#state :selected').text()){
      address += $('#state :selected').text()+",";
    }
    if($('#city :selected').text()){
      address += $('#city :selected').text()+",";
    }
    if(address.length>0){
      address = address+"usa";
    }else{
      address = "florida,usa";
    }
    console.log(address);
    
    if (!this.geocoder) this.geocoder = new google.maps.Geocoder();
    this.geocoder.geocode({
      'address': address
    }, (results, status) => {
      console.log(results);
      if (status == google.maps.GeocoderStatus.OK) {
            var setlat = results[0].geometry.location.lat();
            var setlng = results[0].geometry.location.lng();
            this.latitude = setlat;
            this.longitude = setlng;
            this.locationForm.get('lat').setValue(setlat);
            this.locationForm.get('long').setValue(setlng);
            this.zoom = 16;
      } else {
        this.toastr.error("Sorry, Google can't find an address.");
        return;
      }
    })
  }

  getRegionWiseCities(region_id:number){    
    this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response:LocationModel[]) => {          
          for(var i=0;i<response.length;i++){
            response[i]['text'] = response[i].name;
          }          
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

  resetMap(){
    this.latitude = 0;
    this.longitude = 0;
    this.searchLocation();
  }
}
