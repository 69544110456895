import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { VendorLocationInfoModel } from 'src/app/models/vendor.location.info.model';
import { CustomValidator } from '../../../customvalidation';
import { CheckoutModel } from 'src/app/models/checkout.model';

@Injectable({
    providedIn: 'root'
})
export class CheckoutFormBuilder{

    private form;
    private validationRules = {
        /* location_address: ['',Validators.required],
        location_phone: ['',[CustomValidator.numeric]],
        location_city_id: ['',Validators.required],
        location_region_id: ['',Validators.required],
        location_mobile: ['',''],
        business_hours: ['',''],
        location_zip_code: ['',[Validators.required,CustomValidator.numeric]],
        lat: ['',''],
        long: ['',''],
        firstname: ['',''],
        lastname: ['',''],
        email: ['',''], */
        terms:[false, Validators.requiredTrue] 
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:CheckoutModel){
        this.form.patchValue({
            location_address:response.location_address,
            location_phone:response.location_phone,
            location_city_id:response.location_city_id,
            location_region_id:response.location_region_id,
            location_mobile:response.location_mobile,
            business_hours:response.business_hours,
            location_zip_code:response.location_zip_code,
            lat:response.lat,
            long:response.long,
            firstname:response.firstname,
            lastname:response.lastname,
            email:response.email

        });
    }
}