import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlaceholdersService } from './services/placeholders.service';

@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule
  ],
  exports:[
    
  ]
})
export class CmsModule { }
