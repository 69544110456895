import { Component, OnInit } from '@angular/core';
import { DiscussionService } from '../services/discussions.service';
import { PlannersService } from '../../planners/services/planners.service';
import { Router } from '@angular/router';
import { Config } from '../../../config';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-wedding-forum',
  templateUrl: './wedding-forum.component.html',
  styleUrls: ['./wedding-forum.component.scss']
})
export class WeddingForumComponent implements OnInit {
  discussionCategories: any;
  vendorCategoryId: any;
  VendorCategoryName: any;
  activeUser: any;
  search_text: string = "";
  search_dropdown_visible: boolean = false;
  search_result: any[] = [];
  forums_discussions: any[] = [];
  current_page_number: number = 1;
  total_discussions: number = 0;
  discussions_pagination_items: any[] = [];
  sort_by = 'featured';
  category_banner_image_url: string = Config.imageUrl + 'images/discussion_category_banner/';
  discussion_image_url: string = Config.imageUrl + 'images/discussion_media_images/';

  constructor(
    private discussionService: DiscussionService,
    private plannersService: PlannersService,
    public router: Router,
    private toastr: ToastrService,
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.activeUser["role"] = "Wedding Users";
      } else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Vendor"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
      }else if(localStorage.getItem("USER_TOKEN") && localStorage.getItem("role") === "Community Guest"){
        this.activeUser = {
          'role': localStorage.getItem("role"),
          'id': localStorage.getItem("UID"),
        };
        console.log(this.activeUser);
      }else{
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  async ngOnInit() {
    this.getDiscussionList(1);
    await this.getDiscussionCategories();
    var owl = $('.owl-carousel');
    setTimeout(() => {
      owl.owlCarousel({
        margin: 30,
        nav: true,
        loop:true,
        autoplay:false,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        smartSpeed: 400,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 4
          },
          1000: {
            items: 4
          }
        }
      })
    }, 1000);   
  }
  async getDiscussionList(page_number: number){
    let formData = {
      "category_id": "",
      "page_number": page_number,
      "sort_by": this.sort_by
    };
    this.current_page_number = page_number;
    var response = await this.discussionService.getDiscussionList(formData).toPromise();
    this.forums_discussions = response.data;
    this.total_discussions = response.total_records;
    this.discussions_pagination_items = this.pagination(page_number, Math.ceil( this.total_discussions / 10));
  }
  async getDiscussionCategories() {
    var vendorCategory = await this.discussionService.getDiscussionCategories().toPromise();
    this.discussionCategories = vendorCategory;
  }
  async goToTopic(topicName: any) {
    this.router.navigate(['/community/' + topicName]);
  }
  async goToConvo(slug: string){
    this.router.navigate(['/conversation/' + slug]);
  }
  getSRCofImageData(discussion_image: any){
    if(discussion_image[0].imageData){
      return discussion_image[0].imageData;
    }else if(discussion_image[0].file_name){
      return this.discussion_image_url + discussion_image[0].file_name;
    }else{
      return "https://placehold.co/400x400?text=sample+desktop+ad+image";
    }
  }
  async startNewConvo() {
    if(this.activeUser){
      if(this.activeUser.role !== "Vendor"){
        this.router.navigate(['/conversation/create']);
      }else{
        this.toastr.error("Vendor members may not create new conversations");
      }
    }else{
      $('#login_modal').modal('toggle');
    }
  }
  closeLoginModalPopup(){
    $('#login_modal').modal('hide');
  }
  changeCategory(name){
    var category = this.discussionCategories.filter((e: any) => e.name == name);
    this.VendorCategoryName = category[0].name;

    this.vendorCategoryId = category[0].id;
    this.router.navigate(['/community/' + this.VendorCategoryName]);
  }
  async search_topic(){
    if(this.search_text.length < 3){
      return;
    }
    setTimeout(async () => {
      let formData = {
        "search_text": this.search_text
      };
      var response = await this.discussionService.searchDiscussion(formData).toPromise();
      if(response.hasError === false){
        this.search_result = response.data;
      }
    }, 300);
  }
  openDropdown(){
    this.search_dropdown_visible = true;
  }
  hideDropdown(){
    setTimeout(()=>{
      this.search_dropdown_visible = false;
    }, 300);
  }
  columns(){
    return [
      this.discussionCategories.slice(0, Math.ceil(this.discussionCategories.length / 3)),
      this.discussionCategories.slice(Math.ceil(this.discussionCategories.length / 3), 2 * Math.ceil(this.discussionCategories.length / 3)),
      this.discussionCategories.slice(2 * Math.ceil(this.discussionCategories.length / 3))
    ];  
  }
  relativeTime(created_time: string){
    var now = moment.utc();
    var date = moment.utc(created_time).local();
    if (now.diff(date, 'days') < 1) {
        return date.fromNow();  // e.g., "8 hours ago"
    } else if (now.diff(date, 'days') === 1) {
        return 'Yesterday at ' + date.format('h:mm A');
    } else {
        return date.format('MMM D, YYYY [at] h:mm A');
    }
  }

  pagination(current: number, last: number, delta = 2) {
    if (last === 1) return ["*" + 1 + "*"];

    const left = current - delta,
      right = current + delta + 1,
      range = [];

    if (last > 1 && current !== 1) {
      range.push("<");
    }

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        if (i === left && i > 2) {
          range.push("...");
        }

        if (i === current) {
          range.push("*" + i + "*");
        } else {
          range.push(i);
        }

        if (i === right - 1 && i < last - 1) {
          range.push("...");
        }
      }
    }

    if (last > 1 && current !== last) {
      range.push(">");
    }

    return range;
  }
  getPageItemText(pageItem: any) {
    if (isNaN(pageItem)) {
      if (pageItem.indexOf('*') === 0) {
        return pageItem.substr(1, (pageItem.length - 2));
      }
    } else {
      return pageItem;
    }
  }
  goPrev() {  
    this.current_page_number = this.current_page_number - 1;
    this.getDiscussionList(this.current_page_number);
  }
  goNext() {
    this.current_page_number = this.current_page_number + 1;
    this.getDiscussionList(this.current_page_number);
  }
  goToPage(pageItem: any) {
    this.current_page_number = Number(pageItem);
    this.getDiscussionList(this.current_page_number);
  }
  isNumber(value: any) {
    return !isNaN(value);
  }
}
