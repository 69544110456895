import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { SettingsModel } from 'src/app/models/settings.model';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { LocationModel } from 'src/app/models/location.model';
import { CategoryModel } from 'src/app/models/category.model';
import { VendorsService } from '../services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';
import { RequestPricingComponent } from '../request-pricing/request-pricing.component';
import { AgmCoreModule, MapsAPILoader, GoogleMapsAPIWrapper, AgmMap, LatLngBounds } from '@agm/core';

declare var $: any;
declare var google: any;
@Component({
  selector: 'app-vendor-listing',
  templateUrl: './vendor-listing.component.html',
  styleUrls: ['./vendor-listing.component.css']
})
export class VendorListingComponent implements OnInit, AfterViewInit, OnDestroy {



  geocoder: any;
  vendors: any;
  args: any;
  listingView: number;
  mapView: number;
  lat: number;
  long: number;
  zoom: number;
  iconUrl: string;
  centerstageimage: string;
  attributes: any;
  clearBtn = [];
  fullsearch = {};
  listView = localStorage.getItem("lastView");
  suscribeMapReady: any;

  vendorListingCurrentPage: number = 1;
  vendorMapCurrentPage: number = 1;
  pagination_per_Page: number = Config.pagination_per_Page;

  agmMap: AgmMap;
  map:any;
  vendorPhotosUrl = Config.imageUrl + 'images/vendor_thumb_images/';

  @ViewChild('AgmMap') set content(content: AgmMap) {
    this.agmMap = content;
    if(this.suscribeMapReady != null){
      this.suscribeMapReady.unsubscribe();
    }
    if (content) {
      this.suscribeMapReady = this.agmMap.mapReady.subscribe(map => {
        this.map = map;
        this.updateMapBounds();
        
      });
    }
  }

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private locationService: LocationsService,
    private toastr: ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute,
    public router: Router) {
    //this.loginForm = formBuilder.getForm();
    this.mapsAPILoader.load().then(() => {
      this.geocoder = new google.maps.Geocoder();
    });
  }

  updateMapBounds() {
    if(this.map==null) return;
    const bounds: LatLngBounds = new google.maps.LatLngBounds();
    
    for (let mm=0;mm<this.vendors.length;mm++) {
      console.log(this.vendors[mm]);
      bounds.extend(new google.maps.LatLng(this.vendors[mm].lat, this.vendors[mm].long));
    }
    var offset = 0.0020;     
		var center = bounds.getCenter();                            
		bounds.extend(new google.maps.LatLng(center.lat() + offset, center.lng() + offset));
		bounds.extend(new google.maps.LatLng(center.lat() - offset, center.lng() - offset));
    this.map.fitBounds(bounds);

    console.log(bounds);      
  }
  ngOnInit() {

    this.lat = Config.globalLat;
    this.long = Config.globalLong;
    this.zoom = 10;
    this.iconUrl = "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";

    this.route.params.subscribe(params => {
      this.args = params;
      this.fullsearch["search"] = params;
      this.fullsearch["filter"] = [];
      this.searchVendor(this.fullsearch);
      this.vendorSearchCategoryWiseLeftPanel(this.fullsearch);
    });
    this.route.queryParams.subscribe(params => {

      console.log('params',params);
    });

  }

  ngAfterViewInit(): void {
    //if(this.map){

    //}  
  }

  searchVendor(args) {
    this.vendorService
      .searchVendor(args)
      .subscribe(
        (response: []) => {
          this.vendors = response;
          console.log('this.vendors',this.vendors);
          setTimeout(() => this.updateMapBounds(), 1000);
          if (response.length > 0) {
            this.centerstageimage = this.vendors[0].center_stage_image;
            //this.vendorSearchCategoryWiseLeftPanel(args);
          } else {
            this.centerstageimage = "";
          }

          if (this.listView == 'map') {
            this.listingView = 0;
            this.mapView = 1;            
          } else {
            this.listingView = 1;
            this.mapView = 0;
          }
        },
        error => {
          //this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  requestPricing(vendor_id: number) {
    //this.vendor_id = vendor_id;
    $("#vendor_id").val(vendor_id);
    $("#requestpricing").modal();
  }

  vendorListing(arg: string) {
    if (arg == 'list') {
      this.listingView = 1;
      this.mapView = 0;
    } else if (arg == 'map') {
      this.listingView = 0;
      this.mapView = 1;
      this.updateMapBounds();

    }
    //console.log(this.mapView+"=="+this.mapView);
    localStorage.setItem("lastView", arg);
  }

  VendorProfile(ele: any) {
    this.router.navigate(['profile/' + ele.vendor_id]);
  }

  vendorSearchCategoryWiseLeftPanel(args: any) {
    this.vendorService
      .categoryWiseLeftPanel(args)
      .subscribe(
        (response: any) => {
          this.attributes = response;
          this.attributes.forEach(e => {
            this.clearBtn.push({id: e.id, val: false});
          });
          console.log(response);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  filterSearch() {

    //this.router.navigate(["vendor/listings/PHOTOGRAPHERS/Autaugaville?param1=sdf&param2=456,jhy"]);
    //console.log(Config.baseUrl);return;
    var filter = "";
    this.fullsearch["filter"] = [];
    var filterArr = [];
    let that = this;
    $(".side-nav ul").each(function () {
      var parentID = $(this).attr("id");
      var child = [];
      if ($(this).find("input[type=checkbox]:checked").length > 0) {
        $(this).find("input[type=checkbox]:checked").each(function () {
          child.push($(this).val());
        })
      }
      if ($(this).find("input[type=radio]:checked").length > 0) {
        $(this).find("input[type=radio]:checked").each(function () {
          child.push($(this).val());
        })
      }
      if (child.length > 0 && child[0] != '') {
        filterArr.push({ "id": parentID, "val": child })
        that.clearBtn.forEach(e => {
          if(e.id == parentID){
            e.val = true;
          }
        });
      }
    })

    if (filterArr.length > 0) {
      this.fullsearch["filter"] = filterArr;
    }
    this.searchVendor(this.fullsearch);
    console.log(this.fullsearch);
  }

  clearFilter(parentId: string) {
    $(".side-nav ul").each(function () {
      var newParentID = $(this).attr("id");
      if(newParentID == parentId){
        if ($(this).find("input[type=checkbox]:checked").length > 0) {
          $(this).find("input[type=checkbox]:checked").each(function () {
            $(this).prop("checked", false);
          })
        }
        if ($(this).find("input[type=radio]:checked").length > 0) {
          $(this).find("input[type=radio]:checked").each(function () {
            $(this).prop("checked", false);
          })
        }
  
        $(this).find("input[type=radio]").first().prop("checked", true);
      }
    })
    this.clearBtn.forEach(e => {
      if(e.id === parentId){
        e.val = false;
      }
    });
    this.filterSearch();
  }

  checkFilter(parentID: String){
    return this.clearBtn.filter(e => e.id == parentID)[0].val;
  }

  ngOnDestroy(): void {
    if (this.suscribeMapReady) {
      this.suscribeMapReady.unsubscribe();
    }
  }

}
