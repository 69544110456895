import { Component, OnInit } from '@angular/core';
import { PlaceholdersService } from '../../cms/services/placeholders.service';

@Component({
  selector: 'app-real-wedding',
  templateUrl: './real-wedding.component.html',
  styleUrls: ['./real-wedding.component.css']
})
export class RealWeddingComponent implements OnInit {

  realWedding:any = null;
  constructor(private placeholdersService:PlaceholdersService) { }


  ngOnInit() {
    this.placeholdersService
      .getRealWedding('real-wedding')
      .subscribe((result) => {
        this.realWedding = result.data;
        console.log(this.realWedding);
      });
  }

}
