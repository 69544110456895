import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guest-view-summary',
  templateUrl: './guest-view-summary.component.html',
  styleUrls: ['./guest-view-summary.component.scss']
})
export class GuestViewSummaryComponent implements OnInit {
  eventEdit: number;

  constructor() { }

  ngOnInit() {
    this.eventEdit = Math.random();
  }

  getEventResponse(e:any){

  }

}
