import { Deseriazable } from './deserializable.model';

export class EmployeeModel implements Deseriazable{
    
    id: number;
    token: string;
    password: string;
    confirm_password: string;
    firstname: string;
    lastname: string;
    email: string;
    push_notification: number;
    allow_feature_profile: number;
    parent_account_id : number;
    user_type: string;
    message: string;
    role_id: number;
    manageBy: string;
    created_by: string;
    modify_by: string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}