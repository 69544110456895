import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../password.match';

@Injectable({
    providedIn: 'root'
})
export class ChangePasswordFormBuilder{

    private form;
    private validationRules = {
        new_password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-8])(?=.*?[#?)+(!@$%^&*-]).{8,}$')]],
        old_password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-8])(?=.*?[#?)+(!@$%^&*-]).{8,}$')]],
        confirm_password: ['', [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-8])(?=.*?[#?)+(!@$%^&*-]).{8,}$')]]        
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }
}