import { Deseriazable } from './deserializable.model';

export class CheckoutModel implements Deseriazable{
    
    id: string;    
    location_address: string;
    location_phone: string;
    location_city_id: string; 
    location_region_id: number;   
    location_mobile: string; 
    business_hours: string; 
    location_zip_code: string; 
    lat: string; 
    long: string;
    message: string;
    firstname:string;
    lastname:string;
    email:string;
    package_id : number;
    period: number;
    category_region_rate_subscription_id : number;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}