
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { UsersService } from '../modules/users/services/users.service';

export class BaseService {
   
  constructor(protected usersService: UsersService) { }

  getHttpHeaders(){
    var headerValues = {};
    headerValues['Content-Type'] = 'application/json';
    
    var token = this.usersService.getCurrentUserToken();
    if(token){
      headerValues['Authorization'] = token;
    }
    var httpOptions = {
      headers: new HttpHeaders(headerValues)      
    };
    
    //httpOptions.headers.append('Authorization', this.usersService.getCurrentUserToken());

    return httpOptions;
  }
  
}
