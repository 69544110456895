import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { RequestPriceModel } from 'src/app/models/request.price.model';
import { AgmCoreModule, MapsAPILoader, GoogleMapsAPIWrapper, AgmMap } from '@agm/core';
import { PackageSubscriptionModel } from 'src/app/models/package.subscription.model';
import { VendorLocationInfoModel } from 'src/app/models/vendor.location.info.model';

declare var $: any;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  planPrice: any;
  allfeatures: any;
  myPlan: any;
  model = new PackageSubscriptionModel();
  duration: number;
  first_name: string;
  last_name: string;
  address_country: string;
  address_zip: string;
  address_state: string;
  address_city: string;
  address_street: string;
  address_country_code: string;
  alertMessage = "";


  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr: ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute,
    public router: Router) {

  }

  ngOnInit() {
    $('[name=dmonth]').val(12);
    this.duration = 12;
    this.myPlan = { "title": "Basic", "rate": "0.00", "duration_in_month": "n/a" };
    this.plans();
    this.features();
    this.selectedPlan();
    this.getLocationInfo();
    window.top.scroll(10, 10);
  }

  plans() {
    this.vendorService
      .vendorPricePlans()
      .subscribe(
        (response) => {
          this.planPrice = response;
          this.effectAllPackages(12);
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  features() {
    this.vendorService
      .vendorFeaturePlans()
      .subscribe(
        (response) => {
          this.allfeatures = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  choosePlan(ele: any) {
    this.model.package_id = ele.id;
    //this.model.period = $("#monthly" + ele.id).val();
    this.model.period = this.duration;
    var total_price = $("#hiddenTotal" + ele.id).val();
    this.model.category_region_rate_subscription_id = $("#category_region_rate_subscription" + ele.id).val();

    var calprice = {
      package_id: ele.id,
      period: this.duration
    }
    //console.log(calprice);return;
    this.vendorService
      .calPrice(calprice)
      .subscribe(
        (response: any) => {
          if (response.action == false) {
            this.toastr.error("This plan doesn’t qualify for an upgrade. Contact us for more details.");
            return;
          }
          localStorage.setItem("period", this.duration + "");
          localStorage.setItem("category_region_rate_subscription_id", $("#category_region_rate_subscription" + ele.id).val());
          localStorage.setItem("package_id", ele.id);
          localStorage.setItem("total_price", total_price);
          this.router.navigate(['/vendor/checkout']);
          return false;
        }
      ),
      error => {
        this.toastr.error(error.message);
      }


    /* if (this.myPlan.package_id > ele.id) {
      this.alertMessage = "Select plan can not be less than current plan.";
      this.toastr.error(this.alertMessage);
      //$("#subscriptionAlert").modal();
      return;
    } else if (this.myPlan.package_id == ele.id) {
      if (this.myPlan.duration_in_month > this.duration) {
        this.alertMessage = "Select duration can not be less than current plan duration";
        this.toastr.error(this.alertMessage);
        //$("#subscriptionAlert").modal();
        return;
      }
    } */
    //return;
    //$("#Total"+ele.id).html(ele.price * parseInt($("#monthly"+ele.id).val()));

    return;
    //console.log(this.model);return;
    this.vendorService
      .addPlan(this.model)
      .subscribe(
        (response: PackageSubscriptionModel) => {
          //console.log(response.data);return;
          window.location.href = Config.paypalSandboxSettings.url + "?cmd=_xclick&business=" + Config.paypalSandboxSettings.email
            + "&custom=" + response.data.lastInsertId
            + "&item_name=" + ele.title
            + "&item_number=" + ele.id
            + "&amount=" + response.data.remainingPlanRate
            + "&first_name=" + this.first_name
            + "&last_name=" + this.last_name
            + "&address_country=" + this.address_country
            + "&address_country_code=" + this.address_country_code
            + "&address_street=" + this.address_street
            + "&address_state=" + this.address_state
            + "&address_city=" + this.address_city
            + "&address_zip=" + this.address_zip
            + "&currency_code=USD&notify_url="
            + Config.paypalSandboxSettings.notify_url
            + "&return=" + Config.paypalSandboxSettings.return;
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )

    return;
  }

  selectedPlan() {
    this.vendorService
      .vendorCurrentPlan()
      .subscribe(
        (response: PackageSubscriptionModel) => {
          /*if (response.duration_in_month) {
            this.myPlan = response;
          } else {
            this.myPlan = { "title": "Basic", "rate": "0.00", "duration_in_month": "1" };
          }*/
          this.myPlan = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  totalPrice(ele: any, val: number) {
    var calprice = {
      package_id: ele.id,
      period: val
    }
    
    this.vendorService
      .calPrice(calprice)
      .subscribe(
        (response: any) => {
          //console.log(response.id);return;

          $("#category_region_rate_subscription" + ele.id).val(response.id);

          let permonth = (response.rate / val).toFixed(2);
          $("#PerMonth" + ele.id).html("$" + permonth);
          $("#Total" + ele.id).html("$" + permonth);
          $("#hiddenTotal" + ele.id).val(response.rate);
          $(".notUpgradeBtnArea"+ele.id).hide();
          $(".upgradeBtnArea"+ele.id).hide();
          if(response.action==false && this.myPlan.package_id != ele.id){
            $(".notUpgradeBtnArea"+ele.id).show();
          }else{
            $(".upgradeBtnArea"+ele.id).show();
          }

          //$("#Total" + ele.id).html("$" + response.rate);
          return false;
        }
      ),
      error => {
        this.toastr.error(error.message);
      }
  }

  effectAllPackages(val: any) {
    this.duration = val;
    if (this.planPrice.length > 0) {
      for (let i = 0; i < this.planPrice.length; i++) {
        if(this.planPrice[i].id>1){
          console.log(this.planPrice[i].id);
          this.totalPrice(this.planPrice[i], val);
        }        
      }
    }
  }

  getLocationInfo() {
    this.vendorService
      .getLocationInfo()
      .subscribe(
        (response: any) => {
          this.first_name = response.first_name;
          this.last_name = response.last_name;
          this.address_country = "United States";
          this.address_country_code = "US";
          this.address_zip = response.location_zip_code;
          this.address_state = response.location_region_name;
          this.address_city = response.location_city_name;
          this.address_street = response.location_address;

        },
        error => {
          this.toastr.error(error.message);
          //console.log(error.message);
        }
      )
  }

  benefits() {
    this.router.navigate(['vendor/benefits']);
  }

  toolTip(){
    this.toastr.info("This plan doesn’t qualify for an upgrade. Contact us for more details.");
    return;
  }

  mobileView(val: string) {
    $(".basic").hide();
    $(".pro").hide();
    $(".premier").hide();
    $(".showcase").hide();
    $(".center_stage").hide();

    if (val == 'basic') {
      $(".basic").show();
      $(".pro").hide();
      $(".premier").hide();
      $(".showcase").hide();
      $(".center_stage").hide();
    } else if (val == 'pro') {
      $(".basic").hide();
      $(".pro").show();
      $(".premier").hide();
      $(".showcase").hide();
      $(".center_stage").hide();
    } else if (val == 'premier') {
      $(".basic").hide();
      $(".pro").hide();
      $(".premier").show();
      $(".showcase").hide();
      $(".center_stage").hide();
    } else if (val == 'showcase') {
      $(".basic").hide();
      $(".pro").hide();
      $(".premier").hide();
      $(".showcase").show();
      $(".center_stage").hide();
    } else if (val == 'center_stage') {
      $(".basic").hide();
      $(".pro").hide();
      $(".premier").hide();
      $(".showcase").hide();
      $(".center_stage").show();
    }
  }

}
