import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute  } from '@angular/router';
import { VendorsService } from '../services/vendors.service';
import { EmployeeModel } from 'src/app/models/employee.model';
import { VendorBusinessInfoModel } from 'src/app/models/vendor.business.info.model';

declare var $: any;

@Component({
  selector: 'app-endorsements',
  templateUrl: './endorsements.component.html',
  styleUrls: ['./endorsements.component.css']
})
export class EndorsementsComponent implements OnInit {

  filterVendors: any;
  saveVendors: any;
  saveVendorForm: any;
  vendor_id = new FormControl('');
  vendor_name = new FormControl('');
  showFilterSearchData = 0;
  vid = 0;
  image_url: string = Config.imageUrl;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    public router: Router) { }

  ngOnInit() {
    this.savedVendors();
    window.scroll(10,10);
  }

  vendorFilter(event: any){    
    var inputVal = event.target.value;
    if(inputVal.length==0 || inputVal.length==null){
      this.showFilterSearchData = 0;
    }
    this.vendorService
      .filterVendors(inputVal)
      .subscribe(
        (response:VendorBusinessInfoModel[]) => {
          this.showFilterSearchData = 1; 
          this.filterVendors = response;            
        },
        error => {
          this.showFilterSearchData = 0;
          //this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  savedVendors(){
    this.vendorService
      .savedVendors()
      .subscribe(
        (response:VendorBusinessInfoModel[]) => { 
          this.saveVendors = response;  
        },
        error => {
          //this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  addVendor(){
    var id:any;
    id = this.vendor_id.value; 
    this.vendorService
      .addVendor(id)
      .subscribe(
        (response:VendorBusinessInfoModel) => { 
          this.saveVendors = response;
          this.toastr.success(response.message);
          this.vendor_id.setValue("");
          this.vendor_name.setValue("");
          this.savedVendors() ;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }
 
  selectVendor(ele:any){
    this.vendor_id.setValue(ele.target.getAttribute("id"));
    this.vendor_name.setValue(ele.target.innerHTML.replace(/&amp;/g, '&'));
    this.showFilterSearchData = 0;
  }

  deleteEndorsModal(id:number){
    this.vid = id;
    $("#deleteendors").modal();    
  } 

  deleteEndorsment(vid:number){
    this.vendorService
      .deleteEndorsment(vid)
      .subscribe(
        (response:VendorBusinessInfoModel) => { 
          $('#deleteendors').modal('toggle'); 
          this.toastr.success(response.message);
          this.savedVendors() ;
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }
  saveDescription(ele:any){
    if(ele.description==""){
      this.toastr.error("Comments can not be blank.");
      return ;
    }
    var data = {description:ele.description,prefered_vendor_id:ele.prefered_vendor_id};
    this.vendorService
      .saveComment(data)
      .subscribe(
        (response:any) => { 
          this.toastr.success(response.message);
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

}
