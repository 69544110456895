import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { PriceFormBuilder } from './price.form-builder';
import { PriceModel } from 'src/app/models/price.model';
import { EventTypeModel } from 'src/app/models/eventtype.model';

declare var $: any;

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {
  
  submitted = false;
  model = new PriceModel();
  priceForm: any;
  prices: any;
  price_id = 0;
  pid = 0;
  title = "Add New pricing";
  button = "Create Pricing";
  eventTypes: any;
  currentUrl: string;
  pricepdf: string;

  seasonArray = [];
  seasonVals = [];
  daysVals = [];
  daysArray = [];
  selectedDays = [];
  selectReasons = []; 

  pricingCurrentPage : number = 1;
  pagination_per_Page:number = Config.pagination_per_Page;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: PriceFormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
      
  }
  ngOnInit() {
    this.priceForm = this.formBuilder.getForm();
    this.currentUrl = Config.baseUrl+this.router.url;

    this.seasonVals = Config.seasonArray;
    this.daysVals = Config.daysArray; 
     
    this.getEventType();
    this.getPrices();
    this.resetForm();
    this.getPricePdf();
  }

  get f() { return this.priceForm.controls; }

  onSubmit() {
    //this.priceForm.controls.season.setValue([1,2,4]);
    var seasonArr = [];
    $(".price_section input[type=checkbox]").each(function(){
      if($(this).prop("checked")){
        seasonArr.push($(this).val());
      }
    });

    var daysAr = [];
    $(".price_week input[type=checkbox]").each(function(){
      if($(this).prop("checked")){
        daysAr.push($(this).val());
      }
    })
    
    if(seasonArr.length==0){
      this.toastr.error("Select Seasons.");
      return;
    }
    this.priceForm.controls.season.setValue(seasonArr);

    if(daysAr.length==0){
      this.toastr.error("Select Days Of The Week.");
      return;
    }
    this.priceForm.controls.days_of_week.setValue(daysAr);

    this.submitted = true;
    // stop here if form is invalid
    if (this.priceForm.invalid) {
      return;
    }
    
    this.model.deserialize(this.priceForm.value);
    //console.log(this.model);return;
    this.vendorService
      .savePrice(this.model)
      .subscribe(
        (response: PriceModel) => {
          this.toastr.success(response.message);
          this.getPrices();
          this.resetForm();
          this.daysVals = Config.daysArray;
          this.seasonVals = Config.seasonArray;          
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  getPrices() {
    this.vendorService
      .getPrices()
      .subscribe(
        (response: PriceModel[]) => {          
          for(var i=0;i<response.length;i++){
            var sson = "";
            var dow = "";           
            for(var k=0;k<response[i].season.length;k++)
            {
              sson += this.search(response[i].season[k],Config.seasonArray);                            
            }
            response[i].season = sson.slice(0, -1);;
            for(var k=0;k<response[i].days_of_week.length;k++)
            {
              dow += this.search(response[i].days_of_week[k],Config.daysArray);                            
            }
            response[i].days_of_week = dow.slice(0, -1);
          }
          
          this.prices = response;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  getPrice(id: number) {
    this.vendorService
      .getPrice(id)
      .subscribe(
        (response: PriceModel) => {
          this.formBuilder.setValues(response);
          //this.priceForm.controls.season.setValue([1,4.8]);
          this.selectReasons = response.season;
          this.seasonArray = this.selectReasons;
          this.priceForm.controls.season.setValue(this.seasonArray); 
          this.selectedDays = response.days_of_week;
          this.daysArray = this.selectedDays;
          this.priceForm.controls.days_of_week.setValue(this.daysArray); 
          this.title = "Edit Price";
          this.button = "Save Pricing";
          window.top.scroll(10,10);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  deletePriceModal(id: number) {
    this.pid = id;
    $("#deleteprice").modal();
  }

  deletePrice(pid: any) {
    this.vendorService
      .deletePrice(pid)
      .subscribe(
        (response: PriceModel) => {
          $('#deleteprice').modal('toggle');
          this.toastr.success(response.message);
          this.getPrices();
          this.router.navigate([this.router.url]);
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  getEventType() {
    this.vendorService
      .getEventType()
      .subscribe(
        (response: EventTypeModel[]) => {
          this.eventTypes = response;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  seasonCheck(sVal:any, isChecked: boolean) {    
    if (isChecked) {
      this.seasonArray.push(sVal);
    } 
    if (!isChecked) {
      let index = this.seasonArray.indexOf(sVal);
      if (index > -1) {
        this.seasonArray.splice(index, 1);
      }
    } 
    this.priceForm.controls.season.setValue(this.seasonArray); 
  }

  daysCheck(sVal:any, isChecked: boolean) {    
    if (isChecked) {
      this.daysArray.push(sVal);
    } 
    if (!isChecked) {
      let index = this.daysArray.indexOf(sVal);
      if (index > -1) {
        this.daysArray.splice(index, 1);
      }
    }  
    this.priceForm.controls.days_of_week.setValue(this.daysArray);
  }


  seasonSelected(season_id:any){
    //console.log(this.priceForm.get('id').value);
    if(this.priceForm.get('id').value){
      let index = this.selectReasons.indexOf(season_id);
      if (index > -1) {
        return 1;
      }
    }
    else{
      return 1;
    }
     
  }

  daysOfWeekSelected(days_id:any){
      if(this.priceForm.get('id').value){
        let index = this.selectedDays.indexOf(days_id);
        if (index > -1) {
          return 1;
        }
      }else{
        if(days_id==1 || days_id==32 || days_id==64){
          return 1;
        }
      }  
  }
  resetForm(){
    this.submitted = false;
    this.priceForm.reset();
    this.seasonVals = Config.seasonArray;    
    this.daysVals = Config.daysArray;
    $('.price_week input[type=checkbox]').prop('checked',false);
    $('.price_section input[type=checkbox]').prop('checked',true);
    $('.price_week input[type=checkbox][value=1]').prop('checked', true);
    $('.price_week input[type=checkbox][value=32]').prop('checked', true);
    $('.price_week input[type=checkbox][value=64]').prop('checked', true);

    
    this.title = "Add New";
    this.button = "Create Pricing";
    window.top.scroll(10,10);
  }

  search(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].id === nameKey) {
              return myArray[i].name.slice(0, 2)+",";
          }
      }
  }

  onPDFChanges(file:any){
    this.vendorService
      .savePricingPdf(file[0])
      .subscribe(
        (response) => {
          this.toastr.success(response.message);
          this.getPricePdf();
          $("#pdf").val("");
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  getPricePdf(){
    this.vendorService
      .getAuthVendorDetail()
      .subscribe(
        (response: any) => {
          this.pricepdf = response.data.price_doc;   
          console.log(response.data.price_doc);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }
  
  deletePricePdf(){
    this.vendorService
      .deletePricePdf()
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          this.pricepdf = "";
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

}
