import { Injectable } from '@angular/core';
import { FormBuilder, Validators, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { MustMatch } from '../../password.match';
import { EmployeeModel } from 'src/app/models/employee.model';
import { PriceModel } from 'src/app/models/price.model';
import { CustomValidator } from '../../../customvalidation';

@Injectable({
    providedIn: 'root'
})
export class PriceFormBuilder{

    private form; 
  
    private validationRules = {         
        id:['',''],
        price_per_guest: ['',[Validators.required,CustomValidator.numeric]],
        min_guest: ['',[Validators.required,CustomValidator.numeric]],
        max_guest: ['',[Validators.required,CustomValidator.numeric]],
        days_of_week : [], 
        season: [],
        event_type: ['', Validators.required]        
    }

    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:PriceModel){
        this.form.patchValue({
            id:response.id,
            price_per_guest:response.price_per_guest,
            min_guest:response.min_guest,
            max_guest:response.max_guest,
            days_of_week:response.days_of_week,
            season:response.season,
            event_type:response.event_type,
        });
    }
}

