import { Component, OnInit } from '@angular/core';
import { Config } from '../config';
import { Router,NavigationEnd } from '@angular/router';
import { BaseService } from '../services/base.service';
import { UsersService } from '../modules/users/services/users.service';
import { PlannersService } from '../modules/planners/services/planners.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-front-nav',
  templateUrl: './front-nav.component.html',
  styleUrls: ['./front-nav.component.css']
})
export class FrontNavComponent implements OnInit {

  isLogin = false;
  fname: string;
  role = localStorage.getItem('role');
  weddings: any;
  addNew: boolean=false;
  activeUser: any;
  routevalue: any;
  constructor(
    private usersService: UsersService,
    public router: Router,
    private plannerService: PlannersService,
    private toster: ToastrService,

  ) { 
    
    this.plannerService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }

    },
      (err) => {
        console.error(err);
      });

      this.router.events
        .subscribe(event => {
            this.routevalue = event['url']
        });
  }

  ngOnInit() {
    this.isLoginUser();
    if(this.plannerService.getCurrentUid()){
      this.getWeddings();
    }   
    
    
  }

  isLoginUser() {
    if (this.usersService.getCurrentUserToken())
      this.isLogin = true;

    this.fname = this.usersService.getCurrentUserFname();
  }

  logout() {
    localStorage.setItem("USER_TOKEN", '');
    localStorage.setItem("UID", '');
    localStorage.setItem("fname", '');
    localStorage.setItem("role", '');
    this.plannerService.removeActiveUser();
    localStorage.removeItem("datas");
    // window.location.href = Config.baseUrl;
    this.router.navigate(['/login']);
  }

  home() {
    this.router.navigate(['/']);
  }

  signUp() {
    this.router.navigate(['/vendor/register']);
  }

  signIn() {
    this.router.navigate(['/vendor/login']);
  }

  vendorHome() {
    if (Config.serviceEndPoints.vendors.roles.indexOf(this.usersService.getCurrentUserRole()) > -1) {
      this.router.navigate(['/vendor/home']);
    } else if (Config.serviceEndPoints.weddingUsers.roles.indexOf(this.usersService.getCurrentUserRole()) > -1) {
      this.router.navigate(['/planner-dashboard']);
    }

  }

  redirectTo(arg: any) {
    this.router.navigate([arg]);
  }

  loginUser(){
    if(location.pathname === '/'){
      this.router.navigate(["login"]);
    }else{
      this.router.navigate(["login"], {queryParams: {returnUrl: location.pathname}});
    }
  }

  getWeddings() {
        this.plannerService.weddingClick.subscribe((userData) => {
      if (userData) {
        this.weddings = userData;
        if(this.weddings[0].is_planner==1){
          this.addNew=true;
        }else{
          this.addNew=false;
        }
      } else {
        this.activeUser = null;
      }

    },
      (err) => {
        console.error(err);
      }); 
  }

  activeWedding(element: any) {
    this.plannerService.activeWedding(element).subscribe((result: any) => {
      this.toster.success("New Wedding Selected");
      this.getUpdatedWeddings();
      setTimeout(()=>{        
        this.plannerService.setNewWedding(this.weddings);
      },1000);     
      //window.location.href="planner-dashboard";
      return;
    },
      error => {
        this.toster.error(error.message);
      })
  }

  getUpdatedWeddings() {
    this.plannerService.getWeddings().subscribe((result: any) => {
      this.weddings = result;
      this.plannerService.storeWeddings( this.weddings);

    })
  }

}
