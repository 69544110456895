import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { VendorsService } from '../services/vendors.service';
import { UsersService } from '../../users/services/users.service';
import { ToastrService } from 'ngx-toastr';
import { Config } from '../../../config';

@Component({
  selector: 'app-review-request',
  templateUrl: './review-request.component.html',
  styleUrls: ['./review-request.component.scss']
})
export class ReviewRequestComponent implements OnInit {

  email: string = "";
  message: string = "";
  review_requests: any[] = [];
  total_review_requests: number = 0;
  review_requests_pagination_items: any[] = [];
  review_requests_current_page: number = 1;
  review_request_page_size: number = 10;
  is_request_review_preview_active: boolean = false;
  user_details: any;
  vendor_id: number = null;
  vendor_name: string = '';
  site_url: string = Config.baseUrl;

  constructor(
    public router: Router,
    private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.getRequestLog();
    this.vendor_id = parseInt(localStorage.getItem("UID"));
    this.vendor_name = this.usersService.getCurrentUserFname();
  }

  getRequestLog(){
    var data={
      page_size: this.review_request_page_size,
      page_number: this.review_requests_current_page
    };
    this.vendorService.getRequestForReviewLog(data).subscribe((response:any) => {
      this.review_requests = response.result.review_requests;
      this.total_review_requests = response.result.total_review_requests;
      this.review_requests_pagination_items = this.pagination(this.review_requests_current_page, Math.ceil( this.total_review_requests / this.review_request_page_size));
    }, error => {
      this.toastr.error(error.message);
    });
  }
  send_request(){
    var data={
      user_email: this.email,
      request_message: this.message,
      review_link: Config.baseUrl + "/vendor-ratings/"
    };
    this.vendorService.submitReviewRequest(data).subscribe((response:any) => {
      this.toastr.success("Request Sent!");
      this.getRequestLog();
      this.resetRequestReviewForm();
      this.is_request_review_preview_active = false;
    }, error => {
      this.toastr.error(error.message);
    });
  }
  resetRequestReviewForm(){
    this.email = "";
    this.message = "";
  }
  loadRequestReviewPreview(){
    var data={
      email: this.email,
    };
    this.vendorService.getWeddingUserDetails(data).subscribe((response:any) => {
      this.is_request_review_preview_active = true;
      this.user_details = response.result;
    }, error => {
      this.toastr.error(error.message);
    });
  }
  getNow(){
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return ( year + "-" + ((month > 9) ? '' + month : '0' + month) + "-" + ((day > 9) ? '' + day : '0' + day)) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }
  getRelateiveTime(datetime: string) {
    var msPerMinute: number = 60 * 1000;
    var msPerHour: number = msPerMinute * 60;
    var msPerDay: number = msPerHour * 24;
    var msPerMonth: number = msPerDay * 30;
    var msPerYear: number = msPerDay * 365;
    var currentDate: number = Date.parse(this.getNow());
    var previousDate: number  = Date.parse(datetime);
    var elapsed: number = currentDate - previousDate;
    if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';   
    }else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago';   
    }else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago';   
    }else {
        return Math.round(elapsed/msPerYear ) + ' years ago';   
    }
  }
  pagination(current: number, last: number, delta = 2) {
    if (last === 1) return ["*" + 1 + "*"];

    const left = current - delta,
      right = current + delta + 1,
      range = [];

    if (last > 1 && current !== 1) {
      range.push("<");
    }

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        if (i === left && i > 2) {
          range.push("...");
        }

        if (i === current) {
          range.push("*" + i + "*");
        } else {
          range.push(i);
        }

        if (i === right - 1 && i < last - 1) {
          range.push("...");
        }
      }
    }

    if (last > 1 && current !== last) {
      range.push(">");
    }

    return range;
  }
  getPageItemText(pageItem: any) {
    if (isNaN(pageItem)) {
      if (pageItem.indexOf('*') === 0) {
        return pageItem.substr(1, (pageItem.length - 2));
      }
    } else {
      return pageItem;
    }
  }
  goPrev() {
    this.review_requests_current_page = this.review_requests_current_page - 1;
    this.getRequestLog();
  }
  goNext() {
    this.review_requests_current_page = this.review_requests_current_page + 1;
    this.getRequestLog();
  }
  goToPage(pageItem: any) {
    this.review_requests_current_page = Number(pageItem);
    this.getRequestLog();
  }
  isNumber(value: any) {
    return !isNaN(value);
  }
}
