import { Deseriazable } from './deserializable.model';

export class SocialMediaModel implements Deseriazable{
    
    id: string;   
    mode: string; 
    facebook_link: string;
    twitter_link: string;
    youtube_link: string;    
    instagram_link: string; 
    printerest_link: string; 
    vimeo_link: string;
    message: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}