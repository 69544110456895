import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { VendorsService } from '../services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';
import { PackageSubscriptionModel } from 'src/app/models/package.subscription.model';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.css']
})
export class TopNavComponent implements OnInit {

  isLogin = false;
  fname: string;
  myPlan: any;
  constructor(private usersService: UsersService,public router: Router,private vendorService: VendorsService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.isLoginUser();
    this.myPlan = {"title":"Basic","rate":"0.00","duration_in_month":"1"};
  }

  isLoginUser(){
    if(this.usersService.getCurrentUserToken()){
      this.isLogin = true;
      this.selectedPlan();
    }
        
    
    this.fname = this.usersService.getCurrentUserFname();
  }

  logout(){
    localStorage.setItem("USER_TOKEN",'');
    localStorage.setItem("UID",'');
    localStorage.setItem("fname",'');
    this.router.navigate(['/']);
    window.location.href=Config.baseUrl;
  }

  home(){
    this.router.navigate(['/']);
  }

  signUp(){
    this.router.navigate(['/vendor/register']);
  }

  signIn(){
    this.router.navigate(['/vendor/login']);
  }

  vendorHome(){
    this.router.navigate(['/vendor/home']);
  }

  upgrade(){
    this.router.navigate(['/vendor/membership']);
  }

  selectedPlan(){
    this.vendorService
      .vendorCurrentPlan()
      .subscribe(
        (response:PackageSubscriptionModel) => {          
          if(response.package_id){
            this.myPlan = response;
          }else{
            this.myPlan = {"title":"Basic","rate":"0.00","duration_in_month":"1"};
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      )
    
    /* this.myPlan = {
      "plan_id":3
    } */
  }

}
