import { Component, OnInit } from '@angular/core';
import { DiscussionService } from '../services/discussions.service';
import { PlannersService } from '../services/planners.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Config } from '../../../config';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-wedding-result-forum',
  templateUrl: './wedding-forum-search.component.html',
  styleUrls: ['./wedding-forum-search.component.scss']
})
export class WeddingForumSearchComponent implements OnInit {
  discussionCategories: any;
  vendorCategoryId: any;
  VendorCategoryName: any;
  activeUser: any;
  search_text: string = "";
  forums_discussions: any[] = [];
  current_page_number: number = 1;
  total_discussions: number = 0;
  discussions_pagination_items: any[] = [];
  sort_by = 'last-activity';
  category_banner_image_url: string = Config.imageUrl + 'images/discussion_category_banner/';

  constructor(
    private discussionService: DiscussionService,
    private plannersService: PlannersService,
    public router: Router,
    public route: ActivatedRoute
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    }, (err) => {
        console.error(err);
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      if (params) {
        if (params['search']) {
          this.search_text = params['search'];
          this.getDiscussionList(1);
        }
      }
    });
    await this.getDiscussionCategories();
  }
  async getDiscussionList(page_number: number){
    let formData = {
      "category_id": "",
      "page_number": page_number,
      "sort_by": this.sort_by,
      "search_text": this.search_text,
      "items_in_page": 20
    };
    this.current_page_number = page_number;
    var response = await this.discussionService.getDiscussionList(formData).toPromise();
    this.forums_discussions = response.data;
    this.total_discussions = response.total_records;
    this.discussions_pagination_items = this.pagination(page_number, Math.ceil( this.total_discussions / 20));
  }
  async getDiscussionCategories() {
    var vendorCategory = await this.discussionService.getDiscussionCategories().toPromise();
    this.discussionCategories = vendorCategory;
  }
  async goToTopic(topicName: any) {
    this.router.navigate(['/community/' + topicName]);
  }
  async goToConvo(slug: string){
    this.router.navigate(['/conversation/' + slug]);
  }
  changeCategory(name){
    var category = this.discussionCategories.filter((e: any) => e.name == name);
    this.VendorCategoryName = category[0].name;
    this.vendorCategoryId = category[0].id;
    this.router.navigate(['/community/' + this.VendorCategoryName]);
  }
  columns(){
    return [
      this.discussionCategories.slice(0, Math.ceil(this.discussionCategories.length / 3)),
      this.discussionCategories.slice(Math.ceil(this.discussionCategories.length / 3), 2 * Math.ceil(this.discussionCategories.length / 3)),
      this.discussionCategories.slice(2 * Math.ceil(this.discussionCategories.length / 3))
    ];  
  }
  relativeTime(created_time: string){
    var now = moment();
    var date = moment(created_time);
    if (now.diff(date, 'days') < 1) {
        return date.fromNow();  // e.g., "8 hours ago"
    } else if (now.diff(date, 'days') === 1) {
        return 'Yesterday at ' + date.format('h:mm A');
    } else {
        return date.format('MMM D, YYYY [at] h:mm A');
    }
  }

  pagination(current: number, last: number, delta = 2) {
    if (last === 1) return ["*" + 1 + "*"];

    const left = current - delta,
      right = current + delta + 1,
      range = [];

    if (last > 1 && current !== 1) {
      range.push("<");
    }

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        if (i === left && i > 2) {
          range.push("...");
        }

        if (i === current) {
          range.push("*" + i + "*");
        } else {
          range.push(i);
        }

        if (i === right - 1 && i < last - 1) {
          range.push("...");
        }
      }
    }

    if (last > 1 && current !== last) {
      range.push(">");
    }

    return range;
  }
  getPageItemText(pageItem: any) {
    if (isNaN(pageItem)) {
      if (pageItem.indexOf('*') === 0) {
        return pageItem.substr(1, (pageItem.length - 2));
      }
    } else {
      return pageItem;
    }
  }
  goPrev() {  
    this.current_page_number = this.current_page_number - 1;
    this.getDiscussionList(this.current_page_number);
  }
  goNext() {
    this.current_page_number = this.current_page_number + 1;
    this.getDiscussionList(this.current_page_number);
  }
  goToPage(pageItem: any) {
    this.current_page_number = Number(pageItem);
    this.getDiscussionList(this.current_page_number);
  }
  isNumber(value: any) {
    return !isNaN(value);
  }
}
