import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { UsersService } from '../../users/services/users.service';
import { LoginModel } from 'src/app/models/login.model';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { ForgotPasswordModel } from 'src/app/models/forgotpassword.model';
import { RegisterModel } from 'src/app/models/register.model';
import { PlannerProfileModel } from 'src/app/models/planner.profile.model';
import { BehaviorSubject } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ChecklistService extends BaseService {

  private _selectedPlannerWedding: any;
  public onPlannerWeddingUpdate = new Subject();
  private _weddings: any;
  assignWedding = new Subject<string>();

  public setSocialData = new BehaviorSubject({data : false});
  
  constructor(
    private http: HttpClient, 
    protected usersService: UsersService) {
    super(usersService);
    
  }



  getDefaultCheckList(){
    return this.http
      .get(Config.apiBaseUrl+"/checklist/default", this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }


  geUpdateCheckList(id:any){
    return this.http
      .get(Config.apiBaseUrl+"/checklist/checklist-detail/"+id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getCategoryList(){
    return this.http
      .get(Config.apiBaseUrl+"/checklist/category", this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getVendorCategorys(){
    return this.http
      .get(Config.apiBaseUrl+"/checklist/category-vendor", this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  updateChecklist(data:any) {  
    return this.http
      .put(Config.apiBaseUrl+"/checklist/update", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;         
      }));
  }

  addCheckList(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/add", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  taskMoveToTrash(data:any) {  
    return this.http
      .put(Config.apiBaseUrl+"/checklist/is-trash", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }
        return response;         
      }));
  }

  filterCheckList(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/task_filter", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  changeStatus(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/status", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }


  getCountCheckList(data:any){
    return this.http
      .post(Config.apiBaseUrl+"/checklist/task-statistics-count", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getCountCategoryCheckList(data:any){
    return this.http
      .post(Config.apiBaseUrl+"/checklist/catagory-wise-count", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getCountMonthWiseCheckList(data:any){
    return this.http
      .post(Config.apiBaseUrl+"/checklist/task-date-count-list", data, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }


  downloadCategoryCheckList(id:any){
    return this.http
      .get(Config.apiBaseUrl+"/checklist/download_checklist/"+id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  
  deleteCheckList(id:any){
    return this.http
      .delete(Config.apiBaseUrl+"/checklist/delete/"+id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getPrintCheckList(id:any){
    return this.http
      .get(Config.apiBaseUrl+"/checklist/print_checklist/generated/"+id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  searchValue(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/vendor_detail_by_filter", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  getProgressCategorys(id:any){
    return this.http
      .get(Config.apiBaseUrl+"/checklist/checklist-progressBar/"+id, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  getCountByWhen(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/due-Now-After-Wedding-Count", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  getWeddingVendor(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/save_wedding_vendor_list", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  addWeddingVendor(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/add_custom_wedding_vendor", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }
  

  hairedOrNotVendor(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/hire_wedding_vendor", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

}
