import { Component, OnInit } from '@angular/core';
import { PlaceholdersService } from '../modules/cms/services/placeholders.service';
import { IdeaAndTipsService } from '../modules/planners/services/ideas-and-tips.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ideas-tips',
  templateUrl: './ideas-tips.component.html',
  styleUrls: ['./ideas-tips.component.css']
})
export class IdeasTipsComponent implements OnInit {

  ideaTips:any = null;
  testData:any = null;
  ideaAndTipsCategories: any;
  constructor(private placeholdersService:PlaceholdersService, 
    private ideaAndTipsService: IdeaAndTipsService,
    public router: Router
  ) { }

  async ngOnInit() {
    await this.getIdeaAndTipsCategories();
    this.placeholdersService
      .getIdeasTips('idea-tips')
      .subscribe((result) => {
        this.ideaTips = result.data;
        console.log(this.ideaTips);
      });
    
      this.placeholdersService
      .getAjaxCallTest()
      .subscribe((result) => {
        this.testData = result;
        console.log(this.testData);
      });  

  }

  async getIdeaAndTipsCategories() {
    var categories = await this.ideaAndTipsService.getIdeaAndTipsCategories().toPromise();
    this.ideaAndTipsCategories = categories.data.filter((cat: any, index: number)=>{ return index < 4 });
  }

  goToCategory(cat: any){
    this.router.navigate(['/ideas-and-tips/' + cat.slug]);
  }

}
