import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { RegisterModel } from 'src/app/models/register.model';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { LocationModel } from 'src/app/models/location.model';
import { CategoryModel } from 'src/app/models/category.model';
import { VendorsService } from '../services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';
import { RegionModel } from 'src/app/models/region.model';

@Component({
  selector: 'app-features-benefits',
  templateUrl: './features-benefits.component.html',
  styleUrls: ['./features-benefits.component.css']
})
export class FeaturesBenefitsComponent implements OnInit {

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private locationService: LocationsService,
    private toastr:ToastrService,
    public router: Router) {
    //this.loginForm = formBuilder.getForm();
  }

  ngOnInit() {
  }

}
