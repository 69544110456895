import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MustMatch } from '../../password.match';
import { PlannerProfileModel } from 'src/app/models/planner.profile.model';

@Injectable({
    providedIn: 'root'
})
export class PlannerProfileFormBuilder{
    private form;
    urlRegex = "^[a-z0-9_-]+$";

    private validationRules = {
        id: ['',''],
        first_couple_id: ['',''],
        first_couple_name: ['',Validators.required],
        first_couple_type: ['',Validators.required],
        second_couple_id: ['',''],
        second_couple_name: ['',Validators.required],
        second_couple_type: ['',Validators.required],
        start_datetime: ['',''],
        end_datetime: ['',''],
        event_date: ['',''],
        startHour: ['',''],
        startMinute: ['',''],
        endHour: ['',''],
        endMinute: ['',''],
        state_id: ['',''],
        city_id: ['',''],
        color: ['',''],
        wedding_website_url_name:['',Validators.pattern(this.urlRegex)],
    }

    
    constructor(private fb:FormBuilder){
        this.form = fb.group(this.validationRules);
    }   

    getForm(){
        return this.form;
    }

    setValues(response:PlannerProfileModel){
        this.form.patchValue({
            id:response.id,
            first_couple_id:response.first_couple_id,
            first_couple_name:response.first_couple_name,
            first_couple_type:response.first_couple_type,
            second_couple_id:response.second_couple_id,
            second_couple_name:response.second_couple_name,
            second_couple_type:response.second_couple_type,
            start_datetime:response.start_datetime,
            end_datetime:response.end_datetime,
            color:response.color,
            event_date: response.event_date,
            startHour: response.startHour,
            startMinute: response.startMinute,
            endHour: response.endHour,
            endMinute: response.endMinute,
            state_id: response.state_id,
            city_id: response.city_id,
            wedding_website_url_name: response.wedding_website_url_name,
            
            
        });
    }

    
}