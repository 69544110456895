import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { async } from '@angular/core/testing';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GuestlistService } from '../services/guestlist.service';
import { PlannersService } from '../services/planners.service';
declare var $: any;
import * as CryptoJS from 'crypto-js';
import { map } from 'rxjs/operators';
import { Config } from '../../../config';
import { SeatingChartService } from '../services/seating-chart.service';
@Component({
  selector: 'app-guestlist',
  templateUrl: './guestlist.component.html',
  styleUrls: ['./guestlist.component.css']
})
export class GuestlistComponent implements OnInit {
  showView = "list";
  weddingTable: boolean = true;
  weddingStatus = "Attending";
  showMe: boolean = false;
  guests = [{
    fname: '',
    lname: '',
    age: 1
  }];
  showSetting: boolean = false;
  eventName = 'Snapshot';
  eventEdit: number;
  relationshipName: any;
  activeUser: any;
  age_Type: number;
  need_Hotel: number;
  rsvp_note: string;
  deleteTypes = [
    "Only from this event",
    "From my account"
  ];
  typeOfAge = [
    {
      id: 1,
      value: 'Adult'
    },
    {
      id: 2,
      value: 'Child'
    },
    {
      id: 3,
      value: 'Baby'
    }

  ];
  typeOfAttendance = [
    {
      id: 'Attending',
      value: 'Attending'
    },
    {
      id: 'pending',
      value: 'Pending'
    },
    {
      id: 'Cancelled',
      value: 'Cancelled'
    }

  ];
  typeOfHotel = [
    {
      id: 1,
      value: 'Yes'
    },
    {
      id: 2,
      value: 'No'
    }

  ];
  typeOfTabs = [
    {
      id: 1,
      value: 'Relationship'
    },
    {
      id: 2,
      value: 'Menu'
    },
    {
      id: 3,
      value: 'Seating Chart'
    },
    {
      id: 4,
      value: 'Attendance'
    },
    {
      id: 5,
      value: 'Lists'
    }

  ];
  ageType: any = 1;
  hotelType: any = 2;
  selectAge: boolean[] = [];
  selectHotel: boolean[] = [];
  guestOf: string = null;
  allEvent: any;
  allRelationship: any;
  events = [];
  checkedIDs: any[];
  selectedRelation: any = null;
  email: any = null;
  pnoneNo: any = null;
  mobileNo: any = null;
  address: any = null;
  city: any = null;
  state: any = null;
  zip: any = null;
  allGuests: any;
  group: any;
  guestDetails: any;
  allGuest: any;
  editSelectedRelation: any = null;
  editEmail: any = null;
  editPnoneNo: any = null;
  editMobileNo: any = null;
  editAddress: any = null;
  editCity: any = null;
  editState: any = null;
  editZip: any = null;
  editFirstName: any = null;
  editLastName: any = null;
  editNote: any = null;
  guestData: void;
  editGuestOf: any;
  editRelation: any = null;
  editAgeType: any = 1;
  editHotelType: any = 2;
  editSelectAge: boolean[] = [];
  editSelectHotel: boolean[] = [];
  guestId: any;
  guestIndex: any;
  editGuestId: any;
  singleGustData: any;
  addRelationLavel: any = 'Create a new relationship';
  singleRelationshipData: any;
  eventData: any;
  menuList: any;
  selectedTab: boolean[] = [];
  tab: any = 1;
  menuId: any;
  removeHeading: any;
  removeText: any;
  guest: boolean = false;
  eventsGuests: boolean = false;
  selectedSingleGuest: any;
  apiGuestList: any;
  allRemoveBtnStatus: boolean = false;
  guestEvents: any;
  editMenuList: any;
  editEventList: any;
  editSelectedMenu: any = null;
  editSelectedList: any = null;
  editAttendance: any;
  editEventData: any;
  selectedEditEvent: boolean[] = [];
  snapshot: boolean = true;
  ListItems: any;
  modifyGuest: any[];
  eventItemsArray: any = [];
  filterMenu: any = [];
  menuName: any;
  addMenuLavel: any = 'Create a new menu';
  listName: any;
  addListLavel: any = 'Create a new list';
  singleMenuData: any;
  singleListData: any;
  linkedGuestName: any;
  addLinked: boolean = false;
  additionalField: boolean = false;
  linkedGuestLastName: any;
  linkedGuestMenu: any = null;
  linkedSelectAge: boolean[] = [];
  linkedAgeType: any;
  additionalGuestList: any;
  selectedItems: any;
  selectedValue: any;
  checkedSelectedItem: boolean = false;
  selectedId: any;

  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('menu') menu: ElementRef;

  isMenuOpen = false;
  eventItemStatus: boolean = false;
  searchText: string | undefined | null;
  searchEventText: string;
  guestLink: any;
  addNewGuest: boolean = true;
  gaustCount: any;
  selectedEvent: any;
  eventWiseGuestCount: any;
  tabData: any;
  menuItemShow: any;
  templateUrl: string;
  fileName: any;
  fileType: any;
  tableList: any;
  editSelectedTable: any;
  editTableList: any;
  enableSittingChart: boolean = false;
  guestList: any;
  guestEventData: any;
  guestEventList: any;
  filterEventGuest: any=[];
  checkedGuest: any=[];
  selecList: any;
  addGuestData: any;
  selectOption: any;
  selectedOption: any;
  hide_guest_in_rsvp: boolean;
  constructor(private renderer: Renderer2,
    public router: Router,
    private plannersService: PlannersService, private guestlistService: GuestlistService,
    private _toastr: ToastrService, private seatingChartService: SeatingChartService) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.getEventList(this.activeUser);
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
  }

  ngAfterViewInit() {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.toggleButton && this.menu) {
        if (e.target !== this.toggleButton.nativeElement && e.target !== this.menu.nativeElement) {
          this.isMenuOpen = false;
        }
      }
    });
  }
  async ngOnInit() {
    this.guestlistService.guestMenu.subscribe(res => {
    }, error => {
      console.log(error)
    })
    $('#addGuest').on('hidden.bs.modal', async () => {
      this.guests = [{
        fname: '',
        lname: '',
        age: 1
      }];
      await this.resetAddGuest();

    });
    $('#moveRelationship').on('hidden.bs.modal', async () => {
      this.selectedValue = null;
      this.checkedSelectedItem = false;

    });
    $('#guestStatus').on('hidden.bs.modal', async () => {
      this.selectedValue = null;
      this.checkedSelectedItem = false;

    });
    $('#menuAssign').on('hidden.bs.modal', async () => {

      this.selectedValue = null;
      this.checkedSelectedItem = false;
    });
    $('#listAssign').on('hidden.bs.modal', async () => {
      this.selectedValue = null;
      this.checkedSelectedItem = false;
    });
    var eventList = await this.getEventList(this.activeUser.id);
    this.allEvent = eventList;
    await this.getRelationshipList(this.activeUser.id);
    await this.resetAddGuest();
    await this.getGuestList(this.activeUser.id);
    this.linkedSelectAge = [];
    this.linkedSelectAge[1] = !this.linkedSelectAge[1];

    await this.getGuestCount(this.activeUser.id);

  }

  async getNewEventList() {
    var eventList = await this.getEventList(this.activeUser.id);
    this.allEvent = eventList;
    await this.resetAddGuest();
  }



  addNewTabChange(e: any) {
    this.addNewGuest = !e;
  }
  async resetAddGuest() {
    this.guests = [{
      fname: '',
      lname: '',
      age: 1
    }];
    this.selectAge = [];
    this.selectAge[0] = !this.selectAge[0];
    this.selectHotel = [];
    this.selectHotel[1] = !this.selectHotel[1];

    await this.checkedEvent(this.allEvent);
    this.email = null;
    this.selectedRelation = null;
    this.pnoneNo = null;
    this.mobileNo = null;
    this.address = null;
    this.city = null;
    this.state = null;
    this.zip = null;
    this.guestOf = null;
  }
  async getGuestData(guestId: any) {
    var guestDetails = await this.guestlistService.guestDetails(guestId).toPromise();
    this.guestDetails = guestDetails[0];
    if (this.guestDetails.event.length <= 0) {
      this.weddingTable = false;
    } else {
      for (let index = 0; index < this.guestDetails.event.length; index++) {
        if (this.guestDetails.event[index].event_name == null) {
          this.weddingTable = false;
        } else {
          this.weddingTable = true;
        }
      }
    }
    return this.guestDetails;
    // this.setEditData(this.guestDetails);
  }
  async getAdditionalGuestList(guestId: any) {
    var getAdditionalGuest = await this.guestlistService.getAdditionalGuest(guestId).toPromise();
    return getAdditionalGuest;

  }

  copyGuestLink() {
    if ($("#userinput").setSelectionRange) {
      $("#userinput").setSelectionRange(0, this.guestLink.length);
    } else {
      $("#userinput").select();

    }
    document.execCommand('copy');
    this._toastr.success('Link is copied to clipboard');
  }

  async setEditData(data: any) {
    var hash = CryptoJS.AES.encrypt(JSON.stringify(data.id), "Secret Passphrase").toString();
    hash = hash.replaceAll("/", "$*");
    this.guestLink = location.origin + "/guest-missing-info/" + hash;
    this.guestId = data.id;
    this.editSelectAge = [];
    this.editSelectHotel = [];
    this.editFirstName = data.first_name;
    this.editLastName = data.last_name;
    this.editAddress = data.address;
    this.editAgeType = data.age_type;
    this.editEmail = data.email;
    this.editGuestOf = data.guest_of;
    this.editMobileNo = data.mobile_number;
    this.editHotelType = data.need_hotel;
    this.editCity = data.city;

    this.editState = data.state;
    this.editZip = data.zip_code;
    this.editPnoneNo = data.phone_number;
    this.editRelation = data.relationship_id;

    this.age_Type = data.age_type;
    this.need_Hotel = data.need_hotel;
    // this.editSelectAge[data.age_type] = !this.editSelectAge[data.age_type];
    // this.editSelectHotel[data.need_hotel] = !this.editSelectHotel[data.need_hotel];
    this.editNote = data.note;
    this.guestEvents = data.event;

    this.editAttendance = this.guestEvents[0].attendace;
    this.editEventData = this.guestEvents[0];
    this.editSelectedMenu = this.guestEvents[0].manu_id;
    this.editSelectedList = this.guestEvents[0].list_id;
    this.editSelectedTable = this.guestEvents[0].table_id;
    this.rsvp_note = this.guestEvents[0].note;
    console.log(data.hide_guest_in_rsvp);
    this.hide_guest_in_rsvp = data.hide_guest_in_rsvp;
  }
  async editEventClick(data: any) {
    let submit = {
      wedding_id: data.wedding_id,
      wed_event_id: data.wed_event_id,
      guest_id: data.guest_id
    };
    let sub = {
      wedding_id: data.wedding_id,
      event_id: data.wed_event_id
    };
    var event = await this.guestlistService.getEventRelationData(submit).toPromise();
    var menus = await this.guestlistService.getMenusByEventId(sub).toPromise();
    this.editMenuList = menus.data;
    var lists = await this.guestlistService.getListsByEventId(sub).toPromise();
    this.editEventList = lists.data;
    var tables = await this.guestlistService.getAssignTableList(sub).toPromise();
    this.editTableList = tables.data;
    this.editEventData = event.data[0];

    this.editAttendance = event.data[0].attendace;
    this.selectedEditEvent = [];
    this.selectedEditEvent[data.id] = !this.selectedEditEvent[data.id];


    this.editSelectedMenu = event.data[0].manu_id;
    this.editSelectedList = event.data[0].list_id;
    this.editSelectedTable = event.data[0].table_id;

    this.rsvp_note = data.note;
  }

  async editToggleStatus(e: any, lavel: any, type: number) {
    this.showMe = !e;
    if (type == 1) {
      this.editAttendance = lavel;
      let sub = {
        guest_event_id: this.editEventData.id,
        manu_id: this.editEventData.manu_id,
        list_id: this.editEventData.list_id,
        table_id: this.editEventData.table_id,
        attendace: this.editAttendance,
      }
      await this.updateEvent(sub);
    }

  }
  async menuChange(e: any, data: any) {
    let sub = {
      guest_event_id: data.id,
      manu_id: e.target.value,
      list_id: data.list_id,
      table_id: data.table_id,
      attendace: data.attendace,
    }
    this.changeTable(e, data);
    await this.updateEvent(sub);
  }
  async listChange(e: any, data: any) {
    let sub = {
      guest_event_id: data.id,
      manu_id: data.manu_id,
      list_id: e.target.value,
      table_id: data.table_id,
      attendace: data.attendace,
    }
    await this.updateEvent(sub);
  }
  async getEventList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var eventList = await this.guestlistService.getEventList(data).toPromise();
    this.allEvent = eventList.data;
    return eventList.data;
  }


  async getGuestList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
      search_value: '',
      page_size: 10,
      start_index: 0
    };
    var guestList = await this.guestlistService.getGuestList(data).toPromise();
    this.apiGuestList = guestList.data;
    var grouped = this.groupBy(this.apiGuestList, false, (relation: { relationship_name: any; }) => relation.relationship_name);
    this.allGuest = this.mapEntries(grouped);
    this.allGuest = this.allGuest.filter((e) => e[0] != "Couple")    
  }
  mapEntries(grouped: any) { return Array.from(grouped.entries()); }

  groupBy(list: any, checked: boolean, keyGetter: any) {
    const map = new Map();
    var temp = [];
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      const data = {
        item: item,
        showEventAttend: null,
        isChecked: checked

      };
      temp.push(data);
      if (!collection) {
        map.set(key, [data]);
      } else {
        collection.push(data);
      }
    });
    this.modifyGuest = temp;
    return map;
  }
  searchMenu(searchtext) {
    this.eventItemsArray = this.menuData;
    if (searchtext == '') {
      this.eventItemsArray = this.menuData;
      return
    }
    this.filterMenu = [];
    for (let index = 0; index < this.modifyGuest.length; index++) {
      const element = this.modifyGuest[index].item.event[0]
      if (element) {
        this.eventItemsArray.filter(data => {
          if (this.tab == 2) {
            if (data.menu_name == this.modifyGuest[index].item.event[0].menu_name) {
              if (!this.filterMenu.includes(data)) {
                this.filterMenu.push(data);
              }
            }
          } if (this.tab == 5) {
            if (data.list_name == this.modifyGuest[index].item.event[0].list_name) {
              if (!this.filterMenu.includes(data)) {
                this.filterMenu.push(data);
              }
            }
          }
        }
        )
      }
    }
    this.eventItemsArray = this.filterMenu;
  }

  searchTable(searchtext, newArr) {
    this.eventItemsArray = this.tableList;
    if (searchtext == '') {
      this.eventItemsArray = this.tableList;
      return
    }
    this.filterMenu = [];
    for (let index = 0; index < newArr.length; index++) {
      const element = newArr[index].event[0]
      if (element) {
        this.eventItemsArray.filter(data => {
          if (data.id == newArr[index].event[0].table_id) {
            if (!this.filterMenu.includes(data)) {
              this.filterMenu.push(data);
            }
          }

        }

        )
      }
    }
    this.eventItemsArray = this.filterMenu;
  }
  async checkedEvent(events: any) {
    this.events = [];
    events.forEach((o: any, i: any) => {
      var createData = {
        id: o.id,
        event_name: o.event_name,
        isChecked: false,
        wedding_id: o.wedding_id,
        is_seating_chart: o.is_seating_chart,
        is_track_meals: o.is_track_meals
      };
      this.events.push(createData);
    });

  }

  async getGuestCount(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var gaustCount = await this.guestlistService.getGuestCount(data).toPromise();
    this.gaustCount = gaustCount.data;
  }

  async getRelationshipList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var relationshipList = await this.guestlistService.getRelationshipList(data).toPromise();
    this.allRelationship = relationshipList.data;
    this.allRelationship = this.allRelationship.filter((e) => e.relationship_name != "Couple")
    
    const uniqueData = this.allRelationship.reduce((finalData, currentData) => {
      let obj = finalData.find((item) => item.relationship_name == currentData.relationship_name);
      if (obj) {
        return finalData;
      }
      return finalData.concat([currentData]);
    }, []);
    this.allRelationship = uniqueData;
  }

  async guestView(arg: any, data: any) {
    if (arg == 'grid') {
      var guestData = await this.getGuestData(data.id);
      this.additionalGuestList = await this.getAdditionalGuestList(data.id);
      if (guestData.event.length > 0) {
        let sub = {
          wedding_id: data.wedding_id,
          event_id: guestData.event[0].wed_event_id
        }
        this.selectedEditEvent[guestData.event[0].id] = !this.selectedEditEvent[guestData.event[0].id];
        var menus = await this.guestlistService.getMenusByEventId(sub).toPromise();
        this.editMenuList = menus.data;
        var lists = await this.guestlistService.getListsByEventId(sub).toPromise();
        this.editEventList = lists.data;
        var tables = await this.guestlistService.getAssignTableList(sub).toPromise();
        this.editTableList = tables.data;
        await this.setEditData(guestData);
      }
      else {
        this.editFirstName = data.first_name;
        this.editLastName = data.last_name;
        this.editRelation = data.relationship_id;
        this.editGuestOf = data.guest_of;
        this.age_Type = 1
        this.need_Hotel = 2
      }
      this.showView = "grid";
    } else {
      this.showView = "list";
    }
  }

  async toggleStatus(singleGuest: any, l: any, i: any, j: any, k: any) {
    if (this.selectedSingleGuest) {
      this.selectedSingleGuest.showEventAttend = null;
    }
    if (singleGuest.showEventAttend == k) {
      singleGuest.showEventAttend = null;
    } else {
      singleGuest.showEventAttend = k;
    }
    this.selectedSingleGuest = singleGuest;
    if (j != 'n') {
      const index = singleGuest.item.event[i];
      index.attendace = l;
      this.weddingStatus = l;
      let data = {
        guest_event_id: singleGuest.item.event[i].id,
        manu_id: singleGuest.item.event[i].manu_id,
        list_id: singleGuest.item.event[i].list_id,
        table_id: singleGuest.item.event[i].table_id,
        attendace: singleGuest.item.event[i].attendace,

      }
      await this.updateEvent(data);
      if (this.eventName == 'Snapshot') {
        await this.getGuestCount(this.activeUser.id);
      } else {
        let countData = {
          wedding_id: this.activeUser.id,
          event_id: this.eventData.id,
        };
        var guestCountList = await this.guestlistService.getEventGuestCount(countData).toPromise();
        this.eventWiseGuestCount = guestCountList.data;
      }
    }
  }

  addNewRow() {
    var item = {
      fname: '',
      lname: "",
      age: 1
    }
    this.guests.push(item);
  }
  removeGuest(item: any) {
    this.guests.splice(this.guests.indexOf(item), 1);
  }

  saveGuest(type: any) {
    if (this.guests[0].fname.trim() == '') {
      this._toastr.error('Please enter first name');
      return;
    }
    if (this.guests[0].lname.trim() == '') {
      this._toastr.error('Please enter last name');
      return;
    }
    if (!this.selectedRelation || this.selectedRelation == '') {
      this._toastr.error('Please select relationship name');
      return;
    }
    if (!this.guestOf || this.guestOf == null) {
      this._toastr.error('Please select guest of');
      return;
    }
    var selectedEvents = this.events.filter((e) => e.isChecked == true);
    if (selectedEvents.length == 0) {
      this._toastr.error('Please select at least one event');
      return;
    }
    const first_name = this.guests[0].fname.trim();
    const last_name = this.guests[0].lname.trim();
    const age = this.guests[0].age;
    var g = this.guests;
    g.splice(0, 1);
    let data = {
      additionalGuestList: g,
      wedding_id: this.activeUser.id,
      relationship_id: this.selectedRelation,
      first_name: first_name,
      last_name: last_name,
      email: this.email,
      phone_number: this.pnoneNo,
      mobile_number: this.mobileNo,
      address: this.address,
      city: this.city,
      state: this.state,
      zip_code: this.zip,
      need_hotel: this.hotelType,
      age_type: age,
      invited_to: selectedEvents,
      guest_of: this.guestOf

    };
    this.guestlistService.addGuest(data).subscribe((result: any) => {
      var res = result.data;
      if (this.eventName == 'Snapshot') {
        this.getGuestList(this.activeUser.id);
        this.getGuestCount(this.activeUser.id);
      } else {
        this.resetGuest(2);
        let countData = {
          wedding_id: this.activeUser.id,
          event_id: this.eventData.id,
        };
        var guestCountList: any = this.guestlistService.getEventGuestCount(countData).toPromise();
        this.eventWiseGuestCount = guestCountList.data;
      }
      this.resetAddGuest();
      if (type == 1) {
        $('#addGuest').modal('toggle');
      }

      this._toastr.success('Guest(s) added successfully');

    },
      error => {
        this._toastr.error(error.message);
      });
  }
  showAddGuestModal() {

    if (this.eventName == 'Snapshot') {
      this.events[0]['isChecked'] = true;
      // $('#addGuest').modal('toggle'); 

    } else {
      const index = this.events.findIndex((e) => e.id == this.eventData.id);
      this.events[index]['isChecked'] = true;
      // $('#addEventGuest').modal('toggle');
    }
    this.guestList = this.apiGuestList;
    this.guestList = this.guestList.filter((e) => e.relationship_name != "Couple");
    if (this.eventName == 'Snapshot') {
      $('#addGuest').modal('toggle');
      return;
    }
    if (this.guestList.length > 0) {
      $('#addEventGuest').modal('toggle');
      this.getEventGuestList();
    }
    else {
      $('#addEventGuest').modal('toggle');
      this.getEventGuestList();
    }
  }

  radioChangeHandler(event:any){
    this.selectedOption = event.target.value;
  }

  async deleteGuest(data: any, type: number) {    
    this.removeHeading = 'Remove guest';
    if(this.eventName == 'Snapshot'){
      this.removeText = 'Do you want to permanently remove this guest from your Guest List?';
    }else{
      this.removeText = 'Do you want to remove guest from your account or only from the event?';
    }
    $('#DeleteGuest').modal('toggle');
    if (type == 1) {
      this.guestId = data.item.id;
    } else {
      this.guestId = data.id;
    }

  }

  async submitDeleteGuest(){
  if(this.selectedOption == "Only from this event"){
    let data = {
      wedding_id: this.activeUser.id,
      event_id: this.eventData.id,
      guest_ids:[this.guestId]
    };    
    this.guestlistService.removeGuestsFromEvent(data).subscribe(res =>{
      $('#DeleteGuest').modal('toggle');
        this._toastr.success('Guest was removed successfully');
        this.resetGuest(2);
    },error =>{
      this._toastr.error(error.message);
    })
  }else{
    try {
      if (this.removeHeading == 'Remove guest') {
        var guestDetails = await this.guestlistService.guestDelete(this.guestId).toPromise();
        $('#DeleteGuest').modal('toggle');
        this._toastr.success('Guest was removed successfully');
        this.showView = "list";

        if (this.eventName == 'Snapshot') {
          await this.getGuestList(this.activeUser.id);
          await this.getGuestCount(this.activeUser.id);
        } else {
          await this.resetGuest(2);
          let countData = {
            wedding_id: this.activeUser.id,
            event_id: this.eventData.id,
          };
          var guestCountList = await this.guestlistService.getEventGuestCount(countData).toPromise();
          this.eventWiseGuestCount = guestCountList.data;
        }
      }
    } catch (e) {
      this._toastr.error(e);
    }    
  }  
  }

  async getEventResponse(e: any, i: any) {
    this.getNewEventList();
    if (e.is_seating_chart == 1) {
      this.enableSittingChart = true;
    } else {
      this.enableSittingChart = false;
    }
    if (i == 1) {
      var s = {
        id: e.event_id,
        event_name: e.event_name
      };
      this.eventData = s;
      for (let index = 0; index < this.allEvent.length; index++) {
        if (this.allEvent[index].id == this.eventData.id) {
          this.menuItemShow = this.allEvent[index]
        }
      }
    } else {
      this.eventData = e;
    }

    if (e == 2) {
      this.snapshot = true;
      this.eventName = 'Snapshot';
      this.showSetting = false;
      this.tab = 1;
      await this.getGuestList(this.activeUser.id);
    } else if (e == 3) {
      var eventList = await this.getEventList(this.activeUser.id);
      this.allEvent = eventList;
    }
    else {
      this.snapshot = false;
      let tab = {
        id: 1,
        value: 'Relationship'

      };

      await this.tabSelect(null, 1);

      this.eventName = e.event_name;
      this.showSetting = true;
      await this.resetGuest(2);
      var eventList = await this.getEventList(this.activeUser.id);
      this.allEvent = eventList;
      let sub = {
        wedding_id: this.activeUser.id,
        event_id: this.eventData.id
      };

      var menus = await this.guestlistService.getMenusByEventId(sub).toPromise();
      var lists = await this.guestlistService.getListsByEventId(sub).toPromise();
      var tables = await this.guestlistService.getAssignTableList(sub).toPromise();
      let Unassigned = {
        menu_name: 'Unassigned',
        list_name: 'Unassigned',
        name: 'Unassigned',
        id: null
      };
      this.menuList = menus.data;
      this.ListItems = lists.data;
      this.tableList = tables.data;
      this.ListItems.push(Unassigned);
      this.menuList.push(Unassigned);
      this.tableList.push(Unassigned);
    }

    this.eventItemStatus = false;
    this.guest = false;
    this.allRemoveBtnStatus = false;
    for (let index = 0; index < this.allEvent.length; index++) {
      if (this.allEvent[index].id == this.eventData.id) {
        var data = this.allEvent[index];
        this.guestlistService.guestSettingModel.next(this.allEvent[index]);
      }
    }
    this.guestlistService.guestMenu.next(e.event_name);
  }

  async resetGuest(type: number) {
    if (type == 1) {
      this._toastr.success('Guest are updated');
    }
    let data = {
      wedding_id: this.activeUser.id,
      wed_event_id: this.eventData.id,
    };
    let countData = {
      wedding_id: this.activeUser.id,
      event_id: this.eventData.id,
    };
    var guestList = await this.guestlistService.eventGuestList(data).toPromise();
    this.apiGuestList = guestList.data.filter((e) => e.event.length > 0);
    var grouped = this.groupBy(this.apiGuestList, false, (relation: { relationship_name: any; }) => relation.relationship_name);
    this.allGuest = this.mapEntries(grouped);    
    this.allGuest = this.allGuest.filter((e) => e[0] != "Couple");
    var guestCountList = await this.guestlistService.getEventGuestCount(countData).toPromise();
    this.eventWiseGuestCount = guestCountList.data;
  }

  menuData;
  async tabSelect(eventData: any | null, tab: any) {
    this.selectedTab = [];
    this.eventItemsArray = [];
    this.selectedTab[tab] = !this.selectedTab[tab];
    this.tab = tab;
    if (this.tab == 2) {
      this.eventItemsArray = this.menuList;
      this.menuData = [...this.eventItemsArray];
      // code start 
      const menudataunique = this.menuData.reduce((finalData, currentData) => {
        let obj = finalData.find((item) => item.menu_name == currentData.menu_name.toLowerCase());
        if (obj) {
          return finalData;
        }
        return finalData.concat([currentData]);
      }, []);
      this.menuData = menudataunique;
      const menudataunique1 = this.menuData.reduce((finalData, currentData) => {
        let obj1 = finalData.find((item) => item.menu_name.toLowerCase() == currentData.menu_name);
        if (obj1) {
          return finalData;
        }
        return finalData.concat([currentData]);
      }, []);
      this.menuData = menudataunique1;
      this.eventItemsArray = this.menuData;

      // code end 

    }
    else if (this.tab == 3) {
      this.eventItemsArray = this.tableList;
      this.menuData = [...this.eventItemsArray];
    }
    else if (this.tab == 4) {
      this.eventItemsArray = this.typeOfAttendance;
    }
    else if (this.tab == 5) {
      this.eventItemsArray = this.ListItems;
      this.menuData = [...this.eventItemsArray];
    }

  }
  clickSetting() {
    this.eventEdit = Math.random();
  }

  addRelationship() {

    if (this.relationshipName == null || this.relationshipName == '') {
      this._toastr.error('Please enter relationship name');
      return;
    }
    for (let i = 0; i < this.allRelationship.length; i++) {
      if (this.allRelationship[i].relationship_name == this.relationshipName) {
        this._toastr.error('relationship already exists');
        return
      }
      if (this.allRelationship[i].relationship_name.toLowerCase() == this.relationshipName) {
        this._toastr.error('relationship already exists');
        return
      }
    }
    let submitData = {
      wedding_id: this.activeUser.id,
      relationship_name: this.relationshipName
    };
    if (this.addRelationLavel == 'Create a new relationship') {
      this.guestlistService.addRelationship(submitData).subscribe((result: any) => {
        var res = result.data;
        this.getRelationshipList(this.activeUser.id);
        this._toastr.success(result.message);
        this.relationshipName = '';
        $('#addRelation').modal('toggle');
      },
        error => {
          this._toastr.error(error.message);
        })
    } else {
      let submitData = {
        relationship_id: this.singleRelationshipData.id ? this.singleRelationshipData.id : null,
        wedding_id: this.activeUser.id,
        relationship_name: this.relationshipName
      };
      this.guestlistService.updateRelationship(submitData).subscribe((result: any) => {
        var res = result.data;
        this.getGuestList(this.activeUser.id);
        this.getRelationshipList(this.activeUser.id);
        this._toastr.success(result.message);
        this.relationshipName = '';
        $('#addRelation').modal('toggle');
      },
        error => {
          this._toastr.error(error.message);
        })
    }

  }
  async addUpdateMenu() {
    if (this.menuName == null || this.menuName == '') {
      this._toastr.error('Please enter menu name');
      return;
    }
    for (let i = 0; i < this.menuData.length; i++) {
      if (this.menuData[i].menu_name == this.menuName) {
        this._toastr.error('Menu Name already exists');
        return
      }
      if (this.menuData[i].menu_name.toLowerCase() == this.menuName) {
        this._toastr.error('Menu Name already exists');
        return
      }
    }
    var name = [];
    name.push(this.menuName);
    let data2 = {
      wedding_id: this.activeUser.id,
      wed_event_id: this.eventData.id,
      names: name,
      is_single: true
    };
    if (this.addMenuLavel == 'Create a new menu') {
      this.guestlistService.addMenu(data2).subscribe(async (result: any) => {
        var res = result.data;
        this.singleMenuData = null;
        $('#addMenu').modal('toggle');
        this._toastr.success('Menu was added');

      },
        error => {
          this._toastr.error(error.message);
        })
    } else {
      let submitData = {
        menu_id: this.singleMenuData.id ? this.singleMenuData.id : null,
        wed_event_id: this.eventData.id,
        wedding_id: this.activeUser.id,
        name: this.menuName,
        is_single: true
      };
      this.guestlistService.updateMenu(submitData).subscribe(async (result: any) => {
        var res = result.data;
        this._toastr.success(result.message);
        this.menuName = '';
        $('#addMenu').modal('toggle');
      },
        error => {
          this._toastr.error(error.message);
        })
    }
    let sub = {
      wedding_id: this.activeUser.id,
      event_id: this.eventData.id
    };

    var menus = await this.guestlistService.getMenusByEventId(sub).toPromise();
    let Unassigned = {
      menu_name: 'Unassigned',
      list_name: 'Unassigned',
      id: null
    };
    this.menuList = menus.data;
    this.menuList.push(Unassigned);

    this.singleMenuData = null;
    await this.tabSelect(null, 2);

  }

  async addUpdateList() {
    if (this.listName == null || this.listName == '') {
      this._toastr.error('Please enter list name');
      return;
    }
    for (let i = 0; i < this.menuData.length; i++) {
      if (this.menuData[i].list_name == this.listName) {
        this._toastr.error('list Name already exists');
        return
      }
      if (this.menuData[i].list_name.toLowerCase() == this.listName) {
        this._toastr.error('list Name already exists');
        return
      }
    }
    var name = [];
    name.push(this.listName);
    let data2 = {
      wedding_id: this.activeUser.id,
      wed_event_id: this.eventData.id,
      names: name,
      is_single: true
    };
    if (this.addListLavel == 'Create a new list') {
      this.guestlistService.addList(data2).subscribe(async (result: any) => {
        var res = result.data;
        this.singleMenuData = null;
        $('#addList').modal('toggle');
        this._toastr.success('List was added');

      },
        error => {
          this._toastr.error(error.message);
        })
    } else {

      let submitData = {
        list_id: this.singleListData.id,
        wed_event_id: this.eventData.id,
        wedding_id: this.activeUser.id,
        name: this.listName
      };
      this.guestlistService.updateList(submitData).subscribe((result: any) => {
        var res = result.data;
        this._toastr.success(result.message);
        this.listName = '';
        $('#addList').modal('toggle');
      },
        error => {
          this._toastr.error(error.message);
        })
    }
    let sub = {
      wedding_id: this.activeUser.id,
      event_id: this.eventData.id
    };

    var lists = await this.guestlistService.getListsByEventId(sub).toPromise();
    let Unassigned = {
      menu_name: 'Unassigned',
      list_name: 'Unassigned',
      id: null
    };
    this.ListItems = lists.data;
    this.ListItems.push(Unassigned);

    this.singleListData = null;
    await this.tabSelect(null, 5);

  }
  showAddRelationship() {
    this.addRelationLavel = 'Create a new relationship';
    this.relationshipName = '';
    $('#addRelation').modal('toggle');
  }
  showAddMenu() {
    this.addMenuLavel = 'Create a new menu';
    this.menuName = '';
    this.singleMenuData = null;
    $('#addMenu').modal('toggle');
  }
  showAddList() {
    this.addListLavel = 'Create a new list';
    this.listName = '';
    this.singleListData = null;
    $('#addList').modal('toggle');
  }
  clickAge(age: any, i: number) {
    this.ageType = age.id;
    this.selectAge = [];
    this.selectAge[i] = !this.selectAge[i];
  }
  clickHotel(hotel: any, i: number) {
    this.hotelType = hotel.id;
    this.selectHotel = [];
    this.selectHotel[i] = !this.selectHotel[i];
  }
  editClickAge(age: any, i: number) {
    this.editAgeType = age.id;
    this.editSelectAge = [];
    this.age_Type = age.id
    // this.editSelectAge[age.id] = !this.editSelectAge[age.id];
  }
  editClickHotel(hotel: any, i: number) {
    this.editHotelType = hotel.id;
    this.editSelectHotel = [];
    this.need_Hotel = hotel.id
    // this.editSelectHotel[hotel.id] = !this.editSelectHotel[hotel.id];
  }
  updateGuest() {
    let data = {
      guest_id: this.guestId,
      wedding_id: this.activeUser.id,
      relationship_id: this.editRelation,
      first_name: this.editFirstName,
      last_name: this.editLastName,
      email: this.editEmail,
      phone_number: this.editPnoneNo,
      mobile_number: this.editMobileNo,
      address: this.editAddress,
      city: this.editCity,
      state: this.editState,
      zip_code: this.editZip,
      need_hotel: this.editHotelType,
      age_type: this.editAgeType,
      guest_of: this.editGuestOf,
      note: this.editNote,
      hide_guest_in_rsvp: this.hide_guest_in_rsvp
    };
    this.guestlistService.updateGuest(data).subscribe((result: any) => {
      this.getGuestList(this.activeUser.id);
      if (this.editEmail) {
        this.guestDetails.email = this.editEmail;
      } else {
        this.guestDetails.email = '';
      }
      this._toastr.success(result.message);

    },
      error => {
        this._toastr.error(error.message);
      });

  }
  updateRelationship(data: any) {
    if (data.relationship_name == 'Couple' || data.relationship_name == 'Friend' || data.relationship_name == 'Family' || data.relationship_name == 'Other') {
      return;
    } else {
      this.singleRelationshipData = this.allRelationship.find((x: { id: any; }) => x.id == data.relationship_id);
      if (this.singleRelationshipData && this.singleRelationshipData.id) {
        this.relationshipName = this.singleRelationshipData.relationship_name;
        this.addRelationLavel = 'Edit relationship name';
        $('#addRelation').modal('toggle');
      }

    }

  }

  updateMenu(id: number) {
    this.singleMenuData = this.menuList.find((x: { id: number; }) => x.id == id);
    if (this.singleMenuData && this.singleMenuData.id) {
      this.menuName = this.singleMenuData.menu_name;
      this.addMenuLavel = 'Edit menu name';
      $('#addMenu').modal('toggle');
    }

  }

  updateList(id: number) {
    this.singleListData = this.ListItems.find((x: { id: number; }) => x.id == id);
    if (this.singleListData && this.singleListData.id) {
      this.listName = this.singleListData.list_name;
      this.addListLavel = 'Edit list name';
      $('#addList').modal('toggle');
    }
  }

  async removRelationship(id: number) {
    var guestList = await this.guestlistService.relationshipDelete(id).toPromise();
    $('#addRelation').modal('toggle');
    await this.getRelationshipList(this.activeUser.id);
    await this.getGuestList(this.activeUser.id);
    this._toastr.success('Relationship was deleted');
    this.singleRelationshipData = null;

  }

  async removMenu(id: number) {
    var guestList = await this.guestlistService.menuDelete(id).toPromise();
    $('#addMenu').modal('toggle');
    let sub = {
      wedding_id: this.activeUser.id,
      event_id: this.eventData.id
    };
    await this.resetGuest(2);
    var menus = await this.guestlistService.getMenusByEventId(sub).toPromise();
    let Unassigned = {
      menu_name: 'Unassigned',
      list_name: 'Unassigned',
      id: null
    };
    this.menuList = menus.data;
    this.menuList.push(Unassigned);
    this._toastr.success('Menu was deleted');
    this.singleMenuData = null;
    await this.tabSelect(null, 2);

  }

  async removList(id: number) {
    var removeList = await this.guestlistService.listDelete(id).toPromise();
    $('#addList').modal('toggle');
    let sub = {
      wedding_id: this.activeUser.id,
      event_id: this.eventData.id
    };
    await this.resetGuest(2);
    var lists = await this.guestlistService.getListsByEventId(sub).toPromise();
    let Unassigned = {
      menu_name: 'Unassigned',
      list_name: 'Unassigned',
      id: null
    };
    this.ListItems = lists.data;
    this.ListItems.push(Unassigned);
    this._toastr.success('List was deleted');
    this.singleListData = null;
    await this.tabSelect(null, 5);

  }

  deleteMenu(menu: any, i: number) {
    this.removeHeading = 'Remove menu';
    this.removeText = 'Do you want to permanently remove ' + menu.menu_name + ' from your ' + this.eventName + '?';
    $('#DeleteGuest').modal('toggle');
    this.menuId = menu.id;
  }

  selectAll(e: any) {
    if (e.target.checked == true) {
      this.allRemoveBtnStatus = true;
      if (this.eventName != 'Snapshot') {
        this.eventItemStatus = true;

      } else {
        this.eventItemStatus = false;
      }
      var grouped = this.groupBy(this.apiGuestList, true, (relation: { relationship_name: any; }) => relation.relationship_name);
      this.allGuest = this.mapEntries(grouped);
    } else {
      var grouped = this.groupBy(this.apiGuestList, false, (relation: { relationship_name: any; }) => relation.relationship_name);
      this.allGuest = this.mapEntries(grouped);
      this.allRemoveBtnStatus = false;
      this.eventItemStatus = false;
    }

  }

  async removeAll() {
    var temp = [];
    var data = await this.allGuest.forEach(function (item: any[][], index: any) {
      item[1].forEach((item1, index1) => {
        if (item1.isChecked) {
          temp.push(item1);
        }
      });
    });
    await this.removeMultiGuest(temp);
  }

  async singleChecked() {
    var temp = [];
    var data = await this.allGuest.forEach(function (item: any[][], index: any) {
      item[1].forEach((item1, index1) => {
        if (item1.isChecked) {
          temp.push(item1);
        }
      });
    });
    if (temp.length > 0) {
      this.allRemoveBtnStatus = true;
      if (this.eventName != 'Snapshot') {
        this.eventItemStatus = true;

      } else {
        this.eventItemStatus = false;
      }
    } else {
      this.allRemoveBtnStatus = false;
      this.eventItemStatus = false;
    }
  }

  async removeMultiGuest(guestData: any) {
    var remove1 = await guestData.forEach(async (data: any, index: any) => {
      const item = data.item;
      var guestDetails = await this.guestlistService.guestDelete(item.id).toPromise();

    });
    this._toastr.success('Guests removed successfully');
    this.guest = false;
    this.allRemoveBtnStatus = false;
    if (this.eventName == 'Snapshot') {
      await this.setGuest(2);
      setTimeout(async () => {
        await this.getGuestCount(this.activeUser.id);
      }, 1000);

    } else {
      setTimeout(async () => {
        await this.resetGuest(2);
        let countData = {
          wedding_id: this.activeUser.id,
          event_id: this.eventData.id,
        };
        var guestCountList = await this.guestlistService.getEventGuestCount(countData).toPromise();
        this.eventWiseGuestCount = guestCountList.data;
      }, 500);
    }

  }


  async updateMultiRelationsgip() {
    var update = await this.selectedItems.forEach(async (data: any, index: any) => {
      let submit = {
        guest_id: data.item.id,
        wedding_id: this.activeUser.id,
        relationship_id: this.selectedId,
        first_name: data.item.first_name,
        last_name: data.item.last_name,
        email: data.item.email,
        phone_number: data.item.phone_number,
        mobile_number: data.item.mobile_number,
        address: data.item.address,
        city: data.item.city,
        state: data.item.state,
        zip_code: data.item.zip_code,
        need_hotel: data.item.need_hotel,
        age_type: data.item.age_type,
        guest_of: data.item.guest_of,
        note: data.item.note,
        main_guest_id: data.item.main_guest_id,

      }
      var updateDetails = await this.guestlistService.updateGuest(submit).toPromise();
    });
    this.allRemoveBtnStatus = false;
    $('#moveRelationship').modal('toggle');

    if (this.eventName == 'Snapshot') {
      await this.setGuest(1);
    } else {
      setTimeout(async () => {
        await this.resetGuest(1);
      }, 500);
    }

  }

  async updateEventValue(type: number) {

    var update = await this.selectedItems.forEach(async (data: any, index: any) => {
      if (type == 2) {
        var sub = {
          guest_event_id: data.item.event[0].id,
          manu_id: data.item.event[0].manu_id,
          list_id: data.item.event[0].list_id,
          table_id: data.item.event[0].table_id,
          attendace: this.selectedId,
        }
      } else if (type == 3) {
        var sub = {
          guest_event_id: data.item.event[0].id,
          manu_id: this.selectedId,
          list_id: data.item.event[0].list_id,
          table_id: data.item.event[0].table_id,
          attendace: data.item.event[0].attendace,
        }
      } else if (type == 4) {
        var sub = {
          guest_event_id: data.item.event[0].id,
          manu_id: data.item.event[0].manu_id,
          list_id: this.selectedId,
          table_id: data.item.event[0].table_id,
          attendace: data.item.event[0].attendace,
        }
      }

      var updateDetails = await this.guestlistService.updateEventData(sub).toPromise();
    });
    if (type == 2) {
      $('#guestStatus').modal('toggle');
    } else if (type == 3) {
      $('#menuAssign').modal('toggle');
    } else if (type == 4) {
      $('#listAssign').modal('toggle');
    }
    this.allRemoveBtnStatus = false;
    this.eventItemStatus = false;
    setTimeout(async () => {
      await this.resetGuest(1);
    }, 500);

  }

  async setGuest(type: number) {
    if (type == 1) {
      this._toastr.success('Guest are updated');
    }
    setTimeout(() => {
      this.getGuestList(this.activeUser.id);
    }, 500);
  }

  async updateEvent(data: any) {
    var update = await this.guestlistService.updateEventData(data).toPromise();
    this._toastr.success(update.message);

  }

  getUserEvent(event: any, guest: any) {
    return guest.item.event.find(p => p.wed_event_id == event.id);
  }

  getUserMenuList(event: any, tab: number) {
    if (tab == 2) {
      var menu = this.modifyGuest.filter((e) => e.item.event[0].manu_id == event.id);
    } if (tab == 3) {
      var menu = this.modifyGuest.filter((e) => e.item.event[0].table_id == event.id);
    }
    else if (tab == 5) {
      var menu = this.modifyGuest.filter((e) => e.item.event[0].list_id == event.id);
      // this.guestlistService.guestSettingModel.next(menu);

    } else if (tab == 4) {
      var menu = this.modifyGuest.filter((e) => e.item.event[0].attendace == event.id);
    }
    return menu;
  }

  changedLinkedGuest(e: any) {
    var name = e.target.value;
    if (name.length > 0 && !this.additionalField) {
      this.addLinked = true;
    } else {
      this.addLinked = false;
    }
  }
  addAditionalGuest() {
    this.additionalField = true;
    this.addLinked = false;
  }

  additionalGuestAge(age: any, i: number) {
    this.linkedAgeType = age.id;
    this.linkedSelectAge = [];
    this.linkedSelectAge[age.id] = !this.linkedSelectAge[age.id];
  }

  submitAdditionalGuest() {
    var event = [];
    this.guestDetails.event.forEach((item, index) => {
      let eventData = {
        event_name: item.event_name,
        id: item.id,
        is_seating_chart: item.is_seating_chart,
        is_track_meals: item.is_track_meals,
        wedding_id: item.wedding_id
      }
      event.push(eventData);
    });
    let data = {
      additionalGuestList: [],
      wedding_id: this.activeUser.id,
      relationship_id: this.guestDetails.relationship_id,
      main_guest_id: this.guestDetails.id,
      first_name: this.linkedGuestName,
      last_name: this.linkedGuestLastName,
      email: null,
      phone_number: null,
      mobile_number: null,
      address: null,
      city: null,
      state: null,
      zip_code: null,
      need_hotel: null,
      age_type: this.linkedAgeType,
      invited_to: event,
      guest_of: null

    };
    this.guestlistService.addGuest(data).subscribe((result: any) => {
      var res = result.data;
      this.getGuestList(this.activeUser.id);

      this.linkedGuestName = null;
      this.linkedGuestLastName = null;
      this.addLinked = false;
      this.additionalField = false;
      this.linkedGuestMenu = null;
      this.linkedSelectAge = [];
      this.linkedSelectAge[1] = !this.linkedSelectAge[1];
      $('#addGridGuest').modal('toggle');
      this._toastr.success('Guest(s) added successfully');

    },
      error => {
        this._toastr.error(error.message);
      });
  }

  cancelAdditionalGuest() {
    this.additionalField = false;
    this.addLinked = true;
  }

  async unlinkGuest(data: any, index: number) {
    var unlink = await this.guestlistService.unlinkGuest(data.id).toPromise();
    if (unlink.hasError == false) {
      this.additionalGuestList.splice(index, 1);
      this._toastr.success(unlink.message);
    } else {
      this._toastr.error(unlink.message);
    }

  }

  async selectRelationship(type: number) {
    if (type == 1) {
      $('#moveRelationship').modal('toggle');
    } else if (type == 2) {
      $('#guestStatus').modal('toggle');
    } else if (type == 3) {
      $('#menuAssign').modal('toggle');
    } else if (type == 4) {
      $('#listAssign').modal('toggle');
    }
    var temp = [];
    var data = await this.allGuest.forEach(function (item: any[][], index: any) {
      item[1].forEach((item1, index1) => {
        if (item1.isChecked) {
          temp.push(item1);
        }
      });
    });
    this.selectedItems = temp;
  }

  selectItem(e: any, type: number) {
    this.checkedSelectedItem = true;
    this.selectedId = e.id ? e.id : null;
    if (type == 1) {
      this.selectedValue = e.relationship_name ? e.relationship_name : e;
    } else if (type == 2) {
      this.selectedValue = e.value;
    } else if (type == 3) {
      this.selectedValue = e.menu_name;

    } else if (type == 4) {
      this.selectedValue = e.list_name;
    }
  }

  changeSelection() {
    // this.checkedIDs = [];
    // this.events.forEach((item, index) => {
    //   if (item.isChecked) {
    //       this.checkedIDs.push(item);
    //   }
    // });
  }
  // guestView(arg:any){
  //   if(arg=='grid'){
  //     this.showView = "grid";
  //   }else if(arg=='mlist'){
  //     this.showView = "mlist";
  //   }else{
  //     this.showView = "list";
  //   }
  // }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  searchGuest(e: any) {
    let searchText: string = e.target.value.toLowerCase();
    let newArr: any[] = [];
    this.apiGuestList.forEach((elem: any) => {
      if (elem.first_name || elem.last_name) {
        var s = elem.first_name.toLowerCase();
        var c = elem.last_name.toLowerCase();
        if (s.includes(searchText) || c.includes(searchText)) {
          newArr.push(elem);
        }
      }
    });
    var grouped = this.groupBy(newArr, false, (relation: { relationship_name: any; }) => relation.relationship_name);
    this.allGuest = this.mapEntries(grouped);
    // var tableGroup = this.groupBy(newArr, false, (relation: { relationship_name: any; }) => relation.relationship_name);
    // this.eventItemsArray = this.mapEntries(tableGroup);    
    this.eventItemsArray = this.eventItemsArray;

    if (this.tab == 2 || this.tab == 5) {
      this.searchMenu(searchText);
    }
    if (this.tab == 3) {
      this.searchTable(searchText, newArr);
    }

  }

  guestDownload() {
    var authkey = localStorage.getItem("USER_TOKEN");
    window.open(`${Config.apiBaseUrl}/guestlist/download_guest_list/${this.eventData.id}/${this.activeUser.id}?token=${authkey}`);
    return;

  }

  downloadTemplate() {
    this.templateUrl = location.origin + '/assets/template/guest_templates.csv';
    this.router.navigate([]).then(result => { window.open(this.templateUrl, 'download'); });

  }

  fileChangeEvent(event: any) {
    let file: File = event.target.files[0];
    this.fileName = event.target.files[0].name;
    this.fileType = event.target.files[0].type;
    if (file && file.lastModified) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        let submitedFile = {
          wedding_id: this.activeUser.id,
          file_name: file.name,
          file_extension: file.name.split('.').pop().toLowerCase(),
          csv_file: event.target.result.split(',').pop(),

        };
        this.guestlistService.guestImport(submitedFile).subscribe(
          (res: any) => {
            if (this.eventName == 'Snapshot') {
              this.getGuestList(this.activeUser.id);
              this.getGuestCount(this.activeUser.id);
            } else {
              this.resetGuest(2);
              let countData = {
                wedding_id: this.activeUser.id,
                event_id: this.eventData.id,
              };
              var guestCountList: any = this.guestlistService.getEventGuestCount(countData).toPromise();
              this.eventWiseGuestCount = guestCountList.data;
            }
            this.resetAddGuest();
            $('#addGuest').modal('toggle');
            this._toastr.success('Guest(s) imported successfully');

          },
          (err) => {
            console.error(err);
            this._toastr.error(err.message);
          }

        );

      };
      reader.readAsDataURL(file);
    }
  }

  async changeTable(e: any, data: any) {
    var table = this.tableList.filter((x: any) => x.id == e.target.value);
    var filterMenuData = this.apiGuestList.filter(res => res.event[0].table_id === table[0].id)

    if (table) {
      var tableSeats = JSON.parse(table[0].properties);
      for (let index = 0; index < filterMenuData.length; index++) {
        for (let j = 0; j < tableSeats.seats.length; j++) {
          if (filterMenuData[index].event[0].seat_number == j) {
            tableSeats.seats[j].first_name = filterMenuData[index].first_name;
            tableSeats.seats[j].last_name = filterMenuData[index].last_name;
            // tableSeats.seats[j].guest_id = filterMenuData[index].event[0].guest_id;
          }
          // tableSeats.seats[j].seat_number =  filterMenuData[index].event[0].seat_number;

        }
      }
    }


    var tempArray = [];
    tempArray.push(data.guest_id);
    var unassignData = {
      wedding_id: this.activeUser.id,
      event_id: data.wed_event_id,
      guest_ids: tempArray
    };

    //   const index = tableSeats.seats.findIndex((e) => !e.first_name );
    //  console.log(index);

    for (let index = 0; index < tableSeats.seats.length; index++) {

      if (!tableSeats.seats[index].first_name) {
        var assignData = {
          wedding_id: this.activeUser.id,
          event_id: data.wed_event_id,
          guest_id: data.guest_id,
          table_id: Number(e.target.value),
          seat_number: index
        };
        await this.unassignSeats(unassignData);
        setTimeout(() => {
          this.assignSeats(assignData);
          this.resetGuest(2);
          var eventList = this.getEventList(this.activeUser.id);
          this.allEvent = eventList;
          let sub = {
            wedding_id: this.activeUser.id,
            event_id: this.eventData.id
          };

          var menus = this.guestlistService.getMenusByEventId(sub).toPromise();
          var lists = this.guestlistService.getListsByEventId(sub).toPromise();
          this.guestlistService.getAssignTableList(sub).subscribe(res => {
            this.tableList = res.data;
          }, error => {
            console.log(error);

          })
          // console.log('tables', tables['data']);
          let Unassigned = {
            menu_name: 'Unassigned',
            list_name: 'Unassigned',
            name: 'Unassigned',
            id: null
          };
          this.menuList = menus['data'];
          this.ListItems = lists['data'];
          // this.ListItems.push(Unassigned);
          // this.menuList.push(Unassigned);
          // this.tableList.push(Unassigned);
        }, 1000);

        return;
      } else {
        if (index == tableSeats.seats.length - 1) {
          this._toastr.error('No vacant seats. Please select another table');
        }
      }
    }
  }
  async assignSeats(data: any) {
    this.seatingChartService.assignSeats(data).subscribe(res => {
      this._toastr.success('Table updated Successfully.');
    }, error => {
      this._toastr.error(error.message);
    })
  }

  async unassignSeats(unassignData: any) {
    this.seatingChartService.unassignSeats(unassignData).subscribe(res => {
      // this._toastr.success('Table updated Successfully.');
    }, error => {
      this._toastr.error(error.message);
    })
  }

  // serach guest
  searchEventGuest(e: any) {
    this.searchEventText = e.target.value.toLowerCase();
    const list = [...this.guestEventData];
    const properties = Object.keys(list[0]);
    this.filterEventGuest = list.filter(user => {
      var s = user.first_name.toLowerCase();
      var c = user.last_name.toLowerCase();
      if (s.includes(this.searchEventText) || c.includes(this.searchEventText)) {
        return user
      }
    })
  }

  //  select all guest 
  selectAllGuest(e: any, list) {
    if (e.target.checked == true) {
      for (let index = 0; index < list.length; index++) {
        list[index].isSelected = true;
        if(!this.checkedGuest.includes(list[index].id,index)){
          this.checkedGuest.push(list[index].id);          
        }
      }
    } else {
      for (let index = 0; index < list.length; index++) {
        list[index].isSelected = false;
        this.checkedGuest = [];
      }

    }
  }

  // single guest select 
  singleGuestChecked(e: any, id: any) {
    // this.checkedGuest = [];
    if (e.target.checked == true) {
      this.checkedGuest.push(id);
    } else {
      const index = this.checkedGuest.indexOf(id);
      this.checkedGuest.splice(index, 1);
    }
  }
 
  // get event guestlist
  getEventGuestList(){
    let data = {
      wedding_id: this.activeUser.id,
      event_id: this.eventData.id,
    };
    this.guestlistService.getEventGuestList(data).subscribe(res => {
      this.guestEventData = res.data;
      for (let index = 0; index < this.guestEventData.length; index++) {
        this.guestEventData[index].isSelected = false;  
      }
      this.filterEventGuest = [...this.guestEventData];       
    }, error => {
      this._toastr.error(error.message);
    });
  }

  addEventGuests(){
    let data = {
      wedding_id: this.activeUser.id,
      event_id: this.eventData.id,
      guest_ids:this.checkedGuest
    };
    this.guestlistService.addGuestsToEvent(data).subscribe(res =>{
      this.addGuestData = res.data
      $('#addEventGuest').modal('toggle');
      this._toastr.success('Guest(s) added successfully');
      this.resetGuest(2);
    }),error =>{
      this._toastr.error(error.message);
    }
  }
  showAddEventGuestModal(){
    $('#addEventGuest').modal('toggle');
    $('#addGuest').modal('toggle');
  }

}
