import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { VendorsService } from '../services/vendors.service';
import { ToastrService } from 'ngx-toastr';
import { MapsAPILoader } from '@agm/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageSubscriptionModel } from 'src/app/models/package.subscription.model';
import { LocationFormBuilder } from '../location-info/location.form-builder';
import { VendorLocationInfoModel } from 'src/app/models/vendor.location.info.model';
import { LocationsService } from '../../locations/services/locations.service';
import { LocationModel } from 'src/app/models/location.model';
import { RegionModel } from 'src/app/models/region.model';
import { CheckoutFormBuilder } from './checkout.form-builder';
import { CheckoutModel } from 'src/app/models/checkout.model';

declare var $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  planPrice: any;
  myPlan: any;
  submitted = false;
  cities: any;
  package_id: number;
  total_price: number;
  category_region_rate_subscription_id: number;
  model = new CheckoutModel();
  pkgModel = new PackageSubscriptionModel();
  locationModel = new LocationModel();
  locationForm: any;
  latitude: any;
  longitude: any;
  zoom: number;
  regions: any;
  choosePlan: any;
  perMonthPrice: number;
  period: any;

  first_name: string;
  last_name: string;
  address_country: string;
  address_zip: string;
  address_state: string;
  address_city: string;
  address_street: string;
  address_country_code: string;
  adjAmount: any;
  toBePaid: any;
  changePlan: boolean = false;
  monthlyAdj: any;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: CheckoutFormBuilder,
    private locationService: LocationsService,
    private toastr: ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute,
    public router: Router) {

  }

  ngOnInit() {

    this.locationForm = this.formBuilder.getForm();
    this.myPlan = { "title": "Basic", "rate": "0.00", "duration_in_month": "n/a" };
    this.plans();
    this.selectedPlan();
    this.allRegions();
    this.getLocationInfo();
    window.scroll(0, 0);

    if (localStorage.getItem("package_id") == "" || localStorage.getItem("total_price") == "" || localStorage.getItem("category_region_rate_subscription_id") == "" || localStorage.getItem("duration") == "") {
      this.router.navigate(["vendor/membership"]);
      return;
    } else {
      this.package_id = Number(localStorage.getItem("package_id"));
      this.total_price = Number(localStorage.getItem("total_price"));
      this.category_region_rate_subscription_id = Number(localStorage.getItem("category_region_rate_subscription_id"));
      this.period = Number(localStorage.getItem("period"));
      this.perMonthPrice = Number((this.total_price / this.period).toFixed(2));
    }

    this.adjustmentAmount();

  }

  get f() { return this.locationForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.locationForm.invalid) {
      return;
    }

    this.model.deserialize(this.locationForm.value);
    this.pkgModel.package_id = this.package_id;
    this.pkgModel.period = this.period;
    this.pkgModel.category_region_rate_subscription_id = this.category_region_rate_subscription_id;
    //console.log(this.pkgModel);return;
    this.vendorService
      .addPlan(this.pkgModel)
      .subscribe(
        (response: PackageSubscriptionModel) => {
          //console.log(response.data.remainingPlanRate);return;
          window.location.href = Config.paypalSandboxSettings.url + "?cmd=_xclick&business=" + Config.paypalSandboxSettings.email
            + "&custom=" + response.data.lastInsertId
            + "&item_name=" + this.choosePlan
            + "&item_number=" + this.package_id
            + "&amount=" + response.data.remainingPlanRate
            + "&first_name=" + this.first_name
            + "&last_name=" + this.last_name
            + "&address_country=" + this.address_country
            + "&address_country_code=" + this.address_country_code
            + "&address_street=" + this.address_street
            + "&address_state=" + this.address_state
            + "&address_city=" + this.address_city
            + "&address_zip=" + this.address_zip
            + "&currency_code=USD&notify_url="
            + Config.paypalSandboxSettings.notify_url
            + "&return=" + Config.paypalSandboxSettings.return;
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )

    return;
    //console.log(this.model);return;
    this.vendorService
      .updateLocationInfo(this.model)
      .subscribe(
        (response: CheckoutModel) => {
          this.toastr.success(response.message);
          this.getLocationInfo();

          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  getLocationInfo() {
    this.vendorService
      .getLocationInfo()
      .subscribe(
        (response: any) => {
          this.first_name = response.first_name;
          this.last_name = response.last_name;
          this.address_country = "United States";
          this.address_country_code = "US";
          this.address_zip = response.location_zip_code;
          this.address_state = response.location_region_name;
          this.address_city = response.location_city_name;
          this.address_street = response.location_address;
        },
        error => {
          this.toastr.error(error.message);
          //console.log(error.message);
        }
      )
  }

  plans() {
    this.vendorService
      .vendorPricePlans()
      .subscribe(
        (response) => {
          this.planPrice = response;
          for (let k = 0; k < response.length; k++) {
            if (response[k].id == this.package_id) {
              this.choosePlan = response[k].title;
            }
          }
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  selectedPlan() {
    this.vendorService
      .vendorCurrentPlan()
      .subscribe(
        (response: PackageSubscriptionModel) => {
          this.myPlan = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  getRegionWiseCities(region_id: number) {
    this.locationService
      .getRegionWiseCities(region_id)
      .subscribe(
        (response: LocationModel[]) => {
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.cities = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  allRegions() {
    this.locationService
      .getRegions("")
      .subscribe(
        (response: RegionModel[]) => {
          //this.regions = response;
          for (var i = 0; i < response.length; i++) {
            response[i]['text'] = response[i].name;
          }
          this.regions = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  adjustmentAmount() {
    let data = {
      package_id: this.package_id,
      period: this.period,
      category_region_rate_subscription_id: this.category_region_rate_subscription_id
    };
    this.vendorService
      .adjustmentAmount(data)
      .subscribe(
        (response: any) => {
          this.monthlyAdj = 0.00;
          this.adjAmount = response.remainingPlanRate;
          this.toBePaid = (this.total_price - this.adjAmount).toFixed(2);
          //if(this.package_id!=this.myPlan.package_id){
          this.monthlyAdj = (this.adjAmount / this.period).toFixed(2);
          //}

          //console.log(this.total_price,this.adjAmount);
          /* if(this.adjAmount<=0){
            this.toastr.error("Please choose another plan");
            this.router.navigate(["vendor/membership"]);
          } */
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

}
