import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router,ActivatedRoute  } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { DealFormBuilder } from './deal.form-builder';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { DealModel } from 'src/app/models/deal.model';

declare var $: any;

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.css']
})
export class DealsComponent implements OnInit {
  photo_url: string = Config.imageUrl;
  submitted = false;
  model = new DealModel();
  dealForm: any;
  deals: any;
  deal_id = 0;
  did = 0;
  title = "Add New Deal";
  button = "Create Deal"
  image_name: any;
  selectImage: any;
  currentUrl: string;
  dealpdf: string;
  dealsCurrentPage : number = 1;
  pagination_per_Page:number = Config.pagination_per_Page;
  selectedMoment = new Date();


  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: DealFormBuilder,
    dateTimeAdapter: DateTimeAdapter<any>,
    private toastr:ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
      //dateTimeAdapter.setLocale('en-US');
  }
  ngOnInit() {
    this.dealForm = this.formBuilder.getForm();
    this.currentUrl = Config.baseUrl+this.router.url;
    this.resetForm();
    this.getDeals();
    this.getDealPdf();
   
  }

  get f() { return this.dealForm.controls; }

  onSubmit() {
    
    this.submitted = true;
    // stop here if form is invalid
    //console.log(this.dealForm.controls.deal_value.errors);
    if (this.dealForm.invalid) {
      return;
    }
    //console.log(this.model);return;
    this.model.deserialize(this.dealForm.value);
    var inputDate = this.usDateToDb($("#expire_date").val());
    this.model.expire_date = inputDate;
    
    this.vendorService
      .saveDeal(this.model)
      .subscribe(
        (response:DealModel) => {          
          this.toastr.success(response.message);
          this.resetForm();
          $("#dealphoto").val('');
          //setTimeout(function(){
            //window.location.href=this.currentUrl;
         // },1000);           
          this.getDeals();
          return false;
        },
        error => {
          this.toastr.error(error.message);
          $("#dealphoto").val('');
        }
      )
  }
  
  getDeals(){
    this.vendorService
      .getDeals()
      .subscribe(
        (response:DealModel[]) => {  
          this.deals = response;
          console.log(this.deals);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

    getDeal(id:number){
    this.vendorService
      .getDeal(id)
      .subscribe(
        (response:DealModel) => {  
          this.formBuilder.setValues(response);
          this.selectImage = response.image_name;
          this.title = "Edit Deal";
          this.button = "Save Deal";
          this.deal_id = id;
          this.dbToUsDate(response.expire_date);
          window.top.scroll(10,10);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  } 

  deleteDealModal(id:number){
    this.did = id;
    $("#deletedeal").modal();    
  } 

  deleteDeal(did:any){
    this.vendorService
      .deleteDeal(did)
      .subscribe(
        (response:DealModel) => { 
          $('#deletedeal').modal('toggle'); 
          this.toastr.success(response.message);
          this.getDeals();
          this.router.navigate([this.router.url]);
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

  onFileChanges(files: any) {
    console.log(files[0]);
    this.image_name = files[0];
    //this.model.image_name = files[0];
    this.dealForm.controls.image_name.setValue(files[0]);
  }

  resetForm(){
    this.submitted = false;
    //this.router.navigate([this.router.url]);
    this.dealForm.reset();    
    //window.location.href=this.currentUrl;
    this.selectImage = "";
    $("#dealphoto").val('');
    this.title="Add New Deal";
    this.button = "Create Deal";
    window.scroll(10,10);
  }

  deleteDealPhoto(){
    //alert(this.event_id);
    this.vendorService
      .deleteDealPhoto(this.deal_id)
      .subscribe(
        (response:DealModel) => {          
          this.toastr.success(response.message);
          this.getDeal(this.deal_id);
          return false;
        }
      )
  }

  onPDFChanges(file:any){
    this.vendorService
      .saveDealPdf(file[0])
      .subscribe(
        (response) => {
          this.toastr.success(response.message);
          this.getDealPdf();
          $("#pdf").val("");
          return false;
        },
        error => {
          this.toastr.error(error.message);
          $("#pdf").val("");
        }
      )
  }

  getDealPdf(){
    this.vendorService
      .getAuthVendorDetail()
      .subscribe(
        (response: any) => {
          //this.toastr.success(response.message);
          this.dealpdf = response.data.deal_doc;   
          console.log(response.data.deal_doc);
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }
  
  deleteDealPdf(){
    this.vendorService
      .deleteDealPdf()
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          this.dealpdf = "";
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }

  usDateToDb(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  dbToUsDate(date){
    var exp = date.split("-");
    var year = exp[0];
    var month = exp[1];
    var day = exp[2];
    var convertDate = [month, day, year].join('/');
    $("#expire_date").val(convertDate);
  }
   
}
