import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Config } from '../../../config';
import { BaseService } from '../../../services/base.service';
import { UsersService } from '../../users/services/users.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class VendorManagerService extends BaseService {

  constructor(
    private http: HttpClient,
    protected usersService: UsersService) {
    super(usersService);
  }
  private appendUrl(geturl: any, data?: any) {
    console.log(geturl, data);
    if (typeof (data) === 'object') {
      geturl += '?';
      let i = 1;
      for (const field of Object.keys(data)) {
        geturl += field + '=' + data[field];
        if (i < Object.keys(data).length) {
          geturl += '&';
        }
        i++;
      }
    }
    return geturl;
  }
  // get vendor category list
  getVendorCategorys(weddingId: any) {
    return this.http
      .get(Config.apiBaseUrl + "/vendors/categories?weddingId=" + weddingId, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  // Update status of vendor not needed checkbox --
  updateVendorNotNeededStatus(data: any){
    return this.http
    .post(Config.apiBaseUrl+"/vendors/category/update_vendor_not_needed_status", data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

   // get vendor list under vendor category
   getVendorDetails(vendor_id:any) {
     return this.http
       .get(Config.apiBaseUrl + "/vendors/vendor_details?vendor_id=" + vendor_id, this.getHttpHeaders())
       .pipe(map((response: any) => {
         if (response.hasError) {
           throw new Error(response.message);
        }
         return response;
       }));
   }
  getSavedVendorsList(p1:any,p2:any,p3:any){
    var url= '?';
    if(p1){
      url = url + 'categoryId=' + p1 + "&";
    }
    if(p2){
      url = url + 'hired=' + p2 + '&';
    }
    if(p3){
      url = url + 'weddingId=' + p3;
    }
    
    return this.http
      .get(Config.apiBaseUrl+"/vendors/categories/saved_vendors"+url, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  // get all vendors list 
  getAllVendorList(){
    return this.http
      .get(Config.apiBaseUrl+"/vendors/all", this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  // vendor status count 

  vendorStatusCount(data){
    return this.http
    .post(Config.apiBaseUrl+"/vendors/status_count", data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  // get hired vendor data 
  getHiredVendorData(weddingId: any) {
    return this.http
      .get(Config.apiBaseUrl + "/vendors/hired_vendors?weddingId=" + weddingId, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  // get vendor category count and total active vendor count 
  getVendorCategorysCount(weddingId: any) {
    return this.http
      .get(Config.apiBaseUrl + "/vendors/user_vendor_count?weddingId=" + weddingId, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  // change vendor status 
  vendorUpdateStatus(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/vendors/saved-vendors/change-hired-status", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  // get vendor status list 
  getVendorStatusList(){
    return this.http
      .get(Config.apiBaseUrl + "/vendors/vendor_hired_status", this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }
  searchValue(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/vendor_detail_by_filter", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  addWeddingVendor(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/add_custom_wedding_vendor", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }
  

  getWeddingVendor(data:any) {
    return this.http
      .post(Config.apiBaseUrl+"/checklist/save_wedding_vendor_list", data, this.getHttpHeaders())
      .pipe(map((response:any) => {        
        if(response.hasError){
          throw new Error(response.message);
        }    
        return response;         
      }));
  }

  // download vendor list 
  downloadVendors(p1:any,p2:any){
    var url= '?categoryId='+p1+'&hired='+p2;
    return this.http
      .get(Config.apiBaseUrl+"/vendors/categories/vendors/download"+url, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }

  vendorFileUpload(data){
    return this.http
    .post(Config.apiBaseUrl+"/vendors/review/upload_image", data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  removeFileUpload(data){
    return this.http
    .post(Config.apiBaseUrl+"/vendors/review/uploaded_image/remove", data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  submitReview(data){
    return this.http
    .post(Config.apiBaseUrl+"/vendors/review/new", data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  reviewList(data){
    return this.http
    .post(Config.apiBaseUrl+"/vendors/review/list", data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }
  updateVendor(data){
    return this.http
    .post(Config.apiBaseUrl+"/vendors/review/update", data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  getReviewRequestLog(data: any) {
    let url = Config.apiBaseUrl + "/vendors/review/requests";
    const params = new URLSearchParams({});
    if(data.page_size){
      params.set('page_size', data.page_size);
    }
    if(data.page_number){
      params.set('page_number', data.page_number);
    }

    url = url + "?" + params.toString();
    return this.http
      .get(url, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response;
      }));
  }

  // search by couple 
  searchByouple(data){
    return this.http
    .post(Config.apiBaseUrl+"/search_wedding_users", data, this.getHttpHeaders())
    .pipe(map((response:any) => {        
      if(response.hasError){
        throw new Error(response.message);
      }    
      return response;         
    }));
  }

  // download vendor list 
  getTemplateSettings(p1:any){
    var url= '?template_id='+p1;
    return this.http
      .get(Config.apiBaseUrl+"/wedding-website/template_setting"+url, this.getHttpHeaders())
      .pipe(map((response: any) => {
        if (response.hasError) {
          throw new Error(response.message);
        }
        return response.data;
      }));
  }
}
