
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { GuestlistService } from '../services/guestlist.service';
import { PlannersService } from '../services/planners.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Config } from '../../../config';
declare var $: any;

@Component({
  selector: 'app-create-guest-intitations',
  templateUrl: './create-guest-intitations.component.html',
  styleUrls: ['./create-guest-intitations.component.scss']
})
export class CreateGuestIntitationsComponent implements OnInit {
  public min = new Date();
  inviteSelection: boolean = true;
  showCropper: boolean;
  croppedImage: any = '';
  imageChangedEvent: any;
  fileName: any;
  fileType: any;
  showTham: boolean;
  activeUser: any;
  allEvent: any;
  addInvitationForm: FormGroup;
  submitted: boolean = false;
  title: string = 'TEXT ABOVE NAME';
  belowTitle: string = 'Text below name';
  message: string = 'Invitation message';
  InvitationVersion: string;
  name: string;
  patnerName: string;
  location: string = 'Wedding location';
  weddingDate: any;
  weddingTime: any;
  time: any;
  eventName: any;
  event: any;
  invitationList: any;
  textLavel: string = 'New';
  invitationId: any;
  size: any;
  imageData: any;
  profileImage: string;
  file: { name: any; type: any; base64: any; size: any; };
  selectedTime: any;
  eventLocation: string;
  constructor(private toastr: ToastrService,
    private router: Router, private plannersService: PlannersService, private guestlistService: GuestlistService, public formBuilder: FormBuilder,) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });

    this.addInvitationForm = this.formBuilder.group({
      your_name: ['', Validators.required],
      patner_name: ['', Validators.required],
      wedding_date: ['', Validators.required],
      wedding_time: [''],
      wedding_location: ['', Validators.required],
      invitation_title: [''],
      invitation_messagge: [''],
      invitation_version: ['', Validators.required],
      below_title: [''],
    });

    this.setValue(this.activeUser);
  }
  get f() {
    return this.addInvitationForm.controls;

  }

  setValue(data: any) {
    window.scroll(0, 0);
    this.name = data.first_couple_name;
    this.patnerName = data.second_couple_name;
    this.weddingDate = data.start_datetime;
    this.showTham = false;
    this.croppedImage = null;
    var event_date = new Date(this.datetimeToDate(data.start_datetime));
    this.addInvitationForm.get('wedding_date').setValue(event_date);
    this.addInvitationForm.get('your_name').setValue(data.first_couple_name);
    this.addInvitationForm.get('patner_name').setValue(data.second_couple_name);
    this.addInvitationForm.get('wedding_location').setValue(null);
    this.addInvitationForm.get('invitation_title').setValue(null);
    this.addInvitationForm.get('below_title').setValue(null);
    this.addInvitationForm.get('invitation_messagge').setValue(null);
    this.addInvitationForm.get('invitation_version').setValue(null);
    this.addInvitationForm.get('wedding_time').setValue(null);
    this.title = data.invitation_title;
    this.belowTitle = data.below_name;
    this.location = data.wedding_location;
    this.message = data.invitation_message;
    this.selectedTime = null;

  }
  dateConvert(str: any) {
    const date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join('-');
  }

  datetimeToDate(datetime: any) {
    if (!datetime) return;
    var dateTime = datetime.split(" ");
    var exp = dateTime[0].split("-");
    var year = exp[0];
    var month = exp[1];
    var day = exp[2];
    return [month, day, year].join('/');
  }

  timeConvert(datetime: any) {
    if (!datetime) return;
    const date = new Date(datetime),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    var da = [date.getFullYear(), mnth, day].join('-');
    var d = new Date(datetime);
    var hours = d.getHours();
    var minutes = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    var ta = [hours, minutes].join(':');
    return da + ' ' + ta;
  }


  async onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.addInvitationForm.invalid) {
      console.log('invalid');
      return;
    }
    var date = this.dateConvert(this.addInvitationForm.value.wedding_date);    
    this.time = this.timeConvert(this.addInvitationForm.value.wedding_time);
    let submit = {
      id: this.invitationId ? this.invitationId : null,
      wedding_id: this.activeUser.id,
      event_id: this.event.id,
      name: this.name,
      partner_name: this.patnerName,
      wedding_date: date,
      time: this.time,
      wedding_location: this.location,
      invitation_title: this.title,
      invitation_message: this.message,
      invitation_version: this.addInvitationForm.value.invitation_version,
      below_name: this.addInvitationForm.value.below_title,
    }
    // console.log(submit);
    if (this.textLavel == 'New') {
      // if (!this.file) {
      //   this.toastr.error('Please Upload Image');
      //   return
      // }
      var updateDetails = await this.guestlistService.saveInvitation(submit).toPromise();
      // console.log('updateDetails', updateDetails);
      if (updateDetails && this.file) {
        const imageData = {
          file: this.file,
          invitation_id: updateDetails.data.id,
        }
        var uploadImageDetails = await this.guestlistService.imageUpload(imageData).toPromise();
      }
      this.toastr.success('Invitation Created Successfully');
    } else {
      var updateDetails = await this.guestlistService.updateInvitation(submit).toPromise();
      this.toastr.success(updateDetails.message);
    }
    if (updateDetails) {
      this.submitted = false;

      let submit = {
        wedding_id: this.activeUser.id,
        event_id: this.event.id
      };
      // await this.getInvitationList(submit);
    var invitations = await this.guestlistService.getInvitationList(submit).toPromise();
    this.invitationList = invitations.data;
    this.textLavel = 'Edit';
    }
    window.scroll(0, 0);
  }

  async ngOnInit() {
    var eventList = await this.getEventList(this.activeUser.id);
    this.allEvent = eventList;
    this.event = this.allEvent[0];

    this.eventName = this.allEvent[0].event_name;
    this.eventLocation = this.eventName + " Location"

    let submit = {
      wedding_id: this.activeUser.id,
      event_id: this.event.id
    };
    await this.getInvitationList(submit);
  }
  async getEventList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var eventList = await this.guestlistService.getEventList(data).toPromise();
    return eventList.data;
  }

  fileChangeEvent(event: any): void {
    // console.log('event1',event);
    // this.showCropper = true;
    this.showTham = false;
    this.imageChangedEvent = event;
    this.fileName = event.target.files[0].name;
    this.fileType = event.target.files[0].type;
    var size = event.target.files[0].size;
    this.size = size;
    if (size > 5300000) {
      this.showCropper = false;
      this.toastr.error('Please upload upto 5MB image');
    } else {
      if (this.fileType == 'image/jpeg' || this.fileType == 'image/jpg' || this.fileType == 'image/gif' || this.fileType == 'image/png') {
        this.showCropper = true;
        // var file = {
        //   name: this.fileName,
        //   type: this.fileType,
        //   base64: this.croppedImage,
        //   size: this.size,
        // };

      } else {
        this.showCropper = false;
        this.toastr.error('Please upload a jpeg, jpg, gif, png image');

      }
    }

  }

  imageCropped(event: ImageCroppedEvent) {
    this.showCropper = true;
    this.croppedImage = event.base64;

    // console.log('event', event);
    // console.log('image', this.croppedImage);
    // console.log(event, base64ToFile(event.base64));
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady(e: any) {
    // cropper ready
  }

  async removeCropper() {
    this.showCropper = false;
    this.showTham = true;
    this.file = {
      name: this.fileName,
      type: this.fileType,
      base64: this.croppedImage,
      size: this.size,
    };

    if (this.textLavel != 'New') {
      this.imageData = {
        file: this.file,
        invitation_id: this.invitationId,
      }
      if (this.imageData) {
        var uploadImageDetails = await this.guestlistService.imageUpload(this.imageData).toPromise();
      }
      let submit = {
        wedding_id: this.activeUser.id,
        event_id: this.event.id
      };
      await this.getInvitationList(submit);
        
    }

  }

  newGuest(type: any) {
    this.inviteSelection = true;
    if (type == 1) {
      this.submitted = false;
      this.setValue(this.activeUser);
      if (this.invitationList.length > 0) {
        // this.textLavel = 'Edit';
        this.setValue(this.activeUser);
        this.setInvitationValue(this.invitationList[0]);
      } else {
        this.textLavel = 'New';
      }
    }
    else {
      this.submitted = false;
      this.setValue(this.activeUser);
      if (this.invitationList.length > 0) {
        this.setInvitationValue(this.invitationList[0]);
      }
      this.textLavel = 'New';

    }
  }
  savedGuest() {
    this.inviteSelection = false;
  }
  getName(e: any) {
    this.name = e.target.value;
  }
  getPatnerName(e: any) {
    this.patnerName = e.target.value;

  }
  getLocation(e: any) {
    this.location = e.target.value;

  }
  // getTime(e: any) {
  //   this.time = e.target.value;
  //   console.log(this.time);
  // }
  getTitle(e: any) {
    this.title = e.target.value;

  }
  getBelowTitle(e: any) {
    this.belowTitle = e.target.value;
  }
  getMessage(e: any) {
    this.message = e.target.value;

  }
  async getEvent(e: any) {
    this.eventName = e.event_name;
    this.eventLocation = this.eventName + " Location";
    let submit = {
      wedding_id: this.activeUser.id,
      event_id: e.id
    };
    await this.getInvitationList(submit);
    this.newGuest(1);
  }

  async getInvitationList(submit: any) {
    var invitations = await this.guestlistService.getInvitationList(submit).toPromise();
    this.invitationList = invitations.data;
    if (this.invitationList && this.invitationList.length > 0) {
      this.textLavel = 'Edit';
      this.setInvitationValue(this.invitationList[0]);
    }
  }


  setInvitationValue(data: any) {
    if (!this.inviteSelection) {
      this.inviteSelection = !this.inviteSelection;
    }
    this.textLavel = 'Edit';

    let event_date = null;
    let event_time = null;
    this.showTham = false;
    if (data.wedding_date) {
      event_date = new Date(this.datetimeToDate(data.wedding_date));
      this.weddingDate = data.wedding_date;
    } else {
      event_date = new Date(this.datetimeToDate(this.activeUser.start_datetime));
      this.weddingDate = this.activeUser.start_datetime;
    }
    if (data.time) {
      event_time = new Date(this.timeConvert(data.time));
      this.selectedTime = data.time;
    }
    if (data.file_name) {
      this.showTham = true;
      this.croppedImage = Config.imageUrl + 'uploads/users/invitation/' + data.file_name;
    }
    if (data.invitation_title) {
      this.title = data.invitation_title;
    }
    if (data.below_name) {
      this.belowTitle = data.below_name;
    }
    if (data.wedding_location) {
      this.location = data.wedding_location;
    }
    if (data.invitation_message) {
      this.message = data.invitation_message;
    }
    this.invitationId = data.id;
    this.addInvitationForm.get('wedding_date').setValue(event_date);
    this.addInvitationForm.get('your_name').setValue(data.name);
    this.addInvitationForm.get('patner_name').setValue(data.partner_name);
    this.addInvitationForm.get('wedding_location').setValue(data.wedding_location);
    this.addInvitationForm.get('invitation_title').setValue(data.invitation_title);
    this.addInvitationForm.get('invitation_messagge').setValue(data.invitation_message);
    this.addInvitationForm.get('wedding_time').setValue(event_time);
    this.addInvitationForm.get('invitation_version').setValue(data.invitation_version);
    this.addInvitationForm.get('below_title').setValue(data.below_name);
  }

  getDate(e: any) {
    this.weddingDate = e;
  }
  getTime(e: any) {
    this.selectedTime = e;
  }

  async removeInvitation(data: any, i: number) {
    var invitations = await this.guestlistService.invitationDelete(data.id).toPromise();
    this.toastr.success(invitations.message);
    this.invitationList.splice(i, 1);
    this.inviteSelection = false;
    if (this.invitationList && this.invitationList.length > 0) {
      this.setInvitationValue(this.invitationList[0]);
    } else {
      this.inviteSelection = true;
      this.textLavel = 'New';
      this.setValue(this.activeUser);
    }
  }
}


