import { Deseriazable } from './deserializable.model';

export class AuthResponseModel implements Deseriazable{
    token:string;
    id: string;
    roles: string;
    claims: string;
    name: string;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}