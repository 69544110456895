import { Component,ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError, tap } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SettingsModel } from 'src/app/models/settings.model';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { LocationModel } from 'src/app/models/location.model';
import { CategoryModel } from 'src/app/models/category.model';
import { VendorsService } from '../services/vendors.service';
import { LocationsService } from '../../locations/services/locations.service';
import { MediadModel } from 'src/app/models/media.model';
import { PackageSubscriptionModel } from 'src/app/models/package.subscription.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SortablejsOptions } from 'angular-sortablejs';

declare var $: any;

@Component({
  selector: 'app-other-docs',
  templateUrl: './other-docs.component.html',
  styleUrls: ['./other-docs.component.css']
})
export class OtherDocsComponent implements OnInit {

  submitted = false;
  model = new MediadModel();
  photoForm: any;
  docs: any;
  doc_id: number;
  baseUrl = Config.baseUrl;
  deletebulkdoc = [];
  showDraggable = false;
  showTitle = true;
  myPlan: any;
  uploadFiles: string;
  deviceInfo = null;
  mobile = false;
  desktop = false;
  fileSettings: any;
  filesQueue: any;
  totalFilesCount = 0;
  noImage = Config.noImage;

  @ViewChild('fileUploadProgress') fileUploadProgress: any;


  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private toastr: ToastrService,
    private deviceService: DeviceDetectorService,
    public router: Router) {
      this.deviceDetector();
  }

  ngOnInit() {
    this.getMedia('docs');
    this.selectedPlan();
    window.scroll(10,10);
  }

  onFileChanges(files: any) {
    this.getFileSettings().subscribe(response=>{
      this.fileSettings = response;
      $(".overlayDiv").show();
      let valid = this.fileValidation(files);
      $(".overlayDiv").hide();
      if(valid){
        this.filesQueue = [];
        this.totalFilesCount = files.length;
        for(var i=0;i<files.length;i++){
          this.filesQueue.push(files[i]);
        }
        this.fileUpload();
      }
    });
  }

  fileValidation(files){
    var error_ext = [];
    //File extension checking
    var extarray = ["pdf"];
    /*for(var i=0;i<files.length;i++){
      var filetype = files[i].type.split("/");
      if(filetype[0]!="application"){
        error_ext.push(1);
        break;
      }
      if(extarray.indexOf(filetype[1])==-1){
        error_ext.push(1);
        break;
      }
    }
    if(error_ext.length>0){
      this.toastr.error("Wrong file format.");
      $("#input-file-now").val("");
      return false;
    }*/
    var error_file_size = [];
    //Total count checking
    if(this.fileSettings.remaning>0 && this.fileSettings.remaning>=files.length){
       
      for(var i=0;i<files.length;i++){
        var filetype = files[i].type.split("/");
        if(filetype[0]!="application"){
          error_ext.push(1);
          break;
        }
        if(extarray.indexOf(filetype[1])==-1){
          error_ext.push(1);
          break;
        }
        var convToKb = this.formatBytes(files[i].size);
        if(convToKb<this.fileSettings.min_size || convToKb>this.fileSettings.max_size){
          error_file_size.push(1);
        }
      }

      if(error_ext.length>0){
        this.toastr.error("Wrong file format.");
        $("#input-file-now").val("");
        return false;
      }
      if(error_file_size.length>0){
        this.toastr.error("File size must be between 20KB to 100KB.");
        $("#input-file-now").val("");
        return false;
      }

    }else{
      this.toastr.error("Maximum number of docs for your account has been reached.");
      $("#input-file-now").val("");
      return false;
    }
    return true;
  }

  fileUpload(){
    if(this.filesQueue.length==0){
      this.fileUploadProgress.nativeElement.style.width = "0";
      this.fileUploadProgress.nativeElement.parentElement.style.display = "none";
      this.toastr.success("Media Saved");
      this.getMedia("docs");
      $("input[type=file]").val('');
      return;
    }
    this.fileUploadProgress.nativeElement.parentElement.style.display = "flex";
    let file = this.filesQueue.shift();
    this.vendorService
        .saveMedia([file])
        .subscribe(
          (response) => {
            let persentwidth = (((this.totalFilesCount - this.filesQueue.length)/this.totalFilesCount)*100);
            this.fileUploadProgress.nativeElement.style.width = persentwidth + "%";
            this.fileUploadProgress.nativeElement.innerHTML = persentwidth.toFixed(2) + "%";
            this.fileUpload();
          },
          error => {
            this.toastr.error(error.message);
          }
        );
  }

  deleteDocModal() {    
    $("#deletedoc").modal();
  } 

  deleteDocs(ele:any) {    
    if(ele.target.checked){
      this.deletebulkdoc.push(ele.target.value);
    }else{
      let index = this.deletebulkdoc.indexOf(ele.target.value);
      if (index > -1) {
        this.deletebulkdoc.splice(index, 1);
      }
    }
  }

  deleteMedia() {
    //console.log(this.deletebulkdoc);
    var delete_ids = [];
    $("input[type=checkbox]:checked").each(function(){
      delete_ids.push($(this).val());      
    });
    
    if(delete_ids.length==0){      
      this.toastr.error("Please select document(s) to delete");
      return;
    }
    this.vendorService
      .deleteMedia(delete_ids)
      .subscribe(
        (response: MediadModel) => {
          $('#deletedoc').modal('toggle');
          this.toastr.success(response.message);
          this.getMedia("docs");
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      ) 
  }

  getMedia(type: string) {
    this.vendorService
      .getMedia(type)
      .subscribe(
        (response: MediadModel[]) => {
          this.docs = response;
          console.log(this.docs);
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  saveDocTitle(){
    var doctitle = [];
    /*$(".doctitle").each(function(i){
      //console.log($(this).val());
      //if($(this).val()){
        var id = $(this).attr("id");
        var val = $(this).val();
        var ord = i+1;
        doctitle.push({"id":id,"title":val,"ord": ord});
      //}      
    })*/
    for(let i=0;i<this.docs.length;i++){
      var ord = i+1;
      doctitle.push({"id":this.docs[i].id,"title":this.docs[i].title,"ord": ord});
    }

    this.vendorService
      .saveMediaTitle(doctitle)
      .subscribe(
        (response: any) => {
          this.toastr.success(response.message);
          //this.getMedia("docs");
          //this.ngOnInit();
          
          return false;
        },
        error => {
          this.toastr.error(error.message);
          return false;
        }
      )
    console.log(doctitle);
  }

  onDrop(){
    this.saveDocTitle();
  }

  showShort(ele:any){
    console.log(ele.currentTarget.checked);
    if(ele.target.value=="dragg"){
      this.showDraggable = true;
      this.showTitle = false;
    }else{
      this.showDraggable = false;
      this.showTitle = true;
    }    
  }

  selectedPlan(){
    this.vendorService
      .vendorCurrentPlan()
      .subscribe(
        (response:PackageSubscriptionModel) => {          
          if(response.package_id){
            this.myPlan = response;
            console.log(response.package_id);
            
            this.uploadFiles = "Upload up to 20 supporting docs to your profile.  File size should be a max of 5MB.";
            
          }else{
            this.myPlan = {"title":"Basic","rate":"0.00","duration_in_month":"1"};
          }
          //console.log(response);
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }
  deviceDetector(){
    
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();    
    if(isMobile){
      this.mobile = true;
      this.desktop = false;
    }else{
      this.mobile = false;
      this.desktop = true;
    }
  }

  options: SortablejsOptions = {
    onUpdate: (event: any)=>{
      this.saveDocTitle();
    }
  }

  getFileSettings(){
    var data = {"type":"docs"};
    return this.vendorService
      .getFileSettings(data)
      .pipe(tap(
        response => {},
        error=> {
          this.toastr.error(error.message);
        }
      ));     
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return 0;
    const k = 1024;  
    const i = bytes/k;  
    return parseFloat(i.toFixed(2));
  }
  
}
