import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GuestlistService } from '../services/guestlist.service';
import { PlannersService } from '../services/planners.service';
import { Config } from '../../../config';
declare var $: any;

@Component({
  selector: 'app-guest-invitation',
  templateUrl: './guest-invitation.component.html',
  styleUrls: ['./guest-invitation.component.scss']
})
export class GuestInvitationComponent implements OnInit {
  activeUser: any;
  apiGuestList: any;
  allGauest: any[];
  searchText: string | undefined;
  selectAllGuest: any;
  invitationId: any;
  invitationDetail: any;
  image: string;

  constructor(private _route: ActivatedRoute, public router: Router, private plannersService: PlannersService, private guestlistService: GuestlistService,
    private _toastr: ToastrService) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
    this._route.queryParams.subscribe((params: any) => {
      this.invitationId = params.id;
    });

  }

  async ngOnInit() {
    await this.getGuestList(this.activeUser.id);
    await this.getInvitationDetails(this.invitationId);
  }

  async getInvitationDetails(invitationId: any) {
    let data = {
      invitation_id: invitationId,

    };
    var invitationDetails = await this.guestlistService.getInvitationDetails(data).toPromise();
    this.invitationDetail = invitationDetails.data;
    if(this.invitationDetail.file_name){
      this.image = Config.imageUrl + 'uploads/users/invitation/' + this.invitationDetail.file_name;
    }
    // console.log(this.image);
  }


  async getGuestList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
      search_value: '',
      page_size: 10,
      start_index: 0
    };
    var guestList = await this.guestlistService.getGuestList(data).toPromise();
    this.apiGuestList = guestList.data;
    if (this.apiGuestList && this.apiGuestList.length > 0) {
      this.allGauest = await this.guestSetup(this.apiGuestList, false);
    }

  }

  async guestSetup(guests: any, status: boolean) {
    var temp = [];
    if (guests && guests.length > 0) {
      guests.forEach((item: any) => {
        const data = {
          item: item,
          showEventAttend: null,
          isChecked: status
        };
        if (item.email) {
          temp.push(data);
        }
      });
    }
    return temp
  }

  async selectAll(e: any) {
    this.allGauest = await this.guestSetup(this.apiGuestList, e.target.checked);
  }
  async sendInvitation() {
    var selectedGuest = this.allGauest.filter((e) => e.isChecked == true);
    var emails = [];
    if (selectedGuest && selectedGuest.length > 0) {
      selectedGuest.forEach((item: any) => {
        if (item.item.email) {
          emails.push(item.item.email);
        }
      });
    };
    let data = {
      emails: emails,
      invitation_id: this.invitationId
    };
    var sendEmail = await this.guestlistService.sendInvitation(data).toPromise();
    if(sendEmail){
      this._toastr.success('Email send successfully');
    }
  }

  removeGuest(data: any) {
    const index = this.allGauest.findIndex((e) => e.item.id == data.item.id);
    this.allGauest[index]['isChecked'] = false;
  }

}
