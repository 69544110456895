import { Deseriazable } from './deserializable.model';

export class LoginModel implements Deseriazable{
    
    username: string;
    password: string;
    google_provider_id:any;
    fb_provider_id:any;

    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }
}