import { copyArrayItem, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GuestlistService } from '../services/guestlist.service';
import { PlannersService } from '../services/planners.service';
import { SeatingChartService } from '../services/seating-chart.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
// import * as jsPDF from 'jspdf';
// import  jsPDF  from 'jspdf';

// import {jsPDF} from 'jspdf';
// declare var jsPDF: any;
import jspdf from "jspdf";

import html2canvas from "html2canvas";


declare var $: any;
@Component({
  selector: 'app-seating-chart',
  templateUrl: './seating-chart.component.html',
  styleUrls: ['./seating-chart.component.scss']
})
export class SeatingChartComponent implements OnInit {
  @ViewChild('content') content: ElementRef;
  // @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild('pdfTable') pdfTable: ElementRef;

  seatCount;
  tableWidth;
  tableHeight;
  notes: any;
  tableName: any;
  seatLeft: any;
  seats6 = [];
  seats3 = [];
  squareSeats = [];
  squareOtherSeats = [];
  circleSeats = [];
  rectangle4Seats = [];
  divideCount: any;
  seatBottomLeft: any;
  table6Width: any = 120;
  table3Width: any = 120;
  tableSquareHeight: any = 100;
  tableSquareWidth: any = 100;

  tableCircleeWidth: any = 100;
  tableCircleHeight: any = 100;

  tableSquare4eWidth: any = 100;
  tableSquare4Height: any = 40;
  table: string;
  // showTable6: boolean = false;
  showTable3: boolean = false;
  showSquareTable: boolean = false;
  tableShowHide: any;
  divideSquareCount: any;
  apiGuestList: any;

  collapseShow: boolean = true;
  collapsehide: boolean = false;
  activeUser: any;
  allEvent: any = [];
  addTableName: any;
  allGuest: any;
  modifyGuest: any = [];
  table3Seat: any = [];
  table6Seat: any = [];
  table4Seat: any = [];
  tableSquareSeat: any = [];
  tableOtherSquareSeat: any = [];
  tableCircleeSeat: any = [];
  tableRectangle4Seat: any = [];
  tableGenericSeats: any = [];
  removeHeading: any;
  removeText: any;
  gaustCount: any;
  isLoader = false;
  // genericTableSeats: any = [];

  guests = [{
    fname: '',
    lname: '',
    age: 1
  }];
  typeOfAge = [
    {
      id: 1,
      value: 'Adult'
    },
    {
      id: 2,
      value: 'Child'
    },
    {
      id: 3,
      value: 'Baby'
    }

  ];
  typeOfHotel = [
    {
      id: 1,
      value: 'Yes'
    },
    {
      id: 2,
      value: 'No'
    }

  ];
  eventItemsArray: any = [];
  searchText: string | undefined | null;
  selectHotel: boolean[] = [];
  guestOf: string = null;
  allRelationship: any;
  hotelType: any = 2;
  events = [];
  selectedRelation: any = null;
  email: any = null;
  pnoneNo: any = null;
  mobileNo: any = null;
  address: any = null;
  city: any = null;
  state: any = null;
  zip: any = null;
  templateUrl: string;
  eventName = 'Snapshot';
  eventWiseGuestCount: any;
  fileName: any;
  fileType: any;
  eventData: any;
  need_Hotel: number;
  selectAge: boolean[] = [];
  addNewGuest: boolean = true;
  editHotelType: any = 2;
  editSelectAge: boolean[] = [];
  editSelectHotel: boolean[] = [];
  tableaddEdit: string = "Add"
  tableobject: any;
  indexValue: any;
  deleteTableNameValue: any;
  tableEditData: any;
  tableEditDataIndex: any;
  tableNameValue: any;
  menuItemShow: any;
  allEventData: any;
  chartDownloadPdf: boolean = true;
  listDownloadPdf: boolean = false;
  chartSectionArea: boolean = true;
  listSectionArea: boolean = false;
  blankSquareSeat: boolean = false;
  blankRectSeat: boolean = false;
  blankCircleSeat: boolean = false;

  venueName: any;
  yourName: any;
  partnerName: any;
  addVenueName: string;
  addYoureName: string;
  addPartnerName: string;
  partnersName: boolean = false;
  showPdf: boolean = false;
  tableSeatValue: any[];
  uniqueChars: any[];
  startDrag: boolean = true;
  dropArray: any = [];
  cloneSeat = [
    [
      "Couple",
      [

      ]
    ],
    [
      "Family",
      [

      ]
    ],
    [
      "Friend",
      [

      ]
    ]
  ]
  tableRotate: any;
  RotateValue = 0;
  tableRotateValue: any;
  filterEvent: any;
  sizeType: any;
  eventId: any;
  tableList: any;
  EventGuestList: any;
  guestId: any;
  tableId: any;
  seatNumber: any;
  unassignGuestList: any;
  tableShiftLeftValue: any;
  tableShiftTopValue: any;
  shiftX: number;
  shiftY: number;
  editTableName: any;
  constructor(
    private plannersService: PlannersService,
    private guestlistService: GuestlistService,
    private seatingChartService: SeatingChartService,
    private _toastr: ToastrService,
    public router: Router,
  ) {
    this.plannersService.addActiveUserClick.subscribe((userData) => {
      if (userData) {
        this.activeUser = userData;
        this.getEventList(this.activeUser);
      } else {
        this.activeUser = null;
      }
    },
      (err) => {
        console.error(err);
      });
  }

  ngOnInit() {
    if(window.innerWidth < 576 ){
      let setData = JSON.parse(localStorage.getItem("datas"));
      if(!setData){
        $('#mobileView').modal('show');
        let data = {
          'token': 'token'
        }
        localStorage.setItem('datas', JSON.stringify(data));
      }
    }
   
    
    $('#addGuest').on('hidden.bs.modal', async () => {
      this.guests = [{
        fname: '',
        lname: '',
        age: 1
      }];
      await this.resetAddGuest();
    });
    this.selectHotel[1] = true

    var eventList = this.getEventList(this.activeUser.id);
    this.allEvent = eventList;
    // this.getGuestList(this.activeUser.id);
    this.getRelationshipList(this.activeUser.id);
    // this.resetAddGuest();
    this.table6Width = this.table6Width.toString() + "px";
    this.table3Width = this.table3Width.toString() + "px";
    this.tableSquareWidth = this.tableSquareWidth.toString() + "px";
    this.tableSquareHeight = this.tableSquareHeight.toString() + "px";


    setTimeout(() => {
      this.headTable();

    }, 1000)
  }

  headTable() {
    if (this.tableList <= 0) {
      this.seatCount = 6;
      this.tableName = 'Head Table';
      var type = 'rect3seat'
      this.arrange3Seats(type);
      setTimeout(() => {
        let filterCouple = this.allGuest.filter((e) => e[0] == 'Couple');
        var headTableSeatCount = 2;
        for (let index = 0; index < filterCouple[0][1].length; index++) {
          if (filterCouple[0][1][index].item.table_id == '' || filterCouple[0][1][index].item.table_id == null) {
            if (headTableSeatCount > 3) {
              return
            }
            var guestId = filterCouple[0][1][index].item.id;
            var data = {
              wedding_id: this.activeUser.id,
              event_id: this.eventId,
              guest_id: guestId,
              table_id: this.tableList[0].id,
              seat_number: headTableSeatCount
            }
            headTableSeatCount = headTableSeatCount + 1;
            this.assignSeats(data);
          }
        }
      }, 1000);

    }
  }

  openModal(table, sizeType) {
    this.notes = '';
    this.sizeType = sizeType;
    if (table == 'rect6seat') {
      this.seatCount = 6;
    }
    if (table == 'rect3seat') {
      this.seatCount = 3;
    }
    if (table == 'rectNoSeat') {
      this.seatCount = 6;
    }
    if (table == 'square8seat') {
      this.seatCount = 8;
    }
    if (table == 'squareNoSeatTable') {
      this.seatCount = 8;
    }
    if (table == 'circleSeat') {
      this.seatCount = 8;
    }
    if (table == 'circleNoSeat') {
      this.seatCount = 8;
    }
    if (table == 'rectangle4Seat') {
      this.tableWidth = 1;
      this.tableHeight = 1;
    }
    if (table == 'genericTable') {
      this.tableWidth = 1;
      this.tableHeight = 1;
    }
    this.tableName = '';
    this.tableShowHide = table;
    this.tableaddEdit = "Add"
    $('#addTable').modal('show');
  }

  // table save  

  save(data) {
    if (data == "Add") {

      for (let index = 0; index < this.tableList.length; index++) {
        if (this.tableList[index].name == this.tableName) {
          this._toastr.error('This table name is already exist');
          return;
        }
      }
      if (this.tableName == null || this.tableName == '') {
        this._toastr.error('Please enter table name');
        return;
      }

      if (this.seatCount > 100) {
        this._toastr.error('Max number of seats for this table is 100');
        return;
      }
      if (this.tableShowHide == 'rect6seat' || this.tableShowHide == 'rect3seat' || this.tableShowHide == 'square8seat' || this.tableShowHide == 'circleSeat') {

        if (this.seatCount <= 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 2');
          return;
        }
        if (this.seatCount == null || this.seatCount == '') {
          this._toastr.error('Please enter table seat');
          return;
        }
      }
      if (this.tableShowHide == 'rectNoSeat' || this.tableShowHide == 'circleNoSeat') {
        if (this.seatCount <= 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 2');
          return;
        }
        if (this.seatCount == null || this.seatCount == '') {
          this._toastr.error('Please enter table size');
          return;
        }

      }
      if (this.tableShowHide == 'genericTable') {
        if (this.tableWidth < 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 1');
          return;
        }

        if (this.tableHeight < 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 1');
          return;
        }
        if ((this.tableWidth == null || this.tableWidth == '') && (this.tableHeight == null || this.tableHeight == '')) {
          this._toastr.error('Please enter table size');
          return;
        }
      }
      if (this.tableShowHide == 'rectangle4Seat') {
        if (this.tableWidth < 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 1');
          return;
        }

        if (this.tableHeight < 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 1');
          return;
        }
        if ((this.tableWidth == null || this.tableWidth == '') && (this.tableHeight == null || this.tableHeight == '')) {
          this._toastr.error('Please enter table size');
          return;
        }

      }

      $('#addTable').modal('toggle');
      // add rectangle table with 6 seats 
      if (this.tableShowHide == 'rect6seat') {
        // this.showTable6 = true;
        this.blankRectSeat = false;
        this.arrange6Seats(this.tableShowHide);
      }

      // add rectangle table with 3 seats 
      if (this.tableShowHide == 'rect3seat') {
        this.showTable3 = true;
        this.arrange3Seats(this.tableShowHide);
      }

      // add rectangle table with no seats 
      if (this.tableShowHide == 'rectNoSeat') {
        // this.showTable6 = true;
        this.blankRectSeat = true;
        this.arrange6Seats(this.tableShowHide);
      }

      // add square table with 8 seats 
      if (this.tableShowHide == 'square8seat') {
        this.showSquareTable = true;
        this.blankSquareSeat = false;
        this.arrangeSquare8Seats(this.tableShowHide);
      }

      // add square table with no seats 
      if (this.tableShowHide == 'squareNoSeatTable') {
        this.showSquareTable = true;
        this.blankSquareSeat = true;
        this.arrangeSquare8Seats(this.tableShowHide);
      }
      // add generic table with no seats 
      if (this.tableShowHide == 'genericTable') {
        this.addGenericTable(this.tableShowHide);
      }

      // add circle table with 25 seats 
      if (this.tableShowHide == 'circleSeat') {
        if (this.seatCount > 25) {
          this._toastr.error('Max number of seats for this table is 25');
          return;
        }
        this.blankCircleSeat = false;
        this.showSquareTable = true;
        this.arrangeCircleSeats(this.tableShowHide);
      }

      // add circle table with  no seats 
      if (this.tableShowHide == 'circleNoSeat') {
        if (this.seatCount > 25) {
          this._toastr.error('Max number of seats for this table is 25');
          return;
        }
        this.blankCircleSeat = true;
        this.arrangeCircleSeats(this.tableShowHide);
      }

      // add square table with 4 seats 
      if (this.tableShowHide == 'rectangle4Seat') {

        this.showSquareTable = true;
        this.arrange4SideSeats(this.tableShowHide);
      }
      this.addTableName = this.tableName;
      this.tableName = '';
      this.seatCount = '';
      this.notes = '';
    }
    if (data == "Edit") {
      for (let index = 0; index < this.tableList.length; index++) {
        if (this.editTableName != this.tableName) {
          if (this.tableList[index].name == this.tableName) {
            this._toastr.error('This table name is already exist');
            return;
          }
        }
      }
      if (this.tableName == null || this.tableName == '') {
        this._toastr.error('Please enter table name');
        return;
      }
      //  if (this.seatCount == null || this.seatCount == '') {
      //   this._toastr.error('Please enter table seat');
      //   return;
      // }


      if (this.tableNameValue == 'rect6seat') {

        if (this.seatCount > 100) {
          this._toastr.error('Max number of seats for this table is 100');
          return;
        }
        if (this.seatCount <= 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 2');
          return;
        }

        this.arrange6SeatsEdit();
      }
      if (this.tableNameValue == 'rect3seat') {

        if (this.seatCount > 100) {
          this._toastr.error('Max number of seats for this table is 100');
          return;
        }
        if (this.seatCount <= 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 2');
          return;
        }
        this.arrange3SeatsEdit();
      }
      if (this.tableNameValue == 'square8seat') {
        if (this.seatCount > 100) {
          this._toastr.error('Max number of seats for this table is 100');
          return;
        }
        if (this.seatCount <= 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 2');
          return;
        }
        this.arrangeSquare8SeatsEdit();
      }

      if (this.tableNameValue == 'genericTable') {
        if (this.tableWidth < 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 1');
          return;
        }

        if (this.tableHeight < 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 1');
          return;
        }
        this.editGenericTable();
      }
      if (this.tableNameValue == 'circleSeat') {
        if (this.seatCount > 25) {
          this._toastr.error('Max number of seats for this table is 25');
          return;
        }
        if (this.seatCount <= 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 2');
          return;
        }
        this.editCircleTable();
      }
      if (this.tableNameValue == 'rectangle4Seat') {
        if (this.tableWidth < 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 1');
          return;
        }

        if (this.tableHeight < 1) {
          this._toastr.error('Width / Length / Seat(s) value must be at least 1');
          return;
        }
        this.edit4SideSeats();
      }
    }
  }

  //  Add and edit 6seat table

  arrange6Seats(type) {
    this.seats6 = [];
    this.divideCount = this.seatCount / 2;
    this.table6Width = Math.round(this.divideCount) * 50;
    this.table6Width = this.table6Width.toString() + "px";
    this.seatLeft = -40;
    var bottomSeatCount = this.seatCount - Math.round(this.divideCount);
    if (bottomSeatCount < this.divideCount) {
      this.seatBottomLeft = -15;
    } else {
      this.seatBottomLeft = -40;
    }

    for (let index = 0; index < this.seatCount; index++) {
      if (index < this.divideCount) {
        this.seatLeft = this.seatLeft + 50;
        var seatTop = "-35px";
        var data =
        {
          seatId: "table-1-seat-1",
          left: this.seatLeft.toString() + "px",
          top: seatTop,
        }
      } else {
        this.seatBottomLeft = this.seatBottomLeft + 50;
        var seatTop = "55px";
        var data =
        {
          seatId: "table-1-seat-1",
          left: this.seatBottomLeft.toString() + "px",
          top: seatTop,
        }
      }
      this.seats6.push(data);
    }
    var table6Data = {
      seats: this.seats6,
      tableName: this.tableName,
      tableWidth: this.table6Width,
      blankRectable: this.blankRectSeat ? this.blankRectSeat : ''
    }

    // this.table6Seat.push(table6Data);

    var tableData = {
      name: this.tableName,
      type: type,
      seats_count: this.seatCount,
      hight: 40,
      width: Math.round(this.divideCount) * 50,
      properties: {
        rotation: 0,
        seats: this.seats6
      },
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
      note: this.notes,
      blank_table: this.blankRectSeat ? this.blankRectSeat : '',
    }
    this.createTable(tableData);
  }

  async arrange6SeatsEdit() {
    // this.tableEditData = table;
    // this.tableEditDataIndex = index
    var tableindexing = this.tableEditData[this.tableEditDataIndex]
    var count = tableindexing.properties.seats.length - this.seatCount

    //this.seatCount = tableindexing.properties.seats.length

    this.divideCount = this.seatCount / 2;
    this.table6Width = Math.round(this.divideCount) * 50;
    this.table6Width = this.table6Width.toString() + "px";
    tableindexing.width = Math.round(this.divideCount) * 50;
    tableindexing.name = this.tableName;
    tableindexing.seats_count = this.seatCount;
    tableindexing.note = this.notes;
    if (tableindexing.properties.seats.length > this.seatCount) {
      await this.editunassignSeat(this.seatCount, tableindexing)
      for (let index = 0; index < count; index++) {
        tableindexing.properties.seats = tableindexing.properties.seats.slice(0, -1);
      }


      this.seatLeft = -40;
      var bottomSeatCount = this.seatCount - Math.round(this.divideCount);
      if (bottomSeatCount < this.divideCount) {
        this.seatBottomLeft = -15;
      } else {
        this.seatBottomLeft = -40;
      }
      for (let j = 0; j < tableindexing.properties.seats.length; j++) {
        if (j < this.divideCount) {
          this.seatLeft = this.seatLeft + 50;
          var seatTop = "-35px";
          tableindexing.properties.seats[j].left = this.seatLeft.toString() + "px",
            tableindexing.properties.seats[j].top = seatTop
        } else {
          this.seatBottomLeft = this.seatBottomLeft + 50;
          var seatTop = "55px";
          tableindexing.properties.seats[j].left = this.seatBottomLeft.toString() + "px",
            tableindexing.properties.seats[j].top = seatTop
        }
      }
      // $('#addTable').modal('hide');
    }
    else {
      this.seatLeft = -40;
      var bottomSeatCount = this.seatCount - Math.round(this.divideCount);
      if (bottomSeatCount < this.divideCount) {
        this.seatBottomLeft = -20;
      } else {
        this.seatBottomLeft = -40;
      }
      for (let j = 0; j < this.seatCount; j++) {
        if (j < tableindexing.properties.seats.length) {
          if (j < this.divideCount) {
            this.seatLeft = this.seatLeft + 50;
            var seatTop = "-35px";
            tableindexing.properties.seats[j].left = this.seatLeft.toString() + "px",
              tableindexing.properties.seats[j].top = seatTop
          } else {
            this.seatBottomLeft = this.seatBottomLeft + 50;
            var seatTop = "55px";
            tableindexing.properties.seats[j].left = this.seatBottomLeft.toString() + "px",
              tableindexing.properties.seats[j].top = seatTop
          }
        } else {
          if (j < this.divideCount) {
            this.seatLeft = this.seatLeft + 50;
            var seatTop = "-35px";
            var data =
            {
              seatId: "table-1-seat-1",
              left: this.seatLeft.toString() + "px",
              top: seatTop,
              tableName: this.tableName,
            }
          } else {
            this.seatBottomLeft = this.seatBottomLeft + 50;
            var seatTop = "55px";
            var data =
            {
              seatId: "table-1-seat-1",
              left: this.seatBottomLeft.toString() + "px",
              top: seatTop,
              tableName: this.tableName
            }
          }

          tableindexing.properties.seats.push(data);

        }
      }
    }

    tableindexing.blank_table = tableindexing.blank_table ? true : false
    for (let index = 0; index < tableindexing.properties.seats.length; index++) {
      if (tableindexing.properties.seats[index].guest_id) {
        delete tableindexing.properties.seats[index].first_name
        delete tableindexing.properties.seats[index].guest_id
        delete tableindexing.properties.seats[index].last_name
        delete tableindexing.properties.seats[index].seat_number
        delete tableindexing.properties.seats[index].attendance
        delete tableindexing.properties.seats[index].relationship_name
      }
    }
    this.UpdateTable(tableindexing);
  }
  // Add and Edit 3 seat Table 

  async editunassignSeat(seatcountedit, tableindexing) {
    var guestId = [];
    for (let index = seatcountedit; index < tableindexing.properties.seats.length; index++) {
      if (tableindexing.properties.seats[index].guest_id) {
        guestId.push(tableindexing.properties.seats[index].guest_id);
      }
    }
    var unassignData = {
      event_id: this.eventId,
      wedding_id: this.activeUser.id,
      guest_ids: guestId
    }
    this.unassignSeats(unassignData);
  }

  arrange3Seats(type) {

    this.seats3 = [];
    this.table3Width = Math.round(this.seatCount) * 50;
    this.table3Width = this.table3Width.toString() + "px";
    this.seatLeft = -40;
    this.seatBottomLeft = -40;
    for (let index = 0; index < this.seatCount; index++) {
      this.seatLeft = this.seatLeft + 50;
      var seatTop = "-35px";
      var data =
      {
        seatId: "table-1-seat-1",
        left: this.seatLeft.toString() + "px",
        top: seatTop,
      }
      this.seats3.push(data);
    }
    var table3Data = {
      seats: this.seats3,
      tableName: this.tableName,
      tableWidth: this.table3Width,
    }
    // this.table3Seat.push(table3Data);

    var tableData = {
      name: this.tableName,
      type: type,
      seats_count: this.seatCount,
      hight: 40,
      width: Math.round(this.seatCount) * 50,
      properties: {
        rotation: 0,
        seats: this.seats3
      },
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
      note: this.notes,
    }
    this.createTable(tableData);
  }
  async arrange3SeatsEdit() {
    var tableindexing = this.tableEditData[this.tableEditDataIndex]
    var count = tableindexing.properties.seats.length - this.seatCount
    this.table3Width = Math.round(this.seatCount) * 50;
    this.table3Width = this.table3Width.toString() + "px";
    tableindexing.width = Math.round(this.seatCount) * 50;
    tableindexing.name = this.tableName;
    tableindexing.seats_count = this.seatCount;
    tableindexing.note = this.notes;
    this.seatLeft = -40;
    var seatTop = "-35px";
    if (tableindexing.properties.seats.length > this.seatCount) {
      await this.editunassignSeat(this.seatCount, tableindexing);

      for (let index = 0; index < count; index++) {
        tableindexing.properties.seats = tableindexing.properties.seats.slice(0, -1);
      }
    }
    else {
      for (let j = 0; j < this.seatCount; j++) {
        if (j < tableindexing.properties.seats.length) {
          this.seatLeft = this.seatLeft + 50;
          var seatTop = "-35px";
          tableindexing.properties.seats[j].left = this.seatLeft.toString() + "px",
            tableindexing.properties.seats[j].top = seatTop
        } else {
          this.seatLeft = tableindexing.properties.seats[j - 1].left;
          this.seatLeft = Number(this.seatLeft.slice(0, -2));
          this.seatLeft = this.seatLeft + 50;
          var seatTop = "-35px";
          var data =
          {
            seatId: "table-1-seat-1",
            left: this.seatLeft.toString() + "px",
            top: seatTop,
            tableName: this.tableName,
          }
          tableindexing.properties.seats.push(data);
        }
      }
    }
    for (let index = 0; index < tableindexing.properties.seats.length; index++) {
      if (tableindexing.properties.seats[index].guest_id) {
        delete tableindexing.properties.seats[index].first_name
        delete tableindexing.properties.seats[index].guest_id
        delete tableindexing.properties.seats[index].last_name
        delete tableindexing.properties.seats[index].seat_number
        delete tableindexing.properties.seats[index].attendance
        delete tableindexing.properties.seats[index].relationship_name
      }
    }
    this.UpdateTable(tableindexing);
    $('#addTable').modal('hide');
  }

  // Add Square Table 
  arrangeSquare8Seats(type) {
    this.squareSeats = [];
    let x = this.seatCount;
    var a = x / 4;
    var b = x % 4;

    // width set 
    this.tableSquareWidth = Math.trunc(a) * 50;
    if (b >= 1) this.tableSquareWidth = this.tableSquareWidth + 50;
    this.tableSquareWidth = this.tableSquareWidth.toString() + "px";

    // height set 
    this.tableSquareHeight = Math.trunc(a) * 50;
    if (b >= 2) this.tableSquareHeight = this.tableSquareHeight + 50;
    // console.log(Math.trunc(a), this.tableSquareHeight)
    this.tableSquareHeight = this.tableSquareHeight.toString() + "px";
    var topLeftValue = -40;
    var rightTopValue = -40;
    var bottomLeftValue = -40;
    var leftTopValue = -40;
    var rightleftValue = 51;
    var bottomTop = 51;

    if (this.seatCount >= 20) {
      var rightleftValue = 50.5;
      var bottomTop = 50.5;
    }
    if (this.seatCount > 40) {
      var rightleftValue = 50;
      var bottomTop = 50;
    }
    // position right chair 
    if (b >= 1) {
      var reminder = 1
    } else {
      reminder = 0
    }
    rightleftValue = rightleftValue * (Math.trunc(a) + reminder)

    // position bottom chair 
    if (b >= 2) {
      var reminderbottom = 1
    } else {
      reminderbottom = 0
    }

    bottomTop = bottomTop * (Math.trunc(a) + reminderbottom)

    for (let i = 1; i <= a; i++) {
      var topLeftValue = topLeftValue + 50;
      var rightTopValue = rightTopValue + 50;
      var bottomLeftValue = bottomLeftValue + 50;
      var leftTopValue = leftTopValue + 50;

      var top = {
        top: "-35px",
        left: topLeftValue.toString() + "px",
      }
      var right = {
        top: rightTopValue.toString() + "px",
        left: rightleftValue.toString() + "px",
      }
      var bottom = {
        top: bottomTop.toString() + "px",
        left: bottomLeftValue.toString() + "px",
      }
      var left = {
        top: leftTopValue.toString() + "px",
        left: '-35px'
      }
      this.squareSeats.push(top);
      this.squareSeats.push(right);
      this.squareSeats.push(bottom);
      this.squareSeats.push(left);
    }

    for (let i = 1; i <= b; i++) {
      if (i == 1 && i <= this.seatCount) {
        var topLeftValue = topLeftValue + 50;
        var top = {
          top: '-35px',
          left: topLeftValue.toString() + "px",
        }
        this.squareSeats.push(top);
      }
      if (i == 2 && i <= b) {
        var rightTopValue = rightTopValue + 50;
        var right = {
          top: rightTopValue.toString() + "px",
          left: rightleftValue.toString() + "px",
        }
        this.squareSeats.push(right);
      }
      if (i == 3 && i <= b) {
        var bottomLeftValue = bottomLeftValue + 50;
        var bottom = {
          top: bottomTop.toString() + "px",
          left: bottomLeftValue.toString() + "px",
        }
        this.squareSeats.push(bottom);
      }
      // if (i == 4 && i <= b) {
      //   var left = {
      //     top: '10px',
      //     left: '-35px'
      //   }
      //   this.squareSeats.push(left);
      // }
    }
    var tableSquareData = {
      seats: this.squareSeats,
      tableName: this.tableName,
      tableWidth: this.tableSquareWidth,
      tableHeight: this.tableSquareHeight,
      blanktable: this.blankSquareSeat ? this.blankSquareSeat : '',
    }
    // this.tableSquareSeat.push(tableSquareData);
    var tableData = {
      name: this.tableName,
      type: type,
      seats_count: this.seatCount,
      hight: this.tableSquareHeight,
      width: this.tableSquareWidth,
      properties: {
        seats: this.squareSeats
      },
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
      note: this.notes,
    }
    this.createTable(tableData);
  }
  //  Edit Square Table
  async arrangeSquare8SeatsEdit() {
    var tableindexing = this.tableEditData[this.tableEditDataIndex];
    if (this.seatCount < tableindexing.properties.seats.length) {
      await this.editunassignSeat(this.seatCount, tableindexing);
    }
    this.squareSeats = [];
    let x = this.seatCount;
    var a = x / 4;
    var b = x % 4;
    // width set 
    this.tableSquareWidth = Math.trunc(a) * 50;
    if (b >= 1) this.tableSquareWidth = this.tableSquareWidth + 50;
    this.tableSquareWidth = this.tableSquareWidth.toString() + "px";

    // height set 
    this.tableSquareHeight = Math.trunc(a) * 50;
    if (b >= 2) this.tableSquareHeight = this.tableSquareHeight + 50;
    this.tableSquareHeight = this.tableSquareHeight.toString() + "px";

    tableindexing.width = this.tableSquareWidth;
    tableindexing.hight = this.tableSquareHeight
    tableindexing.name = this.tableName;
    tableindexing.seats_count = this.seatCount;
    tableindexing.note = this.notes;
    var topLeftValue = -40;
    var rightTopValue = -40;
    var bottomLeftValue = -40;
    var leftTopValue = -40;
    var rightleftValue = 51
    var bottomTop = 51

    if (this.seatCount >= 20) {
      var rightleftValue = 50.5;
      var bottomTop = 50.5;
    }
    if (this.seatCount > 40) {
      var rightleftValue = 50;
      var bottomTop = 50;
    }
    // position right chair 
    if (b >= 1) {
      var reminder = 1
    } else {
      reminder = 0
    }
    rightleftValue = rightleftValue * (Math.trunc(a) + reminder)
    // position bottom chair 
    if (b >= 2) {
      var reminderbottom = 1
    } else {
      reminderbottom = 0
    }
    bottomTop = bottomTop * (Math.trunc(a) + reminderbottom)
    for (let i = 1; i <= a; i++) {
      var topLeftValue = topLeftValue + 50;
      var rightTopValue = rightTopValue + 50;
      var bottomLeftValue = bottomLeftValue + 50;
      var leftTopValue = leftTopValue + 50;

      var top = {
        top: "-35px",
        left: topLeftValue.toString() + "px",
      }
      var right = {
        top: rightTopValue.toString() + "px",
        left: rightleftValue.toString() + "px",
      }
      var bottom = {
        top: bottomTop.toString() + "px",
        left: bottomLeftValue.toString() + "px",
      }
      var left = {
        top: leftTopValue.toString() + "px",
        left: '-35px'
      }
      this.squareSeats.push(top);
      this.squareSeats.push(right);
      this.squareSeats.push(bottom);
      this.squareSeats.push(left);
    }

    for (let i = 1; i <= b; i++) {
      if (i == 1 && i <= this.seatCount) {
        var topLeftValue = topLeftValue + 50;
        var top = {
          top: '-35px',
          left: topLeftValue.toString() + "px",
        }
        this.squareSeats.push(top);
      }
      if (i == 2 && i <= b) {
        var rightTopValue = rightTopValue + 50;
        var right = {
          top: rightTopValue.toString() + "px",
          left: rightleftValue.toString() + "px",
        }
        this.squareSeats.push(right);
      }
      if (i == 3 && i <= b) {
        var bottomLeftValue = bottomLeftValue + 50;
        var bottom = {
          top: bottomTop.toString() + "px",
          left: bottomLeftValue.toString() + "px",
        }
        this.squareSeats.push(bottom);
      }
    }
    var tableSquareData = {
      seats: this.squareSeats,
      tableName: this.tableName,
      tableWidth: this.tableSquareWidth,
      tableHeight: this.tableSquareHeight,
    }
    // this.tableSquareSeat.push(tableSquareData);
    // tableindexing = tableSquareData;
    this.tableSquareSeat[this.tableEditDataIndex] = tableSquareData;
    tableindexing.properties.seats = tableSquareData.seats;
    for (let index = 0; index < tableindexing.properties.seats.length; index++) {
      if (tableindexing.properties.seats[index].guest_id) {
        delete tableindexing.properties.seats[index].first_name
        delete tableindexing.properties.seats[index].guest_id
        delete tableindexing.properties.seats[index].last_name
        delete tableindexing.properties.seats[index].seat_number
        delete tableindexing.properties.seats[index].attendance
        delete tableindexing.properties.seats[index].relationship_name
      }
    }

    this.UpdateTable(tableindexing);
    $('#addTable').modal('hide');
  }

  // Add Circle Table 
  arrangeCircleSeats(type) {
    var circleWidth = this.seatCount * 50;
    let radius = circleWidth / (2 * Math.PI);
    this.circleSeats = [];
    let degree = 360 / this.seatCount;
    var rotate = 0;
    var translate = 60
    if (this.seatCount == 4) {
      translate = 45;
    }
    if (this.seatCount == 5) {
      translate = 53;
    }
    for (let index = 0; index < this.seatCount; index++) {
      if (index > 5) {
        translate = translate + 8;
      }
    }

    for (let index = 0; index < this.seatCount; index++) {
      rotate = index * degree;

      var data = {
        rotate: rotate,
        translate: translate
      }
      this.circleSeats.push(data);
    }
    // width set 
    this.tableCircleeWidth = radius * 2;
    this.tableCircleeWidth = this.tableCircleeWidth.toString() + "px";

    // height set 
    this.tableCircleHeight = radius * 2;
    this.tableCircleHeight = this.tableCircleHeight.toString() + "px";

    var tableCircleData = {
      seats: this.circleSeats,
      tableName: this.tableName,
      tableWidth: this.tableCircleeWidth,
      tableHeight: this.tableCircleHeight,
      blanktable: this.blankCircleSeat ? this.blankCircleSeat : '',
    }
    this.tableCircleeSeat.push(tableCircleData);
    var tableData = {
      name: this.tableName,
      type: type,
      seats_count: this.seatCount,
      hight: this.tableCircleHeight,
      width: this.tableCircleeWidth,
      properties: {
        seats: this.circleSeats
      },
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
      note: this.notes,
      blank_table: this.blankCircleSeat ? this.blankCircleSeat : '',
    }
    this.createTable(tableData);
  }

  // edit circle table 
  async editCircleTable() {

    var tableindexing = this.tableEditData[this.tableEditDataIndex];
    var count = tableindexing.properties.seats.length - this.seatCount;
    var circleWidth = this.seatCount * 50;
    let radius = circleWidth / (2 * Math.PI);
    let degree = 360 / this.seatCount;
    var rotate = 0;
    var translate = 60
    if (this.seatCount == 4) {
      translate = 45;
    }
    if (this.seatCount == 5) {
      translate = 53;
    }
    // width set 
    this.tableCircleeWidth = radius * 2;
    this.tableCircleeWidth = this.tableCircleeWidth.toString() + "px";
    tableindexing.width = radius * 2;
    // height set 
    this.tableCircleHeight = radius * 2;
    this.tableCircleHeight = this.tableCircleHeight.toString() + "px";
    tableindexing.hight = radius * 2;

    for (let index = 0; index < this.seatCount; index++) {
      if (index > 5) {
        translate = translate + 8;
      }
    }
    if (this.seatCount > tableindexing.properties.seats.length) {

      for (let index = 0; index < this.seatCount; index++) {
        if (index < tableindexing.properties.seats.length) {
          tableindexing.properties.seats[index].rotate = index * degree;
          tableindexing.properties.seats[index].translate = translate;
        } else {
          rotate = index * degree;

          var data = {
            rotate: rotate,
            translate: translate
          }
          tableindexing.properties.seats.push(data);
        }
      }
    } else {
      await this.editunassignSeat(this.seatCount, tableindexing);
      for (let index = 0; index < count; index++) {
        tableindexing.properties.seats = tableindexing.properties.seats.slice(0, -1);
      }
      for (let index = 0; index < tableindexing.properties.seats.length; index++) {
        tableindexing.properties.seats[index].rotate = index * degree;
        tableindexing.properties.seats[index].translate = translate;
      }
    }
    // tableindexing.tableHeight = this.tableCircleHeight;
    // tableindexing.tableWidth = this.tableCircleeWidth;
    // tableindexing.tableName = this.tableName;
    tableindexing.width = this.tableCircleeWidth;
    tableindexing.hight = this.tableCircleHeight;
    tableindexing.name = this.tableName;
    tableindexing.seats_count = this.seatCount;
    tableindexing.note = this.notes;
    tableindexing.blank_table = tableindexing.blank_table ? true : false;

    for (let index = 0; index < tableindexing.properties.seats.length; index++) {
      if (tableindexing.properties.seats[index].guest_id) {
        delete tableindexing.properties.seats[index].first_name
        delete tableindexing.properties.seats[index].guest_id
        delete tableindexing.properties.seats[index].last_name
        delete tableindexing.properties.seats[index].seat_number
        delete tableindexing.properties.seats[index].attendance
        delete tableindexing.properties.seats[index].relationship_name
      }
    }
    this.UpdateTable(tableindexing);
  }

  // Add Generic Table with width and height

  addGenericTable(type) {
    var Height: any = this.tableHeight * 50;
    var Width: any = this.tableWidth * 50;
    Height = Height.toString() + "px";
    Width = Width.toString() + "px";

    var data = {
      tableName: this.tableName,
      height: Height,
      width: Width
    }
    this.tableGenericSeats.push(data);
    var tableData = {
      name: this.tableName,
      type: type,
      seats_count: '',
      hight: Height,
      width: Width,
      properties: {
        rotation: 0,
        seats: ''
      },
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
      note: this.notes,
      blankTable: '',
    }
    this.createTable(tableData);
  }

  editGenericTable() {
    var tableindexing = this.tableEditData[this.tableEditDataIndex];
    var Height: any = this.tableHeight * 50;
    var Width: any = this.tableWidth * 50;
    Height = Height.toString() + "px";
    Width = Width.toString() + "px";
    tableindexing.hight = this.tableHeight * 50;
    tableindexing.width = this.tableWidth * 50;
    tableindexing.note = this.notes;
    tableindexing.name = this.tableName;
    var data = {
      tableName: this.tableName,
      height: Height,
      width: Width
    }
    // this.tableGenericSeats[this.tableEditDataIndex] = data;
    // tableindexing[this.tableEditDataIndex] = data;
    this.UpdateTable(tableindexing);
  }

  //  Add rectangle 4 sided table 
  table4Width: any = 120;
  tableRectHeight: any = 100;
  rectSeatCount: any;
  leftSideSeats: any;
  rightSideSeats: any;
  leftSeatTop: any;
  rightSeatTop: any;
  seatTopValue: any;
  seatTop: any;
  arrange4SideSeats(type) {
    this.rectSeatCount = this.tableHeight * 2;
    this.seatCount = this.tableWidth * 2;
    this.seats6 = [];
    this.divideCount = this.seatCount / 2;
    this.table4Width = Math.round(this.divideCount) * 50;
    this.table4Width = this.table4Width.toString() + "px";
    this.tableRectHeight = (this.tableHeight * 50).toString() + "px"
    this.seatTopValue = 5;
    this.seatLeft = -40;
    this.leftSeatTop = -40;
    this.rightSeatTop = -40;
    var bottomSeatCount = this.seatCount - Math.round(this.divideCount);
    if (bottomSeatCount < this.divideCount) {
      this.seatBottomLeft = -15;
    } else {
      this.seatBottomLeft = -40;
    }

    for (let index = 0; index < this.seatCount; index++) {
      if (index < this.divideCount) {
        this.seatLeft = this.seatLeft + 50;
        var seatTop = "-35px";
        var data =
        {
          seatId: "table-1-seat-1",
          left: this.seatLeft.toString() + "px",
          top: seatTop,
        }
      } else {
        this.seatBottomLeft = this.seatBottomLeft + 50;
        this.seatTop = this.tableHeight * 50 + this.seatTopValue;
        var data =
        {
          seatId: "table-1-seat-1",
          left: this.seatBottomLeft.toString() + "px",
          top: this.seatTop.toString() + "px",
        }
      }


      this.seats6.push(data);
    }

    for (let i = 0; i < this.rectSeatCount; i++) {
      if (i < this.rectSeatCount / 2) {
        this.leftSideSeats = "-35px";
        this.leftSeatTop = this.leftSeatTop + 50;
        var leftData =
        {
          seatId: "table-1-seat-1",
          left: this.leftSideSeats,
          top: this.leftSeatTop.toString() + "px",
        }
      } else {
        this.rightSideSeats = "-35px";
        this.rightSeatTop = this.rightSeatTop + 50;
        var leftData =
        {
          seatId: "table-1-seat-1",
          left: this.rightSideSeats,
          top: this.rightSeatTop.toString() + "px",
        }
      }
      this.seats6.push(leftData);
    }
    var table6Data = {
      seats: this.seats6,
      tableName: this.tableName,
      tableWidth: this.table4Width,
      tableHeight: this.tableRectHeight,
      blankRectable: this.blankRectSeat ? this.blankRectSeat : ''
    }
    // this.table6Seat.push(table6Data);
    var tableData = {
      name: this.tableName,
      type: type,
      seats_count: this.seatCount + this.rectSeatCount,
      hight: this.tableRectHeight,
      width: Math.round(this.divideCount) * 50,
      properties: {
        rotation: 0,
        seats: this.seats6,
        leftrightSeats: this.rectSeatCount
      },
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
      note: this.notes,
      blank_table: this.blankRectSeat ? this.blankRectSeat : '',
    }
    this.createTable(tableData);
  }
  divideHeight: any;
  async edit4SideSeats() {
    var tableindexing = this.tableEditData[this.tableEditDataIndex];
    this.rectSeatCount = this.tableHeight * 2;
    this.seatCount = this.tableWidth * 2;
    this.seats6 = [];
    this.divideCount = this.seatCount / 2;
    this.divideHeight = this.rectSeatCount / 2;
    this.table4Width = Math.round(this.divideCount) * 50;
    this.table4Width = this.table4Width.toString() + "px";
    this.tableRectHeight = (this.tableHeight * 50).toString() + "px"
    var count = tableindexing.properties.seats.length - (this.seatCount + this.rectSeatCount);

    // this.rectSeatCount = this.tableHeight * 2;
    // this.seatCount = this.seatCount - this.rectSeatCount;
    var topBottomSeatCount = this.seatCount - this.rectSeatCount;
    //   this.divideCount = topBottomSeatCount / 2;
    //   this.table4Width = Math.round(this.divideCount) * 50;
    //   this.table4Width = this.table4Width.toString() + "px";
    //   this.tableRectHeight = this.tableRectHeight.toString() + "px"
    tableindexing.width = Math.round(this.divideCount) * 50;
    tableindexing.hight = (this.tableHeight * 50).toString() + "px";
    tableindexing.name = this.tableName;
    tableindexing.note = this.notes;
    this.seatTopValue = 5;
    this.seatLeft = -40;
    this.leftSeatTop = -40;
    this.rightSeatTop = -40;
    var bottomSeatCount = this.seatCount - Math.round(this.divideCount);
    if (bottomSeatCount < this.divideCount) {
      this.seatBottomLeft = -15;
    } else {
      this.seatBottomLeft = -40;
    }
    if (count > 0) {
      if (tableindexing.properties.seats.length > this.seatCount) {
        await this.editunassignSeat(this.seatCount, tableindexing)
        for (let index = 0; index < count; index++) {
          tableindexing.properties.seats = tableindexing.properties.seats.slice(0, -1);
        }
        for (let j = 0; j < this.seatCount; j++) {
          if (j < this.divideCount) {

            this.seatLeft = this.seatLeft + 50;
            var seatTop = "-35px";
            tableindexing.properties.seats[j].left = this.seatLeft.toString() + "px",
              tableindexing.properties.seats[j].top = seatTop
          } else {
            this.seatBottomLeft = this.seatBottomLeft + 50;
            this.seatTop = this.tableHeight * 50 + this.seatTopValue;
            tableindexing.properties.seats[j].left = this.seatBottomLeft.toString() + "px",
              tableindexing.properties.seats[j].top = this.seatTop.toString() + "px"
          }
        }

        for (let index = this.seatCount; index < tableindexing.properties.seats.length; index++) {

          if (index < (this.seatCount + this.divideHeight)) {
            this.leftSeatTop = this.leftSeatTop + 50;
            tableindexing.properties.seats[index].left = "-35px";
            tableindexing.properties.seats[index].top = this.leftSeatTop.toString() + "px";

          } else {
            this.rightSeatTop = this.rightSeatTop + 50;
            tableindexing.properties.seats[index].left = "-35px";
            tableindexing.properties.seats[index].top = this.rightSeatTop.toString() + "px";
          }
        }
      }
    } else {
      this.seatLeft = -40;
      var bottomSeatCount = this.seatCount - Math.round(this.divideCount);
      if (bottomSeatCount < this.divideCount) {
        this.seatBottomLeft = -20;
      } else {
        this.seatBottomLeft = -40;
      }
      for (let j = 0; j < this.seatCount; j++) {
        if (tableindexing.properties.seats[j]) {
          if (j < this.divideCount) {
            this.seatLeft = this.seatLeft + 50;
            var seatTop = "-35px";
            tableindexing.properties.seats[j].left = this.seatLeft.toString() + "px",
              tableindexing.properties.seats[j].top = seatTop
          } else {
            this.seatBottomLeft = this.seatBottomLeft + 50;
             seatTop = this.tableHeight * 50 + this.seatTopValue;
            tableindexing.properties.seats[j].left = this.seatBottomLeft.toString() + "px",
              tableindexing.properties.seats[j].top = seatTop.toString() + "px"
          }
        } else {
          this.seatBottomLeft = this.seatBottomLeft + 50;
           seatTop = this.tableHeight * 50 + this.seatTopValue;
          var data =
          {
            seatId: "table-1-seat-1",
            left: this.seatBottomLeft.toString() + "px",
            top: seatTop,
            tableName: this.tableName
          }
          tableindexing.properties.seats.push(data);
        }
      }
      for (let i = this.seatCount; i < (this.seatCount + this.rectSeatCount); i++) {        
        if(tableindexing.properties.seats[i]){
          if (i <  (this.seatCount + this.divideHeight)) {
            this.leftSeatTop = this.leftSeatTop + 50;
            tableindexing.properties.seats[i].left = "-35px"; 
            tableindexing.properties.seats[i].top = this.leftSeatTop.toString() + "px";
          }else {
            this.rightSeatTop = this.rightSeatTop + 50;
            tableindexing.properties.seats[i].left = "-35px";
            tableindexing.properties.seats[i].top = this.rightSeatTop.toString() + "px";
          }
        }else{
          if (i <  (this.seatCount + this.divideHeight)) {    
            this.leftSideSeats = "-35px";
            this.leftSeatTop = this.leftSeatTop + 50;
            var leftData =
            {
              seatId: "table-1-seat-1",
              left: this.leftSideSeats,
              top: this.leftSeatTop.toString() + "px",
            }
          } else {
            this.rightSideSeats = "-35px";
            this.rightSeatTop = this.rightSeatTop + 50;
            var leftData =
            {
              seatId: "table-1-seat-1",
              left: this.rightSideSeats,
              top: this.rightSeatTop.toString() + "px",
            }
          }
          tableindexing.properties.seats.push(leftData);
        }
}
    }
    // var bottomSeatCount = topBottomSeatCount - Math.round(this.divideCount);
    // if (bottomSeatCount < this.divideCount) {
    //   this.seatBottomLeft = -15;
    // } else {
    //   this.seatBottomLeft = -40;
    // }
    // if (tableindexing.properties.seats.length > this.seatCount) {
    //   await this.editunassignSeat(this.seatCount, tableindexing)
    //   for (let index = 0; index < count; index++) {
    //     tableindexing.properties.seats = tableindexing.properties.seats.slice(0, -1);
    //   }
    //   for (let j = 0; j < topBottomSeatCount; j++) {        
    //     if (j < this.divideCount) {
    //       this.seatLeft = this.seatLeft + 50;
    //       var seatTop = "-35px";
    //       tableindexing.properties.seats[j].left = this.seatLeft.toString() + "px",
    //         tableindexing.properties.seats[j].top = seatTop
    //     } else {          
    //       this.seatBottomLeft = this.seatBottomLeft + 50;
    //       var seatTop = "105px";
    //       tableindexing.properties.seats[j].left = this.seatBottomLeft.toString() + "px",
    //         tableindexing.properties.seats[j].top = seatTop
    //     }
    //   }

    //   for (let i = topBottomSeatCount; i < tableindexing.properties.seats.length; i++) {
    //     if (i <  (topBottomSeatCount + 2)) {
    //       this.leftSeatTop = this.leftSeatTop + 50;
    //       tableindexing.properties.seats[i].left = "-35px"; 
    //       tableindexing.properties.seats[i].top = this.leftSeatTop.toString() + "px";
    //     } else {
    //       this.rightSeatTop = this.rightSeatTop + 50;
    //       tableindexing.properties.seats[i].left = "-35px";
    //       tableindexing.properties.seats[i].top = this.rightSeatTop.toString() + "px";
    //     }
    //   }
    // } else {
    //   this.seatLeft = -40;
    //   var bottomSeatCount = this.seatCount - Math.round(this.divideCount);
    //   if (bottomSeatCount < this.divideCount) {
    //     this.seatBottomLeft = -20;
    //   } else {
    //     this.seatBottomLeft = -40;
    //   }      
    //   for (let j = 0; j < topBottomSeatCount; j++) {
    //     if(tableindexing.properties.seats[j]){
    //       if(j < this.divideCount){
    //         this.seatLeft = this.seatLeft + 50;
    //         var seatTop = "-35px";
    //         tableindexing.properties.seats[j].left = this.seatLeft.toString() + "px",
    //         tableindexing.properties.seats[j].top = seatTop
    //       }else{
    //         this.seatBottomLeft = this.seatBottomLeft + 50;
    //         var seatTop = "105px";
    //         tableindexing.properties.seats[j].left = this.seatBottomLeft.toString() + "px",
    //         tableindexing.properties.seats[j].top = seatTop
    //       }
    //     }else{
    //       this.seatBottomLeft = this.seatBottomLeft + 50;
    //         var seatTop = "105px";
    //         var data =
    //         {
    //           seatId: "table-1-seat-1",
    //           left: this.seatBottomLeft.toString() + "px",
    //           top: seatTop,
    //           tableName: this.tableName
    //         }            
    //         tableindexing.properties.seats.push(data);
    //     }
    //   }
    //   for (let i = topBottomSeatCount; i < topBottomSeatCount + 4; i++) {        
    //     if(tableindexing.properties.seats[i]){
    //       if (i <  (topBottomSeatCount + 2)) {
    //         this.leftSeatTop = this.leftSeatTop + 50;
    //         tableindexing.properties.seats[i].left = "-35px"; 
    //         tableindexing.properties.seats[i].top = this.leftSeatTop.toString() + "px";
    //       }else {
    //         this.rightSeatTop = this.rightSeatTop + 50;
    //         tableindexing.properties.seats[i].left = "-35px";
    //         tableindexing.properties.seats[i].top = this.rightSeatTop.toString() + "px";
    //       }
    //     }else{
    //       if (i <  (topBottomSeatCount + 2)) {    
    //         this.leftSideSeats = "-35px";
    //         this.leftSeatTop = this.leftSeatTop + 50;
    //         var leftData =
    //         {
    //           seatId: "table-1-seat-1",
    //           left: this.leftSideSeats,
    //           top: this.leftSeatTop.toString() + "px",
    //         }
    //       } else {
    //         this.rightSideSeats = "-35px";
    //         this.rightSeatTop = this.rightSeatTop + 50;
    //         var leftData =
    //         {
    //           seatId: "table-1-seat-1",
    //           left: this.rightSideSeats,
    //           top: this.rightSeatTop.toString() + "px",
    //         }
    //       }
    //       tableindexing.properties.seats.push(leftData);

    //     }

    //   }
    // }
    tableindexing.blank_table = tableindexing.blank_table ? true : false
    tableindexing.properties.leftrightSeats = this.rectSeatCount

    for (let index = 0; index < tableindexing.properties.seats.length; index++) {
      if (tableindexing.properties.seats[index].guest_id) {
        delete tableindexing.properties.seats[index].first_name
        delete tableindexing.properties.seats[index].guest_id
        delete tableindexing.properties.seats[index].last_name
        delete tableindexing.properties.seats[index].seat_number
        delete tableindexing.properties.seats[index].attendance
        delete tableindexing.properties.seats[index].relationship_name
      }
    }
    this.UpdateTable(tableindexing);
  }
  // Add square 4 seat Table 
  arrangeRectangle4Seat() {
    this.rectangle4Seats = [];
    let x = this.seatCount;
    var a = x / 4;
    var b = x % 4;

    // width set 
    this.tableSquare4eWidth = Math.trunc(a) * 100;
    if (b >= 1) this.tableSquare4eWidth = this.tableSquare4eWidth + 50;
    this.tableSquare4eWidth = this.tableSquare4eWidth.toString() + "px";

    // height set 
    this.tableSquare4Height = Math.trunc(a) * 50;
    if (b >= 2) this.tableSquare4Height = this.tableSquare4Height + 50;
    this.tableSquare4Height = this.tableSquare4Height.toString() + "px";


    var topLeftValue = -20;
    var rightTopValue = -12;
    var bottomLeftValue = -20;
    var leftTopValue = -12;
    var rightleftValue = 152.5
    var bottomTop = 42.8

    // position right chair 
    if (b >= 1) {
      var reminder = 1
    } else {
      reminder = 0
    }
    rightleftValue = rightleftValue * (Math.trunc(a) + reminder)

    // position bottom chair 
    if (b >= 2) {
      var reminderbottom = 1
    } else {
      reminderbottom = 0
    }

    bottomTop = bottomTop * (Math.trunc(a) + reminderbottom)

    for (let i = 1; i <= a; i++) {
      // var topLeftValue = topLeftValue + 80;
      // var rightTopValue = rightTopValue + 18;
      // var bottomLeftValue = bottomLeftValue + 80;
      // var leftTopValue = leftTopValue + 18;
      var topLeftValue = 60;
      var rightTopValue = 10;
      var bottomLeftValue = 60;
      var leftTopValue = 10;
      var rightleftValue = 152.5
      var bottomTop = 53

      var top = {
        top: "-35px",
        left: topLeftValue.toString() + "px",
      }
      var right = {
        top: rightTopValue.toString() + "px",
        left: rightleftValue.toString() + "px",
      }
      var bottom = {
        top: bottomTop.toString() + "px",
        left: bottomLeftValue.toString() + "px",
      }
      var left = {
        top: leftTopValue.toString() + "px",
        left: '-35px'
      }
      this.rectangle4Seats.push(top);
      this.rectangle4Seats.push(right);
      this.rectangle4Seats.push(bottom);
      this.rectangle4Seats.push(left);
    }

    for (let i = 1; i <= b; i++) {
      if (i == 1 && i <= this.seatCount) {
        var topLeftValue = topLeftValue + 50;
        var top = {
          top: '-35px',
          left: topLeftValue.toString() + "px",
        }
        this.rectangle4Seats.push(top);
      }
      if (i == 2 && i <= b) {
        var rightTopValue = rightTopValue + 50;
        var right = {
          top: rightTopValue.toString() + "px",
          left: rightleftValue.toString() + "px",
        }
        this.rectangle4Seats.push(right);
      }
      if (i == 3 && i <= b) {
        var bottomLeftValue = bottomLeftValue + 50;
        var bottom = {
          top: bottomTop.toString() + "px",
          left: bottomLeftValue.toString() + "px",
        }
        this.rectangle4Seats.push(bottom);
      }
      // if (i == 4 && i <= b) {
      //   var left = {
      //     top: '10px',
      //     left: '-35px'
      //   }
      //   this.squareSeats.push(left);
      // }
    }
    var tableSquare4Data = {
      seats: this.rectangle4Seats,
      tableName: this.tableName,
      tableWidth: this.tableSquare4eWidth,
      tableHeight: this.tableSquare4Height
    }
    this.tableRectangle4Seat.push(tableSquare4Data);
  }

  // table drag and drop 
  onDragMove(ev) {

  }

  onDragStarted(ev, table) {
  }
  onDragEnded(ev, tableName, table, index, sizeType) {
    var parentPosition = document.getElementById('pdfTable').getBoundingClientRect();
    this.sizeType = sizeType;
    this.tableEditData = table;
    this.tableName = this.tableEditData[index].name;
    this.tableEditDataIndex = index;
    this.tableNameValue = tableName;
    var rightposition = (document.getElementById(this.tableName).getBoundingClientRect().right + 62) - parentPosition.left - 50

    this.shiftX = document.getElementById(this.tableName).getBoundingClientRect().left;
    this.shiftY = document.getElementById(this.tableName).getBoundingClientRect().top;
    var tableRightPosition = document.getElementById(this.tableName).getBoundingClientRect().right;
    this.tableEditData[index].properties.left = this.shiftX - parentPosition.left;
    this.tableEditData[index].properties.top = this.shiftY - parentPosition.top;
    this.tableEditData[index].properties.right = tableRightPosition - parentPosition.left - 100;


    var tableindexing = this.tableEditData[index];
    if (tableName != 'genericTable') {
      for (let index = 0; index < tableindexing.properties.seats.length; index++) {
        if (tableindexing.properties.seats[index].guest_id) {
          delete tableindexing.properties.seats[index].first_name
          delete tableindexing.properties.seats[index].guest_id
          delete tableindexing.properties.seats[index].last_name
          delete tableindexing.properties.seats[index].seat_number
          delete tableindexing.properties.seats[index].attendance
          delete tableindexing.properties.seats[index].relationship_name
        }
      }
    }

    var tableEditData = {
      id: this.tableEditData[index].id,
      name: this.tableEditData[index].name,
      type: this.tableEditData[index].type,
      seats_count: this.tableEditData[index].seats_count,
      hight: this.tableEditData[index].hight,
      width: this.tableEditData[index].width,
      properties: this.tableEditData[index].properties,
      note: this.tableEditData[index].note,
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
      blank_table: this.tableEditData[index].blank_table ? this.tableEditData[index].blank_table : '',
    }
    setTimeout(() => {
      this.UpdateTable(tableEditData);
    }, 100);
  }


  clone;
  cloneId;
  dragStart(ev, allGroupEvent) {
    this.guestId = allGroupEvent;
    this.startDrag = false;
    this.dropArray = allGroupEvent;
    ev.dataTransfer.effectsAllowed = 'copy';
    ev.dataTransfer.setData('text', ev.target.getAttribute('id'));
    const img = new Image();
    img.src = "assets/images/new_user.png";
    ev.dataTransfer.setDragImage(img, 10, 10);
    this.clone = ev.target.cloneNode(true);
    this.cloneId = ev.target.getAttribute('id');
    this.clone.className += ' ' + 'selected';
    //ev.target.parentNode.appendChild(this.clone);
    return true;
  }

  dragEnter(event) {
    setTimeout(() => {
      this.startDrag = false;
    }, 1000);
    event.preventDefault();
    return true;
  }

  dragOver(ev, tableSeatNumber) {
    this.seatNumber = tableSeatNumber;
    ev.preventDefault();
    return false;
  }
  data: any;

  dragDrop(ev, table) {
    this.tableId = table.id;
    setTimeout(() => {
      this.startDrag = true;
    }, 1000);
    var src = ev.dataTransfer.getData('text');
    this.clone.className += ' ' + 'cloned';
    this.clone.id = this.cloneId + '_cloned';
    //const element = document.getElementById(this.cloneId + '_cloned');
    // element.remove();
    ev.target.appendChild(document.getElementById(src));
    // document.getElementById('src').removeAttribute('draggable');
    ev.stopPropagation();

    // for (let j = 0; j < this.tableList.length; j++) {
    //   for (let k = 0; k < this.tableList[j].properties.seats.length; k++) {
    //     if(this.tableList[j].properties.seats[k].guest_id)    {

    //       if(this.tableList[j].properties.seats[k].guest_id == this.guestId)
    //       console.log(this.tableList[j].properties.seats[k].guest_id,this.guestId)

    //        //delete this.tableList[j].properties.seats[k].first_name
    //       //delete this.tableList[j].properties.seats[k].guest_id
    //       // delete this.tableList[j].properties.seats[k].last_name
    //       // delete this.tableList[j].properties.seats[k].seat_number
    //       console.log(this.tableList[j].properties.seats[k])

    //     }    
    //   }
    // }
    // return;

    for (let index = 0; index < this.unassignGuestList.length; index++) {
      if (this.unassignGuestList[index].table_id && this.unassignGuestList[index].seat_number >= 0) {
        if (this.unassignGuestList[index].id == this.guestId) {
          var unassignData = {
            event_id: this.eventId,
            wedding_id: this.activeUser.id,
            guest_ids: [this.guestId]
          }
          this.unassignSeats(unassignData);
        }
      }

    }
    var data = {
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
      guest_id: this.guestId,
      table_id: this.tableId,
      seat_number: this.seatNumber
    }
    this.assignSeats(data);
    return false;
  }


  // rotate table 
  rotateTable(tableName, table, index, sizeType) {
    this.sizeType = sizeType;
    this.tableEditData = table;
    this.tableName = this.tableEditData[index].name;
    this.tableEditDataIndex = index;
    this.tableNameValue = tableName;
    this.tableRotate = table.tableName;
    this.RotateValue = this.tableEditData[index].properties.rotation;
    this.RotateValue = this.RotateValue + 90
    if (this.RotateValue >= 360) {
      this.RotateValue = 0;
    }
    this.tableEditData[index].properties.translateY = '0';
    if (this.RotateValue == 90 || this.RotateValue == 270) {
      const translateY = this.tableEditData[index].width / 2;
      if (this.RotateValue > 180) {
        this.tableEditData[index].properties.translateY = translateY * -1 + 40;
      } else {
        this.tableEditData[index].properties.translateY = translateY - 40;
      }
    }

    // this.tableRotateValue = 'rotate' + '(' + (this.RotateValue) + 'deg' + ')';
    // let element = document.getElementById(this.tableRotate)
    // var rect = element.getBoundingClientRect();
    // this.tableSeatValue = [];
    // var divValue = document.getElementById(this.tableRotate).children;
    // for (let index = 1; index < divValue.length - 1; index++) {
    //   const tableValue = divValue[index].children[0].children[0];
    //   if (tableValue != undefined) {
    //     const tableDataValue = divValue[index].children[0].children[0].children[1].innerHTML;
    //     this.tableSeatValue.push(tableDataValue);
    //     this.uniqueChars = [...new Set(this.tableSeatValue)];
    //   } else {
    //   }
    // }
    this.tableEditData[index].properties.rotation = this.RotateValue;
    var tableindexing = this.tableEditData[index];
    if (tableName != 'genericTable') {
      for (let index = 0; index < tableindexing.properties.seats.length; index++) {
        if (tableindexing.properties.seats[index].guest_id) {
          delete tableindexing.properties.seats[index].first_name
          delete tableindexing.properties.seats[index].guest_id
          delete tableindexing.properties.seats[index].last_name
          delete tableindexing.properties.seats[index].seat_number
          delete tableindexing.properties.seats[index].attendance
          delete tableindexing.properties.seats[index].relationship_name
        }
      }
    }
    var tableEditData = {
      id: this.tableEditData[index].id,
      name: this.tableEditData[index].name,
      type: this.tableEditData[index].type,
      seats_count: this.tableEditData[index].seats_count,
      hight: this.tableEditData[index].hight,
      width: this.tableEditData[index].width,
      properties: this.tableEditData[index].properties,
      note: this.tableEditData[index].note,
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
      blank_table: this.tableEditData[index].blank_table ? this.tableEditData[index].blank_table : '',
    }

    this.UpdateTable(tableEditData);
    this.getEventGuestList();
  }


  dragDropUnassignGuest(event, guest) {
    var unassignData = {
      event_id: this.eventId,
      wedding_id: this.activeUser.id,
      guest_ids: [this.guestId]
    }
    this.unassignSeats(unassignData);
    this.getTableList();
    this.getEventGuestList();

  }
  // delete table 

  deleteTable(deleteTableName, table, index) {
    // this.tableShowHide = table;
    this.removeHeading = 'Remove table';
    this.removeText = 'Are you sure you want to delete this table?';
    $('#DeleteTable').modal('toggle');
    this.tableobject = table;
    this.indexValue = index;
    this.deleteTableNameValue = deleteTableName;
  }
  submitDeleteTable() {
    if (this.deleteTableNameValue == 'rect6seat' || this.deleteTableNameValue == 'rect3seat' || this.deleteTableNameValue == 'rectNoSeat' || this.deleteTableNameValue == 'circleNoSeat' || this.deleteTableNameValue == 'square8seat' ||
      this.deleteTableNameValue == 'genericTable' || this.deleteTableNameValue == 'rectangle4Seat') {
      this.seatingChartService.deleteTable(this.tableobject.id).subscribe(res => {
        this.getTableList();
        this.getEventGuestList();
        this._toastr.success('Table deleted Successfully.');
      }, error => {
        this._toastr.error(error.message);
      })
      // this.resetGuestList(this.tableobject);
      // this.table6Seat.splice(this.indexValue, 1);
    }
    if (this.deleteTableNameValue == 'circleSeat') {
      this.seatingChartService.deleteTable(this.tableobject.id).subscribe(res => {
        this.getTableList();
        this.getEventGuestList();
        this._toastr.success('Table deleted Successfully.');
      }, error => {
        this._toastr.error(error.message);
      })
      // this.resetGuestList(this.tableobject);
      // this.tableCircleeSeat.splice(this.indexValue, 1);
    }
    // this.table3Seat.splice(this.indexValue, 1);
    $('#DeleteTable').modal('hide');
  }

  resetGuestList(table) {
    var childDivs = document.getElementById(table.name).getElementsByClassName('seat');
    for (let index = 0; index < childDivs.length; index++) {
      var childDiv = childDivs[index].getElementsByTagName('div');
      if (childDiv[0] != undefined) {
        var src1 = childDiv[0].id;
        src1 = src1.slice(0, src1.lastIndexOf("_"));
        document.getElementById(src1).appendChild(childDiv[0]);
      }
    }
  }

  // edit table 

  editTable(tableName, table, index, sizeType) {
    this.tableShowHide = tableName;
    // }
    this.sizeType = sizeType;
    this.tableEditData = table;
    this.editTableName = this.tableEditData[index].name
    this.tableName = this.tableEditData[index].name;
    this.tableEditDataIndex = index;
    this.tableNameValue = tableName;
    this.tableaddEdit = "Edit"
    this.seatCount = this.tableEditData[index].seats_count;
    this.notes = this.tableEditData[index].note;
    this.tableHeight = this.tableEditData[index].hight / 50;
    this.tableWidth = this.tableEditData[index].width / 50;
    $('#addTable').modal('show');
  }

  searchGuest(e: any) {
    let searchText: string = e.target.value.toLowerCase();
    let newArr: any[] = [];
    this.EventGuestList.forEach((elem: any) => {
      if (elem.first_name || elem.last_name) {
        var s = elem.first_name.toLowerCase();
        var c = elem.last_name.toLowerCase();
        if (s.includes(searchText) || c.includes(searchText)) {
          newArr.push(elem);
        }
      }
    });
    var grouped = this.groupBy(newArr, false, (relation: { relationship_name: any; }) => relation.relationship_name);
    this.allGuest = this.mapEntries(grouped);
    this.eventItemsArray = this.eventItemsArray;
    // if (this.tab == 2 || this.tab == 5) {
    //   this.searchMenu(searchText);
    // }
  }
  HideCollapse() {
    this.collapseShow = false;
    this.collapsehide = true;
  }
  showCollapse() {
    this.collapseShow = true;
    this.collapsehide = false;
  }
  // add guest code 
  // add guest 

  addNewTabChange(e: any) {
    this.addNewGuest = !e;
  }
  async resetAddGuest() {
    this.guests = [{
      fname: '',
      lname: '',
      age: 1
    }];
    this.selectAge = [];
    this.selectAge[0] = !this.selectAge[0];
    this.selectHotel = [];
    this.selectHotel[1] = !this.selectHotel[1];

    await this.checkedEvent(this.allEvent);
    this.email = null;
    this.selectedRelation = null;
    this.pnoneNo = null;
    this.mobileNo = null;
    this.address = null;
    this.city = null;
    this.state = null;
    this.zip = null;
    this.guestOf = null;
  }

  async getEventList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var eventList = await this.guestlistService.getEventList(data).toPromise();
    this.allEvent = eventList.data;
    this.allEventData = eventList.data;
    this.filterEvent = this.allEventData.filter((e) => e.is_seating_chart);
    this.eventId = this.filterEvent[0].id
    await this.checkedEvent(this.allEvent);
    await this.getTableList();
    await this.getEventGuestList();


    return eventList.data;
  }

  selectEvent(event) {
    this.eventId = event.target.value;
    this.getEventGuestList();
    this.getTableList();
    setTimeout(() => {
      this.headTable();
    }, 1000)
  }

  async getGuestList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
      search_value: '',
      page_size: 10,
      start_index: 0
    };
    var guestList = await this.guestlistService.getGuestList(data).toPromise();
    this.apiGuestList = guestList.data;
    var grouped = this.groupBy(this.apiGuestList, false, (relation: { relationship_name: any; }) => relation.relationship_name);
    this.allGuest = this.mapEntries(grouped);
  }
  mapEntries(grouped: any) { return Array.from(grouped.entries()); }

  groupBy(list: any, checked: boolean, keyGetter: any) {
    const map = new Map();
    var temp = [];
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      const data = {
        item: item,
        showEventAttend: null,
        isChecked: checked

      };
      temp.push(data);
      if (!collection) {
        map.set(key, [data]);
      } else {
        collection.push(data);
      }
    });
    this.modifyGuest = temp;
    return map;
  }
  //     list.splice( index, 0, event.data );
  //   }
  // }

  saveGuest(type: any) {
    if (this.guests[0].fname.trim() == '') {
      this._toastr.error('Please enter first name');
      return;
    }
    if (this.guests[0].lname.trim() == '') {
      this._toastr.error('Please enter last name');
      return;
    }
    if (!this.selectedRelation || this.selectedRelation == '') {
      this._toastr.error('Please select relationship name');
      return;
    }
    if (!this.guestOf || this.guestOf == null) {
      this._toastr.error('Please select guest of');
      return;
    }
    var selectedEvents = this.events.filter((e) => e.isChecked == true);
    if (selectedEvents.length == 0) {
      this._toastr.error('Please select at least one event');
      return;
    }
    const first_name = this.guests[0].fname.trim();
    const last_name = this.guests[0].lname.trim();
    const age = this.guests[0].age;
    var g = this.guests;
    g.splice(0, 1);
    let data = {
      additionalGuestList: g,
      wedding_id: this.activeUser.id,
      relationship_id: this.selectedRelation,
      first_name: first_name,
      last_name: last_name,
      email: this.email,
      phone_number: this.pnoneNo,
      mobile_number: this.mobileNo,
      address: this.address,
      city: this.city,
      state: this.state,
      zip_code: this.zip,
      need_hotel: this.hotelType,
      age_type: age,
      invited_to: selectedEvents,
      guest_of: this.guestOf

    };
    this.guestlistService.addGuest(data).subscribe((result: any) => {
      var res = result.data;
      if (this.eventName == 'Snapshot') {
        this.getGuestList(this.activeUser.id);
        this.getGuestCount(this.activeUser.id);
        this.getEventGuestList();
      } else {
        // this.resetGuest(2);
        let countData = {
          wedding_id: this.activeUser.id,
          event_id: this.eventData.id,
        };
        var guestCountList: any = this.guestlistService.getEventGuestCount(countData).toPromise();
        this.eventWiseGuestCount = guestCountList.data;

      }
      // this.resetAddGuest();
      if (type == 1) {
        $('#addGuest').modal('toggle');
      }

      this._toastr.success('Guest(s) added successfully');

    },
      error => {
        this._toastr.error(error.message);
      });
  }
  addNewRow() {
    var item = {
      fname: '',
      lname: "",
      age: 1
    }
    this.guests.push(item);
  }
  removeGuest(item: any) {
    this.guests.splice(this.guests.indexOf(item), 1);
  }

  async getRelationshipList(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var relationshipList = await this.guestlistService.getRelationshipList(data).toPromise();
    this.allRelationship = relationshipList.data;
    const uniqueData = this.allRelationship.reduce((finalData, currentData) => {
      let obj = finalData.find((item) => item.relationship_name == currentData.relationship_name);
      if (obj) {
        return finalData;
      }
      return finalData.concat([currentData]);
    }, []);
    this.allRelationship = uniqueData;
  }
  clickHotel(hotel: any, i: number) {
    this.hotelType = hotel.id;
    this.selectHotel = [];
    this.selectHotel[i] = !this.selectHotel[i];
  }
  editClickHotel(hotel: any, i: number) {
    this.editHotelType = hotel.id;
    this.editSelectHotel = [];
    this.need_Hotel = hotel.id
    // this.editSelectHotel[hotel.id] = !this.editSelectHotel[hotel.id];
  }
  async checkedEvent(events: any) {
    this.events = [];
    events.forEach((o: any, i: any) => {
      var createData = {
        id: o.id,
        event_name: o.event_name,
        isChecked: false,
        wedding_id: o.wedding_id,
        is_seating_chart: o.is_seating_chart,
        is_track_meals: o.is_track_meals
      };
      this.events.push(createData);
    });
  }

  showAddGuestModal() {
    this.events[0]['isChecked'] = true;
    // if (this.eventName == 'Snapshot') {
    //   this.events[0]['isChecked'] = true;
    // } else {
    //   const index = this.events.findIndex((e) => e.id == this.eventData.id);
    //   this.events[index]['isChecked'] = true;
    //   console.log(this.events)
    $('#addGuest').modal('toggle');
    // }
  }

  downloadTemplate() {
    this.templateUrl = location.origin + '/assets/template/guest_template.csv';
    this.router.navigate([]).then(result => { window.open(this.templateUrl, 'download'); });

  }
  async getGuestCount(weddingId: any) {
    let data = {
      wedding_id: weddingId,
    };
    var gaustCount = await this.guestlistService.getGuestCount(data).toPromise();
    this.gaustCount = gaustCount.data;
  }
  fileChangeEvent(event: any) {
    let file: File = event.target.files[0];
    this.fileName = event.target.files[0].name;
    this.fileType = event.target.files[0].type;
    if (file && file.lastModified) {
      let reader = new FileReader();
      reader.onload = (event: any) => {
        let submitedFile = {
          wedding_id: this.activeUser.id,
          file_name: file.name,
          file_extension: file.name.split('.').pop().toLowerCase(),
          csv_file: event.target.result.split(',').pop(),

        };
        this.guestlistService.guestImport(submitedFile).subscribe(
          (res: any) => {
            if (this.eventName == 'Snapshot') {
              this.getGuestList(this.activeUser.id);
              this.getGuestCount(this.activeUser.id);
            } else {
              // this.resetGuest(2);
              let countData = {
                wedding_id: this.activeUser.id,
                event_id: this.eventData.id,
              };
              var guestCountList: any = this.guestlistService.getEventGuestCount(countData).toPromise();
              this.eventWiseGuestCount = guestCountList.data;
            }
            this.resetAddGuest();
            $('#addGuest').modal('toggle');
            this._toastr.success('Guest(s) imported successfully');

          },
          (err) => {
            console.error(err);
            this._toastr.error(err.message);
          }

        );

      };
      reader.readAsDataURL(file);
    }
  }

  downloadPDFModal() {
    $('#downloadPDF').modal('toggle');
    this.showPdf = true;
    for (let index = 0; index < this.tableList.length; index++) {
      if (this.tableList[index].properties.left) {
        document.getElementById(this.tableList[index].name).style.top = (this.tableList[index].properties.top + 200) + 'px';
        document.getElementById(this.tableList[index].name).style.top = (this.tableList[index].properties.top + 200) + 'px';
      }
    }
  }

  chartDownload() {
    this.chartDownloadPdf = true;
    this.listDownloadPdf = false;
    this.chartSectionArea = true;
    this.listSectionArea = false;
  }

  listDownload() {
    this.listDownloadPdf = true;
    this.chartDownloadPdf = false;
    this.listSectionArea = true;
    this.chartSectionArea = false;
  }

  ChartSection() {
    this.chartSectionArea = true;
    this.listSectionArea = false;
    this.showCollapse();
  }

  listSection() {
    this.listSectionArea = true;
    this.chartSectionArea = false;
    this.HideCollapse();
  }
  downloadChartPdf() {
    // console.log(this.venueName, this.yourName, this.partnerName)
    this.addVenueName = this.venueName;
    this.addYoureName = this.yourName;
    this.addPartnerName = this.partnerName;
    if (this.yourName == null || this.yourName == '' && this.partnerName == null || this.partnerName == '') {
      this.partnersName = false;
    } else {
      this.partnersName = true;
    }

    var data = document.getElementById("pdfTable");
    // const pdfTable = this.pdfTable.nativeElement;
    // const doc = new jsPDF("p", "mm", "a4");

    // let _elementHandlers =  
    // {  
    //   '#editor':function(element,renderer){  
    //     return true;  
    //   }  
    // };  

    // console.log(pdfTable);

    // doc.fromHTML(pdfTable.innerHTML, 15, 15, {
    //   width: 190,
    //   elementHandlers : _elementHandlers  
    // },res =>
    // {doc.save('tableToPdf.pdf');},
    // );
    html2canvas(data).then(canvas => {
      const contentDataURL = canvas.toDataURL("image/png");
      let pdf = new jspdf("p", "mm", "a4");
      // const imgProps= pdf.getImageProperties(contentDataURL);
      var imgWidth = 210;
      const pdfHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.setFontSize(10);
      pdf.setTextColor(150);
      // pdf.text('Page ' + 1 + ' of ' + 1, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight() + 500);
      var position = 0;
      pdf.addImage(contentDataURL, "PDF", 0, position, imgWidth, pdfHeight);
      pdf.save("Print_Plan.pdf"); // Generated PDF
    });


    $('#downloadPDF').modal('toggle');
    this.showPdf = false;
    setTimeout(() => {
      this.getTableList();
    }, 100);
    this.getEventGuestList()

  }
  overlay(id) {
    this.tableSeatValue = [];
    var divValue = document.getElementById(id).children;
    for (let index = 1; index < divValue.length - 1; index++) {
      const tableValue = divValue[index].children[0].children[0];
      if (tableValue != undefined) {
        const tableDataValue = divValue[index].children[0].children[0].children[1].innerHTML;
        this.tableSeatValue.push(tableDataValue);
        this.uniqueChars = [...new Set(this.tableSeatValue)];

        // console.log(index, divValue[index].children[0].children[0].children[1].innerHTML);
      } else {
        // this.tableSeatValue.push('NA');
      }
      // console.log(this.tableSeatValue)
    }
    return this.uniqueChars
  }

  // add table 

  createTable(data) {
    this.seatingChartService.createTable(data).subscribe(res => {
      this.getTableList();
      this.getEventGuestList()
    }, error => {
      this._toastr.error(error.message);
    })
  }

  // get table list
  async getTableList() {
    let data = {
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
    }
    this.seatingChartService.getTableList(data).subscribe(res => {
      this.tableList = res.data;
      this.table6Seat = this.tableList.filter((e) => (e.type == 'rect6seat' || e.type == "rectNoSeat"))
      for (let index = 0; index < this.table6Seat.length; index++) {
        this.table6Seat[index].properties = JSON.parse(this.table6Seat[index].properties);

        setTimeout(() => {
          if (this.table6Seat[index].properties.left) {
            document.getElementById(this.table6Seat[index].name).style.position = 'absolute';
            document.getElementById(this.table6Seat[index].name).style.left = this.table6Seat[index].properties.left + 'px';
            document.getElementById(this.table6Seat[index].name).style.top = this.table6Seat[index].properties.top + 'px';
          }
        }, 10);
      }

      this.table4Seat = this.tableList.filter((e) => (e.type == 'rectangle4Seat' || e.type == "rectNoSeat"))
      for (let index = 0; index < this.table4Seat.length; index++) {
        this.table4Seat[index].properties = JSON.parse(this.table4Seat[index].properties);

        setTimeout(() => {
          if (this.table4Seat[index].properties.left) {
            document.getElementById(this.table4Seat[index].name).style.position = 'absolute';
            document.getElementById(this.table4Seat[index].name).style.left = this.table4Seat[index].properties.left + 'px';
            document.getElementById(this.table4Seat[index].name).style.top = this.table4Seat[index].properties.top + 'px';
          }
        }, 10);
      }
      this.table3Seat = this.tableList.filter((e) => e.type == 'rect3seat')
      for (let index = 0; index < this.table3Seat.length; index++) {
        this.table3Seat[index].properties = JSON.parse(this.table3Seat[index].properties);
        setTimeout(() => {
          if (this.table3Seat[index].properties.left) {
            document.getElementById(this.table3Seat[index].name).style.position = 'absolute';
            document.getElementById(this.table3Seat[index].name).style.left = this.table3Seat[index].properties.left + 'px';
            document.getElementById(this.table3Seat[index].name).style.top = this.table3Seat[index].properties.top + 'px';
          }
        }, 10);
      }

      this.tableSquareSeat = this.tableList.filter((e) => e.type == 'square8seat')
      for (let index = 0; index < this.tableSquareSeat.length; index++) {
        this.tableSquareSeat[index].properties = JSON.parse(this.tableSquareSeat[index].properties);
        setTimeout(() => {
          if (this.tableSquareSeat[index].properties.left) {
            document.getElementById(this.tableSquareSeat[index].name).style.position = 'absolute';
            document.getElementById(this.tableSquareSeat[index].name).style.left = this.tableSquareSeat[index].properties.left + 'px';
            document.getElementById(this.tableSquareSeat[index].name).style.top = this.tableSquareSeat[index].properties.top + 'px';
          }
        }, 10);
      }

      this.tableCircleeSeat = this.tableList.filter((e) => (e.type == 'circleSeat' || e.type == "circleNoSeat"))
      for (let index = 0; index < this.tableCircleeSeat.length; index++) {
        this.tableCircleeSeat[index].properties = JSON.parse(this.tableCircleeSeat[index].properties);
        setTimeout(() => {
          if (this.tableCircleeSeat[index].properties.left) {
            document.getElementById(this.tableCircleeSeat[index].name).style.position = 'absolute';
            document.getElementById(this.tableCircleeSeat[index].name).style.left = (this.tableCircleeSeat[index].properties.left) + 'px';
            document.getElementById(this.tableCircleeSeat[index].name).style.top = (this.tableCircleeSeat[index].properties.top) + 'px';
          }
        }, 10);
      }

      this.tableGenericSeats = this.tableList.filter((e) => e.type == 'genericTable')
      for (let index = 0; index < this.tableGenericSeats.length; index++) {
        this.tableGenericSeats[index].properties = JSON.parse(this.tableGenericSeats[index].properties);
        setTimeout(() => {
          if (this.tableGenericSeats[index].properties.left) {
            document.getElementById(this.tableGenericSeats[index].name).style.position = 'absolute';
            document.getElementById(this.tableGenericSeats[index].name).style.left = this.tableGenericSeats[index].properties.left + 'px';
            document.getElementById(this.tableGenericSeats[index].name).style.top = this.tableGenericSeats[index].properties.top + 'px';
          }
        }, 10);
      }
      setTimeout(() => {
        const ids = this.tableList.map(object => {
          // var right = document.getElementById(object.name).getBoundingClientRect().right;
          var parentPosition = document.getElementById('pdfTable').getBoundingClientRect();
          var right = (document.getElementById(object.name).getBoundingClientRect().right + 62) - parentPosition.left - 50
          return right;
        });
        const max = Math.max(...ids);
        if (max >= document.getElementById('pdfTable').getBoundingClientRect().width) {
          document.getElementById('pdfTable').style.width = 'calc(' + max + 'px' + ' + 100px)';
        } else {
          if (max <= 992) {
            document.getElementById('pdfTable').style.width = '100%';
          } else {
            document.getElementById('pdfTable').style.width = 'calc(' + max + 'px' + ' + 100px)';
          }
        }
      }, 500);
    }, error => {
      this._toastr.error(error.message);
    })

  }

  async getEventGuestList() {
    let guestData = {
      wedding_id: this.activeUser.id,
      event_id: this.eventId,
    }
    this.seatingChartService.getEventGuestList(guestData).subscribe(res => {
      this.unassignGuestList = res.data
      this.EventGuestList = res.data;
      setTimeout(() => {
        for (let index = 0; index < this.EventGuestList.length; index++) {
          for (let j = 0; j < this.tableList.length; j++) {
            if (this.EventGuestList[index].table_id) {
              if (this.tableList[j].id == this.EventGuestList[index].table_id) {
                // this.tableList[j].properties.seats[this.EventGuestList[index].seat_number].push(
                //   {seat_number:this.EventGuestList[index].table_id,
                //   guestId:this.EventGuestList[index].id
                //   })
                this.tableList[j].properties.seats[this.EventGuestList[index].seat_number].seat_number = this.EventGuestList[index].seat_number
                this.tableList[j].properties.seats[this.EventGuestList[index].seat_number].guest_id = this.EventGuestList[index].id
                this.tableList[j].properties.seats[this.EventGuestList[index].seat_number].first_name = this.EventGuestList[index].first_name
                this.tableList[j].properties.seats[this.EventGuestList[index].seat_number].last_name = this.EventGuestList[index].last_name
                this.tableList[j].properties.seats[this.EventGuestList[index].seat_number].attendance = this.EventGuestList[index].attendance
                this.tableList[j].properties.seats[this.EventGuestList[index].seat_number].relationship_name = this.EventGuestList[index].relationship_name
              }
            }

          }
        }
      }, 500)

      // this.EventGuestList = this.EventGuestList.filter((data, index) => {
      //   var uniqueChars = JSON.stringify(data.first_name && data.last_name);
      //   return index === this.EventGuestList.findIndex(obj => {
      //     return JSON.stringify(obj.first_name && obj.last_name) === uniqueChars;
      //   })
      // })
      this.allGuest = this.EventGuestList.filter((e) => e.table_id == null || e.table_id == undefined || e.table_id == '');
      this.allGuest = this.allGuest.filter((e) => e.attendance != 'Cancelled')
      var grouped = this.groupBy(this.allGuest, false, (relation: { relationship_name: any; }) => relation.relationship_name);
      this.allGuest = this.mapEntries(grouped);
      //  this.allGuest = this.allGuest.forEach(e => { 
      //   console.log(e[1]);
      //   e[1].filter((data) => data.item.table_id == null || data.item.table_id == undefined || data.item.table_id == '');        
      //  });
    }, error => {
      this._toastr.error(error.message);
    })
  }

  UpdateTable(tableEditData) {
    this.seatingChartService.editTableApi(tableEditData).subscribe(res => {
      // this._toastr.success('Table updated ');
      this.isLoader = false;
      setTimeout(() => {
        this.getTableList();
      }, 100);
      this.getEventGuestList()
      $('#addTable').modal('hide');
    }, error => {
      this._toastr.error(error.message);
    })
  }

  assignSeats(data) {
    this.seatingChartService.assignSeats(data).subscribe(res => {
      this._toastr.success('Table updated');
      this.getTableList();
      this.getEventGuestList();

    }, error => {
      this._toastr.error(error.message);
    })
  }

  unassignSeats(unassignData) {
    this.seatingChartService.unassignSeats(unassignData).subscribe(res => {
      // this._toastr.success('Table updated Successfully.');
    }, error => {
      this._toastr.error(error.message);
    })
  }

}



