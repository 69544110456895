import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { VendorBusinessInfoModel } from 'src/app/models/vendor.business.info.model';
import { BusinessFormBuilder } from './business.form-builder';

declare var $: any;

@Component({
  selector: 'app-business-info',
  templateUrl: './business-info.component.html',
  styleUrls: ['./business-info.component.css']
})
export class BusinessInfoComponent implements OnInit {

  submitted = false;
  model = new VendorBusinessInfoModel();
  businessForm: any;
  userID: string;
  desclength=0;
  profileUrl : string;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: BusinessFormBuilder,
    private toastr:ToastrService,
    public router: Router) {
  }
  ngOnInit() {
    this.businessForm = this.formBuilder.getForm();    
    this.getBusinessInfo();
    $('form, input,textarea, select').attr('autocomplete', 'nope');
    window.scroll(0,0);
    
  }

  get f() { return this.businessForm.controls; }

  onSubmit() {

    this.submitted = true;
    // stop here if form is invalid
    
    if($("#business_description").val().length<300 || $("#business_description").val().length>2000)
    {
      this.toastr.error("Description must be 300 to 2,000 characters");
      return;
    }
    
    if (this.businessForm.invalid) {
      return;
    }
    

    this.model.deserialize(this.businessForm.value);

    this.vendorService
      .updateBusinessInfo(this.model)
      .subscribe(
        (response:VendorBusinessInfoModel) => {          
          this.toastr.success(response.message); 
          return false;
        },
        error => {
          this.toastr.error(error.message);
          console.log(error.message);
        }
      )
  }
  
  getBusinessInfo(){
    this.vendorService
      .getBusinessInfo()
      .subscribe(
        (response:VendorBusinessInfoModel) => {
          this.formBuilder.setValues(response);
          this.profileUrl = Config.baseUrl+"/profile/"+response.slug;
          if(response.business_description){
            this.desclength = response.business_description.length;
          } 
        }
      ) 
  }

  descLengthCount(){
    this.desclength = $("#business_description").val().length;
  }

}
