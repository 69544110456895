import { Component, OnInit, Input, HostBinding, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { VendorsService } from '../services/vendors.service';
import { ActivatedRoute } from '@angular/router';
import { Config } from 'src/app/config';

@Component({
  selector: 'app-check-availability',
  templateUrl: './check-availability.component.html',
  styleUrls: ['./check-availability.component.css']
})
export class CheckAvailabilityComponent implements OnInit {

  numberOfMonths:any = 2;
  months: any;
  firstDate: any;
  lastDate: any;
  firstDateYear:number;
  lastDateYear:number;
  vendorId: number;
  args: any;
  currentDate = new Date();
  datesInAllMonthsInDisplay:any;

  @HostBinding('class.show-avilability-calendar') @Input() showCalendar:boolean;
  @Output() close:EventEmitter<boolean> = new EventEmitter();

  constructor(
    private vendorService: VendorsService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
  }

  loadInit(){
    let currentDate = new Date();
    this.fillMonthDates(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1));
  }

  fillMonthDates(startDate:any){
    this.months = [];
    this.datesInAllMonthsInDisplay = [];
    let counter = new Date(startDate);
    for(let monthIndex = 0; monthIndex < this.numberOfMonths; monthIndex++){
      let monthStart = new Date(counter);
      let monthEnd = new Date(monthStart.getFullYear(), monthStart.getMonth()+1, 0);
      let displayStart = new Date(monthStart);
      let displayEnd = new Date(monthEnd);
      if(displayStart.getDay() != 1){
        displayStart.setDate(displayStart.getDate() - (displayStart.getDay()==0?6:displayStart.getDay()-1));
      }
      if(displayEnd.getDay() != 0){
        displayEnd.setDate(displayEnd.getDate() + (displayEnd.getDay()==1?6:7-displayEnd.getDay()));
      }
      let numberOfDaysDisplay = (displayEnd.getTime() - displayStart.getTime()) / (1000 * 3600 * 24); 
      displayEnd.setDate(displayEnd.getDate() + (41-numberOfDaysDisplay));

      let monthStartData = {items:[], start:monthStart, end:monthEnd};
      this.months.push(monthStartData);

      for(let i=new Date(displayStart); i<=displayEnd; i.setDate(i.getDate() + 1)){
        let dateStr = i.toDateString();        
        let available = false;        
        if(!this.datesInAllMonthsInDisplay[dateStr]){
          this.datesInAllMonthsInDisplay[dateStr] = {date:new Date(i),available:available};
        }
        this.months[monthIndex].items.push(this.datesInAllMonthsInDisplay[dateStr]);
      }
      counter.setDate(monthEnd.getDate() + 1);
    }

    if(this.months.length>0){
      this.firstDate = this.months[0].start;
      this.lastDate = this.months[this.months.length-1].end;
    }
    this.firstDateYear = this.firstDate.getFullYear();
    this.lastDateYear = this.lastDate.getFullYear();
    this.getBookingsByDateRange(this.vendorId,this.firstDate,this.lastDate);
  }

  showPreviousMonth(){
    let targetStart = this.firstDate;
    targetStart.setDate(targetStart.getDate() - 1);
    targetStart = new Date(targetStart.getFullYear(), targetStart.getMonth(), 1);
    this.fillMonthDates(targetStart);
  }

  showNextMonth(){
    let targetStart = this.months[0].end;
    targetStart.setDate(targetStart.getDate() + 1);
    this.fillMonthDates(targetStart);
    
  }

  showPreviousYear(){
    let targetStart = new Date(this.firstDate.getFullYear() -1, this.firstDate.getMonth(), 1);
    this.fillMonthDates(targetStart);
  }

  showNextYear(){
    let targetStart = new Date(this.firstDate.getFullYear() + 1, this.firstDate.getMonth(), 1);
    this.fillMonthDates(targetStart);
  }

  getBookingsByDateRange(vendor_id:number,startDate:string,endDate:string) {
    this.vendorService
      .getBookingsByDateRange({ vendor_id: vendor_id, startDate: this.usDateToDb(startDate), endDate: this.usDateToDb(endDate) })
      .subscribe(
        (response: any) => {
          for(let day in this.datesInAllMonthsInDisplay){
            let dayItem = this.datesInAllMonthsInDisplay[day];
            if(dayItem.date<this.currentDate){
              dayItem.available = false;
            }else if(response.available_week_days.indexOf(Config.daysArray[dayItem.date.getDay()].id) < 0){
              dayItem.available = false;
              //console.log(day, dayItem.date, dayItem.date.getDay(), Config.daysArray[dayItem.date.getDay()].id);
            }else{
              dayItem.available = true;
            }
          }
          for(let i=0; i<response.data.length; i++){
            let data = response.data[i];
            // let dateStr = new Date(data.booking_date_time +'T00:00:00').toDateString();
            let dateStr = new Date(data.booking_date_time).toDateString();            
            if(data.block_count>0){
              this.datesInAllMonthsInDisplay[dateStr].available = false;
            }else if(data.total_booking>=response.booking_per_day && response.auto_blocked){
              this.datesInAllMonthsInDisplay[dateStr].available = false;
            }
          }
          
        },
        error => {
          this.toastr.error(error.message);
        }
      );
  }

  closeMe(){
    this.close.emit(true);
  }

  usDateToDb(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

}
