import { Component, OnInit } from '@angular/core';
import { VendorsService } from '../services/vendors.service';
import { Config } from '../../../config';
declare var $: any;

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})

export class ReviewsComponent implements OnInit {
  user_id: number;
  reviews: any[] = [];
  ratings: number[] = [1, 2, 3, 4, 5];
  current_page: number = 1;
  pagination_items: any[] = [];
  total_reviews: number = 0;
  photo_url: string = Config.imageUrl;
  lightBoxCurrentImageIndex: number = 0;
  lightBoxImages: any[] = [];
  lightBoxImage: any;
  replyMessage: any;
  selected_reply: any;
  selected_review: any;
  update_reply_message: string;

  constructor(private vendorService: VendorsService) { }

  ngOnInit() {
    this.getAllReviews();
  }
  getAllReviews(){
    this.vendorService.getAllVendorReviews(this.current_page).subscribe((response:any) => {
      this.reviews = response.data.reviews;
      this.user_id = response.data.vendor_id;
      console.log(this.reviews);
      for (let index = 0; index < this.reviews.length; index++) {
        if(!this.reviews[index].replyMessage){
          this.reviews[index].replyMessage='';
        }
      }
      this.total_reviews = response.data.total_review;
      this.pagination_items = this.pagination(this.current_page, Math.ceil( this.total_reviews / 10));
    }, error => {
      console.log(error.message);
    });
  }
  getReviewTime(date: string){
    return new Date(date).toDateString();
  }
  goPrev() {
    this.current_page = this.current_page - 1;
    this.getAllReviews();
  }
  goNext() {
    this.current_page = this.current_page + 1;
    this.getAllReviews();
  }
  goToPage(pageItem: any) {
    this.current_page = Number(pageItem);
    this.getAllReviews();
  }
  pagination(current: number, last: number, delta = 2) {
    if (last === 1) return ["*" + 1 + "*"];

    const left = current - delta,
      right = current + delta + 1,
      range = [];

    if (last > 1 && current !== 1) {
      range.push("<");
    }

    for (let i = 1; i <= last; i++) {
      if (i == 1 || i == last || (i >= left && i < right)) {
        if (i === left && i > 2) {
          range.push("...");
        }

        if (i === current) {
          range.push("*" + i + "*");
        } else {
          range.push(i);
        }

        if (i === right - 1 && i < last - 1) {
          range.push("...");
        }
      }
    }

    if (last > 1 && current !== last) {
      range.push(">");
    }

    return range;
  }
  getPageItemText(pageItem: any) {
    if (isNaN(pageItem)) {
      if (pageItem.indexOf('*') === 0) {
        return pageItem.substr(1, (pageItem.length - 2));
      }
    } else {
      return pageItem;
    }
  }
  isNumber(value: any) {
    return !isNaN(value);
  }
  openReviewLighbox(imgs: any[], index: number){
    this.lightBoxCurrentImageIndex = index;    
    for (let index = 0; index < imgs.length; index++) {
      this.lightBoxImage =  imgs[index].image_name;
      this.lightBoxImages.push(this.lightBoxImage);
    }
    this.lightBoxImage = this.lightBoxImages[index];
  }
  storyPlusSlides() {
    if(this.lightBoxCurrentImageIndex < this.lightBoxImages.length - 1){
      this.lightBoxCurrentImageIndex = this.lightBoxCurrentImageIndex + 1
      this.lightBoxImage = this.lightBoxImages[this.lightBoxCurrentImageIndex];
    }
  }
  storyMinussSlides() { 
    if(this.lightBoxCurrentImageIndex > 0){
      this.lightBoxCurrentImageIndex = this.lightBoxCurrentImageIndex - 1
      this.lightBoxImage = this.lightBoxImages[this.lightBoxCurrentImageIndex];
    }
  }
  sendReply(reviewData: any){
    var data={
      review_id: reviewData.id,
      reply_message: reviewData.replyMessage
    };
    this.vendorService.submitReviewReply(data).subscribe((response:any) => {
      let resReview = response;
      this.reviews = this.reviews.map((eachReview)=>{
        if(eachReview.id === resReview.id){
          return resReview;
        }else{
          return eachReview;
        }
      });
    }, error => {
      console.log(error.message);
    });
  }
  getNow(){
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    return ( year + "-" + ((month > 9) ? '' + month : '0' + month) + "-" + ((day > 9) ? '' + day : '0' + day)) + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  }
  getRelateiveTime(datetime: string) {
    var msPerMinute: number = 60 * 1000;
    var msPerHour: number = msPerMinute * 60;
    var msPerDay: number = msPerHour * 24;
    var msPerMonth: number = msPerDay * 30;
    var msPerYear: number = msPerDay * 365;
    var currentDate: number = Date.parse(this.getNow());
    var previousDate: number  = Date.parse(datetime);
    var elapsed: number = currentDate - previousDate;
    if (elapsed < msPerMinute) {
        return Math.round(elapsed/1000) + ' seconds ago';   
    }else if (elapsed < msPerHour) {
        return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }else if (elapsed < msPerDay ) {
        return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }else if (elapsed < msPerMonth) {
        return Math.round(elapsed/msPerDay) + ' days ago';   
    }else if (elapsed < msPerYear) {
        return Math.round(elapsed/msPerMonth) + ' months ago';   
    }else {
        return Math.round(elapsed/msPerYear ) + ' years ago';   
    }
  }
  deleteReply(reply: any, review: any){
    this.selected_review = review;
    this.selected_reply = reply;
    $('#confirmDeleteReplyModal').modal('show');
  }
  confirmDeleteReply(){
    let formData = {
      review_id: this.selected_review.id,
      reply_id: this.selected_reply.id
    };
    this.vendorService.deleteReviewReply(formData).subscribe((response:any) => {
      let resReview = response;
      this.reviews = this.reviews.map((eachReview)=>{
        if(eachReview.id === resReview.id){
          return resReview;
        }else{
          return eachReview;
        }
      });
    }, error => {
      console.log(error.message);
    });
  }
  EditReply(reply: any, review: any){
    this.selected_review = review;
    this.selected_reply = reply;
    this.update_reply_message = this.selected_reply.message;
    $('#confirmEditReplyModal').modal('show');
  }
  updateReply(){
    let formData = {
      review_id: this.selected_review.id,
      reply_id: this.selected_reply.id,
      message: this.update_reply_message
    };
    this.vendorService.updateReviewReply(formData).subscribe((response:any) => {
      let resReview = response;
      this.reviews = this.reviews.map((eachReview)=>{
        if(eachReview.id === resReview.id){
          return resReview;
        }else{
          return eachReview;
        }
      });
    }, error => {
      console.log(error.message);
    });
  }
  parseInt(value: number){
    return Number.parseInt(value + "");
  }
}
