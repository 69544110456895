import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { modelGroupProvider } from '@angular/forms/src/directives/ng_model_group';
import { catchError } from 'rxjs/operators';
import { AuthResponseModel } from 'src/app/models/auth-response.model';
import { UsersService } from '../../users/services/users.service';
import { Config } from '../../../config';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PlaceholdersService } from '../../cms/services/placeholders.service';
import { VendorsService } from '../services/vendors.service';
import { EmployeeModel } from 'src/app/models/employee.model';
import { EmployeeFormBuilder } from './employee.form-builder';

declare var $: any;
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

  submitted = false;
  model = new EmployeeModel();
  employeeForm: any;
  employees: any;
  employee_id = 0;
  eid = 0;
  title = "Add New User";
  button = "Create User";
  hideResult = true;
  access = localStorage.getItem("role");
  parent_id = localStorage.getItem("parent_id");
  UID = localStorage.getItem("UID");
  employeeUserType = [];
  searchParams = { "role": "", "uid": "" };
  inactive = false;
  pushNotification = 0;
  allowAccess = true;
  showForm = false;

  employeeCurrentPage: number = 1;
  pagination_per_Page: number = Config.pagination_per_Page;

  constructor(private usersService: UsersService,
    private vendorService: VendorsService,
    private formBuilder: EmployeeFormBuilder,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    public router: Router) {
  }
  ngOnInit() {
    this.employeeForm = this.formBuilder.getForm();
    this.resetForm();
    this.employeeUserType = Config.employeeRole;
    this.showForm = true;
    if (this.access == "Vendor Manager") {
      this.employeeUserType = ["User"];
    } else if (this.access == "Vendor User") {
      this.employeeUserType = [];
      this.button = "Save User";
      this.allowAccess = false;
      this.hideResult = false;
      this.showForm = false;
    }
    this.searchParams["role"] = this.access;
    this.searchParams["uid"] = this.UID;
    
    
    this.getEmployees(this.searchParams);
    window.scroll(10, 10);

  }

  get f() { return this.employeeForm.controls; }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.employeeForm.invalid) {
      return;
    }
    //console.log(this.employeeForm.get('user_type').value);

    if (this.employeeForm.get('user_type').value == 'Manager') {
      this.model.role_id = Config.managerRole;
      this.model.user_type = 'Manager';
    } else if (this.employeeForm.get('user_type').value == 'User') {
      this.model.role_id = Config.userRole;
      this.model.user_type = 'User';
    } else if (this.employeeForm.get('user_type').value == 'Vendor') {
      this.model.role_id = Config.vendorRole;
      this.model.user_type = '';
    }
    this.model.created_by = this.UID;
    this.model.modify_by = this.UID;

    this.model.deserialize(this.employeeForm.value);
    this.model.manageBy = this.access;
    this.vendorService
      .saveEmployee(this.model)
      .subscribe(
        (response: EmployeeModel) => {
          this.toastr.success(response.message);
          this.getEmployees(this.searchParams);
          this.resetForm();
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  getEmployees(params) {
    this.vendorService
      .getEmployees(params)
      .subscribe(
        (response: EmployeeModel[]) => {
          for (let i = 0; i < response.length; i++) {
            if (response[i].parent_account_id == 0) {
              response[i].user_type = "Vendor Admin";
            }
          }
          this.employees = response;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  getEmployee(id: any) {
    if(!this.allowAccess){
      this.showForm = true;
    }
    $("#user_type option[value='Vendor']").remove();
    this.vendorService
      .getEmployee(id)
      .subscribe(
        (response: EmployeeModel) => {
          this.employeeForm.controls['user_type'].enable();
          this.formBuilder.setValues(response);
          this.title = "Edit User";
          this.button = "Save User";
          /* if(id==this.UID){
            this.pushNotification = true;
          }else{
            this.pushNotification = false;
          } */
          
          if (response.parent_account_id == 0) {
            //this.employeeUserType = ['Vendor'];            
            $('#user_type').append('<option value="Vendor">Vendor Admin</option>'); 
            this.employeeForm.get('user_type').setValue("Vendor");

          } else if (response.user_type == "Manager") {
            this.employeeUserType = ["Manager", "User"];
            this.employeeForm.get('user_type').setValue("Manager");

          } else if (response.user_type == "User") {
            this.employeeUserType = ["User"];
            this.employeeForm.get('user_type').setValue("User");
          }
          
          this.employeeForm.controls['user_type'].disable();
          window.top.scroll(10, 10);
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }

  deleteEmployeeModal(id: number) {
    this.eid = id;
    $("#deleteemployee").modal();
  }

  deleteEmployee(eid: any) {
    this.vendorService
      .deleteEmployee(eid)
      .subscribe(
        (response: EmployeeModel) => {
          $('#deleteemployee').modal('toggle');
          this.toastr.success(response.message);
          this.getEmployees(this.searchParams);
          return false;
        },
        error => {
          this.toastr.error(error.message);
        }
      )
  }
  resetForm() {
    this.submitted = false;
    this.title = "Add New User";
    this.button = "Create User";
    //$(".panel-body").find("input,textarea,select").val('');
    $("#user_type option[value='Vendor']").remove();
    this.employeeForm.controls['user_type'].enable();
    this.employeeUserType = Config.employeeRole;
    if (this.access == "Vendor Manager") {
      this.employeeUserType = ["User"];
      this.hideResult = false;
    } else if (this.access == "Vendor User") {
      this.employeeUserType = [];
      this.hideResult = false;
    }
    this.employeeForm.reset();
    if(!this.allowAccess){
      this.showForm = false;
    }
    window.scroll(10, 10);
  }  

}
