import { Deseriazable } from './deserializable.model';

export class AvailabilityModel implements Deseriazable{
    
    id: number;
    token: string;
    booking_per_day: string;
    available_week_days: any;
    goal_year: number;
    auto_blocked: number;
    fname: string;
    lname: string;
    email: string;
    phone: string;
    start_hour: string;
    event_length: string;
    event_type_id: string;
    note: string;
    region_id: number;
    city_id: string;
    is_block: string;
    is_fullday: string;
    booking_date_time: string;
    message: string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}