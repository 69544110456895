import { Deseriazable } from './deserializable.model';

export class LocationModel implements Deseriazable{
    
    id: string;
    name: string;
    region_id: string;
    state_id:string;


    deserialize(input: any): this {
        Object.assign(this,input);
        return this;
    }

}